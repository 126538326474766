import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import ShiiteSitesArchive from "../components/ShiiteSitesArchive";
import ShiitesDispersionArchive from "../components/ShiitesDispersionArchive";

const ShiitesDispersion = () => {
    return (
        <div>
            <HeaderPage />
            <ShiitesDispersionArchive />
            <Footer />
        </div>
    );
};

export default ShiitesDispersion;
