import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import GuidedSayingsTextPageEl from "../components/GuidedSayingsTextPageEl";
import ContributeTextPage from "../components/ContributeTextPage";

const ContributePage = () => {
    return (
        <div>
            <HeaderPage />
            <ContributeTextPage />
            <Footer />
        </div>
    );
};

export default ContributePage;
