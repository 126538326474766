import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import SearchTextPage from "../components/SearchTextPage";
import CategoryTextPage from "../components/CategoryTextPage";
let login = true;

const Category = () => {
    return (
        <div>
            <HeaderPage />
            <CategoryTextPage />
            <Footer />
        </div>

    );
};
export default Category;
