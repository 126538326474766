import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import ContributeArchive from "../components/ContributeArchive";
import GuidedSayingsArchive from "../components/GuidedSayingsArchive";

const GuidedSayings = () => {
    return (
        <div>
            <HeaderPage />
            <GuidedSayingsArchive />
            <Footer />
        </div>
    );
};

export default GuidedSayings;
