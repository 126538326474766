import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import SearchTextPage from "../components/SearchTextPage";
let login = true;

const Search = () => {
    return (
        <div>
            <HeaderPage />
            <SearchTextPage />
            <Footer />
        </div>

    );
};
export default Search;
