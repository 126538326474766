import {NavLink, useNavigate} from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <div className="flex-2-fix">
            <div className="body not-found-page-404">
                <div className="container-text-not-f-404">
                    <div className="content-p-404">
                        <span className="text-404">404</span>
                        <span>لم يتم العثور على الصفحة المطلوبة</span>
                    </div>
                    <div className="content-p-404-last mt-5">
                        <button className="link-to-home-page link-to-home-page-f" onClick={() => navigate(-1)}>
                            <svg className="ms-2" width="29" height="23" viewBox="0 0 29 23" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M19.246 18.3778C19.3365 18.3784 19.4262 18.361 19.5099 18.3268C19.5937 18.2926 19.6699 18.2422 19.7341 18.1784L25.9216 11.9909C25.9861 11.927 26.0372 11.851 26.0721 11.7672C26.107 11.6834 26.125 11.5936 26.125 11.5028C26.125 11.4121 26.107 11.3222 26.0721 11.2384C26.0372 11.1547 25.9861 11.0786 25.9216 11.0147L19.7341 4.82719C19.6701 4.76308 19.594 4.71224 19.5101 4.67755C19.4264 4.64285 19.3367 4.625 19.246 4.625C19.1553 4.625 19.0656 4.64285 18.9819 4.67755C18.8981 4.71224 18.822 4.76308 18.7579 4.82719C18.6938 4.89129 18.6429 4.96739 18.6082 5.05114C18.5736 5.1349 18.5557 5.22466 18.5557 5.31531C18.5557 5.40597 18.5736 5.49573 18.6082 5.57948C18.6429 5.66323 18.6938 5.73933 18.7579 5.80344L24.4641 11.5028L18.7579 17.2022C18.6935 17.2661 18.6423 17.3422 18.6074 17.4259C18.5725 17.5097 18.5545 17.5996 18.5545 17.6903C18.5545 17.7811 18.5725 17.8709 18.6074 17.9547C18.6423 18.0385 18.6935 18.1145 18.7579 18.1784C18.8221 18.2422 18.8983 18.2926 18.9821 18.3268C19.0658 18.361 19.1555 18.3784 19.246 18.3778Z"
                                    fill="white" fillOpacity="0.85"/>
                                <path
                                    d="M2.98798 12.1875H25.1995C25.445 12.1875 25.6804 12.115 25.8539 11.9861C26.0275 11.8572 26.125 11.6823 26.125 11.5C26.125 11.3177 26.0275 11.1428 25.8539 11.0139C25.6804 10.885 25.445 10.8125 25.1995 10.8125H2.98798C2.74254 10.8125 2.5071 10.885 2.33357 11.0139C2.16005 11.1428 2.0625 11.3177 2.0625 11.5C2.0625 11.6823 2.16005 11.8572 2.33357 11.9861C2.5071 12.115 2.74254 12.1875 2.98798 12.1875Z"
                                    fill="white" fillOpacity="0.85"/>
                            </svg>

                            العودة للصفحة
                            السابقة
                        </button>
                        <NavLink className="link-to-home-page me-3" to="/">
                            <svg className="ms-1" width="24" height="25" viewBox="0 0 24 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5 13.2555C5 11.8978 5 11.2189 5.27446 10.6221C5.54892 10.0254 6.06437 9.5836 7.09525 8.69998L8.09525 7.84284C9.95857 6.24571 10.8902 5.44714 12 5.44714C13.1098 5.44714 14.0414 6.24571 15.9047 7.84284L16.9047 8.69998C17.9356 9.5836 18.4511 10.0254 18.7255 10.6221C19 11.2189 19 11.8978 19 13.2555V17.4959C19 19.3815 19 20.3243 18.4142 20.9101C17.8284 21.4959 16.8856 21.4959 15 21.4959H9C7.11438 21.4959 6.17157 21.4959 5.58579 20.9101C5 20.3243 5 19.3815 5 17.4959V13.2555Z"
                                    stroke="#737373" strokeWidth="1.25"/>
                                <path
                                    d="M14.5 21.5V16.3125C14.5 15.8638 14.1362 15.5 13.6875 15.5H10.3125C9.86377 15.5 9.5 15.8638 9.5 16.3125V21.5"
                                    stroke="#737373" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            العودة للصفحة
                            الرئيسية
                        </NavLink>
                    </div>
                    <section className="section-footer-page-404">
                        <div className="footer-page-404 d-flex">
                            <div className="social-media-container-footer-404 position-relative hidden-phone">
                                <span>تابعونا على وسائل التواصل الاجتماعي</span>
                            </div>
                            <ul className="social-list social-list-w-21 p-0 mt-1 social-container">
                                <li className="item-1">
                                    <NavLink to="//https://api.whatsapp.com/send?phone=989392532308" title="whatsapp"
                                             rel="nofollow" target="_blank">
                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_1428_7781)">
                                                <path
                                                    d="M12.2813 23.3175C18.5317 23.3175 23.5988 18.2504 23.5988 12C23.5988 5.74956 18.5317 0.682556 12.2813 0.682556C6.03087 0.682556 0.963867 5.74956 0.963867 12C0.963867 18.2504 6.03087 23.3175 12.2813 23.3175Z"
                                                    fill="#045E5E"/>
                                                <path
                                                    d="M12.0252 6.29163C8.73174 6.29163 6.05148 8.87317 6.05032 12.0458C6.04955 13.0604 6.32516 14.0506 6.84774 14.9227L6 17.9045L9.16761 17.1044C10.0487 17.5653 11.0285 17.8057 12.0228 17.805H12.0252C15.3186 17.805 17.9988 15.2231 18 12.0505C18.0008 10.5137 17.3799 9.06711 16.2515 7.97975C15.1235 6.89201 13.6235 6.29201 12.0252 6.29163ZM12.0252 16.833H12.0232C11.1321 16.833 10.2581 16.6023 9.49548 16.1665L9.31355 16.0627L7.43458 16.5373L7.93626 14.7721L7.81819 14.5914C7.32229 13.835 7.0583 12.9502 7.05871 12.0458C7.05987 9.40853 9.288 7.26363 12.0271 7.26363C13.3533 7.26401 14.6001 7.76221 15.5381 8.66608C16.476 9.56995 16.992 10.7719 16.9912 12.0501C16.9901 14.6874 14.7623 16.833 12.0248 16.833H12.0252ZM14.7492 13.2505C14.5997 13.1789 13.8658 12.8309 13.7288 12.7825C13.5921 12.7349 13.4926 12.7101 13.3932 12.8541C13.2941 12.9981 13.0076 13.3221 12.9209 13.4177C12.8334 13.5137 12.7463 13.5253 12.5969 13.4537C12.4475 13.3817 11.9663 13.2299 11.3965 12.7399C10.9525 12.359 10.6529 11.8883 10.5658 11.7439C10.4787 11.6003 10.5565 11.5225 10.6312 11.4509C10.6982 11.387 10.7806 11.2832 10.855 11.1992C10.9293 11.1152 10.9541 11.0552 11.0044 10.9592C11.0539 10.8636 11.0292 10.7792 10.9916 10.7076C10.9541 10.6352 10.656 9.92763 10.531 9.64001C10.4102 9.35975 10.2871 9.3973 10.1954 9.39266C10.1083 9.38879 10.0092 9.38763 9.9089 9.38763C9.81019 9.38763 9.648 9.42363 9.51097 9.56763C9.37432 9.71163 8.98839 10.0592 8.98839 10.7669C8.98839 11.4749 9.52335 12.1585 9.59806 12.2545C9.67277 12.3501 10.651 13.8029 12.1486 14.4261C12.5048 14.5736 12.7827 14.6622 12.9999 14.7288C13.3575 14.8383 13.6831 14.8225 13.9401 14.7857C14.2266 14.7443 14.8235 14.4381 14.9474 14.1025C15.072 13.7669 15.072 13.4789 15.0348 13.4189C14.9985 13.3589 14.8986 13.3229 14.7492 13.2505Z"
                                                    fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1428_7781">
                                                    <rect width="23.7667" height="22.6349" fill="white"
                                                          transform="translate(0.395508 0.682556)"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="//https://twitter.com/aqaed_com" title="twitter" rel="nofollow"
                                             target="_blank">
                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_1428_7777)">
                                                <path
                                                    d="M12.5147 23.3175C18.7651 23.3175 23.8322 18.2504 23.8322 12C23.8322 5.74956 18.7651 0.682556 12.5147 0.682556C6.26427 0.682556 1.19727 5.74956 1.19727 12C1.19727 18.2504 6.26427 23.3175 12.5147 23.3175Z"
                                                    fill="#045E5E"/>
                                                <path
                                                    d="M17.0992 10.6326C17.1068 10.7428 17.1068 10.853 17.1068 10.9642C17.1068 14.3529 14.5271 18.2611 9.80994 18.2611V18.259C8.41649 18.2611 7.05197 17.8619 5.87891 17.1093C6.08153 17.1337 6.28516 17.1459 6.48931 17.1464C7.64409 17.1474 8.76587 16.7599 9.67436 16.0464C8.57696 16.0256 7.61464 15.3101 7.27846 14.2655C7.66288 14.3397 8.05898 14.3244 8.43629 14.2213C7.23987 13.9796 6.37911 12.9284 6.37911 11.7076C6.37911 11.6965 6.37911 11.6858 6.37911 11.6751C6.7356 11.8737 7.13475 11.9839 7.54303 11.9961C6.41618 11.243 6.06883 9.74389 6.74931 8.57184C8.05136 10.174 9.97245 11.148 12.0347 11.2511C11.828 10.3604 12.1104 9.42701 12.7766 8.80087C13.8095 7.82991 15.4341 7.87968 16.405 8.91208C16.9794 8.79884 17.5298 8.58809 18.0336 8.28949C17.8421 8.88313 17.4415 9.3874 16.9062 9.70783C17.4146 9.64791 17.9112 9.51182 18.3789 9.30412C18.0346 9.82006 17.6009 10.2695 17.0992 10.6326Z"
                                                    fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1428_7777">
                                                    <rect width="23.7667" height="22.6349" fill="white"
                                                          transform="translate(0.628906 0.682556)"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="//https://facebook.com/aqaed" title="facebook" rel="nofollow"
                                             target="_blank">
                                        <svg width="27" height="26" viewBox="0 0 27 26" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M24.4758 13.0025C24.4758 7.06457 19.6623 2.25092 13.7243 2.25092C7.78631 2.25092 2.97266 7.06457 2.97266 13.0025C2.97266 18.3689 6.90436 22.8169 12.0443 23.6235V16.1103H9.31442V13.0025H12.0443V10.6338C12.0443 7.93919 13.6494 6.45076 16.1053 6.45076C17.2816 6.45076 18.512 6.66076 18.512 6.66076V9.30665H17.1563C15.8207 9.30665 15.4042 10.1354 15.4042 10.9857V13.0025H18.386L17.9094 16.1103H15.4042V23.6235C20.5441 22.8169 24.4758 18.3689 24.4758 13.0025Z"
                                                fill="#045E5E"/>
                                        </svg>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="//https://tiktok.com/@aqaed" title="tiktok" rel="nofollow"
                                             target="_blank">
                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.8252 20C16.348 20 20.8252 15.5228 20.8252 10C20.8252 4.47715 16.348 0 10.8252 0C5.30235 0 0.825195 4.47715 0.825195 10C0.825195 15.5228 5.30235 20 10.8252 20Z"
                                                fill="#045E5E"/>
                                            <path
                                                d="M14.9138 9.32545C14.8274 9.33396 14.7399 9.33884 14.6523 9.33884C13.6962 9.33884 12.8034 8.85716 12.2792 8.05679V12.4235C12.2792 14.2055 10.8341 15.6505 9.05218 15.6505C7.27023 15.6505 5.8252 14.2055 5.8252 12.4235C5.8252 10.6415 7.27023 9.19652 9.05218 9.19652C9.11908 9.19652 9.18597 9.2026 9.25165 9.20625V10.796C9.18597 10.7875 9.1203 10.7766 9.05218 10.7766C8.14235 10.7766 7.40524 11.5137 7.40524 12.4235C7.40524 13.3333 8.14235 14.0704 9.05218 14.0704C9.96202 14.0704 10.766 13.354 10.766 12.4442L10.7818 5.0293H12.3035C12.447 6.39405 13.5466 7.45957 14.915 7.55931V9.32667"
                                                fill="white"/>
                                        </svg>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="//https://instagram.com/aqaed.ar" title="instagram" rel="nofollow"
                                             target="_blank">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_1428_7767)">
                                                <path
                                                    d="M12.0568 23.2044C18.2447 23.2044 23.2611 18.1881 23.2611 12.0001C23.2611 5.81211 18.2447 0.795776 12.0568 0.795776C5.86887 0.795776 0.852539 5.81211 0.852539 12.0001C0.852539 18.1881 5.86887 23.2044 12.0568 23.2044Z"
                                                    fill="#045E5E"/>
                                                <path
                                                    d="M15.5404 7.76538C15.1147 7.76538 14.7695 8.1107 14.7695 8.53632C14.7695 8.96193 15.1147 9.30726 15.5404 9.30726C15.966 9.30726 16.3114 8.96193 16.3114 8.53632C16.3114 8.1107 15.966 7.76538 15.5404 7.76538Z"
                                                    fill="white"/>
                                                <path
                                                    d="M12.1137 8.75171C10.3293 8.75171 8.87891 10.2036 8.87891 11.9864C8.87891 13.7692 10.3309 15.2212 12.1137 15.2212C13.8964 15.2212 15.3484 13.7692 15.3484 11.9864C15.3484 10.2036 13.8964 8.75171 12.1137 8.75171ZM12.1137 14.06C10.9701 14.06 10.0417 13.13 10.0417 11.988C10.0417 10.8461 10.9717 9.91615 12.1137 9.91615C13.2556 9.91615 14.1856 10.8461 14.1856 11.988C14.1856 13.13 13.2556 14.06 12.1137 14.06Z"
                                                    fill="white"/>
                                                <path
                                                    d="M14.686 18.5524H9.44035C7.26406 18.5524 5.49414 16.7824 5.49414 14.6061V9.36055C5.49414 7.18425 7.26406 5.41431 9.44035 5.41431H14.686C16.8623 5.41431 18.6322 7.18425 18.6322 9.36055V14.6061C18.6322 16.7824 16.8623 18.5524 14.686 18.5524ZM9.44035 6.65101C7.94666 6.65101 6.73085 7.86685 6.73085 9.36055V14.6061C6.73085 16.0999 7.94666 17.3156 9.44035 17.3156H14.686C16.1797 17.3156 17.3955 16.0999 17.3955 14.6061V9.36055C17.3955 7.86525 16.1797 6.65101 14.686 6.65101H9.44035Z"
                                                    fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1428_7767">
                                                    <rect width="23.529" height="22.4086" fill="white"
                                                          transform="translate(0.295898 0.795715)"/>
                                                </clipPath>
                                            </defs>
                                        </svg>

                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="//https://t.me/aqaed_com" title="telegram" rel="nofollow"
                                             target="_blank">
                                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_1428_7765)">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M23.7367 11.9999C23.7367 18.1879 18.7203 23.2042 12.5324 23.2042C6.34446 23.2042 1.32812 18.1879 1.32812 11.9999C1.32812 5.81199 6.34446 0.795654 12.5324 0.795654C18.7203 0.795654 23.7367 5.81199 23.7367 11.9999ZM12.9339 9.06716C11.8441 9.52044 9.6661 10.4586 6.39983 11.8817C5.86943 12.0926 5.59158 12.299 5.56629 12.5007C5.52355 12.8417 5.95056 12.976 6.53201 13.1588C6.61112 13.1837 6.69307 13.2095 6.77708 13.2367C7.34914 13.4227 8.11869 13.6403 8.51874 13.6489C8.88162 13.6567 9.28665 13.5071 9.7338 13.2C12.7856 11.14 14.361 10.0988 14.4598 10.0763C14.5296 10.0605 14.6263 10.0406 14.6918 10.0988C14.7574 10.157 14.7509 10.2673 14.7439 10.2969C14.7017 10.4772 13.0255 12.0356 12.1581 12.842C11.8877 13.0934 11.6959 13.2716 11.6567 13.3124C11.5688 13.4036 11.4793 13.4899 11.3933 13.5728C10.8618 14.0852 10.4633 14.4694 11.4154 15.0968C11.8729 15.3983 12.239 15.6476 12.6042 15.8964C13.0031 16.1681 13.401 16.439 13.9158 16.7765C14.0469 16.8624 14.1722 16.9517 14.2942 17.0386C14.7584 17.3696 15.1754 17.667 15.6907 17.6195C15.9901 17.592 16.2993 17.3104 16.4564 16.4708C16.8276 14.4865 17.5572 10.1871 17.7258 8.41548C17.7406 8.26027 17.722 8.06162 17.7071 7.97441C17.6921 7.88721 17.661 7.76296 17.5476 7.67098C17.4133 7.56205 17.2062 7.53908 17.1134 7.54064C16.692 7.54815 16.0454 7.77296 12.9339 9.06716Z"
                                                      fill="#045E5E"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1428_7765">
                                                    <rect width="23.529" height="22.4086" fill="white"
                                                          transform="translate(0.766602 0.795715)"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="//https://youtube.com/@aqaed-com" title="youtube" rel="nofollow"
                                             target="_blank">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_1428_7762)">
                                                <path
                                                    d="M11.8858 23.3175C18.1362 23.3175 23.2033 18.2504 23.2033 12C23.2033 5.74956 18.1362 0.682556 11.8858 0.682556C5.63536 0.682556 0.568359 5.74956 0.568359 12C0.568359 18.2504 5.63536 23.3175 11.8858 23.3175Z"
                                                    fill="#045E5E"/>
                                                <path
                                                    d="M17.9406 10.3818C17.9406 8.88113 16.7239 7.66437 15.2233 7.66437H8.27309C6.77242 7.66437 5.55566 8.88113 5.55566 10.3818V13.6152C5.55566 15.1158 6.77242 16.3325 8.27309 16.3325H15.2233C16.7239 16.3325 17.9406 15.1158 17.9406 13.6152V10.3818ZM13.854 12.241L10.7374 13.7822C10.6157 13.8488 10.2005 13.7595 10.2005 13.6216V10.4564C10.2005 10.3153 10.619 10.2277 10.7407 10.2974L13.7242 11.9198C13.8491 11.9912 13.9805 12.1713 13.854 12.241Z"
                                                    fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1428_7762">
                                                    <rect width="23.7667" height="22.6349" fill="white"
                                                          transform="translate(0 0.682556)"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
