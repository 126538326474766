import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import AboutTextPage from "../components/AboutTextPage";

const About = () => {
    return (
        <div>
            <HeaderPage />
            <AboutTextPage />
            <Footer />
        </div>
    );
};

export default About;
