import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import ContactUsPage from "../components/ContactUsPage";

const ContactUs = () => {
    return (
        <div>
            <HeaderPage />
            <ContactUsPage />
            <Footer />
        </div>
    );
};

export default ContactUs;
