import {NavLink} from "react-router-dom";
import React, {useEffect, useState} from "react";
import image from "../assets/uploads/book.jpg";
import ReactCountryFlag from "react-country-flag";
import countries from "i18n-iso-countries";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import img from "../assets/uploads/user.jpg";
import {Select, Button} from "antd";
import Cookies from "universal-cookie";
import {Helmet} from "react-helmet";
import {useUser} from "../services/UserContext";

const options = [
    {value: 'val1', label: 'التسلسل الزمني'},
    {value: 'val2', label: 'الزمني'},
    {value: 'val3', label: 'التسلسل'}
]

const options1 = [
    {value: 'val1', label: 'التسلسل w'},
    {value: 'val2', label: 'الزمني'},
]

const ContactUsPage = () => {


    const [successMessage, setSuccessMessage] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    let handleSubmitContactUs = async (e) => {
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Cookie", "mylanguageClient=ar");

        var formdata = new FormData();
        formdata.append("name", getName);
        formdata.append("email", getEmail);
        formdata.append("title", getTitle);
        formdata.append("body", getBody);
        formdata.append("religion", getReligion);
        formdata.append("country", getCountry);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://dashboard.aqaed.net/api/v1/addContactUses", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result['status'] == 200) {
                    setSuccessMessage("تم إرسال المعلومات بنجاح");
                    setShowSuccessMessage(true);

                    setName("")
                    setEmail("")
                    setTitle("")
                    setBody("")
                    setReligion("")
                    setCountry("")

                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);
                 const inputElements = document.querySelectorAll('input');
                    const inputElements1 = document.querySelectorAll('textarea');
                    inputElements1.forEach(input => {
                        input.value = '';
                    });
                    inputElements.forEach(input => {
                        input.value = '';
                    });
                    return () => clearTimeout(timer);
                } else {
                    setErrorMessage("للأسف ، لم تتم معالجة طلبك. يرجى المحاولة مرة أخرى لاحقًا")
                    setShowErrorMessage(true);
                    const timer = setTimeout(() => {
                        setShowErrorMessage(false);
                    }, 5000);
                    return () => clearTimeout(timer);
                }
            });
    };

    const [getCount, setCount] = useState(1);
    const [getName, setName] = useState("");
    const [getEmail, setEmail] = useState("");
    const [getTitle, setTitle] = useState("");
    const [getBody, setBody] = useState("");
    const [getReligion, setReligion] = useState("");
    const [getCountry, setCountry] = useState("");


    const handleChangeCountry = (value) => {
        setCountry(value)
    };


    const [emailIsLogin, setEmailIsLogin] = useState(false);
    const [passIsLogin, setPassIsLogin] = useState(false);
    const { userData, setLoginData } = useUser();

    useEffect(() => {
        const getUserList = () => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("email", emailIsLogin);
            formdata.append("password", passIsLogin);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            setName(userData?.name);
            setCountry(userData?.country);
            setReligion(userData?.previous_religion);

        };
        const cookies = new Cookies();
        // setSuccessMessage("تم تسجيل الدخول بنجاح");
        // let code = email.value + "m/!@m" + pass;
        if ((cookies.get('myCat') != undefined)) {
            let login = atob(cookies.get('myCat'));
            let arr = login.split("m/!@m");
            setEmailIsLogin(arr[0]);
            setEmail(emailIsLogin);
            setPassIsLogin(arr[1]);
            getUserList();
        }

    }, [emailIsLogin,userData]);


    const handleClick = (id, e) => {
        setCount(id)
    };
    return (
        <>
            <Helmet>
                <title>اتصل بنا</title>
                <meta name="description" content="استخدم نموذج للاتصال بنا"/>
                <link rel="canonical" href="https://aqaed.net/contact"/>
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:site" content="@aqaedcom"/>
                <meta name="twitter:creator" content="گروه ایده‌پرداز ترسیم"/>
                <meta name="twitter:title" content="اتصل بنا"/>
                <meta name="twitter:description" content="استخدم نموذج للاتصال بنا"/>
                <meta name="twitter:image" content="/logo.svg"/>
                <meta property="og:type"/>
                <meta property="og:title" content="اتصل بنا"/>
                <meta property="og:site_name" content="مركز الأبحاث العقائدية"/>
                <meta property="og:locale" content="ar_sa"/>
                <meta property="og:image" content="/logo.svg"/>
            </Helmet>
            <section className="aq-menu-head filter-container-mt back-main-2">
                <div className="aq-container">
                    <div className="row">
                        <div className="w-200 width-0-phone"></div>
                        <div className="w-100-200 width-100-phone d-flex justify-content-between">
                            <div className="filter-container">
                                <div
                                    className="aq-container position-relative d-flex justify-content-between align-items-center">
                                    <div className="question-container d-flex justify-content-end align-items-center">
                                        <svg className="show-o-container" width="50" height="50" viewBox="0 0 50 50" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="25" cy="25" r="25" fill="#045E5E"/>
                                            <path
                                                d="M32.6095 28.2761L36.1403 31.807C36.6151 32.2818 36.6151 33.0516 36.1403 33.5264C33.5734 36.0933 29.5095 36.3821 26.6053 34.204L24.5048 32.6286C22.1801 30.885 20.115 28.8199 18.3714 26.4952L16.796 24.3947C14.6179 21.4905 14.9067 17.4266 17.4736 14.8597C17.9484 14.3849 18.7182 14.3849 19.193 14.8597L22.7239 18.3905C23.2446 18.9112 23.2446 19.7554 22.7239 20.2761L21.3623 21.6377C21.1459 21.8541 21.0923 22.1846 21.2292 22.4583C22.8114 25.6227 25.3773 28.1886 28.5417 29.7708C28.8154 29.9077 29.1459 29.8541 29.3623 29.6377L30.7239 28.2761C31.2446 27.7554 32.0888 27.7554 32.6095 28.2761Z"
                                                stroke="#F3F3F3"/>
                                        </svg>
                                        <div className="question-container-data">
                                            <h1 className="faq-title m-0"> تواصَل معنا </h1>
                                            <small className="faq-desc d-block">
                                                طرق الاتصال بالمركز
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="question-container-sec">
                <div className="position-relative">
                    <div className="aq-container">
                        <div className="row justify-content-center mt-5">
                            <div className="col-sm-7 col-10 position-relative">
                                <div className="green-border">
                                    <div className="user-name-container align-items-start">
                                        <span className="go-to-left mt-1-5 color-main-2 hidden-phone">إرسال رسالة</span>
                                        <div className="phone-center">
                                            <div className="w-34 ms-2 phone-inline ">
                                                <svg width="34" height="35" viewBox="0 0 34 35" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="17" cy="17.5" r="17" fill="#045E5E"/>
                                                    <path
                                                        d="M22 10V10C23.3807 10 24.5 11.1193 24.5 12.5L24.5 14.2857C24.5 14.4852 24.5 14.585 24.4719 14.6651C24.4217 14.8088 24.3088 14.9217 24.1651 14.9719C24.085 15 23.9852 15 23.7857 15L19.5 15M22 10V10C20.6193 10 19.5 11.1193 19.5 12.5L19.5 15M22 10L13.5 10C11.6144 10 10.6716 10 10.0858 10.5858C9.5 11.1716 9.5 12.1144 9.5 14L9.5 25L12 24.1667L14.5 25L17 24.1667L19.5 25L19.5 15"
                                                        stroke="white" strokeOpacity="0.9"/>
                                                    <path d="M12.832 13.3333L16.1654 13.3333" stroke="white"
                                                          strokeOpacity="0.9" strokeLinecap="round"/>
                                                    <path d="M13.668 16.6667H12.8346" stroke="white" strokeOpacity="0.9"
                                                          strokeLinecap="round"/>
                                                    <path d="M12.832 20L15.332 20" stroke="white" strokeOopacity="0.9"
                                                          strokeLinecap="round"/>
                                                </svg>
                                            </div>
                                            <span className="mt-1-5 color-main-2 phone-inline hidden-desktop">إرسال رسالة</span>
                                        </div>
                                        {showSuccessMessage ?
                                            <div
                                                className={showSuccessMessage ? "d-flex position-fixed success-message" : "d-flex position-fixed success-message close"}>
                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M12.2531 1.20251C6.37312 1.20251 1.45312 6.12251 1.45312 12.0025C1.45312 17.8825 6.37312 22.8025 12.2531 22.8025C18.1331 22.8025 23.0531 18.0025 23.0531 12.0025C23.0531 6.00251 18.2531 1.20251 12.2531 1.20251ZM10.6916 16.2055L6.85156 12.3655L8.05156 11.1655L10.6916 13.8055L16.4516 8.04553L17.6516 9.24553L10.6916 16.2055Z"
                                                          fill="white"/>
                                                    <path opacity="0.01" fillRule="evenodd" clipRule="evenodd"
                                                          d="M10.6916 16.202L6.85156 12.362L8.05156 11.162L10.6916 13.802L16.4516 8.04199L17.6516 9.24199L10.6916 16.202Z"
                                                          fill="white"/>
                                                </svg>
                                                <span className="me-1 text-white">
                                                   {successMessage}
                                               </span>
                                            </div>
                                            : ""}

                                        {showErrorMessage ?
                                            <div className={errorMessage ?
                                                "d-flex bg-color-red  position-fixed success-message" :
                                                "d-flex bg-color-red position-fixed success-message close"}>
                                                <svg className="w-23" width="17" height="16" viewBox="0 0 17 16" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd"
                                                          d="M8.95 0.794189C4.95 0.794189 1.75 3.99419 1.75 7.99419C1.75 11.9942 4.95 15.1942 8.95 15.1942C12.95 15.1942 16.15 11.9942 16.15 7.99419C16.15 3.99419 12.95 0.794189 8.95 0.794189ZM8.30928 3.98967H9.50928V9.58967H8.30928V3.98967ZM8.95007 12.7906C8.47007 12.7906 8.15007 12.4706 8.15007 11.9906C8.15007 11.5106 8.47007 11.1906 8.95007 11.1906C9.43007 11.1906 9.75007 11.5106 9.75007 11.9906C9.75007 12.4706 9.43007 12.7906 8.95007 12.7906Z"
                                                          fill="#fff"/>
                                                    <path opacity="0.01" fillRule="evenodd" clipRule="evenodd"
                                                          d="M8.30758 3.98886H9.50758V9.58886H8.30758V3.98886ZM8.94837 12.7898C8.46837 12.7898 8.14837 12.4698 8.14837 11.9898C8.14837 11.5098 8.46837 11.1898 8.94837 11.1898C9.42837 11.1898 9.74837 11.5098 9.74837 11.9898C9.74837 12.4698 9.42837 12.7898 8.94837 12.7898Z"
                                                          fill="#fff"/>
                                                </svg>
                                                <span className="me-1 text-white">
                                                    {errorMessage??"البريد الإلكتروني الذي تم إدخاله غير صحيح!"}
                                                </span>
                                            </div>
                                            : ""}
                                        <form className="row form-container-2" onSubmit={handleSubmitContactUs}>
                                            <div className="col-12 mt-1">
                                                <h2 className="text-page-title f-14-phone-c phone-center">يمكنكم التواصل معنا من خلال هذه
                                                    النافذة</h2>
                                            </div>
                                            <div className="col-md-4">
                                                <input className="w-100" type="text" onChange={(e)=> {
                                                    setName(e.target.value)
                                                }} value={getName} placeholder="الإسم الكامل" required
                                                       maxLength="500"/>
                                            </div>
                                            <div className="col-md-4 child-m-0-in">
                                                {emailIsLogin?
                                                    getCountry?
                                                        <Select
                                                            showSearch
                                                            placeholder="الدولة"
                                                            defaultValue={getCountry?getCountry.toLocaleUpperCase() : undefined}
                                                            onChange={handleChangeCountry}
                                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                            filterSort={(optionA, optionB) =>
                                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                            }
                                                            options={[
                                                                {
                                                                    value: "AD",
                                                                    label: "أندورا",
                                                                },
                                                                {
                                                                    value: "AE",
                                                                    label: "الامارات العربية المتحدة",
                                                                },
                                                                {
                                                                    value: "AF",
                                                                    label: "أفغانستان",
                                                                },
                                                                {
                                                                    value: "AG",
                                                                    label: "أنتيجوا وبربودا",
                                                                },
                                                                {
                                                                    value: "AI",
                                                                    label: "أنجويلا",
                                                                },
                                                                {
                                                                    value: "AL",
                                                                    label: "ألبانيا",
                                                                },
                                                                {
                                                                    value: "AM",
                                                                    label: "أرمينيا",
                                                                },
                                                                {
                                                                    value: "AO",
                                                                    label: "أنجولا",
                                                                },
                                                                {
                                                                    value: "AQ",
                                                                    label: "القطب الجنوبي",
                                                                },
                                                                {
                                                                    value: "AR",
                                                                    label: "الأرجنتين",
                                                                },
                                                                {
                                                                    value: "AS",
                                                                    label: "ساموا الأمريكية",
                                                                },
                                                                {
                                                                    value: "AT",
                                                                    label: "النمسا",
                                                                },
                                                                {
                                                                    value: "AU",
                                                                    label: "أستراليا",
                                                                },
                                                                {
                                                                    value: "AW",
                                                                    label: "آروبا",
                                                                },
                                                                {
                                                                    value: "AX",
                                                                    label: "جزر أولان",
                                                                },
                                                                {
                                                                    value: "AZ",
                                                                    label: "أذربيجان",
                                                                },
                                                                {
                                                                    value: "BA",
                                                                    label: "البوسنة والهرسك",
                                                                },
                                                                {
                                                                    value: "BB",
                                                                    label: "بربادوس",
                                                                },
                                                                {
                                                                    value: "BD",
                                                                    label: "بنجلاديش",
                                                                },
                                                                {
                                                                    value: "BE",
                                                                    label: "بلجيكا",
                                                                },
                                                                {
                                                                    value: "BF",
                                                                    label: "بوركينا فاسو",
                                                                },
                                                                {
                                                                    value: "BG",
                                                                    label: "بلغاريا",
                                                                },
                                                                {
                                                                    value: "BH",
                                                                    label: "البحرين",
                                                                },
                                                                {
                                                                    value: "BI",
                                                                    label: "بوروندي",
                                                                },
                                                                {
                                                                    value: "BJ",
                                                                    label: "بنين",
                                                                },
                                                                {
                                                                    value: "BL",
                                                                    label: "سان بارتيلمي",
                                                                },
                                                                {
                                                                    value: "BM",
                                                                    label: "برمودا",
                                                                },
                                                                {
                                                                    value: "BN",
                                                                    label: "بروناي",
                                                                },
                                                                {
                                                                    value: "BO",
                                                                    label: "بوليفيا",
                                                                },
                                                                {
                                                                    value: "BQ",
                                                                    label: "بونير",
                                                                },
                                                                {
                                                                    value: "BR",
                                                                    label: "البرازيل",
                                                                },
                                                                {
                                                                    value: "BS",
                                                                    label: "الباهاما",
                                                                },
                                                                {
                                                                    value: "BT",
                                                                    label: "بوتان",
                                                                },
                                                                {
                                                                    value: "BV",
                                                                    label: "جزيرة بوفيه",
                                                                },
                                                                {
                                                                    value: "BW",
                                                                    label: "بتسوانا",
                                                                },
                                                                {
                                                                    value: "BY",
                                                                    label: "روسيا البيضاء",
                                                                },
                                                                {
                                                                    value: "BZ",
                                                                    label: "بليز",
                                                                },
                                                                {
                                                                    value: "CA",
                                                                    label: "كندا",
                                                                },
                                                                {
                                                                    value: "CC",
                                                                    label: "جزر كوكوس",
                                                                },
                                                                {
                                                                    value: "CD",
                                                                    label: "جمهورية الكونغو الديمقراطية",
                                                                },
                                                                {
                                                                    value: "CF",
                                                                    label: "جمهورية افريقيا الوسطى",
                                                                },
                                                                {
                                                                    value: "CG",
                                                                    label: "الكونغو - برازافيل",
                                                                },
                                                                {
                                                                    value: "CH",
                                                                    label: "سويسرا",
                                                                },
                                                                {
                                                                    value: "CI",
                                                                    label: "ساحل العاج",
                                                                },
                                                                {
                                                                    value: "CK",
                                                                    label: "جزر كوك",
                                                                },
                                                                {
                                                                    value: "CL",
                                                                    label: "شيلي",
                                                                },
                                                                {
                                                                    value: "CM",
                                                                    label: "الكاميرون",
                                                                },
                                                                {
                                                                    value: "CN",
                                                                    label: "الصين",
                                                                },
                                                                {
                                                                    value: "CO",
                                                                    label: "كولومبيا",
                                                                },
                                                                {
                                                                    value: "CR",
                                                                    label: "كوستاريكا",
                                                                },
                                                                {
                                                                    value: "CU",
                                                                    label: "كوبا",
                                                                },
                                                                {
                                                                    value: "CV",
                                                                    label: "الرأس الأخضر",
                                                                },
                                                                {
                                                                    value: "CW",
                                                                    label: "كوراساو",
                                                                },
                                                                {
                                                                    value: "CX",
                                                                    label: "جزيرة الكريسماس",
                                                                },
                                                                {
                                                                    value: "CY",
                                                                    label: "قبرص",
                                                                },
                                                                {
                                                                    value: "CZ",
                                                                    label: "جمهورية التشيك",
                                                                },
                                                                {
                                                                    value: "DE",
                                                                    label: "ألمانيا",
                                                                },
                                                                {
                                                                    value: "DJ",
                                                                    label: "جيبوتي",
                                                                },
                                                                {
                                                                    value: "DK",
                                                                    label: "الدانمرك",
                                                                },
                                                                {
                                                                    value: "DM",
                                                                    label: "دومينيكا",
                                                                },
                                                                {
                                                                    value: "DO",
                                                                    label: "جمهورية الدومينيك",
                                                                },
                                                                {
                                                                    value: "DZ",
                                                                    label: "الجزائر",
                                                                },
                                                                {
                                                                    value: "EC",
                                                                    label: "الاكوادور",
                                                                },
                                                                {
                                                                    value: "EE",
                                                                    label: "استونيا",
                                                                },
                                                                {
                                                                    value: "EG",
                                                                    label: "مصر",
                                                                },
                                                                {
                                                                    value: "EH",
                                                                    label: "الصحراء الغربية",
                                                                },
                                                                {
                                                                    value: "ER",
                                                                    label: "اريتريا",
                                                                },
                                                                {
                                                                    value: "ES",
                                                                    label: "أسبانيا",
                                                                },
                                                                {
                                                                    value: "ET",
                                                                    label: "اثيوبيا",
                                                                },
                                                                {
                                                                    value: "FI",
                                                                    label: "فنلندا",
                                                                },
                                                                {
                                                                    value: "FJ",
                                                                    label: "فيجي",
                                                                },
                                                                {
                                                                    value: "FK",
                                                                    label: "جزر فوكلاند",
                                                                },
                                                                {
                                                                    value: "FM",
                                                                    label: "ميكرونيزيا",
                                                                },
                                                                {
                                                                    value: "FO",
                                                                    label: "جزر فارو",
                                                                },
                                                                {
                                                                    value: "FR",
                                                                    label: "فرنسا",
                                                                },
                                                                {
                                                                    value: "GA",
                                                                    label: "الجابون",
                                                                },
                                                                {
                                                                    value: "GB",
                                                                    label: "المملكة المتحدة",
                                                                },
                                                                {
                                                                    value: "GD",
                                                                    label: "جرينادا",
                                                                },
                                                                {
                                                                    value: "GE",
                                                                    label: "جورجيا",
                                                                },
                                                                {
                                                                    value: "GF",
                                                                    label: "غويانا",
                                                                },
                                                                {
                                                                    value: "GG",
                                                                    label: "غيرنزي",
                                                                },
                                                                {
                                                                    value: "GH",
                                                                    label: "غانا",
                                                                },
                                                                {
                                                                    value: "GI",
                                                                    label: "جبل طارق",
                                                                },
                                                                {
                                                                    value: "GL",
                                                                    label: "جرينلاند",
                                                                },
                                                                {
                                                                    value: "GM",
                                                                    label: "غامبيا",
                                                                },
                                                                {
                                                                    value: "GN",
                                                                    label: "غينيا",
                                                                },
                                                                {
                                                                    value: "GP",
                                                                    label: "جوادلوب",
                                                                },
                                                                {
                                                                    value: "GQ",
                                                                    label: "غينيا الاستوائية",
                                                                },
                                                                {
                                                                    value: "GR",
                                                                    label: "اليونان",
                                                                },
                                                                {
                                                                    value: "GS",
                                                                    label: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
                                                                },
                                                                {
                                                                    value: "GT",
                                                                    label: "جواتيمالا",
                                                                },
                                                                {
                                                                    value: "GU",
                                                                    label: "جوام",
                                                                },
                                                                {
                                                                    value: "GW",
                                                                    label: "غينيا بيساو",
                                                                },
                                                                {
                                                                    value: "GY",
                                                                    label: "غيانا",
                                                                },
                                                                {
                                                                    value: "HK",
                                                                    label: "هونج كونج الصينية",
                                                                },
                                                                {
                                                                    value: "HM",
                                                                    label: "جزيرة هيرد وماكدونالد",
                                                                },
                                                                {
                                                                    value: "HN",
                                                                    label: "هندوراس",
                                                                },
                                                                {
                                                                    value: "HR",
                                                                    label: "كرواتيا",
                                                                },
                                                                {
                                                                    value: "HT",
                                                                    label: "هايتي",
                                                                },
                                                                {
                                                                    value: "HU",
                                                                    label: "المجر",
                                                                },
                                                                {
                                                                    value: "ID",
                                                                    label: "اندونيسيا",
                                                                },
                                                                {
                                                                    value: "IE",
                                                                    label: "أيرلندا",
                                                                },
                                                                {
                                                                    value: "IL",
                                                                    label: "اسرائيل",
                                                                },
                                                                {
                                                                    value: "IM",
                                                                    label: "جزيرة مان",
                                                                },
                                                                {
                                                                    value: "IN",
                                                                    label: "الهند",
                                                                },
                                                                {
                                                                    value: "IO",
                                                                    label: "المحيط الهندي البريطاني",
                                                                },
                                                                {
                                                                    value: "IQ",
                                                                    label: "العراق",
                                                                },
                                                                {
                                                                    value: "IR",
                                                                    label: "ايران",
                                                                },
                                                                {
                                                                    value: "IS",
                                                                    label: "أيسلندا",
                                                                },
                                                                {
                                                                    value: "IT",
                                                                    label: "ايطاليا",
                                                                },
                                                                {
                                                                    value: "JE",
                                                                    label: "جيرسي",
                                                                },
                                                                {
                                                                    value: "JM",
                                                                    label: "جامايكا",
                                                                },
                                                                {
                                                                    value: "JO",
                                                                    label: "الأردن",
                                                                },
                                                                {
                                                                    value: "JP",
                                                                    label: "اليابان",
                                                                },
                                                                {
                                                                    value: "KE",
                                                                    label: "كينيا",
                                                                },
                                                                {
                                                                    value: "KG",
                                                                    label: "قرغيزستان",
                                                                },
                                                                {
                                                                    value: "KH",
                                                                    label: "كمبوديا",
                                                                },
                                                                {
                                                                    value: "KI",
                                                                    label: "كيريباتي",
                                                                },
                                                                {
                                                                    value: "KM",
                                                                    label: "جزر القمر",
                                                                },
                                                                {
                                                                    value: "KN",
                                                                    label: "سانت كيتس ونيفيس",
                                                                },
                                                                {
                                                                    value: "KP",
                                                                    label: "كوريا الشمالية",
                                                                },
                                                                {
                                                                    value: "KR",
                                                                    label: "كوريا الجنوبية",
                                                                },
                                                                {
                                                                    value: "KW",
                                                                    label: "الكويت",
                                                                },
                                                                {
                                                                    value: "KY",
                                                                    label: "جزر الكايمن",
                                                                },
                                                                {
                                                                    value: "KZ",
                                                                    label: "كازاخستان",
                                                                },
                                                                {
                                                                    value: "LA",
                                                                    label: "لاوس",
                                                                },
                                                                {
                                                                    value: "LB",
                                                                    label: "لبنان",
                                                                },
                                                                {
                                                                    value: "LC",
                                                                    label: "سانت لوسيا",
                                                                },
                                                                {
                                                                    value: "LI",
                                                                    label: "ليختنشتاين",
                                                                },
                                                                {
                                                                    value: "LK",
                                                                    label: "سريلانكا",
                                                                },
                                                                {
                                                                    value: "LR",
                                                                    label: "ليبيريا",
                                                                },
                                                                {
                                                                    value: "LS",
                                                                    label: "ليسوتو",
                                                                },
                                                                {
                                                                    value: "LT",
                                                                    label: "ليتوانيا",
                                                                },
                                                                {
                                                                    value: "LU",
                                                                    label: "لوكسمبورج",
                                                                },
                                                                {
                                                                    value: "LV",
                                                                    label: "لاتفيا",
                                                                },
                                                                {
                                                                    value: "LY",
                                                                    label: "ليبيا",
                                                                },
                                                                {
                                                                    value: "MA",
                                                                    label: "المغرب",
                                                                },
                                                                {
                                                                    value: "MC",
                                                                    label: "موناكو",
                                                                },
                                                                {
                                                                    value: "MD",
                                                                    label: "مولدافيا",
                                                                },
                                                                {
                                                                    value: "ME",
                                                                    label: "الجبل الأسود",
                                                                },
                                                                {
                                                                    value: "MF",
                                                                    label: "سانت مارتين",
                                                                },
                                                                {
                                                                    value: "MG",
                                                                    label: "مدغشقر",
                                                                },
                                                                {
                                                                    value: "MH",
                                                                    label: "جزر المارشال",
                                                                },
                                                                {
                                                                    value: "MK",
                                                                    label: "مقدونيا",
                                                                },
                                                                {
                                                                    value: "ML",
                                                                    label: "مالي",
                                                                },
                                                                {
                                                                    value: "MM",
                                                                    label: "ميانمار",
                                                                },
                                                                {
                                                                    value: "MN",
                                                                    label: "منغوليا",
                                                                },
                                                                {
                                                                    value: "MO",
                                                                    label: "ماكاو الصينية",
                                                                },
                                                                {
                                                                    value: "MP",
                                                                    label: "جزر ماريانا الشمالية",
                                                                },
                                                                {
                                                                    value: "MQ",
                                                                    label: "مارتينيك",
                                                                },
                                                                {
                                                                    value: "MR",
                                                                    label: "موريتانيا",
                                                                },
                                                                {
                                                                    value: "MS",
                                                                    label: "مونتسرات",
                                                                },
                                                                {
                                                                    value: "MT",
                                                                    label: "مالطا",
                                                                },
                                                                {
                                                                    value: "MU",
                                                                    label: "موريشيوس",
                                                                },
                                                                {
                                                                    value: "MV",
                                                                    label: "جزر الملديف",
                                                                },
                                                                {
                                                                    value: "MW",
                                                                    label: "ملاوي",
                                                                },
                                                                {
                                                                    value: "MX",
                                                                    label: "المكسيك",
                                                                },
                                                                {
                                                                    value: "MY",
                                                                    label: "ماليزيا",
                                                                },
                                                                {
                                                                    value: "MZ",
                                                                    label: "موزمبيق",
                                                                },
                                                                {
                                                                    value: "NA",
                                                                    label: "ناميبيا",
                                                                },
                                                                {
                                                                    value: "NC",
                                                                    label: "كاليدونيا الجديدة",
                                                                },
                                                                {
                                                                    value: "NE",
                                                                    label: "النيجر",
                                                                },
                                                                {
                                                                    value: "NF",
                                                                    label: "جزيرة نورفوك",
                                                                },
                                                                {
                                                                    value: "NG",
                                                                    label: "نيجيريا",
                                                                },
                                                                {
                                                                    value: "NI",
                                                                    label: "نيكاراجوا",
                                                                },
                                                                {
                                                                    value: "NL",
                                                                    label: "هولندا",
                                                                },
                                                                {
                                                                    value: "NO",
                                                                    label: "النرويج",
                                                                },
                                                                {
                                                                    value: "NP",
                                                                    label: "نيبال",
                                                                },
                                                                {
                                                                    value: "NR",
                                                                    label: "نورو",
                                                                },
                                                                {
                                                                    value: "NU",
                                                                    label: "نيوي",
                                                                },
                                                                {
                                                                    value: "NZ",
                                                                    label: "نيوزيلاندا",
                                                                },
                                                                {
                                                                    value: "OM",
                                                                    label: "عمان",
                                                                },
                                                                {
                                                                    value: "PA",
                                                                    label: "بنما",
                                                                },
                                                                {
                                                                    value: "PE",
                                                                    label: "بيرو",
                                                                },
                                                                {
                                                                    value: "PF",
                                                                    label: "بولينيزيا الفرنسية",
                                                                },
                                                                {
                                                                    value: "PG",
                                                                    label: "بابوا غينيا الجديدة",
                                                                },
                                                                {
                                                                    value: "PH",
                                                                    label: "الفيلبين",
                                                                },
                                                                {
                                                                    value: "PK",
                                                                    label: "باكستان",
                                                                },
                                                                {
                                                                    value: "PL",
                                                                    label: "بولندا",
                                                                },
                                                                {
                                                                    value: "PM",
                                                                    label: "سانت بيير وميكولون",
                                                                },
                                                                {
                                                                    value: "PN",
                                                                    label: "بتكايرن",
                                                                },
                                                                {
                                                                    value: "PR",
                                                                    label: "بورتوريكو",
                                                                },
                                                                {
                                                                    value: "PS",
                                                                    label: "فلسطين",
                                                                },
                                                                {
                                                                    value: "PT",
                                                                    label: "البرتغال",
                                                                },
                                                                {
                                                                    value: "PW",
                                                                    label: "بالاو",
                                                                },
                                                                {
                                                                    value: "PY",
                                                                    label: "باراجواي",
                                                                },
                                                                {
                                                                    value: "QA",
                                                                    label: "قطر",
                                                                },
                                                                {
                                                                    value: "RE",
                                                                    label: "روينيون",
                                                                },
                                                                {
                                                                    value: "RO",
                                                                    label: "رومانيا",
                                                                },
                                                                {
                                                                    value: "RS",
                                                                    label: "صربيا",
                                                                },
                                                                {
                                                                    value: "RU",
                                                                    label: "روسيا",
                                                                },
                                                                {
                                                                    value: "RW",
                                                                    label: "رواندا",
                                                                },
                                                                {
                                                                    value: "SA",
                                                                    label: "المملكة العربية السعودية",
                                                                },
                                                                {
                                                                    value: "SB",
                                                                    label: "جزر سليمان",
                                                                },
                                                                {
                                                                    value: "SC",
                                                                    label: "سيشل",
                                                                },
                                                                {
                                                                    value: "SD",
                                                                    label: "السودان",
                                                                },
                                                                {
                                                                    value: "SE",
                                                                    label: "السويد",
                                                                },
                                                                {
                                                                    value: "SG",
                                                                    label: "سنغافورة",
                                                                },
                                                                {
                                                                    value: "SH",
                                                                    label: "سانت هيلنا",
                                                                },
                                                                {
                                                                    value: "SI",
                                                                    label: "سلوفينيا",
                                                                },
                                                                {
                                                                    value: "SJ",
                                                                    label: "سفالبارد وجان مايان",
                                                                },
                                                                {
                                                                    value: "SK",
                                                                    label: "سلوفاكيا",
                                                                },
                                                                {
                                                                    value: "SL",
                                                                    label: "سيراليون",
                                                                },
                                                                {
                                                                    value: "SM",
                                                                    label: "سان مارينو",
                                                                },
                                                                {
                                                                    value: "SN",
                                                                    label: "السنغال",
                                                                },
                                                                {
                                                                    value: "SO",
                                                                    label: "الصومال",
                                                                },
                                                                {
                                                                    value: "SR",
                                                                    label: "سورينام",
                                                                },
                                                                {
                                                                    value: "SS",
                                                                    label: "جنوب السودان",
                                                                },
                                                                {
                                                                    value: "ST",
                                                                    label: "ساو تومي وبرينسيبي",
                                                                },
                                                                {
                                                                    value: "SV",
                                                                    label: "السلفادور",
                                                                },
                                                                {
                                                                    value: "SX",
                                                                    label: "سينت مارتن",
                                                                },
                                                                {
                                                                    value: "SY",
                                                                    label: "سوريا",
                                                                },
                                                                {
                                                                    value: "SZ",
                                                                    label: "سوازيلاند",
                                                                },
                                                                {
                                                                    value: "TC",
                                                                    label: "جزر الترك وجايكوس",
                                                                },
                                                                {
                                                                    value: "TD",
                                                                    label: "تشاد",
                                                                },
                                                                {
                                                                    value: "TF",
                                                                    label: "المقاطعات الجنوبية الفرنسية",
                                                                },
                                                                {
                                                                    value: "TG",
                                                                    label: "توجو",
                                                                },
                                                                {
                                                                    value: "TH",
                                                                    label: "تايلند",
                                                                },
                                                                {
                                                                    value: "TJ",
                                                                    label: "طاجكستان",
                                                                },
                                                                {
                                                                    value: "TK",
                                                                    label: "توكيلو",
                                                                },
                                                                {
                                                                    value: "TL",
                                                                    label: "تيمور الشرقية",
                                                                },
                                                                {
                                                                    value: "TM",
                                                                    label: "تركمانستان",
                                                                },
                                                                {
                                                                    value: "TN",
                                                                    label: "تونس",
                                                                },
                                                                {
                                                                    value: "TO",
                                                                    label: "تونجا",
                                                                },
                                                                {
                                                                    value: "TR",
                                                                    label: "تركيا",
                                                                },
                                                                {
                                                                    value: "TT",
                                                                    label: "ترينيداد وتوباغو",
                                                                },
                                                                {
                                                                    value: "TV",
                                                                    label: "توفالو",
                                                                },
                                                                {
                                                                    value: "TW",
                                                                    label: "تايوان",
                                                                },
                                                                {
                                                                    value: "TZ",
                                                                    label: "تانزانيا",
                                                                },
                                                                {
                                                                    value: "UA",
                                                                    label: "أوكرانيا",
                                                                },
                                                                {
                                                                    value: "UG",
                                                                    label: "أوغندا",
                                                                },
                                                                {
                                                                    value: "UM",
                                                                    label: "جزر الولايات المتحدة البعيدة الصغيرة",
                                                                },
                                                                {
                                                                    value: "US",
                                                                    label: "الولايات المتحدة الأمريكية",
                                                                },
                                                                {
                                                                    value: "UY",
                                                                    label: "أورجواي",
                                                                },
                                                                {
                                                                    value: "UZ",
                                                                    label: "أوزبكستان",
                                                                },
                                                                {
                                                                    value: "VA",
                                                                    label: "الفاتيكان",
                                                                },
                                                                {
                                                                    value: "VC",
                                                                    label: "سانت فنسنت وغرنادين",
                                                                },
                                                                {
                                                                    value: "VE",
                                                                    label: "فنزويلا",
                                                                },
                                                                {
                                                                    value: "VG",
                                                                    label: "جزر فرجين البريطانية",
                                                                },
                                                                {
                                                                    value: "VI",
                                                                    label: "جزر فرجين الأمريكية",
                                                                },
                                                                {
                                                                    value: "VN",
                                                                    label: "فيتنام",
                                                                },
                                                                {
                                                                    value: "VU",
                                                                    label: "فانواتو",
                                                                },
                                                                {
                                                                    value: "WF",
                                                                    label: "جزر والس وفوتونا",
                                                                },
                                                                {
                                                                    value: "WS",
                                                                    label: "ساموا",
                                                                },
                                                                {
                                                                    value: "XK",
                                                                    label: "كوسوفو",
                                                                },
                                                                {
                                                                    value: "YE",
                                                                    label: "اليمن",
                                                                },
                                                                {
                                                                    value: "YT",
                                                                    label: "مايوت",
                                                                },
                                                                {
                                                                    value: "ZA",
                                                                    label: "جمهورية جنوب افريقيا",
                                                                },
                                                                {
                                                                    value: "ZM",
                                                                    label: "زامبيا",
                                                                },
                                                                {
                                                                    value: "ZW",
                                                                    label: "زيمبابوي",
                                                                }
                                                            ]}/>
                                                            :""
                                                    :
                                                    <Select
                                                        showSearch
                                                        placeholder="الدولة"
                                                        onChange={handleChangeCountry}
                                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        options={[
                                                            {
                                                                value: "AD",
                                                                label: "أندورا",
                                                            },
                                                            {
                                                                value: "AE",
                                                                label: "الامارات العربية المتحدة",
                                                            },
                                                            {
                                                                value: "AF",
                                                                label: "أفغانستان",
                                                            },
                                                            {
                                                                value: "AG",
                                                                label: "أنتيجوا وبربودا",
                                                            },
                                                            {
                                                                value: "AI",
                                                                label: "أنجويلا",
                                                            },
                                                            {
                                                                value: "AL",
                                                                label: "ألبانيا",
                                                            },
                                                            {
                                                                value: "AM",
                                                                label: "أرمينيا",
                                                            },
                                                            {
                                                                value: "AO",
                                                                label: "أنجولا",
                                                            },
                                                            {
                                                                value: "AQ",
                                                                label: "القطب الجنوبي",
                                                            },
                                                            {
                                                                value: "AR",
                                                                label: "الأرجنتين",
                                                            },
                                                            {
                                                                value: "AS",
                                                                label: "ساموا الأمريكية",
                                                            },
                                                            {
                                                                value: "AT",
                                                                label: "النمسا",
                                                            },
                                                            {
                                                                value: "AU",
                                                                label: "أستراليا",
                                                            },
                                                            {
                                                                value: "AW",
                                                                label: "آروبا",
                                                            },
                                                            {
                                                                value: "AX",
                                                                label: "جزر أولان",
                                                            },
                                                            {
                                                                value: "AZ",
                                                                label: "أذربيجان",
                                                            },
                                                            {
                                                                value: "BA",
                                                                label: "البوسنة والهرسك",
                                                            },
                                                            {
                                                                value: "BB",
                                                                label: "بربادوس",
                                                            },
                                                            {
                                                                value: "BD",
                                                                label: "بنجلاديش",
                                                            },
                                                            {
                                                                value: "BE",
                                                                label: "بلجيكا",
                                                            },
                                                            {
                                                                value: "BF",
                                                                label: "بوركينا فاسو",
                                                            },
                                                            {
                                                                value: "BG",
                                                                label: "بلغاريا",
                                                            },
                                                            {
                                                                value: "BH",
                                                                label: "البحرين",
                                                            },
                                                            {
                                                                value: "BI",
                                                                label: "بوروندي",
                                                            },
                                                            {
                                                                value: "BJ",
                                                                label: "بنين",
                                                            },
                                                            {
                                                                value: "BL",
                                                                label: "سان بارتيلمي",
                                                            },
                                                            {
                                                                value: "BM",
                                                                label: "برمودا",
                                                            },
                                                            {
                                                                value: "BN",
                                                                label: "بروناي",
                                                            },
                                                            {
                                                                value: "BO",
                                                                label: "بوليفيا",
                                                            },
                                                            {
                                                                value: "BQ",
                                                                label: "بونير",
                                                            },
                                                            {
                                                                value: "BR",
                                                                label: "البرازيل",
                                                            },
                                                            {
                                                                value: "BS",
                                                                label: "الباهاما",
                                                            },
                                                            {
                                                                value: "BT",
                                                                label: "بوتان",
                                                            },
                                                            {
                                                                value: "BV",
                                                                label: "جزيرة بوفيه",
                                                            },
                                                            {
                                                                value: "BW",
                                                                label: "بتسوانا",
                                                            },
                                                            {
                                                                value: "BY",
                                                                label: "روسيا البيضاء",
                                                            },
                                                            {
                                                                value: "BZ",
                                                                label: "بليز",
                                                            },
                                                            {
                                                                value: "CA",
                                                                label: "كندا",
                                                            },
                                                            {
                                                                value: "CC",
                                                                label: "جزر كوكوس",
                                                            },
                                                            {
                                                                value: "CD",
                                                                label: "جمهورية الكونغو الديمقراطية",
                                                            },
                                                            {
                                                                value: "CF",
                                                                label: "جمهورية افريقيا الوسطى",
                                                            },
                                                            {
                                                                value: "CG",
                                                                label: "الكونغو - برازافيل",
                                                            },
                                                            {
                                                                value: "CH",
                                                                label: "سويسرا",
                                                            },
                                                            {
                                                                value: "CI",
                                                                label: "ساحل العاج",
                                                            },
                                                            {
                                                                value: "CK",
                                                                label: "جزر كوك",
                                                            },
                                                            {
                                                                value: "CL",
                                                                label: "شيلي",
                                                            },
                                                            {
                                                                value: "CM",
                                                                label: "الكاميرون",
                                                            },
                                                            {
                                                                value: "CN",
                                                                label: "الصين",
                                                            },
                                                            {
                                                                value: "CO",
                                                                label: "كولومبيا",
                                                            },
                                                            {
                                                                value: "CR",
                                                                label: "كوستاريكا",
                                                            },
                                                            {
                                                                value: "CU",
                                                                label: "كوبا",
                                                            },
                                                            {
                                                                value: "CV",
                                                                label: "الرأس الأخضر",
                                                            },
                                                            {
                                                                value: "CW",
                                                                label: "كوراساو",
                                                            },
                                                            {
                                                                value: "CX",
                                                                label: "جزيرة الكريسماس",
                                                            },
                                                            {
                                                                value: "CY",
                                                                label: "قبرص",
                                                            },
                                                            {
                                                                value: "CZ",
                                                                label: "جمهورية التشيك",
                                                            },
                                                            {
                                                                value: "DE",
                                                                label: "ألمانيا",
                                                            },
                                                            {
                                                                value: "DJ",
                                                                label: "جيبوتي",
                                                            },
                                                            {
                                                                value: "DK",
                                                                label: "الدانمرك",
                                                            },
                                                            {
                                                                value: "DM",
                                                                label: "دومينيكا",
                                                            },
                                                            {
                                                                value: "DO",
                                                                label: "جمهورية الدومينيك",
                                                            },
                                                            {
                                                                value: "DZ",
                                                                label: "الجزائر",
                                                            },
                                                            {
                                                                value: "EC",
                                                                label: "الاكوادور",
                                                            },
                                                            {
                                                                value: "EE",
                                                                label: "استونيا",
                                                            },
                                                            {
                                                                value: "EG",
                                                                label: "مصر",
                                                            },
                                                            {
                                                                value: "EH",
                                                                label: "الصحراء الغربية",
                                                            },
                                                            {
                                                                value: "ER",
                                                                label: "اريتريا",
                                                            },
                                                            {
                                                                value: "ES",
                                                                label: "أسبانيا",
                                                            },
                                                            {
                                                                value: "ET",
                                                                label: "اثيوبيا",
                                                            },
                                                            {
                                                                value: "FI",
                                                                label: "فنلندا",
                                                            },
                                                            {
                                                                value: "FJ",
                                                                label: "فيجي",
                                                            },
                                                            {
                                                                value: "FK",
                                                                label: "جزر فوكلاند",
                                                            },
                                                            {
                                                                value: "FM",
                                                                label: "ميكرونيزيا",
                                                            },
                                                            {
                                                                value: "FO",
                                                                label: "جزر فارو",
                                                            },
                                                            {
                                                                value: "FR",
                                                                label: "فرنسا",
                                                            },
                                                            {
                                                                value: "GA",
                                                                label: "الجابون",
                                                            },
                                                            {
                                                                value: "GB",
                                                                label: "المملكة المتحدة",
                                                            },
                                                            {
                                                                value: "GD",
                                                                label: "جرينادا",
                                                            },
                                                            {
                                                                value: "GE",
                                                                label: "جورجيا",
                                                            },
                                                            {
                                                                value: "GF",
                                                                label: "غويانا",
                                                            },
                                                            {
                                                                value: "GG",
                                                                label: "غيرنزي",
                                                            },
                                                            {
                                                                value: "GH",
                                                                label: "غانا",
                                                            },
                                                            {
                                                                value: "GI",
                                                                label: "جبل طارق",
                                                            },
                                                            {
                                                                value: "GL",
                                                                label: "جرينلاند",
                                                            },
                                                            {
                                                                value: "GM",
                                                                label: "غامبيا",
                                                            },
                                                            {
                                                                value: "GN",
                                                                label: "غينيا",
                                                            },
                                                            {
                                                                value: "GP",
                                                                label: "جوادلوب",
                                                            },
                                                            {
                                                                value: "GQ",
                                                                label: "غينيا الاستوائية",
                                                            },
                                                            {
                                                                value: "GR",
                                                                label: "اليونان",
                                                            },
                                                            {
                                                                value: "GS",
                                                                label: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
                                                            },
                                                            {
                                                                value: "GT",
                                                                label: "جواتيمالا",
                                                            },
                                                            {
                                                                value: "GU",
                                                                label: "جوام",
                                                            },
                                                            {
                                                                value: "GW",
                                                                label: "غينيا بيساو",
                                                            },
                                                            {
                                                                value: "GY",
                                                                label: "غيانا",
                                                            },
                                                            {
                                                                value: "HK",
                                                                label: "هونج كونج الصينية",
                                                            },
                                                            {
                                                                value: "HM",
                                                                label: "جزيرة هيرد وماكدونالد",
                                                            },
                                                            {
                                                                value: "HN",
                                                                label: "هندوراس",
                                                            },
                                                            {
                                                                value: "HR",
                                                                label: "كرواتيا",
                                                            },
                                                            {
                                                                value: "HT",
                                                                label: "هايتي",
                                                            },
                                                            {
                                                                value: "HU",
                                                                label: "المجر",
                                                            },
                                                            {
                                                                value: "ID",
                                                                label: "اندونيسيا",
                                                            },
                                                            {
                                                                value: "IE",
                                                                label: "أيرلندا",
                                                            },
                                                            {
                                                                value: "IL",
                                                                label: "اسرائيل",
                                                            },
                                                            {
                                                                value: "IM",
                                                                label: "جزيرة مان",
                                                            },
                                                            {
                                                                value: "IN",
                                                                label: "الهند",
                                                            },
                                                            {
                                                                value: "IO",
                                                                label: "المحيط الهندي البريطاني",
                                                            },
                                                            {
                                                                value: "IQ",
                                                                label: "العراق",
                                                            },
                                                            {
                                                                value: "IR",
                                                                label: "ايران",
                                                            },
                                                            {
                                                                value: "IS",
                                                                label: "أيسلندا",
                                                            },
                                                            {
                                                                value: "IT",
                                                                label: "ايطاليا",
                                                            },
                                                            {
                                                                value: "JE",
                                                                label: "جيرسي",
                                                            },
                                                            {
                                                                value: "JM",
                                                                label: "جامايكا",
                                                            },
                                                            {
                                                                value: "JO",
                                                                label: "الأردن",
                                                            },
                                                            {
                                                                value: "JP",
                                                                label: "اليابان",
                                                            },
                                                            {
                                                                value: "KE",
                                                                label: "كينيا",
                                                            },
                                                            {
                                                                value: "KG",
                                                                label: "قرغيزستان",
                                                            },
                                                            {
                                                                value: "KH",
                                                                label: "كمبوديا",
                                                            },
                                                            {
                                                                value: "KI",
                                                                label: "كيريباتي",
                                                            },
                                                            {
                                                                value: "KM",
                                                                label: "جزر القمر",
                                                            },
                                                            {
                                                                value: "KN",
                                                                label: "سانت كيتس ونيفيس",
                                                            },
                                                            {
                                                                value: "KP",
                                                                label: "كوريا الشمالية",
                                                            },
                                                            {
                                                                value: "KR",
                                                                label: "كوريا الجنوبية",
                                                            },
                                                            {
                                                                value: "KW",
                                                                label: "الكويت",
                                                            },
                                                            {
                                                                value: "KY",
                                                                label: "جزر الكايمن",
                                                            },
                                                            {
                                                                value: "KZ",
                                                                label: "كازاخستان",
                                                            },
                                                            {
                                                                value: "LA",
                                                                label: "لاوس",
                                                            },
                                                            {
                                                                value: "LB",
                                                                label: "لبنان",
                                                            },
                                                            {
                                                                value: "LC",
                                                                label: "سانت لوسيا",
                                                            },
                                                            {
                                                                value: "LI",
                                                                label: "ليختنشتاين",
                                                            },
                                                            {
                                                                value: "LK",
                                                                label: "سريلانكا",
                                                            },
                                                            {
                                                                value: "LR",
                                                                label: "ليبيريا",
                                                            },
                                                            {
                                                                value: "LS",
                                                                label: "ليسوتو",
                                                            },
                                                            {
                                                                value: "LT",
                                                                label: "ليتوانيا",
                                                            },
                                                            {
                                                                value: "LU",
                                                                label: "لوكسمبورج",
                                                            },
                                                            {
                                                                value: "LV",
                                                                label: "لاتفيا",
                                                            },
                                                            {
                                                                value: "LY",
                                                                label: "ليبيا",
                                                            },
                                                            {
                                                                value: "MA",
                                                                label: "المغرب",
                                                            },
                                                            {
                                                                value: "MC",
                                                                label: "موناكو",
                                                            },
                                                            {
                                                                value: "MD",
                                                                label: "مولدافيا",
                                                            },
                                                            {
                                                                value: "ME",
                                                                label: "الجبل الأسود",
                                                            },
                                                            {
                                                                value: "MF",
                                                                label: "سانت مارتين",
                                                            },
                                                            {
                                                                value: "MG",
                                                                label: "مدغشقر",
                                                            },
                                                            {
                                                                value: "MH",
                                                                label: "جزر المارشال",
                                                            },
                                                            {
                                                                value: "MK",
                                                                label: "مقدونيا",
                                                            },
                                                            {
                                                                value: "ML",
                                                                label: "مالي",
                                                            },
                                                            {
                                                                value: "MM",
                                                                label: "ميانمار",
                                                            },
                                                            {
                                                                value: "MN",
                                                                label: "منغوليا",
                                                            },
                                                            {
                                                                value: "MO",
                                                                label: "ماكاو الصينية",
                                                            },
                                                            {
                                                                value: "MP",
                                                                label: "جزر ماريانا الشمالية",
                                                            },
                                                            {
                                                                value: "MQ",
                                                                label: "مارتينيك",
                                                            },
                                                            {
                                                                value: "MR",
                                                                label: "موريتانيا",
                                                            },
                                                            {
                                                                value: "MS",
                                                                label: "مونتسرات",
                                                            },
                                                            {
                                                                value: "MT",
                                                                label: "مالطا",
                                                            },
                                                            {
                                                                value: "MU",
                                                                label: "موريشيوس",
                                                            },
                                                            {
                                                                value: "MV",
                                                                label: "جزر الملديف",
                                                            },
                                                            {
                                                                value: "MW",
                                                                label: "ملاوي",
                                                            },
                                                            {
                                                                value: "MX",
                                                                label: "المكسيك",
                                                            },
                                                            {
                                                                value: "MY",
                                                                label: "ماليزيا",
                                                            },
                                                            {
                                                                value: "MZ",
                                                                label: "موزمبيق",
                                                            },
                                                            {
                                                                value: "NA",
                                                                label: "ناميبيا",
                                                            },
                                                            {
                                                                value: "NC",
                                                                label: "كاليدونيا الجديدة",
                                                            },
                                                            {
                                                                value: "NE",
                                                                label: "النيجر",
                                                            },
                                                            {
                                                                value: "NF",
                                                                label: "جزيرة نورفوك",
                                                            },
                                                            {
                                                                value: "NG",
                                                                label: "نيجيريا",
                                                            },
                                                            {
                                                                value: "NI",
                                                                label: "نيكاراجوا",
                                                            },
                                                            {
                                                                value: "NL",
                                                                label: "هولندا",
                                                            },
                                                            {
                                                                value: "NO",
                                                                label: "النرويج",
                                                            },
                                                            {
                                                                value: "NP",
                                                                label: "نيبال",
                                                            },
                                                            {
                                                                value: "NR",
                                                                label: "نورو",
                                                            },
                                                            {
                                                                value: "NU",
                                                                label: "نيوي",
                                                            },
                                                            {
                                                                value: "NZ",
                                                                label: "نيوزيلاندا",
                                                            },
                                                            {
                                                                value: "OM",
                                                                label: "عمان",
                                                            },
                                                            {
                                                                value: "PA",
                                                                label: "بنما",
                                                            },
                                                            {
                                                                value: "PE",
                                                                label: "بيرو",
                                                            },
                                                            {
                                                                value: "PF",
                                                                label: "بولينيزيا الفرنسية",
                                                            },
                                                            {
                                                                value: "PG",
                                                                label: "بابوا غينيا الجديدة",
                                                            },
                                                            {
                                                                value: "PH",
                                                                label: "الفيلبين",
                                                            },
                                                            {
                                                                value: "PK",
                                                                label: "باكستان",
                                                            },
                                                            {
                                                                value: "PL",
                                                                label: "بولندا",
                                                            },
                                                            {
                                                                value: "PM",
                                                                label: "سانت بيير وميكولون",
                                                            },
                                                            {
                                                                value: "PN",
                                                                label: "بتكايرن",
                                                            },
                                                            {
                                                                value: "PR",
                                                                label: "بورتوريكو",
                                                            },
                                                            {
                                                                value: "PS",
                                                                label: "فلسطين",
                                                            },
                                                            {
                                                                value: "PT",
                                                                label: "البرتغال",
                                                            },
                                                            {
                                                                value: "PW",
                                                                label: "بالاو",
                                                            },
                                                            {
                                                                value: "PY",
                                                                label: "باراجواي",
                                                            },
                                                            {
                                                                value: "QA",
                                                                label: "قطر",
                                                            },
                                                            {
                                                                value: "RE",
                                                                label: "روينيون",
                                                            },
                                                            {
                                                                value: "RO",
                                                                label: "رومانيا",
                                                            },
                                                            {
                                                                value: "RS",
                                                                label: "صربيا",
                                                            },
                                                            {
                                                                value: "RU",
                                                                label: "روسيا",
                                                            },
                                                            {
                                                                value: "RW",
                                                                label: "رواندا",
                                                            },
                                                            {
                                                                value: "SA",
                                                                label: "المملكة العربية السعودية",
                                                            },
                                                            {
                                                                value: "SB",
                                                                label: "جزر سليمان",
                                                            },
                                                            {
                                                                value: "SC",
                                                                label: "سيشل",
                                                            },
                                                            {
                                                                value: "SD",
                                                                label: "السودان",
                                                            },
                                                            {
                                                                value: "SE",
                                                                label: "السويد",
                                                            },
                                                            {
                                                                value: "SG",
                                                                label: "سنغافورة",
                                                            },
                                                            {
                                                                value: "SH",
                                                                label: "سانت هيلنا",
                                                            },
                                                            {
                                                                value: "SI",
                                                                label: "سلوفينيا",
                                                            },
                                                            {
                                                                value: "SJ",
                                                                label: "سفالبارد وجان مايان",
                                                            },
                                                            {
                                                                value: "SK",
                                                                label: "سلوفاكيا",
                                                            },
                                                            {
                                                                value: "SL",
                                                                label: "سيراليون",
                                                            },
                                                            {
                                                                value: "SM",
                                                                label: "سان مارينو",
                                                            },
                                                            {
                                                                value: "SN",
                                                                label: "السنغال",
                                                            },
                                                            {
                                                                value: "SO",
                                                                label: "الصومال",
                                                            },
                                                            {
                                                                value: "SR",
                                                                label: "سورينام",
                                                            },
                                                            {
                                                                value: "SS",
                                                                label: "جنوب السودان",
                                                            },
                                                            {
                                                                value: "ST",
                                                                label: "ساو تومي وبرينسيبي",
                                                            },
                                                            {
                                                                value: "SV",
                                                                label: "السلفادور",
                                                            },
                                                            {
                                                                value: "SX",
                                                                label: "سينت مارتن",
                                                            },
                                                            {
                                                                value: "SY",
                                                                label: "سوريا",
                                                            },
                                                            {
                                                                value: "SZ",
                                                                label: "سوازيلاند",
                                                            },
                                                            {
                                                                value: "TC",
                                                                label: "جزر الترك وجايكوس",
                                                            },
                                                            {
                                                                value: "TD",
                                                                label: "تشاد",
                                                            },
                                                            {
                                                                value: "TF",
                                                                label: "المقاطعات الجنوبية الفرنسية",
                                                            },
                                                            {
                                                                value: "TG",
                                                                label: "توجو",
                                                            },
                                                            {
                                                                value: "TH",
                                                                label: "تايلند",
                                                            },
                                                            {
                                                                value: "TJ",
                                                                label: "طاجكستان",
                                                            },
                                                            {
                                                                value: "TK",
                                                                label: "توكيلو",
                                                            },
                                                            {
                                                                value: "TL",
                                                                label: "تيمور الشرقية",
                                                            },
                                                            {
                                                                value: "TM",
                                                                label: "تركمانستان",
                                                            },
                                                            {
                                                                value: "TN",
                                                                label: "تونس",
                                                            },
                                                            {
                                                                value: "TO",
                                                                label: "تونجا",
                                                            },
                                                            {
                                                                value: "TR",
                                                                label: "تركيا",
                                                            },
                                                            {
                                                                value: "TT",
                                                                label: "ترينيداد وتوباغو",
                                                            },
                                                            {
                                                                value: "TV",
                                                                label: "توفالو",
                                                            },
                                                            {
                                                                value: "TW",
                                                                label: "تايوان",
                                                            },
                                                            {
                                                                value: "TZ",
                                                                label: "تانزانيا",
                                                            },
                                                            {
                                                                value: "UA",
                                                                label: "أوكرانيا",
                                                            },
                                                            {
                                                                value: "UG",
                                                                label: "أوغندا",
                                                            },
                                                            {
                                                                value: "UM",
                                                                label: "جزر الولايات المتحدة البعيدة الصغيرة",
                                                            },
                                                            {
                                                                value: "US",
                                                                label: "الولايات المتحدة الأمريكية",
                                                            },
                                                            {
                                                                value: "UY",
                                                                label: "أورجواي",
                                                            },
                                                            {
                                                                value: "UZ",
                                                                label: "أوزبكستان",
                                                            },
                                                            {
                                                                value: "VA",
                                                                label: "الفاتيكان",
                                                            },
                                                            {
                                                                value: "VC",
                                                                label: "سانت فنسنت وغرنادين",
                                                            },
                                                            {
                                                                value: "VE",
                                                                label: "فنزويلا",
                                                            },
                                                            {
                                                                value: "VG",
                                                                label: "جزر فرجين البريطانية",
                                                            },
                                                            {
                                                                value: "VI",
                                                                label: "جزر فرجين الأمريكية",
                                                            },
                                                            {
                                                                value: "VN",
                                                                label: "فيتنام",
                                                            },
                                                            {
                                                                value: "VU",
                                                                label: "فانواتو",
                                                            },
                                                            {
                                                                value: "WF",
                                                                label: "جزر والس وفوتونا",
                                                            },
                                                            {
                                                                value: "WS",
                                                                label: "ساموا",
                                                            },
                                                            {
                                                                value: "XK",
                                                                label: "كوسوفو",
                                                            },
                                                            {
                                                                value: "YE",
                                                                label: "اليمن",
                                                            },
                                                            {
                                                                value: "YT",
                                                                label: "مايوت",
                                                            },
                                                            {
                                                                value: "ZA",
                                                                label: "جمهورية جنوب افريقيا",
                                                            },
                                                            {
                                                                value: "ZM",
                                                                label: "زامبيا",
                                                            },
                                                            {
                                                                value: "ZW",
                                                                label: "زيمبابوي",
                                                            }
                                                        ]}/>
                                                }

                                            </div>
                                            <div className="col-md-4">
                                                <input className="w-100" onChange={(e)=> {
                                                    setReligion(e.target.value)
                                                }} value={getReligion} type="text" placeholder="الدين والمذهب" maxLength="500"/>
                                            </div>
                                            <div className="col-md-4">
                                                <input className="w-100" onChange={(e)=> {
                                                    setTitle(e.target.value)
                                                }} value={getTitle} type="text" required placeholder="الموضوع" maxLength="500"/>
                                            </div>
                                            <div className="col-md-4">
                                                <input className="w-100 email-input" onChange={(e)=> {
                                                    setEmail(e.target.value)
                                                }} value={getEmail} type="email" placeholder="البريد الالكتروني"
                                                       required/>
                                            </div>
                                            <div className="col-12">
                                                <input className="w-100" onChange={(e)=> {
                                                    setBody(e.target.value)
                                                }} value={getBody}  type="text" placeholder="نص الرسالة:"
                                                       required/>
                                            </div>
                                            <div className="col-12 d-flex justify-content-sm-end justify-content-center align-items-center mt-5 ">
                                                <button type="submit" title="إرسال رسالة" aria-label="إرسال رسالة" className="more-btn btn fw-light border-0">
                                                    إرسال رسالة
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="row border-red-and-dashed justify-content-center border-green-and-dashed border-green-and-dashed-2">
                            <div className="col-7 position-relative"></div>
                        </div>
                    </div>
                </div>

                <div className="send-answer border-radius-m10">
                    <div className="aq-container">
                        <div className="row justify-content-center">
                            <div className="col-sm-7 col-12 position-relative py-sm-5 py-4">
                                <div className="green-border white-b after-t-0 align-items-center">
                                    <div
                                        className="user-name-container user-name-container-line-white-b align-items-start">
                                        <span
                                            className="go-to-left color-red text-white mt-3 pt-2 hidden-phone">معلومات التواصل</span>
                                        <div className="phone-center">
                                            <div className="w-34 ms-2 mt-3 phone-inline">
                                                <svg className="z-index-2 position-relative"  width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="17" cy="17" r="17" fill="white"/>
                                                    <path d="M23.1075 11.8149C23.6693 12.6557 23.6693 13.8261 23.6693 16.1668C23.6693 18.5076 23.6693 19.678 23.1075 20.5187C22.8643 20.8827 22.5518 21.1952 22.1878 21.4384C21.4534 21.9291 20.4674 21.9912 18.6693 21.999V22.0002L17.748 23.8428C17.4409 24.457 16.5644 24.457 16.2572 23.8428L15.3359 22.0002V21.999C13.5379 21.9912 12.5518 21.9291 11.8174 21.4384C11.4534 21.1952 11.1409 20.8827 10.8977 20.5187C10.3359 19.678 10.3359 18.5076 10.3359 16.1668C10.3359 13.8261 10.3359 12.6557 10.8977 11.8149C11.1409 11.451 11.4534 11.1385 11.8174 10.8953C12.6581 10.3335 13.8285 10.3335 16.1693 10.3335H17.8359C20.1767 10.3335 21.3471 10.3335 22.1878 10.8953C22.5518 11.1385 22.8643 11.451 23.1075 11.8149Z" stroke="#045E5E" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.5 14.5L19.5 14.5" stroke="#045E5E" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.5 17.8333H17" stroke="#045E5E" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </div>
                                            <span className="color-red text-white mt-3 pt-2 phone-inline hidden-desktop">
                                              معلومات التواصل
                                            </span>
                                        </div>
                                        <div className="pe-sm-2 pe-0 w-100">
                                            <div className="books-module books-module-2 pb-0 position-relative">
                                                <div className="col-12">
                                                    <p className="text-page-title fw-bold text-white mt-sm-3 mt-0 mb-2 mb-sm-0
                                                     phone-center mb-0">
                                                        طرق الاتصال بنا
                                                    </p>
                                                </div>
                                            </div>
                                            <table className="book-value-container book-value-container-3">
                                                <tbody>
                                                <tr>
                                                    <th className="key text-white">
                                                        <svg width="27" height="30" viewBox="0 0 27 30" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <rect x="5" y="7.5" width="20" height="15" rx="2"
                                                                  stroke="white" strokeOpacity="0.95"/>
                                                            <path
                                                                d="M5 11.25L14.1056 15.8028C14.6686 16.0843 15.3314 16.0843 15.8944 15.8028L25 11.25"
                                                                stroke="white" strokeOpacity="0.95"/>
                                                        </svg>
                                                        البريد الالكتروني
                                                    </th>
                                                    <td className="value text-white-60 color-fff">
                                                        <NavLink className="text-white-60" to="mailto:info@aqaed.com" target="_blank" rel="nofollow"
                                                                 title="info@aqaed.com">
                                                            info@aqaed.com
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="key text-white">
                                                        <svg width="24" height="30" viewBox="0 0 24 30" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M19.6095 18.2761L23.1403 21.807C23.6151 22.2818 23.6151 23.0516 23.1403 23.5264C20.5734 26.0933 16.5095 26.3821 13.6053 24.204L11.5048 22.6286C9.18006 20.885 7.11496 18.8199 5.37143 16.4952L3.79601 14.3947C1.61788 11.4905 1.90669 7.42665 4.47364 4.85969C4.94844 4.3849 5.71823 4.3849 6.19303 4.85969L9.72386 8.39052C10.2446 8.91122 10.2446 9.75544 9.72386 10.2761L8.36233 11.6377C8.14595 11.8541 8.09231 12.1846 8.22916 12.4583C9.81137 15.6227 12.3773 18.1886 15.5417 19.7708C15.8154 19.9077 16.1459 19.8541 16.3623 19.6377L17.7239 18.2761C18.2446 17.7554 19.0888 17.7554 19.6095 18.2761Z"
                                                                stroke="white" strokeOpacity="0.95"/>
                                                        </svg>

                                                        واتساب التواصل مع المركز
                                                    </th>
                                                    <td className="value text-white-60 color-fff">
                                                        <NavLink className="text-white-60" target="_blank" rel="nofollow"
                                                                 to="https://api.whatsapp.com/send?phone=9647815599556"
                                                                 title="9647815599556">
                                                            +9647815599556
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="key text-white">
                                                        <svg width="24" height="30" viewBox="0 0 24 30" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M19.6095 18.2761L23.1403 21.807C23.6151 22.2818 23.6151 23.0516 23.1403 23.5264C20.5734 26.0933 16.5095 26.3821 13.6053 24.204L11.5048 22.6286C9.18006 20.885 7.11496 18.8199 5.37143 16.4952L3.79601 14.3947C1.61788 11.4905 1.90669 7.42665 4.47364 4.85969C4.94844 4.3849 5.71823 4.3849 6.19303 4.85969L9.72386 8.39052C10.2446 8.91122 10.2446 9.75544 9.72386 10.2761L8.36233 11.6377C8.14595 11.8541 8.09231 12.1846 8.22916 12.4583C9.81137 15.6227 12.3773 18.1886 15.5417 19.7708C15.8154 19.9077 16.1459 19.8541 16.3623 19.6377L17.7239 18.2761C18.2446 17.7554 19.0888 17.7554 19.6095 18.2761Z"
                                                                stroke="white" strokeOpacity="0.95"/>
                                                        </svg>

                                                        واتساب الإجابة على الأسئلة
                                                    </th>
                                                    <td className="value text-white-60 color-fff">
                                                        <NavLink className="text-white-60" target="_blank" rel="nofollow"
                                                                 to="https://api.whatsapp.com/send?phone=989392532308"
                                                                 title="989392532308">
                                                            +989392532308
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-sm-7 col-12 position-relative pt-sm-5 pt-3">
                                <div className="green-border white-b after-t-0  align-items-center">
                                    <div
                                        className="user-name-container user-name-container-line-white-b after-b-0 align-items-start">
                                        <span
                                            className="go-to-left color-red text-white mt-3 hidden-phone pt-2">منصّات التواصل</span>
                                        <div className="phone-center">
                                            <div className="w-34 phone-inline ms-2 mt-3">
                                                <svg className="z-index-2 position-relative"  width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="17" cy="17" r="17" fill="white"/>
                                                    <path d="M23.1075 11.8149C23.6693 12.6557 23.6693 13.8261 23.6693 16.1668C23.6693 18.5076 23.6693 19.678 23.1075 20.5187C22.8643 20.8827 22.5518 21.1952 22.1878 21.4384C21.4534 21.9291 20.4674 21.9912 18.6693 21.999V22.0002L17.748 23.8428C17.4409 24.457 16.5644 24.457 16.2572 23.8428L15.3359 22.0002V21.999C13.5379 21.9912 12.5518 21.9291 11.8174 21.4384C11.4534 21.1952 11.1409 20.8827 10.8977 20.5187C10.3359 19.678 10.3359 18.5076 10.3359 16.1668C10.3359 13.8261 10.3359 12.6557 10.8977 11.8149C11.1409 11.451 11.4534 11.1385 11.8174 10.8953C12.6581 10.3335 13.8285 10.3335 16.1693 10.3335H17.8359C20.1767 10.3335 21.3471 10.3335 22.1878 10.8953C22.5518 11.1385 22.8643 11.451 23.1075 11.8149Z" stroke="#045E5E" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.5 14.5L19.5 14.5" stroke="#045E5E" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.5 17.8333H17" stroke="#045E5E" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                            </div>
                                            <span
                                                className="color-red text-white phone-inline mt-3 pt-2 hidden-desktop">منصّات التواصل</span>
                                        </div>
                                        <div className="pe-sm-2 pe-0 w-100 mb-5">
                                            <div className="books-module books-module-2 pb-0 position-relative">
                                                <div className="col-12">
                                                    <p className="text-page-title fw-bold text-white mt-sm-3 mt-0 mb-4 mb-sm-0 phone-center mb-0">
                                                        تابعونا على وسائل التواصل الاجتماعي
                                                    </p>
                                                </div>
                                            </div>
                                            <ul className="social-container-ul ul-ir-r mt-3 hidden-phone">
                                                <li>
                                                    <NavLink to="https://instagram.com/aqaed.ar" title="instagram" target="_blank" rel="nofollow">
                                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_593_11481)">
                                                                <path
                                                                    d="M14.016 27.0239C21.2088 27.0239 27.0398 21.193 27.0398 14.0001C27.0398 6.80727 21.2088 0.976318 14.016 0.976318C6.82314 0.976318 0.992188 6.80727 0.992188 14.0001C0.992188 21.193 6.82314 27.0239 14.016 27.0239Z"
                                                                    fill="white" fillOpacity="0.6"/>
                                                                <path
                                                                    d="M18.0679 9.07776C17.5731 9.07776 17.1719 9.47915 17.1719 9.97389C17.1719 10.4686 17.5731 10.87 18.0679 10.87C18.5627 10.87 18.9641 10.4686 18.9641 9.97389C18.9641 9.47915 18.5627 9.07776 18.0679 9.07776Z"
                                                                    fill="#045E5E"/>
                                                                <path
                                                                    d="M14.0804 10.2242C12.0062 10.2242 10.3203 11.912 10.3203 13.9843C10.3203 16.0565 12.0081 17.7443 14.0804 17.7443C16.1526 17.7443 17.8404 16.0565 17.8404 13.9843C17.8404 11.912 16.1526 10.2242 14.0804 10.2242ZM14.0804 16.3945C12.7511 16.3945 11.672 15.3135 11.672 13.9861C11.672 12.6587 12.753 11.5778 14.0804 11.5778C15.4078 11.5778 16.4888 12.6587 16.4888 13.9861C16.4888 15.3135 15.4078 16.3945 14.0804 16.3945Z"
                                                                    fill="#045E5E"/>
                                                                <path
                                                                    d="M17.0751 21.6164H10.9777C8.44797 21.6164 6.39062 19.5591 6.39062 17.0293V10.9319C6.39062 8.40222 8.44797 6.34485 10.9777 6.34485H17.0751C19.6049 6.34485 21.6622 8.40222 21.6622 10.9319V17.0293C21.6622 19.5591 19.6049 21.6164 17.0751 21.6164ZM10.9777 7.78239C9.24142 7.78239 7.82817 9.19567 7.82817 10.9319V17.0293C7.82817 18.7657 9.24142 20.1789 10.9777 20.1789H17.0751C18.8115 20.1789 20.2247 18.7657 20.2247 17.0293V10.9319C20.2247 9.1938 18.8115 7.78239 17.0751 7.78239H10.9777Z"
                                                                    fill="#045E5E"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_593_11481">
                                                                    <rect width="27.35" height="26.0476" fill="white"
                                                                          transform="translate(0.34375 0.976196)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        @aqaed.ar
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="https://facebook.com/aqaed" title="facebook" target="_blank" rel="nofollow">
                                                        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M27.8389 15.0029C27.8389 8.10073 22.2436 2.50537 15.3413 2.50537C8.4391 2.50537 2.84375 8.10073 2.84375 15.0029C2.84375 21.2408 7.41394 26.4111 13.3886 27.3487V18.6155H10.2154V15.0029H13.3886V12.2496C13.3886 9.11738 15.2543 7.38724 18.109 7.38724C19.4764 7.38724 20.9066 7.63134 20.9066 7.63134V10.7069H19.3307C17.7782 10.7069 17.2941 11.6703 17.2941 12.6586V15.0029H20.7602L20.2061 18.6155H17.2941V27.3487C23.2687 26.4111 27.8389 21.2408 27.8389 15.0029Z" fill="white" fill-opacity="0.6"/>
                                                        </svg>

                                                        @aqaed
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="https://t.me/aqaed_com" title="telegram" target="_blank" rel="nofollow">
                                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_593_11465)">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M26.7038 14C26.7038 21.1928 20.8728 27.0238 13.68 27.0238C6.4872 27.0238 0.65625 21.1928 0.65625 14C0.65625 6.80715 6.4872 0.976196 13.68 0.976196C20.8728 0.976196 26.7038 6.80715 26.7038 14ZM14.1467 10.5909C12.88 11.1178 10.3483 12.2083 6.55156 13.8625C5.93503 14.1077 5.61206 14.3476 5.58267 14.582C5.53298 14.9785 6.02933 15.1345 6.70522 15.347C6.79716 15.376 6.89242 15.4059 6.99007 15.4376C7.65504 15.6538 8.54956 15.9067 9.01457 15.9167C9.43639 15.9258 9.90718 15.752 10.4269 15.395C13.9744 13.0004 15.8055 11.7901 15.9204 11.764C16.0015 11.7456 16.1139 11.7224 16.1901 11.7901C16.2663 11.8578 16.2588 11.986 16.2507 12.0204C16.2016 12.23 14.2532 14.0414 13.2449 14.9787C12.9306 15.271 12.7077 15.4782 12.6621 15.5256C12.56 15.6316 12.4559 15.7319 12.3559 15.8283C11.7381 16.4239 11.2749 16.8704 12.3816 17.5998C12.9134 17.9502 13.339 18.24 13.7635 18.5291C14.2272 18.845 14.6896 19.1599 15.2881 19.5522C15.4405 19.6521 15.5861 19.7559 15.7279 19.8569C16.2675 20.2416 16.7522 20.5873 17.3512 20.5321C17.6992 20.5002 18.0586 20.1729 18.2412 19.1969C18.6727 16.8904 19.5208 11.8928 19.7168 9.83343C19.734 9.65301 19.7124 9.4221 19.6951 9.32074C19.6776 9.21937 19.6414 9.07494 19.5096 8.96803C19.3536 8.84141 19.1128 8.81471 19.005 8.81652C18.5151 8.82525 17.7635 9.08657 14.1467 10.5909Z" fill="white" fillOpacity="0.6"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_593_11465">
                                                                    <rect width="27.35" height="26.0476" fill="white" transform="translate(0 0.976196)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        @aqaed_com
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="https//tiktok.com/@aqaed" title="tiktok" target="_blank" rel="nofollow">
                                                        <svg className="width-3" width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.5 27.5C20.9558 27.5 27 21.4558 27 14C27 6.54416 20.9558 0.5 13.5 0.5C6.04416 0.5 0 6.54416 0 14C0 21.4558 6.04416 27.5 13.5 27.5Z" fill="white" fillOpacity="0.6"/>
                                                            <path d="M19.0196 13.0894C18.903 13.1009 18.7848 13.1074 18.6666 13.1074C17.3759 13.1074 16.1706 12.4572 15.4629 11.3767V17.2717C15.4629 19.6774 13.5121 21.6282 11.1064 21.6282C8.70079 21.6282 6.75 19.6774 6.75 17.2717C6.75 14.8661 8.70079 12.9153 11.1064 12.9153C11.1967 12.9153 11.287 12.9235 11.3757 12.9284V15.0746C11.287 15.0631 11.1984 15.0484 11.1064 15.0484C9.87816 15.0484 8.88306 16.0435 8.88306 17.2717C8.88306 18.5 9.87816 19.4951 11.1064 19.4951C12.3347 19.4951 13.4201 18.5279 13.4201 17.2996L13.4415 7.28955H15.4957C15.6895 9.13196 17.1739 10.5704 19.0212 10.7051V13.091" fill="#045E5E"/>
                                                        </svg>
                                                        @aqaed
                                                    </NavLink>
                                                </li>
                                            </ul>
                                            <ul className="social-container-ul ul-ir-r mb-5 hidden-phone">
                                                <li>
                                                    <NavLink to="https://twitter.com/aqaed_com" title="twitter" target="_blank" rel="nofollow">
                                                        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_880_47324)">
                                                                <path d="M14.4278 27.4122C21.8351 27.4122 27.84 21.4073 27.84 14C27.84 6.59261 21.8351 0.587769 14.4278 0.587769C7.02047 0.587769 1.01562 6.59261 1.01562 14C1.01562 21.4073 7.02047 27.4122 14.4278 27.4122Z" fill="white" fillOpacity="0.6"/>
                                                                <path d="M19.8674 12.3794C19.8764 12.51 19.8764 12.6406 19.8764 12.7724C19.8764 16.7883 16.8192 21.4198 11.2289 21.4198V21.4174C9.57757 21.4198 7.9605 20.9468 6.57031 20.0549C6.81044 20.0838 7.05176 20.0982 7.29369 20.0988C8.66221 20.1 9.99162 19.6409 11.0683 18.7953C9.76774 18.7706 8.62731 17.9227 8.22891 16.6848C8.68448 16.7726 9.15389 16.7546 9.60104 16.6324C8.18317 16.3459 7.1631 15.1002 7.1631 13.6534C7.1631 13.6402 7.1631 13.6276 7.1631 13.6149C7.58557 13.8502 8.05859 13.9808 8.54245 13.9953C7.20703 13.1028 6.79539 11.3262 7.60182 9.93724C9.14487 11.836 11.4215 12.9902 13.8655 13.1124C13.6205 12.0568 13.9552 10.9507 14.7447 10.2087C15.9688 9.05799 17.894 9.11697 19.0447 10.3405C19.7253 10.2062 20.3777 9.9565 20.9747 9.60263C20.7478 10.3062 20.273 10.9037 19.6387 11.2835C20.2411 11.2125 20.8297 11.0512 21.3839 10.8051C20.9759 11.4165 20.4619 11.9491 19.8674 12.3794Z" fill="#045E5E"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_880_47324">
                                                                    <rect width="28.1656" height="26.8244" fill="white" transform="translate(0.34375 0.587769)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>

                                                        @aqaed_com
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="https://api.whatsapp.com/send?phone=989392532308" title="whatsapp" target="_blank" rel="nofollow">
                                                        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_880_47328)">
                                                                <path d="M14.3409 27.3175C21.6959 27.3175 27.6584 21.355 27.6584 14C27.6584 6.64499 21.6959 0.682556 14.3409 0.682556C6.98587 0.682556 1.02344 6.64499 1.02344 14C1.02344 21.355 6.98587 27.3175 14.3409 27.3175Z" fill="white" fillOpacity="0.6"/>
                                                                <path d="M14.0352 7.28284C10.1598 7.28284 7.00589 10.3206 7.00453 14.0539C7.00362 15.2478 7.32794 16.413 7.94287 17.4392L6.94531 20.9479L10.6727 20.0064C11.7095 20.5488 12.8624 20.8317 14.0325 20.8309H14.0352C17.9107 20.8309 21.0646 17.7927 21.0659 14.0594C21.0668 12.251 20.3362 10.5488 19.0084 9.26929C17.6811 7.98932 15.916 7.28329 14.0352 7.28284ZM14.0352 19.6871H14.033C12.9844 19.6871 11.9559 19.4156 11.0585 18.9027L10.8444 18.7807L8.63341 19.3391L9.22374 17.262L9.08481 17.0493C8.50127 16.1593 8.19063 15.1181 8.19112 14.0539C8.19248 10.9506 10.8144 8.42661 14.0375 8.42661C15.5981 8.42706 17.0652 9.0133 18.1689 10.0769C19.2726 11.1405 19.8798 12.5548 19.8789 14.0589C19.8775 17.1623 17.2561 19.6871 14.0348 19.6871H14.0352ZM17.2406 15.4714C17.0648 15.3872 16.2011 14.9777 16.0399 14.9207C15.8791 14.8647 15.762 14.8355 15.645 15.005C15.5284 15.1744 15.1913 15.5557 15.0893 15.6682C14.9863 15.7812 14.8838 15.7948 14.708 15.7106C14.5322 15.6258 13.966 15.4473 13.2955 14.8706C12.773 14.4224 12.4205 13.8685 12.318 13.6986C12.2155 13.5296 12.307 13.4381 12.395 13.3538C12.4738 13.2786 12.5708 13.1566 12.6582 13.0577C12.7457 12.9589 12.7748 12.8883 12.8341 12.7753C12.8924 12.6628 12.8632 12.5635 12.819 12.4792C12.7748 12.394 12.4241 11.5614 12.277 11.2229C12.1349 10.8932 11.99 10.9373 11.8821 10.9319C11.7796 10.9273 11.663 10.926 11.545 10.926C11.4288 10.926 11.238 10.9683 11.0767 11.1378C10.9159 11.3072 10.4618 11.7163 10.4618 12.5489C10.4618 13.382 11.0913 14.1865 11.1792 14.2994C11.2671 14.4119 12.4182 16.1214 14.1805 16.8548C14.5996 17.0283 14.9266 17.1326 15.1822 17.211C15.6031 17.3399 15.9861 17.3212 16.2886 17.278C16.6257 17.2292 17.3281 16.8689 17.4738 16.474C17.6205 16.0791 17.6205 15.7402 17.5768 15.6696C17.534 15.599 17.4164 15.5566 17.2406 15.4714Z" fill="#045E5E"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_880_47328">
                                                                    <rect width="27.9667" height="26.6349" fill="white" transform="translate(0.351562 0.682556)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        @aqaed
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="https://youtube.com/@aqaed-com" title="youtube" target="_blank" rel="nofollow">
                                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_593_11467)">
                                                                <path d="M13.8116 27.1554C21.077 27.1554 26.9669 21.2654 26.9669 14C26.9669 6.73451 21.077 0.844666 13.8116 0.844666C6.5461 0.844666 0.65625 6.73451 0.65625 14C0.65625 21.2654 6.5461 27.1554 13.8116 27.1554Z" fill="white" fillOpacity="0.6"/>
                                                                <path d="M20.8493 12.119C20.8493 10.3746 19.435 8.96027 17.6907 8.96027H9.61184C7.86747 8.96027 6.45312 10.3746 6.45312 12.119V15.8774C6.45312 17.6217 7.86747 19.036 9.61184 19.036H17.6907C19.435 19.036 20.8493 17.6217 20.8493 15.8774V12.119ZM16.099 14.2801L12.4764 16.0716C12.3349 16.149 11.8522 16.0452 11.8522 15.8849V12.2057C11.8522 12.0417 12.3387 11.9398 12.4801 12.0209L15.9481 13.9067C16.0934 13.9897 16.2461 14.1991 16.099 14.2801Z" fill="#045E5E"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_593_11467">
                                                                    <rect width="27.6262" height="26.3107" fill="white" transform="translate(0 0.844666)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        @aqaed-com
                                                    </NavLink>
                                                </li>
                                            </ul>
                                            <ul className="social-container-ul ul-ir-r mb-5 hidden-desktop">
                                                <li>
                                                    <NavLink to="https://instagram.com/aqaed.ar" title="instagram" target="_blank" rel="nofollow">
                                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_593_11481)">
                                                                <path
                                                                    d="M14.016 27.0239C21.2088 27.0239 27.0398 21.193 27.0398 14.0001C27.0398 6.80727 21.2088 0.976318 14.016 0.976318C6.82314 0.976318 0.992188 6.80727 0.992188 14.0001C0.992188 21.193 6.82314 27.0239 14.016 27.0239Z"
                                                                    fill="white" fillOpacity="0.6"/>
                                                                <path
                                                                    d="M18.0679 9.07776C17.5731 9.07776 17.1719 9.47915 17.1719 9.97389C17.1719 10.4686 17.5731 10.87 18.0679 10.87C18.5627 10.87 18.9641 10.4686 18.9641 9.97389C18.9641 9.47915 18.5627 9.07776 18.0679 9.07776Z"
                                                                    fill="#045E5E"/>
                                                                <path
                                                                    d="M14.0804 10.2242C12.0062 10.2242 10.3203 11.912 10.3203 13.9843C10.3203 16.0565 12.0081 17.7443 14.0804 17.7443C16.1526 17.7443 17.8404 16.0565 17.8404 13.9843C17.8404 11.912 16.1526 10.2242 14.0804 10.2242ZM14.0804 16.3945C12.7511 16.3945 11.672 15.3135 11.672 13.9861C11.672 12.6587 12.753 11.5778 14.0804 11.5778C15.4078 11.5778 16.4888 12.6587 16.4888 13.9861C16.4888 15.3135 15.4078 16.3945 14.0804 16.3945Z"
                                                                    fill="#045E5E"/>
                                                                <path
                                                                    d="M17.0751 21.6164H10.9777C8.44797 21.6164 6.39062 19.5591 6.39062 17.0293V10.9319C6.39062 8.40222 8.44797 6.34485 10.9777 6.34485H17.0751C19.6049 6.34485 21.6622 8.40222 21.6622 10.9319V17.0293C21.6622 19.5591 19.6049 21.6164 17.0751 21.6164ZM10.9777 7.78239C9.24142 7.78239 7.82817 9.19567 7.82817 10.9319V17.0293C7.82817 18.7657 9.24142 20.1789 10.9777 20.1789H17.0751C18.8115 20.1789 20.2247 18.7657 20.2247 17.0293V10.9319C20.2247 9.1938 18.8115 7.78239 17.0751 7.78239H10.9777Z"
                                                                    fill="#045E5E"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_593_11481">
                                                                    <rect width="27.35" height="26.0476" fill="white"
                                                                          transform="translate(0.34375 0.976196)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        @aqaed.ar
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="https://facebook.com/aqaed" title="facebook" target="_blank" rel="nofollow">
                                                        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M27.8389 15.0029C27.8389 8.10073 22.2436 2.50537 15.3413 2.50537C8.4391 2.50537 2.84375 8.10073 2.84375 15.0029C2.84375 21.2408 7.41394 26.4111 13.3886 27.3487V18.6155H10.2154V15.0029H13.3886V12.2496C13.3886 9.11738 15.2543 7.38724 18.109 7.38724C19.4764 7.38724 20.9066 7.63134 20.9066 7.63134V10.7069H19.3307C17.7782 10.7069 17.2941 11.6703 17.2941 12.6586V15.0029H20.7602L20.2061 18.6155H17.2941V27.3487C23.2687 26.4111 27.8389 21.2408 27.8389 15.0029Z" fill="white" fill-opacity="0.6"/>
                                                        </svg>

                                                        @aqaed
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="https://t.me/aqaed_com" title="telegram" target="_blank" rel="nofollow">
                                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_593_11465)">
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M26.7038 14C26.7038 21.1928 20.8728 27.0238 13.68 27.0238C6.4872 27.0238 0.65625 21.1928 0.65625 14C0.65625 6.80715 6.4872 0.976196 13.68 0.976196C20.8728 0.976196 26.7038 6.80715 26.7038 14ZM14.1467 10.5909C12.88 11.1178 10.3483 12.2083 6.55156 13.8625C5.93503 14.1077 5.61206 14.3476 5.58267 14.582C5.53298 14.9785 6.02933 15.1345 6.70522 15.347C6.79716 15.376 6.89242 15.4059 6.99007 15.4376C7.65504 15.6538 8.54956 15.9067 9.01457 15.9167C9.43639 15.9258 9.90718 15.752 10.4269 15.395C13.9744 13.0004 15.8055 11.7901 15.9204 11.764C16.0015 11.7456 16.1139 11.7224 16.1901 11.7901C16.2663 11.8578 16.2588 11.986 16.2507 12.0204C16.2016 12.23 14.2532 14.0414 13.2449 14.9787C12.9306 15.271 12.7077 15.4782 12.6621 15.5256C12.56 15.6316 12.4559 15.7319 12.3559 15.8283C11.7381 16.4239 11.2749 16.8704 12.3816 17.5998C12.9134 17.9502 13.339 18.24 13.7635 18.5291C14.2272 18.845 14.6896 19.1599 15.2881 19.5522C15.4405 19.6521 15.5861 19.7559 15.7279 19.8569C16.2675 20.2416 16.7522 20.5873 17.3512 20.5321C17.6992 20.5002 18.0586 20.1729 18.2412 19.1969C18.6727 16.8904 19.5208 11.8928 19.7168 9.83343C19.734 9.65301 19.7124 9.4221 19.6951 9.32074C19.6776 9.21937 19.6414 9.07494 19.5096 8.96803C19.3536 8.84141 19.1128 8.81471 19.005 8.81652C18.5151 8.82525 17.7635 9.08657 14.1467 10.5909Z" fill="white" fillOpacity="0.6"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_593_11465">
                                                                    <rect width="27.35" height="26.0476" fill="white" transform="translate(0 0.976196)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        @aqaed_com
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="https//tiktok.com/@aqaed" title="tiktok" target="_blank" rel="nofollow">
                                                        <svg className="width-3" width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M13.5 27.5C20.9558 27.5 27 21.4558 27 14C27 6.54416 20.9558 0.5 13.5 0.5C6.04416 0.5 0 6.54416 0 14C0 21.4558 6.04416 27.5 13.5 27.5Z" fill="white" fillOpacity="0.6"/>
                                                            <path d="M19.0196 13.0894C18.903 13.1009 18.7848 13.1074 18.6666 13.1074C17.3759 13.1074 16.1706 12.4572 15.4629 11.3767V17.2717C15.4629 19.6774 13.5121 21.6282 11.1064 21.6282C8.70079 21.6282 6.75 19.6774 6.75 17.2717C6.75 14.8661 8.70079 12.9153 11.1064 12.9153C11.1967 12.9153 11.287 12.9235 11.3757 12.9284V15.0746C11.287 15.0631 11.1984 15.0484 11.1064 15.0484C9.87816 15.0484 8.88306 16.0435 8.88306 17.2717C8.88306 18.5 9.87816 19.4951 11.1064 19.4951C12.3347 19.4951 13.4201 18.5279 13.4201 17.2996L13.4415 7.28955H15.4957C15.6895 9.13196 17.1739 10.5704 19.0212 10.7051V13.091" fill="#045E5E"/>
                                                        </svg>
                                                        @aqaed
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="https://twitter.com/aqaed_com" title="twitter" target="_blank" rel="nofollow">
                                                        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_880_47324)">
                                                                <path d="M14.4278 27.4122C21.8351 27.4122 27.84 21.4073 27.84 14C27.84 6.59261 21.8351 0.587769 14.4278 0.587769C7.02047 0.587769 1.01562 6.59261 1.01562 14C1.01562 21.4073 7.02047 27.4122 14.4278 27.4122Z" fill="white" fillOpacity="0.6"/>
                                                                <path d="M19.8674 12.3794C19.8764 12.51 19.8764 12.6406 19.8764 12.7724C19.8764 16.7883 16.8192 21.4198 11.2289 21.4198V21.4174C9.57757 21.4198 7.9605 20.9468 6.57031 20.0549C6.81044 20.0838 7.05176 20.0982 7.29369 20.0988C8.66221 20.1 9.99162 19.6409 11.0683 18.7953C9.76774 18.7706 8.62731 17.9227 8.22891 16.6848C8.68448 16.7726 9.15389 16.7546 9.60104 16.6324C8.18317 16.3459 7.1631 15.1002 7.1631 13.6534C7.1631 13.6402 7.1631 13.6276 7.1631 13.6149C7.58557 13.8502 8.05859 13.9808 8.54245 13.9953C7.20703 13.1028 6.79539 11.3262 7.60182 9.93724C9.14487 11.836 11.4215 12.9902 13.8655 13.1124C13.6205 12.0568 13.9552 10.9507 14.7447 10.2087C15.9688 9.05799 17.894 9.11697 19.0447 10.3405C19.7253 10.2062 20.3777 9.9565 20.9747 9.60263C20.7478 10.3062 20.273 10.9037 19.6387 11.2835C20.2411 11.2125 20.8297 11.0512 21.3839 10.8051C20.9759 11.4165 20.4619 11.9491 19.8674 12.3794Z" fill="#045E5E"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_880_47324">
                                                                    <rect width="28.1656" height="26.8244" fill="white" transform="translate(0.34375 0.587769)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>

                                                        @aqaed_com
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="https://facebook.com/aqaed" title="facebook" target="_blank" rel="nofollow">
                                                        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_880_47328)">
                                                                <path d="M14.3409 27.3175C21.6959 27.3175 27.6584 21.355 27.6584 14C27.6584 6.64499 21.6959 0.682556 14.3409 0.682556C6.98587 0.682556 1.02344 6.64499 1.02344 14C1.02344 21.355 6.98587 27.3175 14.3409 27.3175Z" fill="white" fillOpacity="0.6"/>
                                                                <path d="M14.0352 7.28284C10.1598 7.28284 7.00589 10.3206 7.00453 14.0539C7.00362 15.2478 7.32794 16.413 7.94287 17.4392L6.94531 20.9479L10.6727 20.0064C11.7095 20.5488 12.8624 20.8317 14.0325 20.8309H14.0352C17.9107 20.8309 21.0646 17.7927 21.0659 14.0594C21.0668 12.251 20.3362 10.5488 19.0084 9.26929C17.6811 7.98932 15.916 7.28329 14.0352 7.28284ZM14.0352 19.6871H14.033C12.9844 19.6871 11.9559 19.4156 11.0585 18.9027L10.8444 18.7807L8.63341 19.3391L9.22374 17.262L9.08481 17.0493C8.50127 16.1593 8.19063 15.1181 8.19112 14.0539C8.19248 10.9506 10.8144 8.42661 14.0375 8.42661C15.5981 8.42706 17.0652 9.0133 18.1689 10.0769C19.2726 11.1405 19.8798 12.5548 19.8789 14.0589C19.8775 17.1623 17.2561 19.6871 14.0348 19.6871H14.0352ZM17.2406 15.4714C17.0648 15.3872 16.2011 14.9777 16.0399 14.9207C15.8791 14.8647 15.762 14.8355 15.645 15.005C15.5284 15.1744 15.1913 15.5557 15.0893 15.6682C14.9863 15.7812 14.8838 15.7948 14.708 15.7106C14.5322 15.6258 13.966 15.4473 13.2955 14.8706C12.773 14.4224 12.4205 13.8685 12.318 13.6986C12.2155 13.5296 12.307 13.4381 12.395 13.3538C12.4738 13.2786 12.5708 13.1566 12.6582 13.0577C12.7457 12.9589 12.7748 12.8883 12.8341 12.7753C12.8924 12.6628 12.8632 12.5635 12.819 12.4792C12.7748 12.394 12.4241 11.5614 12.277 11.2229C12.1349 10.8932 11.99 10.9373 11.8821 10.9319C11.7796 10.9273 11.663 10.926 11.545 10.926C11.4288 10.926 11.238 10.9683 11.0767 11.1378C10.9159 11.3072 10.4618 11.7163 10.4618 12.5489C10.4618 13.382 11.0913 14.1865 11.1792 14.2994C11.2671 14.4119 12.4182 16.1214 14.1805 16.8548C14.5996 17.0283 14.9266 17.1326 15.1822 17.211C15.6031 17.3399 15.9861 17.3212 16.2886 17.278C16.6257 17.2292 17.3281 16.8689 17.4738 16.474C17.6205 16.0791 17.6205 15.7402 17.5768 15.6696C17.534 15.599 17.4164 15.5566 17.2406 15.4714Z" fill="#045E5E"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_880_47328">
                                                                    <rect width="27.9667" height="26.6349" fill="white" transform="translate(0.351562 0.682556)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>

                                                        @aqaed
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="https://youtube.com/@aqaed-com" title="youtube" target="_blank" rel="nofollow">
                                                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_593_11467)">
                                                                <path d="M13.8116 27.1554C21.077 27.1554 26.9669 21.2654 26.9669 14C26.9669 6.73451 21.077 0.844666 13.8116 0.844666C6.5461 0.844666 0.65625 6.73451 0.65625 14C0.65625 21.2654 6.5461 27.1554 13.8116 27.1554Z" fill="white" fillOpacity="0.6"/>
                                                                <path d="M20.8493 12.119C20.8493 10.3746 19.435 8.96027 17.6907 8.96027H9.61184C7.86747 8.96027 6.45312 10.3746 6.45312 12.119V15.8774C6.45312 17.6217 7.86747 19.036 9.61184 19.036H17.6907C19.435 19.036 20.8493 17.6217 20.8493 15.8774V12.119ZM16.099 14.2801L12.4764 16.0716C12.3349 16.149 11.8522 16.0452 11.8522 15.8849V12.2057C11.8522 12.0417 12.3387 11.9398 12.4801 12.0209L15.9481 13.9067C16.0934 13.9897 16.2461 14.1991 16.099 14.2801Z" fill="#045E5E"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_593_11467">
                                                                    <rect width="27.6262" height="26.3107" fill="white" transform="translate(0 0.844666)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        @aqaed-com
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
};

export default ContactUsPage;
