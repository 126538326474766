import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import ContributeArchive from "../components/ContributeArchive";

const Contribute = () => {
    return (
        <div>
            <HeaderPage />
            <ContributeArchive />
            <Footer />
        </div>
    );
};

export default Contribute;
