import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

import App from "./App";

import "./index.css";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <GoogleReCaptchaProvider
        reCaptchaKey="6LfbgTgoAAAAAGQwE-fU07bCsZ50fhcz8dGLzVbV"
        language="ar"
    >
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </GoogleReCaptchaProvider>
);
