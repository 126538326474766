import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import SeminarArchive from "../components/SeminarArchive";


const Seminar = () => {
    return (
        <div>
            <HeaderPage />
            <SeminarArchive />

            <Footer />
        </div>
    );
};

export default Seminar;
