import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import "swiper/css/pagination";
import image from '../assets/uploads/slider1.jpg'
import { Pagination } from "swiper";
import {NavLink} from "react-router-dom";
import {useEffect, useState} from "react";

const Slider = (props) => {
    return (
        <>
            {props.data.length > 0 ?
                <div className="slider-module">

                    <Swiper
                        speed={1500}
                        centeredSlides= {true}
                        centeredSlidesBounds ={true}
                        simulateTouch ={true}
                        pagination={{
                            clickable: true,
                        }}
                        loopFillGroupWithBlank={false}
                        grabCursor={true}
                        modules={[Pagination]}
                        spaceBetween={0}
                        slidesPerView={1} >
                        {props.data.map( slider =>
                        <SwiperSlide>
                            <img className="image-slider" src={slider.image} alt={slider.title} title={slider.title}/>
                            <div className="aq-container">
                                <div className="slider-container">
                                    <NavLink to={slider.link}  title={slider.title}>
                                        <h2>
                                            {slider.title}
                                        </h2>
                                    </NavLink>
                                    <p>{slider.description}</p>
                                </div>
                            </div>

                        </SwiperSlide>
                        )}
                    </Swiper>
                </div>

                : ""
            }

        </>

    );
};

export default Slider;