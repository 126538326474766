import {NavLink} from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import countries from "i18n-iso-countries";
import {Select} from "antd";
import React, {useEffect, useState} from "react";
import Cookies from "universal-cookie";
import {useUser} from "../services/UserContext";
countries.registerLocale(require("i18n-iso-countries/langs/ar.json"));
const options = [
    {value: 'val1', label: 'التسلسل الزمني'},
    {value: 'val2', label: 'الزمني'},
    {value: 'val3', label: 'التسلسل'}
]
const GuidedForm = () => {
    const [sub, setSub] = useState([]);
    const [defSub, setDefSub] = useState('');
    const [defSubTitle, setDefSubTitle] = useState('');

    const [emailIsLogin, setEmailIsLogin] = useState(false);
    const [passIsLogin, setPassIsLogin] = useState(false);
    const [idIsLogin, setIdIsLogin] = useState(false);
    const [nameIsLogin, setNameIsLogin] = useState(false);
    const [lastNameIsLogin, setLastNameIsLogin] = useState(false);
    const [CountryIsLogin, setCountryIsLogin] = useState(false);
    const [religionIsLogin, setReligionIsLogin] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [getContributePublicUser, setContributePublicUser] = useState(true);
    const [getContributePublicFAQ, setContributePublicFAQ] = useState(true);
    const [getQuestion, setQuestion] = useState("");
    const { userData, setLoginData } = useUser();
    useEffect(() => {
        const getUserList = () => {
                fetch(`https://dashboard.aqaed.net/api/v1/getCategoriesItemsQuestion?field=lft&orderType=ASC`)
                    .then(res => res.json())
                    .then(res => {
                        setDefSub(res.data[0].id);
                        setDefSubTitle(res.data[0].title);


                        const results = []
                        const data2 = res.data;
                        // Store results in the results array
                        data2.forEach((value) => {
                            results.push({
                                value: value.id,
                                label: value.title,
                            });
                        });
                        // Update the options state
                        setSub(results)

                    });
        }
        getUserList();
    }, []);
    useEffect(() => {
        const getUserList = () => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("email", emailIsLogin);
            formdata.append("password", passIsLogin);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            setIdIsLogin(userData?.id);
            setNameIsLogin(userData?.name);
            setLastNameIsLogin(userData?.family);
            setCountryIsLogin(userData?.country);
            setReligionIsLogin(userData?.previous_religion);
        };
        const cookies = new Cookies();
        // setSuccessMessage("تم تسجيل الدخول بنجاح");
        // let code = email.value + "m/!@m" + pass;
        if ((cookies.get('myCat') != undefined)) {
            let login = atob(cookies.get('myCat'));
            let arr = login.split("m/!@m");
            setEmailIsLogin(arr[0]);
            setPassIsLogin(arr[1]);
            getUserList();
        }

    }, [emailIsLogin,userData]);
    const handleChangeSub = (value) => {
        setDefSub(value)
    };

    const sendQuestion = (event) => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Cookie", "mylanguageClient=ar");

        var formdata = new FormData();
        formdata.append("user_id", parseInt(idIsLogin));
        formdata.append("question", getQuestion);
        getContributePublicFAQ?
            formdata.append("private", "yes")

    :
        formdata.append("private", "no")
        formdata.append("category", defSubTitle);
        getContributePublicUser?
        formdata.append("show_name_and_family", "no"):
        formdata.append("show_name_and_family", "yes")

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://dashboard.aqaed.net/api/v1/addQA", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result['status'] == 200) {
                    setSuccessMessage("تم إرسال المعلومات بنجاح");
                    setShowSuccessMessage(true);
                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);

                    setDefSubTitle("")
                    setQuestion("")
                 const inputElements = document.querySelectorAll('input');
                    const inputElements1 = document.querySelectorAll('textarea');
                    inputElements1.forEach(input => {
                        input.value = '';
                    });
                    inputElements.forEach(input => {
                        input.value = '';
                    });
                    return () => clearTimeout(timer);
                } else {
                    setErrorMessage("للأسف ، لم تتم معالجة طلبك. يرجى المحاولة مرة أخرى لاحقًا")
                    setShowErrorMessage(true);
                    const timer = setTimeout(() => {
                        setShowErrorMessage(false);
                    }, 5000);
                    return () => clearTimeout(timer);
                }

            });
    }

    return (
        <div className="green-box pb-5">
            <div className="container pb-4">
                <div className="d-flex justify-content-center mt-5">
                    <div className="login-is-rew text-center">
                        <h2>
                            أرسل سوالاً عقائدياً
                        </h2>
                        <small>
                            إن كان عندك سؤال أو استفسار بشأن عقائد مذهب أهل البيت(عليهم السلام)، يمكنك ذكره هنا، وستجد الإجابة الوافية إن شاء الله تعالى
                        </small>
                    </div>
                </div>
                <div className="d-flex mb-0 f-15 mt-4 phone-jus-center phone-center">
                    <div className="mb-0 mt-2 d-sm-flex d-block align-items-center">
                        <p className="color-90 m-0 text-white-80 fw-light d-inline-block">
                            <svg className="ms-sm-2 phone-m-auto ms-0 d-sm-inline-block d-block"
                                width="37" height="37" viewBox="0 0 37 37"
                                fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="18.4924" cy="18.4924" r="18.4924"
                                        fill="white"/>
                                <path
                                    d="M24.2626 24.3633C23.9088 23.373 23.1292 22.4979 22.0447 21.8738C20.9602 21.2497 19.6314 20.9114 18.2645 20.9114C16.8975 20.9114 15.5687 21.2497 14.4842 21.8738C13.3997 22.4979 12.6201 23.373 12.2663 24.3633"
                                    stroke="#045E5E" strokeWidth="0.924049"
                                    strokeLinecap="round"/>
                                <circle cx="18.2611" cy="14.7013" r="3.10488"
                                        stroke="#045E5E" strokeWidth="0.924049"
                                        strokeLinecap="round"/>
                            </svg>
                            {
                                nameIsLogin + " " + lastNameIsLogin
                            }
                        </p>
                        <div className="d-flex justify-content-center align-items-center gap-3 religion-container-11">
                        <div
                            className="d-flex align-items-center phone-jus-center phone-m-b-2 me-sm-5 me-0">
                            <ReactCountryFlag
                                countryCode={CountryIsLogin}
                                svg
                                style={{
                                    width: '2em',
                                    height: '2em',
                                    borderRadius: '10px'
                                }}
                                title={countries.getName(CountryIsLogin, 'ar')}
                                alt={countries.getName(CountryIsLogin, 'ar')}
                            />
                            <span className="me-2 text-white-80 fw-light">
                                                            {countries.getName(CountryIsLogin, 'ar')}
                                                        </span>
                        </div>
                        <div
                            className="text-white-80 me-sm-5 me-0 d-flex align-items-center gap-2">
                            <svg width="23" height="24" viewBox="0 0 23 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M3.83464 4.82568H19.168C20.2221 4.82568 21.0846 5.68818 21.0846 6.74235V18.2423C21.0846 19.2965 20.2221 20.159 19.168 20.159H3.83464C2.78047 20.159 1.91797 19.2965 1.91797 18.2423V6.74235C1.91797 5.68818 2.78047 4.82568 3.83464 4.82568Z"
                                    stroke="white" strokeOpacity="0.7" strokeWidth="1.5"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M21.0846 6.74243L11.5013 13.4508L1.91797 6.74243"
                                    stroke="white" strokeOpacity="0.7" strokeWidth="1.5"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <span className="text-white-80 fw-light">{emailIsLogin}</span>
                        </div>
                        </div>

                    </div>
                </div>
                {showSuccessMessage ?
                    <div
                        className={showSuccessMessage ? "d-flex position-fixed success-message" : "d-flex position-fixed success-message close"}>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M12.2531 1.20251C6.37312 1.20251 1.45312 6.12251 1.45312 12.0025C1.45312 17.8825 6.37312 22.8025 12.2531 22.8025C18.1331 22.8025 23.0531 18.0025 23.0531 12.0025C23.0531 6.00251 18.2531 1.20251 12.2531 1.20251ZM10.6916 16.2055L6.85156 12.3655L8.05156 11.1655L10.6916 13.8055L16.4516 8.04553L17.6516 9.24553L10.6916 16.2055Z"
                                  fill="white"/>
                            <path opacity="0.01" fillRule="evenodd" clipRule="evenodd"
                                  d="M10.6916 16.202L6.85156 12.362L8.05156 11.162L10.6916 13.802L16.4516 8.04199L17.6516 9.24199L10.6916 16.202Z"
                                  fill="white"/>
                        </svg>
                        <span className="me-1 text-white">
                       {successMessage}
                   </span>
                    </div>
                    : ""}

                {showErrorMessage ?
                    <div
                        className={errorMessage ? "d-flex bg-color-red  position-fixed success-message" : "d-flex bg-color-red position-fixed success-message close"}>
                        <svg className="w-23" width="17" height="16" viewBox="0 0 17 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M8.95 0.794189C4.95 0.794189 1.75 3.99419 1.75 7.99419C1.75 11.9942 4.95 15.1942 8.95 15.1942C12.95 15.1942 16.15 11.9942 16.15 7.99419C16.15 3.99419 12.95 0.794189 8.95 0.794189ZM8.30928 3.98967H9.50928V9.58967H8.30928V3.98967ZM8.95007 12.7906C8.47007 12.7906 8.15007 12.4706 8.15007 11.9906C8.15007 11.5106 8.47007 11.1906 8.95007 11.1906C9.43007 11.1906 9.75007 11.5106 9.75007 11.9906C9.75007 12.4706 9.43007 12.7906 8.95007 12.7906Z"
                                  fill="#fff"/>
                            <path opacity="0.01" fillRule="evenodd" clipRule="evenodd"
                                  d="M8.30758 3.98886H9.50758V9.58886H8.30758V3.98886ZM8.94837 12.7898C8.46837 12.7898 8.14837 12.4698 8.14837 11.9898C8.14837 11.5098 8.46837 11.1898 8.94837 11.1898C9.42837 11.1898 9.74837 11.5098 9.74837 11.9898C9.74837 12.4698 9.42837 12.7898 8.94837 12.7898Z"
                                  fill="#fff"/>
                        </svg>
                        <span className="me-1 text-white">
                    {errorMessage ?? "البريد الإلكتروني الذي تم إدخاله غير صحيح!"}
                </span>
                    </div>
                    : ""}
                <form className="row form-container" onSubmit={sendQuestion}>

                    <div className="col-md-4 child-m-0-in color-red-se">
                        {sub?.length>0 ?
                        <Select
                            showSearch
                            placeholder="موضوع"
                            defaultValue={defSub}
                            onChange={handleChangeSub}
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            options={sub}
                        />
                            :"" }
                    </div>
                    <div className="col-12">
                        <textarea className="w-100" onChange={(e) => {
                            setQuestion(e.target.value)
                        }} value={getQuestion} placeholder="السؤال" required ></textarea>
                    </div>
                    <div className="col-12">
                        <div className="radio-container">
                            <label className="radio-label mt-0"  title="السؤال عام لا بأس بنشره">
                                <input type="radio"
                                       onChange={(e)=>{
                                           e.target.value?
                                               setContributePublicFAQ(true)
                                               :   setContributePublicFAQ(false)
                                       }}
                                       value="no" checked={getContributePublicFAQ ? true : false}/>
                                <span>السؤال عام لا بأس بنشره</span>
                            </label>
                            <label className="radio-label mt-1" title="السؤال خاص لا ارغب أن ينشر">
                                <input type="radio" checked={getContributePublicFAQ ? false : true}  onChange={(e)=>{
                                    e.target.value?
                                    setContributePublicFAQ(false)
                                        :   setContributePublicFAQ(true)
                                }} value="yes"/>
                                    <span>السؤال خاص لا ارغب أن ينشر</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-12 d-flex pr-21px justify-content-between align-items-center mt-4">
                        <div className="checkbox" onClick={()=>{setContributePublicUser(!getContributePublicUser)}}>
                            {getContributePublicUser?
                                <input className="checkbox-pop"
                                       checked={true} type="checkbox"/>
                                :
                                <input className="checkbox-pop"
                                       checked={false}   type="checkbox"/>
                            }

                            <label htmlFor="check2" className="color-label-ff c-pointer">
                                <span className="ms-0"></span>
                                عدم عرض معلوماتي الخاصّة على الموقع
                            </label>
                        </div>
                        <button aria-label="كتابة سؤال" type="submit" title=" الإرسال" className="more-btn btn fw-light
                        h-green-hover color-white hidden-phone">
                            الإرسال
                        </button>
                    </div>
                    <div className="col-12 d-flex justify-content-center hidden-desktop phone-flex align-items-center mt-4">
                        <button aria-label=" إرسال السؤال" type="submit" title="  إرسال السؤال" className="more-btn btn fw-light
                        h-green-hover color-white">
                            إرسال السؤال
                        </button>
                    </div>

                </form>
            </div>
        </div>
    );
};

export default GuidedForm;
