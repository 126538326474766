import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import SearchTextPage from "../components/SearchTextPage";
import CategoryTextPage from "../components/CategoryTextPage";
import AuthorTextPage from "../components/AuthorTextPage";
let login = true;

const Author = () => {
    return (
        <div>
            <HeaderPage />
            <AuthorTextPage />
            <Footer />
        </div>

    );
};
export default Author;
