// UserContext.js
import { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState({
        id: null,
        name: null,
        family: null,
        country: null,
        previous_religion: null,
        avatar: null,
    });

    const [getEmailIsLogin, setEmailIsLogin] = useState(false);
    const [getPassIsLogin, setPassIsLogin] = useState(false);

    useEffect(() => {
        const fetchUserData = async (email, password) => {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");

                const formdata = new FormData();
                formdata.append("email", email);
                formdata.append("password", password);

                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow',
                };

                const response = await fetch(`https://dashboard.aqaed.net/api/v1/login`, requestOptions);
                const result = await response.json();
                setUserData({
                    id: result?.data?.id,
                    name: result?.data?.name,
                    family: result?.data?.family,
                    country: result?.data?.country,
                    previous_religion: result?.data?.previous_religion,
                    avatar: result?.data?.avatar,
                });
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        // Check if the user is already logged in
        const cookies = new Cookies();
        if (cookies.get('myCat')) {
            let login = atob(cookies.get('myCat'));
            let arr = login.split("m/!@m");
            // Assuming setEmailIsLogin, setPassIsLogin, and getUserList are available here
            setEmailIsLogin(() => arr[0]); // Wrap in a function to get the value
            setPassIsLogin(() => arr[1]);  // Wrap in a function to get the value
            if (arr[0])  // Check if arr[0] is truthy
                fetchUserData(arr[0], arr[1]); // Pass email and password to fetchUserData
        }
    }, []); // This will run only once when the component mounts

    const setLoginData = (data) => {
        setUserData(data);
    };

    return (
        <UserContext.Provider value={{ userData, setLoginData }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    return useContext(UserContext);
};
