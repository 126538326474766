import {NavLink, useSearchParams} from "react-router-dom";
import Select from 'react-select';
import ReactCountryFlag from "react-country-flag";
import {Scrollbars} from 'react-custom-scrollbars';
import faqFiles from "../assets/files/faq_2021.03.12_03.08.01.7z";
import image from "../assets/uploads/book.jpg";
import countries from "i18n-iso-countries";
import {useEffect, useState} from "react";
import React from "react";
import {getVisibilityWatcher} from "web-vitals/dist/modules/lib/getVisibilityWatcher";
import axios from "axios";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import img from "../assets/uploads/user.jpg";
import {useRef} from "react";
import {Helmet} from "react-helmet";
import Cookies from "universal-cookie";
import imageLoading from "../assets/svg/infinit-black.svg";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import {useUser} from "../services/UserContext";


const getMonthName = (day) => {
    switch (day) {
        case '01' :
            return ' محرم ';
        case '02' :
            return ' صفر ';
        case '03' :
            return ' ربيع الاول ';
        case '04' :
            return ' ربيع الثاني ';
        case '05' :
            return ' جمادى الاول ';
        case '06' :
            return ' جمادى الثانية ';
        case '07' :
            return ' رجب ';
        case '08' :
            return ' شعبان ';
        case '09' :
            return ' رمضان ';
        case '10' :
            return ' شوال ';
        case '11' :
            return ' ذوالقعدة ';
        case '12' :
            return ' ذوالحجة ';
    }
}

const years = [
    {value: '1445', label: '1445'}, {value: '1444', label: '1444'},
    {value: '1443', label: '1443'},
    {value: '1442', label: '1442'},
    {value: '1441', label: '1441'},
    {value: '1440', label: '1440'},
    {value: '1439', label: '1439'},
    {value: '1438', label: '1438'},
    {value: '1437', label: '1437'},
    {value: '1436', label: '1436'},
    {value: '1435', label: '1435'},
    {value: '1434', label: '1434'},
    {value: '1433', label: '1433'},
    {value: '1432', label: '1432'},
    {value: '1431', label: '1431'},
    {value: '1430', label: '1430'},
    {value: '1429', label: '1429'},
    {value: '1428', label: '1428'},
]

const month = [
    {value: '01', label: 'محرم'},
    {value: '02', label: 'صفر'},
    {value: '03', label: 'ربيع الاول'},
    {value: '04', label: 'ربيع الثاني'},
    {value: '05', label: 'جمادى الاول'},
    {value: '06', label: 'جمادى الثانية'},
    {value: '07', label: 'رجب'},
    {value: '08', label: 'شعبان'},
    {value: '09', label: 'رمضان'},
    {value: '10', label: 'شوال'},
    {value: '11', label: 'ذوالقعدة'},
    {value: '12', label: 'ذوالحجة'}
]

const Filter = (props) => {

    const [getQuestion, setQuestion] = useState([]);
    const [searchParams] = useSearchParams();
    const [totalPages, setTotalPages] = useState(1);
    const [phoneSearch, setPhoneSearch] = useState(false);
    const [page, setPage] = useState((searchParams.get('page') != null) ? parseInt(searchParams.get('page')) : 1);

    const [totalPages2, setTotalPages2] = useState(1);
    const [phoneSearch2, setPhoneSearch2] = useState(false);
    const [page2, setPage2] = useState(1);
    const [userList, setUserList] = useState([]);
    const [userListFilter, setUserListFilter] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchF, setSearchF] = useState('');
    const [subFilter, setSubFilter] = useState('');
    const [subFilterTitle, setSubFilterTitle] = useState();
    const [monthFilter, setMonthFilter] = useState('');
    const [monthFilterTitle, setMonthFilterTitle] = useState();
    const [yearFilter, setYearFilter] = useState('');
    const [yearFilterTitle, setYearFilterTitle] = useState();
    const [sub, setSub] = useState(false);
    const [sub2, setSub2] = useState(false);
    const [options2, setOptions] = useState(false);
    const [focus, setFocus] = useState(false);
    const [subject, setSubject] = useState(false);
    const [month2, setMonth2] = useState(false);
    const [year2, setYear2] = useState(false);
    const [newLoading, setNewLoading] = useState(false);
    const [visibilitiSubject, setVisibilitiSubject] = useState(false);
    const [totalSearch, setTotalSearch] = useState(0);


    const [about, setAbout] = useState(false);
    const [showLogin1, setShowLogin1] = useState(false);

    const [Allsubject, setAllsubject] = useState("");
    const [hasSub, setHasSub] = useState(false);

    const [visibilitiSubject12, setVisibilitiSubject12] = useState("");
    const [visibilitiSubject12Title, setVisibilitiSubject12Title] = useState(false);

    const [userListFilter1, setUserListFilter1] = useState([]);
    const [subBooks, setSubBooks] = useState([]);
    const [defSub2, setDefSub2] = useState('');
    const [defSub2Title, setDefSub2Title] = useState('');
    const [filter3, setFilter3] = useState([]);

    const [loadingPage, setLoadingPage] = useState(false);


    const [defSub, setDefSub] = useState('الف');

    const [emailIsLogin, setEmailIsLogin] = useState(false);
    const [passIsLogin, setPassIsLogin] = useState(false);
    const [nameIsLogin, setNameIsLogin] = useState(false);
    const [lastNameIsLogin, setLastNameIsLogin] = useState(false);
    const [CountryIsLogin, setCountryIsLogin] = useState(false);
    const [religionIsLogin, setReligionIsLogin] = useState(false);
    const [avatarIsLogin, setAvatarIsLogin] = useState(false);
    const [myLoading, setMyLoading] = useState(false);
    const { userData, setLoginData } = useUser();

    const hasItem = (item) => {
        let bol = false;
        if (props.Sub33?.length > 0) {
            for (const value of props.Sub33) {
                if (value.Alphabet == item) {
                    bol = true;
                    break;
                }
            }
            return bol;
        }
    }

    const hasItem1 = (item) => {
        let bol = false;
        if (filteredResults?.length > 0) {
            for (const value of filteredResults) {
                if (value.Alphabet == item) {
                    bol = true;
                    break;
                }
            }
            return bol;
        }
    }

    useEffect(() => {
        const getUserList = () => {
            if (!sub) {
                setLoading(true);
                fetch(`https://dashboard.aqaed.net/api/v1/archiveQuestionAnswer/12?page=${page}`)
                    .then(res => res.json())
                    .then(res => {
                        setTotalPages(res.meta.last_page);
                        setUserList([...userList, ...res.data]);
                        setLoading(false);
                    });
            }
        }
        getUserList();
    }, [page, sub]);


    useEffect(() => {
        const getUserList = () => {
            if (defSub2.length == 0) {
                fetch(`https://dashboard.aqaed.net/api/v1/getCategoriesItemsQuestion?field=lft&orderType=ASC`)
                    .then(res => res.json())
                    .then(res => {
                        setData22(res.data)
                        setDataBack22(res.data)
                        setLoading222(false)
                        setLoadingPage(true)
                        setDefSub2(res.data[0].id);
                        setDefSub2Title(res.data[0].title);
                        setSubBooks(res.data[0].books);

                        fetch(`https://dashboard.aqaed.net/api/v1/archiveQuestionAnswer/10?field=order&orderType=asc&category=${res.data[0].id}`)
                            .then(res => res.json())
                            .then(res => {
                                setLoadingPage(false)
                                setFilter3(res.data);
                                let results = []
                                if (res.data?.length > 0) {
                                    if (res.data[0].related_category?.length > 0) {
                                        res.data[0].related_category.forEach((item) => {
                                            results.push({
                                                id: item.id,
                                                title: item.title,
                                            });
                                        });
                                        setAllsubject(results)
                                    } else {
                                        setAllsubject([])
                                    }
                                } else {
                                    setAllsubject([])
                                }
                            });
                    });
            } else {
                setLoadingPage(true)
                fetch(`https://dashboard.aqaed.net/api/v1/archiveQuestionAnswer/10?field=order&orderType=asc&category=${defSub2}`)
                    .then(res => res.json())
                    .then(res => {
                        setFilter3(res.data);
                        setLoadingPage(false);
                        let results = []
                        if (res.data?.length > 0) {
                        if (res.data[0].related_category?.length > 0) {
                            res.data[0].related_category.forEach((item) => {
                                results.push({
                                    id: item.id,
                                    title: item.title,
                                });
                            });
                            setAllsubject(results)
                        } else {
                            setAllsubject([])
                        }
                        } else {
                            setAllsubject([])
                        }


                    });

                fetch(`https://dashboard.aqaed.net/api/v1/getCategoriesItemsQuestion?field=lft&orderType=ASC`)
                    .then(res => res.json())
                    .then(res => {
                        setData22(res.data)
                        setDataBack22(res.data)
                        setLoading222(false)
                    });

                fetch(`https://dashboard.aqaed.net/api/v1/getCategoriesSingle/${defSub2}`)
                    .then(res => res.json())
                    .then(res => {
                            setSubBooks(res.data.books.data)
                            }
                        );
            }

        }
        getUserList();
    }, [defSub2]);


    useEffect(() => {
        const getUserList = () => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("email", emailIsLogin);
            formdata.append("password", passIsLogin);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            setNameIsLogin(userData?.name);
            setLastNameIsLogin(userData?.family);
            setCountryIsLogin(userData?.country);
            setReligionIsLogin(userData?.previous_religion);
            setAvatarIsLogin(userData?.avatar);
        };
        const cookies = new Cookies();
        // setSuccessMessage("تم تسجيل الدخول بنجاح");
        // let code = email.value + "m/!@m" + pass;
        if ((cookies.get('myCat') != undefined)) {
            let login = atob(cookies.get('myCat'));
            let arr = login.split("m/!@m");
            setEmailIsLogin(arr[0]);
            setPassIsLogin(arr[1]);
            getUserList();
        }

    }, [emailIsLogin,userData]);


    useEffect(() => {
        if (yearFilter == "" && monthFilter == "" && visibilitiSubject12 == "" && searchF == "" && subFilter == "") {
            setSub(false)
        } else {
            setNewLoading(true)
        }
        const getUserList = () => {
            if (sub) {
                setPage(1)
                setUserListFilter([])
                fetch(`https://dashboard.aqaed.net/api/v1/archiveQuestionAnswer/10?field=order&orderType=asc&page=${page2}&search=${searchF}&category=${visibilitiSubject12}&month=${monthFilter}&year=${yearFilter}&country=${subFilter}`)
                    .then(res => res.json())
                    .then(res => {
                        setTotalPages2(res.meta.last_page);
                        setTotalSearch(res.meta.total);
                        setUserList([...[], ...[]])
                        if (res.data.length > 0) {
                            setUserListFilter([...userListFilter, ...res.data]);
                        } else {
                            setUserListFilter([...[], ...res.data]);
                        }
                        setNewLoading(false)
                        setMyLoading(true);
                    });
            }
        }
        getUserList();
    }, [sub2, page2]);

    useEffect(() => {
        async function fetchData() {
            // Fetch data
            const {data} = await axios.get("https://dashboard.aqaed.net/api/v1/getCountryQuestion");
            const results = []
            const data2 = data;
            // Store results in the results array
            data2.forEach((value) => {
                results.push({
                    value: value,
                    label: countries.getName(value, 'ar'),
                });
            });
            // Update the options state
            setOptions(results)
        }

        // Trigger the fetch
        fetchData();
    }, []);

    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [notFunded, setNotFunded] = useState(false);


    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchValue !== '') {
            const filteredData = props.Sub33.filter((item) => {
                return Object.values(item.title).join('').includes(searchValue)
            })
            if (filteredData.length == 0) {
                setNotFunded(true)
            } else {
                setNotFunded(false)
            }
            setFilteredResults(filteredData)
        }
    }

    const filterSearch = event => {
        setSearchF(event.target.value);
    };

    const handleChangeSubject = event => {
        event ?
            setSubFilter(event.value) :
            setSubFilter("")
    };

    const handleChangeMonth = event => {
        event ?
            setMonthFilter(event.value) :
            setMonthFilter("")
    };


    const [data22, setData22] = useState([])
    const [dataBack22, setDataBack22] = useState([])
    const [loading222, setLoading222] = useState(false)

    const searchItems222 = (searchValue) => {
        if (searchValue.length > 0) {
            const newData = dataBack22.filter((item) => {
                return Object.values(item.title).join('').includes(searchValue)
            })
            setData22(newData);
        } else {
            setData22(dataBack22);
        }
    }

    const handleChangeYear = event => {
        event ?
            setYearFilter(event.value) :
            setYearFilter("")
    };

    const submitFilter = event => {
        setMyLoading(false);
        event.preventDefault();
        setSub(true);
        setUserListFilter([]);
        setSub2(!sub2);
    };

    const wrapperRef = useRef(null);

    useEffect(() => {
        /**
         * if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (window.innerWidth >= 900)
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    setVisibilitiSubject(false)
                }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <>

            <>
                <div className={showLogin1 ? "modal-faq-cat" : "modal-faq-cat modal-close"}>
                    <div className="header-green d-sm-flex d-block justify-content-between align-items-center">
                        <i className="sub-box-close hidden-desktop" onClick={() => {
                            setShowLogin1(false)
                        }}>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.5 6.5L6.5 19.5" stroke="white" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path d="M6.5 6.5L19.5 19.5" stroke="white" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </i>
                        <div className="title phone-center mb-3 mb-md-0">
                            جميع مواضيع الأسئلة والأجوبة
                        </div>
                        <div className="filter-subjects">
                            <form className="botton-submit1 big-form">
                                <div className="search-sub-menu">
                                    <div
                                        className="input-group search-input-group  position-relative">
                                    <span className="input-group-btn input-group-btn-22 btn-search">
                                        <button className="btn" type='submit' onClick={(event) => {
                                            event.preventDefault()
                                        }}>
                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M9.66667 15.8333C13.3486 15.8333 16.3333 12.8486 16.3333 9.16667C16.3333 5.48477 13.3486 2.5 9.66667 2.5C5.98477 2.5 3 5.48477 3 9.16667C3 12.8486 5.98477 15.8333 9.66667 15.8333Z"
                                                    stroke="#FFFFFFCC" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                                <path d="M17.999 17.4999L14.374 13.8749" stroke="#FFFFFFCC"
                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                           </svg>
                                        </button>
                                    </span>
                                        <input
                                            className="search-text-box-1 form-control form-control-2  form-control-45"
                                            onChange={(e) => searchItems222(e.target.value)}
                                            placeholder="البحث ..."/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className={data22.length > 0 ? "all-subjects-faq-list" : "all-subjects-faq-list"}>
                        <Scrollbars
                            autoHideTimeout={500}
                            autoHideDuration={200}
                            renderThumbVertical={({style, ...props}) => (
                                <div
                                    {...props}
                                    style={{
                                        ...style,
                                        backgroundColor: '#B0B0B0',
                                        borderRadius: '3px',
                                        cursor: 'pointer',
                                        width: '2px',
                                        right: '-10px'
                                    }}
                                />
                            )}
                        >
                            {
                                !loading222 ?
                                    data22.length > 0 ?
                                        data22.map((item, key) =>
                                            <NavLink
                                                className={visibilitiSubject12 == item.id ? "btn btn-not-fill m-1 fw-light g-b-se selected" :
                                                    "btn btn-not-fill m-1 fw-light"} onClick={(event) => {
                                                window.scrollTo(0, 0)
                                                if (visibilitiSubject12 == item.id) {
                                                    event.preventDefault();
                                                    setVisibilitiSubject12("")
                                                    setVisibilitiSubject12Title("")
                                                    setShowLogin1(false)
                                                    setUserListFilter([])
                                                    setSub(true);
                                                    setSub2(!sub2);
                                                } else {
                                                    event.preventDefault();
                                                    setVisibilitiSubject12(item.id)
                                                    setVisibilitiSubject12Title(item.title)
                                                    setShowLogin1(false)
                                                    setUserListFilter([])
                                                    setSub(true);
                                                    setSub2(!sub2);
                                                }

                                            }}
                                                title={item.title}>{item.title}
                                                <span className="green-subject"> ({item.count_content}) </span>
                                            </NavLink>
                                        )
                                        : "لم يتم العثور على الفئة المطلوبة"
                                    : "تحميل..."
                            }
                        </Scrollbars>

                    </div>
                </div>
                <div onClick={() => {
                    setShowLogin1(false)
                }} className={showLogin1 ? "overlay-modal" : "overlay-modal close"}></div>
            </>
            <button aria-label="show all categories"
                    className="floating-btn floating-btn-2 position-fixed btn bg-transparent top-32" onClick={() => {
                setShowLogin1(true)
            }}>
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="25" cy="25" r="25" fill="#cddfdf" fillOpacity="1"/>
                    <rect x="17.418" y="16.3336" width="15.1667" height="18.4167" rx="2" stroke="#045E5E"
                          strokeWidth="2"/>
                    <path d="M21.75 21.75H28.25" stroke="#045E5E" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M21.75 26.0833H28.25" stroke="#045E5E" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M21.75 30.4167H26.0833" stroke="#045E5E" strokeWidth="2" strokeLinecap="round"/>
                </svg>
                <span className="hidden-phone">مشاهدة جميع الأسئلة و الأجوبة</span>
            </button>
            <Helmet>
                <title>الأسئلة والأجوبة العقائدية</title>
                <meta name="description"
                      content="موسوعة معرفية شاملة تحتوي على آلاف الأسئلة العقائدية مع أجوبتها بترتيب موضوعي الفبائيً"/>
                <link rel="canonical" href="https://aqaed.net/faq"/>
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:site" content="@aqaedcom"/>
                <meta name="twitter:creator" content="گروه ایده‌پرداز ترسیم"/>
                <meta name="twitter:title" content="الأسئلة والأجوبة العقائدية"/>
                <meta name="twitter:description"
                      content="موسوعة معرفية شاملة تحتوي على آلاف الأسئلة العقائدية مع أجوبتها بترتيب موضوعي الفبائيً"/>
                <meta name="twitter:image" content="/logo.svg"/>
                <meta property="og:type" content="article"/>
                <meta property="og:title" content="الأسئلة والأجوبة العقائدية"/>
                <meta property="og:site_name" content="مركز الأبحاث العقائدية"/>
                <meta property="og:locale" content="ar_sa"/>
                <meta property="og:image" content="/logo.svg"/>
            </Helmet>
            <section className="aq-menu-head filter-container-mt border-rad-top-10 back-main-2">
                <div className="aq-container">
                    <div className="row">
                        <div className="w-200 width-0-phone"></div>
                        <div className="w-100-200 width-100-phone d-flex justify-content-between">
                            <div className="filter-container w-100">
                                <div
                                    className="aq-container position-relative d-flex justify-content-between mb-3 align-items-center">
                                    <div className="question-container d-flex justify-content-end align-items-center">
                                        <svg className="show-o-container" width="50" height="50" viewBox="0 0 50 50"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="25" cy="25" r="25" fill="#045E5E"/>
                                            <circle cx="25.311" cy="36.6153" r="1.05125" stroke="white"
                                                    strokeWidth="1.69624"/>
                                            <path
                                                d="M25.3075 30.9226V27.9394C25.3075 25.9541 26.5779 24.1916 28.4613 23.5638V23.5638C30.3447 22.936 31.615 21.1734 31.615 19.1882V18.1092C31.615 14.7352 28.8798 12 25.5058 12H25.3075C21.824 12 19 14.824 19 18.3075V18.3075"
                                                stroke="white" strokeWidth="1.69624"/>
                                        </svg>

                                        <div className="question-container-data">
                                            <h1 className="faq-title m-0"> الأسئلة والأجوبة العقائدية </h1>
                                            <small className="faq-desc d-block">
                                                موسوعة معرفية شاملة تحتوي على آلاف الأسئلة العقائدية مع أجوبتها بترتيب
                                                موضوعي الفبائي
                                            </small>
                                        </div>
                                    </div>
                                    {userList.length > 0 ?
                                        userList.map((question, key) =>
                                            key == 0 ?
                                                <NavLink download target="_blank" to={question.file_download.link}
                                                         title="تحميل جميع الأسئلة والأجوبة"
                                                         className="more-btn download-btn-g hidden-phone d-flex">
                                                    <svg className="ms-2" width="22" height="22" viewBox="0 0 22 22"
                                                         fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10.9993 12.8334L10.2922 13.5405L10.9993 14.2476L11.7065 13.5405L10.9993 12.8334ZM11.9993 4.58336C11.9993 4.03107 11.5516 3.58336 10.9993 3.58336C10.4471 3.58336 9.99935 4.03107 9.99935 4.58336L11.9993 4.58336ZM5.70891 8.95713L10.2922 13.5405L11.7065 12.1263L7.12312 7.54292L5.70891 8.95713ZM11.7065 13.5405L16.2898 8.95713L14.8756 7.54292L10.2922 12.1263L11.7065 13.5405ZM11.9993 12.8334L11.9993 4.58336L9.99935 4.58336L9.99935 12.8334L11.9993 12.8334Z"
                                                            fill="white"/>
                                                        <path
                                                            d="M4.58398 14.6666L4.58398 15.5833C4.58398 16.5958 5.4048 17.4166 6.41732 17.4166L15.584 17.4166C16.5965 17.4166 17.4173 16.5958 17.4173 15.5833V14.6666"
                                                            stroke="white" strokeWidth="2"/>
                                                    </svg>

                                                    <div>
                                                        <span>تحميل جميع الأسئلة والأجوبة</span>
                                                        <div
                                                            className="d-flex justify-content-between download-information">
                                                            <span> الحجم: {question.file_download.size}</span>
                                                            <span>
                                                                    {(new Date(question.file_download.update)).getFullYear() + "/"}
                                                                {parseInt((new Date(question.file_download.update)).getMonth()) + 1}
                                                                {"/" + (new Date(question.file_download.update)).getDate().toString().padStart(2, '0')}
                                                                </span>
                                                        </div>
                                                    </div>

                                                </NavLink>
                                                : ""
                                        )
                                        : ""
                                    }
                                </div>
                                <form className="botton-submit1 question-container-data">
                                    <div className="search-sub-menu child-m-s-0">
                                        <div
                                            className={phoneSearch ? "input-group search-input-group search-input-group-2 position-relative w-input open-s" : focus ? "input-group search-input-group search-input-group-2 position-relative w-input focus-hover" : "input-group search-input-group search-input-group-2 position-relative w-input"}>
                                            <span className="input-group-btn input-group-btn-22 btn-search"
                                                  onClick={() => setPhoneSearch(true)}>
                                                <button className="btn" type="button" onClick={(event => {
                                                    event.preventDefault()
                                                })}>
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M9.66667 15.8333C13.3486 15.8333 16.3333 12.8486 16.3333 9.16667C16.3333 5.48477 13.3486 2.5 9.66667 2.5C5.98477 2.5 3 5.48477 3 9.16667C3 12.8486 5.98477 15.8333 9.66667 15.8333Z"
                                                            stroke="#FFFFFFCC" strokeLinecap="round"
                                                            strokeLinejoin="round"/>
                                                        <path d="M17.999 17.4999L14.374 13.8749" stroke="#FFFFFFCC"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </button>
                                            </span>
                                            <input className="search-text-box-1 form-control form-control-2"
                                                   onFocus={() => setFocus(true)}
                                                   onBlur={() => setFocus(false)}
                                                   onChange={filterSearch}
                                                   placeholder="البحث ..."/>
                                            <svg className="close-input" onClick={() => setPhoneSearch(false)}
                                                 width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.5 6.5L6.5 19.5" stroke="#DEFFFF" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path d="M6.5 6.5L19.5 19.5" stroke="#DEFFFF" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                            </svg>
                                        </div>

                                        <div ref={wrapperRef}
                                             className={visibilitiSubject12Title.length > 0 ? "position-relative hidden-phone item-selected"
                                                 : "position-relative hidden-phone"}>
                                            <div
                                                className="select-by-filter d-flex align-items-center c-pointer h-100 ">
                                                <p onClick={() => {
                                                    setVisibilitiSubject(true)
                                                }} className="m-0 d-inline px-2 select">
                                                    {
                                                        visibilitiSubject12Title.length > 0 ? visibilitiSubject12Title :
                                                            "مواضيع الأسئلة والأجوبة حسب ترتيب الحروف"}
                                                </p>
                                                {!(visibilitiSubject12Title.length > 0) ?
                                                    <svg onClick={() => {
                                                        setVisibilitiSubject(true)
                                                    }} className="ms-2" width="19" height="19" viewBox="0 0 20 20"
                                                         fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 7.5L10 12.5L15 7.5" stroke="white"
                                                              strokeOpacity="0.8"
                                                              strokeWidth="2" strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                    </svg>
                                                    :
                                                    <svg onClick={() => {
                                                        setVisibilitiSubject12Title("")
                                                        setVisibilitiSubject12("")
                                                    }} height="20" width="20" viewBox="0 0 20 20" aria-hidden="true"
                                                         focusable="false" className="fill-08">
                                                        <path
                                                            d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                                    </svg>
                                                }
                                            </div>
                                            <div
                                                className={visibilitiSubject ? "alphabet-subjects-container position-absolute fade show visible" : "alphabet-subjects-container position-absolute fade invisible"}>
                                                <div className="d-flex justify-content-between pb-3 c-pointer"
                                                     onClick={() => {
                                                         setVisibilitiSubject(false)
                                                     }}>
                                                    <span className="f-15-w">
                                                        مواضيع الأسئلة والأجوبة حسب ترتيب الحروف
                                                    </span>
                                                    <span>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6 15L12 9L18 15" stroke="#045E5E"
                                                                  strokeWidth="2" strokeLinecap="round"
                                                                  strokeLinejoin="round"/>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div className="d-sm-flex">
                                                    <div className="new-tab-more">
                                                        <div className="d-flex w-100 justify-content-evenly">
                                                            <ul className="list-unstyled p-0 first-c mb-0">
                                                                <li className={hasItem("الف") ? (defSub == "الف") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("الف"))
                                                                            setDefSub("الف")
                                                                    }}><span>الف</span>
                                                                </li>
                                                                <li className={hasItem("ب") ? (defSub == "ب") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ب"))
                                                                            setDefSub("ب")
                                                                    }}><span>ب</span>
                                                                </li>
                                                                <li className={hasItem("ت") ? (defSub == "ت") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ت"))
                                                                            setDefSub("ت")
                                                                    }}><span>ت</span>
                                                                </li>
                                                                <li className={hasItem("ث") ? (defSub == "ث") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ث"))
                                                                            setDefSub("ث")
                                                                    }}><span>ث</span>
                                                                </li>
                                                                <li className={hasItem("ج") ? (defSub == "ج") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ج"))
                                                                            setDefSub("ج")
                                                                    }}><span>ج</span>
                                                                </li>
                                                                <li className={hasItem("ح") ? (defSub == "ح") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ح"))
                                                                            setDefSub("ح")
                                                                    }}><span>ح</span>
                                                                </li>
                                                                <li className={hasItem("خ") ? (defSub == "خ") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("خ"))
                                                                            setDefSub("خ")
                                                                    }}><span>خ</span>
                                                                </li>
                                                                <li className={hasItem("د") ? (defSub == "د") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("د"))
                                                                            setDefSub("د")
                                                                    }}><span>د</span>
                                                                </li>
                                                                <li className={hasItem("ذ") ? (defSub == "ذ") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ذ"))
                                                                            setDefSub("ذ")
                                                                    }}><span>ذ</span>
                                                                </li>
                                                                <li className={hasItem("ر") ? (defSub == "ر") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ر"))
                                                                            setDefSub("ر")
                                                                    }}><span>ر</span>
                                                                </li>
                                                                <li className={hasItem("ز") ? (defSub == "ز") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ز"))
                                                                            setDefSub("ز")
                                                                    }}><span>ز</span>
                                                                </li>
                                                                <li className={hasItem("س") ? (defSub == "س") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("س"))
                                                                            setDefSub("س")
                                                                    }}><span>س</span>
                                                                </li>
                                                                <li className={hasItem("ش") ? (defSub == "ش") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ش"))
                                                                            setDefSub("ش")
                                                                    }}><span>ش</span>
                                                                </li>
                                                                <li className={hasItem("ص") ? (defSub == "ص") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ص"))
                                                                            setDefSub("ص")
                                                                    }}><span>ص</span>
                                                                </li>
                                                            </ul>
                                                            <ul className="list-unstyled p-0 second-c mb-0">
                                                                <li className={hasItem("ض") ? (defSub == "ض") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ض"))
                                                                            setDefSub("ض")
                                                                    }}><span>ض</span>
                                                                </li>
                                                                <li className={hasItem("ط") ? (defSub == "ط") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ط"))
                                                                            setDefSub("ط")
                                                                    }}><span>ط</span>
                                                                </li>
                                                                <li className={hasItem("ظ") ? (defSub == "ظ") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ظ"))
                                                                            setDefSub("ظ")
                                                                    }}><span>ظ</span>
                                                                </li>
                                                                <li className={hasItem("ع") ? (defSub == "ع") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ع"))
                                                                            setDefSub("ع")
                                                                    }}><span>ع</span>
                                                                </li>
                                                                <li className={hasItem("غ") ? (defSub == "غ") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("غ"))
                                                                            setDefSub("غ")
                                                                    }}><span>غ</span>
                                                                </li>
                                                                <li className={hasItem("ف") ? (defSub == "ف") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ف"))
                                                                            setDefSub("ف")
                                                                    }}><span>ف</span>
                                                                </li>
                                                                <li className={hasItem("ق") ? (defSub == "ق") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ق"))
                                                                            setDefSub("ق")
                                                                    }}><span>ق</span>
                                                                </li>
                                                                <li className={hasItem("ك") ? (defSub == "ك") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ك"))
                                                                            setDefSub("ك")
                                                                    }}><span>ك</span>
                                                                </li>
                                                                <li className={hasItem("ل") ? (defSub == "ل") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ل"))
                                                                            setDefSub("ل")
                                                                    }}><span>ل</span>
                                                                </li>
                                                                <li className={hasItem("م") ? (defSub == "م") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("م"))
                                                                            setDefSub("م")
                                                                    }}><span>م</span>
                                                                </li>
                                                                <li className={hasItem("ن") ? (defSub == "ن") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ن"))
                                                                            setDefSub("ن")
                                                                    }}><span>ن</span>
                                                                </li>
                                                                <li className={hasItem("ه") ? (defSub == "ه") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ه"))
                                                                            setDefSub("ه")
                                                                    }}><span>ه</span>
                                                                </li>
                                                                <li className={hasItem("و") ? (defSub == "و") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("و"))
                                                                            setDefSub("و")
                                                                    }}><span>و</span>
                                                                </li>
                                                                <li className={hasItem("ي") ? (defSub == "ي") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ي"))
                                                                            setDefSub("ي")
                                                                    }}><span>ي</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="fw-light">
                                                                <div className={hasItem("الأسئلة المتفرقة") ? (defSub == "الأسئلة المتفرقة") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("الأسئلة المتفرقة"))
                                                                        setDefSub("الأسئلة المتفرقة")
                                                                }}><span className="font-little">الأسئلة المتفرقة</span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <ul className="subject-container-div">
                                                        {
                                                            props.Sub33 ?
                                                                props.Sub33.length > 0 ?
                                                                    props.Sub33.map(items =>
                                                                        items.Alphabet == defSub ?
                                                                            <li
                                                                                onClick={
                                                                                    () => {
                                                                                        if (visibilitiSubject12 == items.id) {
                                                                                            setVisibilitiSubject12Title("")
                                                                                            setVisibilitiSubject12("")
                                                                                        } else {
                                                                                            setVisibilitiSubject12Title(items.title)
                                                                                            setVisibilitiSubject12(items.id)
                                                                                            setVisibilitiSubject(false)
                                                                                        }
                                                                                    }
                                                                                }
                                                                                className={(visibilitiSubject12 == items.id) ? "select" : ""}
                                                                                title={items.title + " (" + items.count_content + ")"}
                                                                            > <span>
                                                                                   {items.title} <span
                                                                                className="green-subject">({items.count_content})</span>
                                                                            </span>
                                                                            </li>
                                                                            : ""
                                                                    )
                                                                    : ""
                                                                : ""
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>


                                        <div
                                            className={visibilitiSubject12Title.length > 0 ? "position-relative hidden-desktop select" : "position-relative hidden-desktop"}>
                                            <div
                                                className="select-by-filter d-flex align-items-center c-pointer h-100">
                                                <p onClick={() => {
                                                    setVisibilitiSubject(true)
                                                }} className="m-0 d-inline px-2">
                                                    {
                                                        visibilitiSubject12Title.length > 0 ? visibilitiSubject12Title :
                                                            "مواضيع الأسئلة والأجوبة حسب ترتيب الحروف"}
                                                </p>
                                                {!(visibilitiSubject12Title.length > 0) ?
                                                    <svg onClick={() => {
                                                        setVisibilitiSubject(true)
                                                    }} className="ms-2" width="19" height="19" viewBox="0 0 20 20"
                                                         fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 7.5L10 12.5L15 7.5" stroke="white"
                                                              strokeOpacity="0.8"
                                                              strokeWidth="2" strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                    </svg>
                                                    :
                                                    <svg onClick={() => {
                                                        setVisibilitiSubject12Title("")
                                                        setVisibilitiSubject12("")
                                                    }} className="ms-2" width="26" height="26" viewBox="0 0 26 26"
                                                         fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19.5 6.5L6.5 19.5" stroke="white"
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                        <path d="M6.5 6.5L19.5 19.5" stroke="white"
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                    </svg>

                                                }
                                            </div>
                                            <div
                                                className={visibilitiSubject ? "overlay-phone-modal fade show " : "overlay-phone-modal invisible fade "}
                                                onClick={() => setVisibilitiSubject(false)}></div>
                                            <div
                                                className={visibilitiSubject ? "alphabet-subjects-container position-absolute fade show visible" : "alphabet-subjects-container position-absolute fade invisible"}>

                                                <div className="d-flex justify-content-between pb-3 c-pointer"
                                                     onClick={() => {
                                                         setVisibilitiSubject(false)
                                                     }}>
                                                    <span className="f-15-w">
                                                        مواضيع الأسئلة والأجوبة حسب ترتيب الحروف
                                                    </span>
                                                    <span>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6 15L12 9L18 15" stroke="#045E5E"
                                                                  strokeWidth="2" strokeLinecap="round"
                                                                  strokeLinejoin="round"/>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div className="d-sm-flex">
                                                    <div className="d-block width-100-phone overflow-scroll">
                                                        <ul className="list-unstyled p-0 d-flex top-ul gap-3 mb-0">
                                                            <li className={hasItem("الف") ? (defSub == "الف") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("الف"))
                                                                        setDefSub("الف")
                                                                }}><span>الف</span>
                                                            </li>
                                                            <li className={hasItem("ب") ? (defSub == "ب") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ب"))
                                                                        setDefSub("ب")
                                                                }}><span>ب</span>
                                                            </li>
                                                            <li className={hasItem("ت") ? (defSub == "ت") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ت"))
                                                                        setDefSub("ت")
                                                                }}><span>ت</span>
                                                            </li>
                                                            <li className={hasItem("ث") ? (defSub == "ث") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ث"))
                                                                        setDefSub("ث")
                                                                }}><span>ث</span>
                                                            </li>
                                                            <li className={hasItem("ج") ? (defSub == "ج") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ج"))
                                                                        setDefSub("ج")
                                                                }}><span>ج</span>
                                                            </li>
                                                            <li className={hasItem("ح") ? (defSub == "ح") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ح"))
                                                                        setDefSub("ح")
                                                                }}><span>ح</span>
                                                            </li>
                                                            <li className={hasItem("خ") ? (defSub == "خ") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("خ"))
                                                                        setDefSub("خ")
                                                                }}><span>خ</span>
                                                            </li>
                                                            <li className={hasItem("د") ? (defSub == "د") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("د"))
                                                                        setDefSub("د")
                                                                }}><span>د</span>
                                                            </li>
                                                            <li className={hasItem("ذ") ? (defSub == "ذ") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ذ"))
                                                                        setDefSub("ذ")
                                                                }}><span>ذ</span>
                                                            </li>
                                                            <li className={hasItem("ر") ? (defSub == "ر") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ر"))
                                                                        setDefSub("ر")
                                                                }}><span>ر</span>
                                                            </li>
                                                            <li className={hasItem("ز") ? (defSub == "ز") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ز"))
                                                                        setDefSub("ز")
                                                                }}><span>ز</span>
                                                            </li>
                                                            <li className={hasItem("س") ? (defSub == "س") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("س"))
                                                                        setDefSub("س")
                                                                }}><span>س</span>
                                                            </li>
                                                            <li className={hasItem("ش") ? (defSub == "ش") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ش"))
                                                                        setDefSub("ش")
                                                                }}><span>ش</span>
                                                            </li>
                                                            <li className={hasItem("ص") ? (defSub == "ص") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ص"))
                                                                        setDefSub("ص")
                                                                }}><span>ص</span>
                                                            </li>
                                                            <li className={hasItem("ض") ? (defSub == "ض") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ض"))
                                                                        setDefSub("ض")
                                                                }}><span>ض</span>
                                                            </li>
                                                            <li className={hasItem("ط") ? (defSub == "ط") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ط"))
                                                                        setDefSub("ط")
                                                                }}><span>ط</span>
                                                            </li>
                                                            <li className={hasItem("ظ") ? (defSub == "ظ") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ظ"))
                                                                        setDefSub("ظ")
                                                                }}><span>ظ</span>
                                                            </li>
                                                            <li className={hasItem("ع") ? (defSub == "ع") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ع"))
                                                                        setDefSub("ع")
                                                                }}><span>ع</span>
                                                            </li>
                                                            <li className={hasItem("غ") ? (defSub == "غ") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("غ"))
                                                                        setDefSub("غ")
                                                                }}><span>غ</span>
                                                            </li>
                                                            <li className={hasItem("ف") ? (defSub == "ف") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ف"))
                                                                        setDefSub("ف")
                                                                }}><span>ف</span>
                                                            </li>
                                                            <li className={hasItem("ق") ? (defSub == "ق") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ق"))
                                                                        setDefSub("ق")
                                                                }}><span>ق</span>
                                                            </li>
                                                            <li className={hasItem("ك") ? (defSub == "ك") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ك"))
                                                                        setDefSub("ك")
                                                                }}><span>ك</span>
                                                            </li>
                                                            <li className={hasItem("ل") ? (defSub == "ل") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ل"))
                                                                        setDefSub("ل")
                                                                }}><span>ل</span>
                                                            </li>
                                                            <li className={hasItem("م") ? (defSub == "م") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("م"))
                                                                        setDefSub("م")
                                                                }}><span>م</span>
                                                            </li>
                                                            <li className={hasItem("ن") ? (defSub == "ن") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ن"))
                                                                        setDefSub("ن")
                                                                }}><span>ن</span>
                                                            </li>
                                                            <li className={hasItem("ه") ? (defSub == "ه") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ه"))
                                                                        setDefSub("ه")
                                                                }}><span>ه</span>
                                                            </li>
                                                            <li className={hasItem("و") ? (defSub == "و") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("و"))
                                                                        setDefSub("و")
                                                                }}><span>و</span>
                                                            </li>
                                                            <li className={hasItem("ي") ? (defSub == "ي") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ي"))
                                                                        setDefSub("ي")
                                                                }}><span>ي</span>
                                                            </li>
                                                            <li className={hasItem("الأسئلة المتفرقة") ? (defSub == "الأسئلة المتفرقة") ? "width-100-im select" : "width-100-im" : "width-100-im disable"}
                                                                onClick={() => {
                                                                    if (hasItem("الأسئلة المتفرقة"))
                                                                        setDefSub("الأسئلة المتفرقة")
                                                                }}><span>الأسئلة المتفرقة</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <ul className="subject-container-div">
                                                        {
                                                            props.Sub33 ?
                                                                props.Sub33.length > 0 ?
                                                                    props.Sub33.map((items, key) =>
                                                                        items.Alphabet == defSub ?
                                                                            <li onClick={
                                                                                () => {
                                                                                    if (visibilitiSubject12 == items.id) {
                                                                                        setVisibilitiSubject12Title("")
                                                                                        setVisibilitiSubject12("")
                                                                                    } else {
                                                                                        setVisibilitiSubject12Title(items.title)
                                                                                        setVisibilitiSubject12(items.id)
                                                                                        setVisibilitiSubject(false)
                                                                                    }
                                                                                }
                                                                            }
                                                                                className={(visibilitiSubject12 == items.id) ? "select" : ""}

                                                                            >{items.title} <span
                                                                                className="green-subject"> ({items.count_content})</span>
                                                                            </li>
                                                                            : ""
                                                                    )
                                                                    : ""
                                                                : ""
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        {/*{options2.length > 0 ?*/}
                                        {/*    <Select*/}
                                        {/*        className="basic-single hidden-phone"*/}
                                        {/*        classNamePrefix="select"*/}
                                        {/*        isClearable={true}*/}
                                        {/*        isRtl={true}*/}
                                        {/*        isSearchable={false}*/}
                                        {/*        name="color"*/}
                                        {/*        onChange={handleChangeSubject}*/}
                                        {/*        options={options2}*/}
                                        {/*        placeholder={'الدولة'}*/}
                                        {/*        theme={(theme) => ({*/}
                                        {/*            ...theme,*/}
                                        {/*            borderRadius: 0,*/}
                                        {/*            colors: {*/}
                                        {/*                ...theme.colors,*/}
                                        {/*                primary: '#045E5E',*/}
                                        {/*            },*/}
                                        {/*        })}*/}
                                        {/*        styles={{*/}
                                        {/*            dropdownIndicator: (provided, state) => ({*/}
                                        {/*                ...provided,*/}
                                        {/*                transform: state.selectProps.menuIsOpen && "rotate(180deg)"*/}
                                        {/*            })*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*    : ""}*/}
                                        {/*<div*/}
                                        {/*    className={subFilter ?*/}
                                        {/*        "select-by-filter d-flex align-items-center c-pointer hidden-desktop d-flex-imp selected-back-w" :*/}
                                        {/*        "select-by-filter d-flex align-items-center c-pointer hidden-desktop d-flex-imp"*/}
                                        {/*    }>*/}
                                        {/*    <p className="m-0 d-inline px-2"*/}
                                        {/*       onClick={() => setSubject(true)}>   {subFilterTitle ?? "الدولة"}</p>*/}
                                        {/*    {!subFilter ?*/}
                                        {/*        <svg className="ms-2" width="19" height="19" viewBox="0 0 20 20"*/}
                                        {/*             fill="none"*/}
                                        {/*             xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*            <path d="M5 7.5L10 12.5L15 7.5" stroke="white" strokeOpacity="0.8"*/}
                                        {/*                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                        {/*        </svg> : <svg className="ms-2" onClick={() => {*/}
                                        {/*            setSubFilter("");*/}
                                        {/*            setSubFilterTitle("الدولة");*/}
                                        {/*        }*/}
                                        {/*        } width="26" height="26" viewBox="0 0 26 26" fill="none"*/}
                                        {/*                      xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*            <path d="M19.5 6.5L6.5 19.5" stroke="white"*/}
                                        {/*                  strokeLinecap="round"*/}
                                        {/*                  strokeLinejoin="round"/>*/}
                                        {/*            <path d="M6.5 6.5L19.5 19.5" stroke="white"*/}
                                        {/*                  strokeLinecap="round"*/}
                                        {/*                  strokeLinejoin="round"/>*/}
                                        {/*        </svg>}*/}
                                        {/*</div>*/}
                                        {/*<div*/}
                                        {/*    className={subject ? "phone-modal-container position-fixed" : "phone-modal-container position-fixed close-modal"}>*/}
                                        {/*    <div className="overlay-phone-modal"*/}
                                        {/*         onClick={() => setSubject(false)}></div>*/}
                                        {/*    <div className="phone-modal">*/}
                                        {/*         <span className="close-phone-modal" onClick={() => setSubject(false)}>*/}
                                        {/*            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"*/}
                                        {/*                 xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*                <path d="M12 4L4 12" stroke="#045E5E" strokeLinecap="square"*/}
                                        {/*                      strokeLinejoin="round"/>*/}
                                        {/*                <path d="M4 4L12 12" stroke="#045E5E" strokeLinecap="square"*/}
                                        {/*                      strokeLinejoin="round"/>*/}
                                        {/*            </svg>*/}
                                        {/*        </span>*/}
                                        {/*        <div className="modal-title-green">*/}
                                        {/*            الدولة*/}
                                        {/*        </div>*/}
                                        {/*        {options2 ?*/}
                                        {/*            <div className="modal-option-container">*/}
                                        {/*                {options2.map(subject =>*/}
                                        {/*                    <div*/}
                                        {/*                        className={(subFilter == subject.value) ? "modal-option select" : "modal-option"}*/}
                                        {/*                        onClick={() => {*/}
                                        {/*                            if (subFilter != subject.value) {*/}
                                        {/*                                setSubFilter(subject.value);*/}
                                        {/*                                setSubFilterTitle(subject.label);*/}
                                        {/*                            } else {*/}
                                        {/*                                setSubFilter("");*/}
                                        {/*                                setSubFilterTitle("الدولة");*/}
                                        {/*                            }*/}
                                        {/*                            setSubject(false)*/}
                                        {/*                        }}>*/}
                                        {/*                        {subject.label}*/}
                                        {/*                    </div>*/}
                                        {/*                )}*/}
                                        {/*            </div> : ""}*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<Select*/}
                                        {/*    className="basic-single hidden-phone"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isRtl={true}*/}
                                        {/*    isSearchable={false}*/}
                                        {/*    name="color"*/}
                                        {/*    options={years}*/}
                                        {/*    placeholder={'السنة'}*/}
                                        {/*    onChange={handleChangeYear}*/}
                                        {/*    theme={(theme) => ({*/}
                                        {/*        ...theme,*/}
                                        {/*        borderRadius: 0,*/}
                                        {/*        colors: {*/}
                                        {/*            ...theme.colors,*/}
                                        {/*            primary: '#045E5E',*/}
                                        {/*        },*/}
                                        {/*    })}*/}
                                        {/*    styles={{*/}
                                        {/*        dropdownIndicator: (provided, state) => ({*/}
                                        {/*            ...provided,*/}
                                        {/*            transform: state.selectProps.menuIsOpen && "rotate(180deg)"*/}
                                        {/*        })*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                        {/*<div*/}
                                        {/*    className={*/}
                                        {/*        yearFilter ?*/}
                                        {/*            "select-by-filter d-flex align-items-center c-pointer hidden-desktop d-flex-imp selected-back-w" :*/}
                                        {/*            "select-by-filter d-flex align-items-center c-pointer hidden-desktop d-flex-imp"*/}
                                        {/*    }*/}
                                        {/*>*/}
                                        {/*    <p className="m-0 d-inline px-2"*/}
                                        {/*       onClick={() => setYear2(true)}>   {yearFilterTitle ?? "السنة"}</p>*/}
                                        {/*    {!yearFilter ?*/}
                                        {/*        <svg className="ms-2" width="19" height="19" viewBox="0 0 20 20"*/}
                                        {/*             fill="none"*/}
                                        {/*             xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*            <path d="M5 7.5L10 12.5L15 7.5" stroke="white" strokeOpacity="0.8"*/}
                                        {/*                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>*/}
                                        {/*        </svg>*/}
                                        {/*        : <svg className="ms-2" onClick={() => {*/}
                                        {/*            setYearFilter("");*/}
                                        {/*            setYearFilterTitle("السنة");*/}
                                        {/*        }*/}
                                        {/*        } width="26" height="26" viewBox="0 0 26 26" fill="none"*/}
                                        {/*               xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*            <path d="M19.5 6.5L6.5 19.5" stroke="white"*/}
                                        {/*                  strokeLinecap="round"*/}
                                        {/*                  strokeLinejoin="round"/>*/}
                                        {/*            <path d="M6.5 6.5L19.5 19.5" stroke="white"*/}
                                        {/*                  strokeLinecap="round"*/}
                                        {/*                  strokeLinejoin="round"/>*/}
                                        {/*        </svg>}*/}
                                        {/*</div>*/}
                                        {/*<div*/}
                                        {/*    className={year2 ? "phone-modal-container position-fixed" : "phone-modal-container position-fixed close-modal"}>*/}
                                        {/*    <div className="overlay-phone-modal" onClick={() => setYear2(false)}></div>*/}
                                        {/*    <div className="phone-modal">*/}
                                        {/*         <span className="close-phone-modal" onClick={() => setYear2(false)}>*/}
                                        {/*            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"*/}
                                        {/*                 xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*                <path d="M12 4L4 12" stroke="#045E5E" strokeLinecap="square"*/}
                                        {/*                      strokeLinejoin="round"/>*/}
                                        {/*                <path d="M4 4L12 12" stroke="#045E5E" strokeLinecap="square"*/}
                                        {/*                      strokeLinejoin="round"/>*/}
                                        {/*            </svg>*/}
                                        {/*        </span>*/}
                                        {/*        <div className="modal-title-green">*/}
                                        {/*            السنة*/}
                                        {/*        </div>*/}
                                        {/*        {years ?*/}
                                        {/*            <div className="modal-option-container">*/}
                                        {/*                {years.map(subject =>*/}

                                        {/*                    <div*/}
                                        {/*                        className={(yearFilter == subject.value) ? "modal-option select" : "modal-option"}*/}
                                        {/*                        onClick={() => {*/}
                                        {/*                            if (yearFilter != subject.value) {*/}
                                        {/*                                setYearFilter(subject.value);*/}
                                        {/*                                setYearFilterTitle(subject.label);*/}
                                        {/*                            } else {*/}
                                        {/*                                setYearFilter("");*/}
                                        {/*                                setYearFilterTitle("السنة");*/}
                                        {/*                            }*/}
                                        {/*                            setYear2(false)*/}
                                        {/*                        }}>*/}
                                        {/*                        {subject.label}*/}
                                        {/*                    </div>*/}
                                        {/*                )}*/}
                                        {/*            </div>*/}
                                        {/*            : ""}*/}

                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        {/*<Select*/}
                                        {/*    className="basic-single hidden-phone"*/}
                                        {/*    classNamePrefix="select"*/}
                                        {/*    isClearable={true}*/}
                                        {/*    isRtl={true}*/}
                                        {/*    isSearchable={false}*/}
                                        {/*    onChange={handleChangeMonth}*/}
                                        {/*    name="color"*/}
                                        {/*    options={month}*/}
                                        {/*    placeholder={'شهر'}*/}
                                        {/*    theme={(theme) => ({*/}
                                        {/*        ...theme,*/}
                                        {/*        borderRadius: 0,*/}
                                        {/*        colors: {*/}
                                        {/*            ...theme.colors,*/}
                                        {/*            primary: '#045E5E',*/}
                                        {/*        },*/}
                                        {/*    })}*/}
                                        {/*    styles={{*/}
                                        {/*        dropdownIndicator: (provided, state) => ({*/}
                                        {/*            ...provided,*/}
                                        {/*            transform: state.selectProps.menuIsOpen && "rotate(180deg)"*/}
                                        {/*        })*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                        {/*<div*/}
                                        {/*    className={*/}
                                        {/*        monthFilter ?*/}
                                        {/*            "select-by-filter d-flex align-items-center c-pointer hidden-desktop d-flex-imp selected-back-w" :*/}
                                        {/*            "select-by-filter d-flex align-items-center c-pointer hidden-desktop d-flex-imp"*/}
                                        {/*    }>*/}
                                        {/*    <p className="m-0 d-inline px-2"*/}
                                        {/*       onClick={() => setMonth2(true)}>   {monthFilterTitle ?? "شهر"}</p>*/}
                                        {/*    {*/}
                                        {/*        !monthFilter ?*/}
                                        {/*            <svg className="ms-2" width="19" height="19" viewBox="0 0 20 20"*/}
                                        {/*                 fill="none"*/}
                                        {/*                 xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*                <path d="M5 7.5L10 12.5L15 7.5" stroke="white"*/}
                                        {/*                      strokeOpacity="0.8"*/}
                                        {/*                      strokeWidth="2" strokeLinecap="round"*/}
                                        {/*                      strokeLinejoin="round"/>*/}
                                        {/*            </svg>*/}
                                        {/*            : <svg className="ms-2" onClick={() => {*/}
                                        {/*                setMonthFilter("");*/}
                                        {/*                setMonthFilterTitle("شهر");*/}
                                        {/*            }*/}
                                        {/*            } width="26" height="26" viewBox="0 0 26 26" fill="none"*/}
                                        {/*                   xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*                <path d="M19.5 6.5L6.5 19.5" stroke="white"*/}
                                        {/*                      strokeLinecap="round"*/}
                                        {/*                      strokeLinejoin="round"/>*/}
                                        {/*                <path d="M6.5 6.5L19.5 19.5" stroke="white"*/}
                                        {/*                      strokeLinecap="round"*/}
                                        {/*                      strokeLinejoin="round"/>*/}
                                        {/*            </svg>}*/}
                                        {/*</div>*/}
                                        {/*<div*/}
                                        {/*    className={month2 ? "phone-modal-container position-fixed" : "phone-modal-container position-fixed close-modal"}>*/}
                                        {/*    <div className="overlay-phone-modal" onClick={() => setMonth2(false)}></div>*/}
                                        {/*    <div className="phone-modal">*/}
                                        {/*         <span className="close-phone-modal" onClick={() => setMonth2(false)}>*/}
                                        {/*            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"*/}
                                        {/*                 xmlns="http://www.w3.org/2000/svg">*/}
                                        {/*                <path d="M12 4L4 12" stroke="#045E5E" strokeLinecap="square"*/}
                                        {/*                      strokeLinejoin="round"/>*/}
                                        {/*                <path d="M4 4L12 12" stroke="#045E5E" strokeLinecap="square"*/}
                                        {/*                      strokeLinejoin="round"/>*/}
                                        {/*            </svg>*/}
                                        {/*        </span>*/}
                                        {/*        <div className="modal-title-green">*/}
                                        {/*            شهر*/}
                                        {/*        </div>*/}
                                        {/*        {month ?*/}
                                        {/*            <div className="modal-option-container">*/}
                                        {/*                {month.map(subject =>*/}

                                        {/*                    <div*/}
                                        {/*                        className={(monthFilter == subject.value) ? "modal-option select" : "modal-option"}*/}
                                        {/*                        onClick={() => {*/}
                                        {/*                            if (monthFilter != subject.value) {*/}
                                        {/*                                setMonthFilter(subject.value);*/}
                                        {/*                                setMonthFilterTitle(subject.label);*/}
                                        {/*                            } else {*/}
                                        {/*                                setMonthFilter("");*/}
                                        {/*                                setMonthFilterTitle("شهر");*/}
                                        {/*                            }*/}
                                        {/*                            setMonth2(false)*/}
                                        {/*                        }}>*/}
                                        {/*                        {subject.label}*/}
                                        {/*                    </div>*/}
                                        {/*                )}*/}
                                        {/*            </div>*/}
                                        {/*            : ""}*/}

                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <div onClick={submitFilter}
                                             className="select-by-filter d-flex align-items-center c-pointer hidden-desktop d-flex-imp">
                                            <p className="m-0 d-inline px-2">تنفيذ</p>
                                        </div>
                                        <button onClick={submitFilter} title="تنفيذ"
                                                className="more-btn border-0-im fw-light color-white hidden-phone">
                                            تنفيذ
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="question-container-sec">
                <div className="phone-nav-container hidden-desktop">
                    <div
                        className="aq-container py-sm-5 mb-phone-0 py-0 d-flex justify-content-center align-items-center">
                        <div className="btn-navigation  navi-container-faq navi-container-media-2 ">
                            <div className="navi3">
                                <div className="d-flex justify-content-between px-3 py-2 w-100 align-items-center">
                                    <div className="d-flex gap-3 justify-content-between">
                                        <div className="d-block statistics">
                                            <span className="text-number">{props.answerIsPublished}</span>
                                            <span className="text">إجابة منشورة</span>
                                        </div>
                                        <div className="d-block statistics">
                                            <span className="text-number">{props.termsOfBeliefs}</span>
                                            <span className="text">موضوعاً عقائدياً</span>
                                        </div>
                                        <div className="d-block statistics">
                                            <span className="text-number">{props.answerIsSent}</span>
                                            <span className="text">إجابة مرسَلة</span>
                                        </div>
                                    </div>
                                    <div className="d-flex gap-2 justify-content-between">
                                        <div onClick={() => {
                                            setAbout(true)
                                        }}>
                                            <svg width="29" height="28" viewBox="0 0 29 28" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="14.7988" cy="14" r="10.5" fill="white" fillOpacity="0.9"/>
                                                <path
                                                    d="M15.3815 18.5833C15.3815 18.9055 15.1203 19.1667 14.7982 19.1667C14.476 19.1667 14.2148 18.9055 14.2148 18.5833C14.2148 18.2612 14.476 18 14.7982 18C15.1203 18 15.3815 18.2612 15.3815 18.5833Z"
                                                    fill="#990000" stroke="#990000"/>
                                                <path d="M14.7988 15.4138V9" stroke="#990000" strokeWidth="1.75"
                                                      strokeLinecap="round"/>
                                            </svg>
                                        </div>
                                        {    userList.length > 0 ?
                                                userList.map((question, key) =>
                                                    key == 0 ?
                                                        <NavLink key={key} download target="_blank"
                                                                 to={question.file_download.link}
                                                                 title="تحميل جميع الأسئلة والأجوبة">
                                                            <svg width="26" height="24" viewBox="0 0 26 24" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <rect y="0.700195" width="25.7992" height="22.5992"
                                                                      rx="4.8"
                                                                      fill="white" fillOpacity="0.3"/>
                                                                <path
                                                                    d="M9.52539 9.97526L12.9002 13.3501M12.9002 13.3501L16.2751 9.97526M12.9002 13.3501L12.9002 7.27539"
                                                                    stroke="white" strokeWidth="1.12495"
                                                                    strokeLinecap="round"/>
                                                                <path
                                                                    d="M8.17578 14.7002L8.17578 15.3752C8.17578 16.1207 8.78017 16.7251 9.52572 16.7251L16.2754 16.7251C17.0209 16.7251 17.6253 16.1207 17.6253 15.3752V14.7002"
                                                                    stroke="white" strokeWidth="1.12495"/>
                                                            </svg>
                                                        </NavLink>
                                                        : ""
                                                )
                                                : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="phone-m-9 question-answer-container py-sm-5 py-0 position-relative">
                    <div className="aq-container px-sm-5 px-3 hidden-phone">
                        <div
                            className="d-flex px-sm-5 px-0 justify-content-between align-items-center border-dashed-after ">
                            <div className="guided-container max-w-58">
                                <div className="guided-title">
                                    <svg className="ms-2" width="32" height="32" viewBox="0 0 32 32" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="16" cy="16" r="12" fill="#990000"/>
                                        <path
                                            d="M16.6673 10C16.6673 10.3682 16.3688 10.6667 16.0007 10.6667C15.6325 10.6667 15.334 10.3682 15.334 10C15.334 9.63181 15.6325 9.33334 16.0007 9.33334C16.3688 9.33334 16.6673 9.63181 16.6673 10Z"
                                            fill="#EEEEEE" stroke="#EEEEEE" strokeWidth="2"/>
                                        <path d="M16 22.6633V15.3333" stroke="#EEEEEE" strokeWidth="2"
                                              strokeLinecap="round"/>
                                    </svg>
                                    <h2 className="d-inline">إلى كلّ الأحرار في العالم:</h2>
                                </div>
                                <ul className="list-color">
                                    <li>
                                        لا يغرَّنكم ما يلقيه خصوم الإسلام والتشيّع من شبهات ملؤها الأكاذيب والافتراءات،
                                        أو عدم فهم العقيدة فهماً صحيحاً..
                                        إن كان عندكم سؤال أو استفسار عن عقائد مذهب أهل البيت(عليهم السلام) يمكنكم ذكره
                                        هنا، وستوافيكم الإجابة إن شاء الله تعالى.

                                    </li>
                                    <li>
                                        وضع الأسئلة يخضع إلى التقسيم الموضوعي، وغير ملزمين بنشر جميع ما يصلنا من أسئلة،
                                        أو إجابتها.
                                    </li>
                                    <li>
                                        الإجابة ستكون مخصّصة بالأسئلة العقائدية والمسائل الخلافية؛ معتذرين عن الإجابة
                                        على الأسئلة الفقهية وغيرها.
                                    </li>
                                    <li>
                                        يتَّبع المنهج والرأي المشهور في الإجابة عموماً، ولا يمثّل رأي المركز إلاّ ما
                                        يوجد على موقعه وصفحاته حاليّاً؛ ممّا صدر منه.
                                    </li>
                                    <li className="red">
                                        الآراء الواردة في موقع وصفحات مركز الأبحاث العقائدية لاتمثّل بالضرورة رأي سماحة
                                        السيّد السيستاني(دام ظلّه).
                                    </li>
                                </ul>
                            </div>
                            <div className="d-flex gap-5 detail-container">
                                <div className="sec-1">
                                    <VisibilitySensor partialVisibility offset={{bottom: 100}}>
                                        {({isVisible}) => (
                                            <div className="child-ff">
                                                {isVisible ? <CountUp end={props.answerIsPublished} separator=""/> : 0}
                                            </div>
                                        )}
                                    </VisibilitySensor>
                                    <span>
                                    إجابة منشورة
                                </span>
                                </div>
                                <div className="sec-1">

                                    <VisibilitySensor partialVisibility offset={{bottom: 100}}>
                                        {({isVisible}) => (
                                            <div className="child-ff">
                                                {isVisible ? <CountUp end={props.termsOfBeliefs} separator=""/> : 0}
                                            </div>
                                        )}
                                    </VisibilitySensor>

                                    <span>
                                    موضوعاً عقائدياً
                                </span>
                                </div>
                                <div className="sec-1">
                                    <VisibilitySensor partialVisibility offset={{bottom: 100}}>
                                        {({isVisible}) => (
                                            <div className="child-ff">
                                                {isVisible ? <CountUp end={props.answerIsSent} separator=""/> : 0}
                                            </div>
                                        )}
                                    </VisibilitySensor>
                                    <span>
إجابة مرسَلة
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className="title-container-2 text-center mt-sm-3 mb-sm-3 mt-5 mb-0">
                            <h2>أحدث الأسئلة والأجوبة العقائدية</h2>
                        </div>
                    </div>

                    <div className={about ? "hidden-desktop fade show" : "hidden-desktop invisible fade"}>
                        <div className={about ? "overlay-phone-modal fade show "
                            : "overlay-phone-modal invisible fade "}
                             onClick={() => {
                                 setAbout(false)
                             }}></div>
                        <div className="guided-container modal-phone-22">
                            <div className="d-flex justify-content-end mb-2" onClick={() => {
                                setAbout(false)
                            }}>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 4L4 12" stroke="#045E5E" strokeLinecap="square"
                                          strokeLinejoin="round"/>
                                    <path d="M4 4L12 12" stroke="#045E5E" strokeLinecap="square"
                                          strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className="guided-title">
                                <svg className="ms-2" width="32" height="32" viewBox="0 0 32 32" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="16" cy="16" r="12" fill="#990000"/>
                                    <path
                                        d="M16.6673 10C16.6673 10.3682 16.3688 10.6667 16.0007 10.6667C15.6325 10.6667 15.334 10.3682 15.334 10C15.334 9.63181 15.6325 9.33334 16.0007 9.33334C16.3688 9.33334 16.6673 9.63181 16.6673 10Z"
                                        fill="#EEEEEE" stroke="#EEEEEE" strokeWidth="2"/>
                                    <path d="M16 22.6633V15.3333" stroke="#EEEEEE" strokeWidth="2"
                                          strokeLinecap="round"/>
                                </svg>
                                <h2 className="d-inline">إلى كلّ الأحرار في العالم:</h2>
                            </div>
                            <ul className="list-color">
                                <li>
                                    لا يغرَّنكم ما يلقيه خصوم الإسلام والتشيّع من شبهات ملؤها الأكاذيب والافتراءات،
                                    أو عدم فهم العقيدة فهماً صحيحاً..
                                    إن كان عندكم سؤال أو استفسار عن عقائد مذهب أهل البيت(عليهم السلام) يمكنكم ذكره
                                    هنا، وستوافيكم الإجابة إن شاء الله تعالى.

                                </li>
                                <li>
                                    وضع الأسئلة يخضع إلى التقسيم الموضوعي، وغير ملزمين بنشر جميع ما يصلنا من أسئلة،
                                    أو إجابتها.
                                </li>
                                <li>
                                    الإجابة ستكون مخصّصة بالأسئلة العقائدية والمسائل الخلافية؛ معتذرين عن الإجابة
                                    على الأسئلة الفقهية وغيرها.
                                </li>
                                <li>
                                    يتَّبع المنهج والرأي المشهور في الإجابة عموماً، ولا يمثّل رأي المركز إلاّ ما
                                    يوجد على موقعه وصفحاته حاليّاً؛ ممّا صدر منه.
                                </li>
                                <li className="red">
                                    الآراء الواردة في موقع وصفحات مركز الأبحاث العقائدية لاتمثّل بالضرورة رأي سماحة
                                    السيّد السيستاني(دام ظلّه).
                                </li>
                            </ul>
                        </div>
                    </div>

                    {
                        (yearFilter != "" || monthFilter != "" || visibilitiSubject12 != "" || searchF != "" || subFilter != "") && sub ?
                            <div className="container phone-m-t-5">
                                <span className="search-result">

                              تمّ العثور على
                                       <b> {myLoading ? totalSearch :
                                           <img className="image-loading image-loading-2" src={imageLoading}
                                                alt="loading"
                                                title="loading"/>} </b>
                                    نتيجة للبحث عن
                                    <span> {searchF} </span> {searchF != "" && (visibilitiSubject12 != "" || subFilter != "" || yearFilter != "" || monthFilter != "") ? " - " : ""}
                                    <span> {visibilitiSubject12Title} </span> {visibilitiSubject12 != "" && (subFilter != "" || yearFilter != "" || monthFilter != "") ? " - " : ""}
                                    <span> {countries.getName(subFilter, 'ar')}</span> {subFilter != "" && (yearFilter != "" || monthFilter != "") ? " - " : ""}
                                    <span
                                        className="number">{yearFilter}</span> {yearFilter != "" && (monthFilter != "") ? " - " : ""}
                                    <span> {getMonthName(monthFilter)} </span>
                            .
                                </span>

                            </div>
                            : ""
                    }
                    {!sub ?
                        userList.length > 0 && (
                            userList.map((question, key) => (
                                <div
                                    className={key == 0 ? "gh-padding container d-flex justify-content-between align-items-center py-2 phone-m-t-5 phone-mt-0-imp" : "gh-padding container d-flex justify-content-between align-items-center py-2"}>
                                    <div className="w-100">
                                        <div className="card-question">
                                            <div className="d-flex justify-content-between">
                                                <div className="right">
                                                    <ul className="personal-information">
                                                        <li>
                                                            <svg className="mb-1 hidden-phone" width="21" height="21"
                                                                 viewBox="0 0 21 21" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M17.2712 17.5383C16.8914 16.4751 16.0544 15.5357 14.8901 14.8656C13.7258 14.1956 12.2993 13.8324 10.8317 13.8324C9.36415 13.8324 7.93759 14.1956 6.7733 14.8656C5.609 15.5357 4.77203 16.4751 4.3922 17.5383"
                                                                    stroke="#045E5E" strokeLinecap="round"/>
                                                                <circle cx="10.8324" cy="7.16536" r="3.33333"
                                                                        stroke="#045E5E" strokeLinecap="round"/>
                                                            </svg>
                                                            {question.user?.country?.length > 0 ?
                                                                <ReactCountryFlag className="hidden-desktop"
                                                                                  countryCode={question.user?.country}
                                                                                  svg
                                                                                  style={{
                                                                                      width: '1.4em',
                                                                                      height: '1.4em',
                                                                                  }}
                                                                                  title={question.user?.country}
                                                                                  alt={question.user?.country}
                                                                />
                                                                : ""}
                                                            {question.user?.name + " " + question.user?.family}

                                                        </li>

                                                        <li className="hidden-phone">
                                                            {question.user?.country?.length > 0 ?
                                                                <>
                                                                    <ReactCountryFlag
                                                                        countryCode={question.user?.country}
                                                                        svg
                                                                        style={{
                                                                            width: '1.4em',
                                                                            height: '1.4em',
                                                                        }}
                                                                        title={question.user?.country}
                                                                        alt={question.user?.country}
                                                                    />
                                                                    {countries.getName(question.user?.country, 'ar')}
                                                                </>
                                                                : ""}
                                                        </li>

                                                        {question.category != null ?
                                                            <li>
                                                                <NavLink
                                                                    to={`/category-faq/${question.category.id}`}
                                                                    title={question.category.title}>
                                                                    <svg className="mb-1" width="20" height="20"
                                                                         viewBox="0 0 20 20" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <rect x="4.16699" y="3.3335" width="11.6667"
                                                                              height="14.1667" rx="2" stroke="#045E5E"/>
                                                                        <path d="M7.5 7.5H12.5" stroke="#045E5E"
                                                                              strokeLinecap="round"/>
                                                                        <path d="M7.5 10.8334H12.5" stroke="#045E5E"
                                                                              strokeLinecap="round"/>
                                                                        <path d="M7.5 14.1666H10.8333" stroke="#045E5E"
                                                                              strokeLinecap="round"/>
                                                                    </svg>
                                                                    {question.category.title}
                                                                </NavLink>
                                                            </li> : ""
                                                        }

                                                        <li title="تعليقاً وتعقيباً">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M16.1036 4.81481C16.6654 5.65555 16.6654 6.82594 16.6654 9.16671C16.6654 11.5075 16.6654 12.6779 16.1036 13.5186C15.8604 13.8826 15.5479 14.1951 15.1839 14.4383C14.4495 14.929 13.4634 14.9911 11.6654 14.9989V15L10.7441 16.8427C10.4369 17.4569 9.56045 17.4569 9.25334 16.8427L8.33203 15V14.9989C6.53395 14.9911 5.54792 14.929 4.81346 14.4383C4.4495 14.1951 4.13699 13.8826 3.8938 13.5186C3.33203 12.6779 3.33203 11.5075 3.33203 9.16671C3.33203 6.82594 3.33203 5.65555 3.8938 4.81481C4.13699 4.45084 4.4495 4.13834 4.81346 3.89514C5.65421 3.33337 6.82459 3.33337 9.16536 3.33337H10.832C13.1728 3.33337 14.3432 3.33337 15.1839 3.89514C15.5479 4.13834 15.8604 4.45084 16.1036 4.81481Z"
                                                                    stroke="#045E5E" strokeLinecap="round"
                                                                    strokeLinejoin="round"/>
                                                                <path d="M7.5 7.5L12.5 7.5" stroke="#045E5E"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path d="M7.5 10.8334H10" stroke="#045E5E"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                            <span className="number">
                                                    {question.comment_count}
                                                </span>

                                                        </li>
                                                    </ul>
                                                    <NavLink to={"/qa/" + question.id} title={question.title}>
                                                        <h3>{question.title.length > 120 ?
                                                            `${question.title.substring(0, 120)}...` : question.title
                                                        }</h3>
                                                    </NavLink>
                                                </div>
                                                <div className="left hidden-phone">
                                                    <svg className="mb-1 ms-1" width="19" height="18"
                                                         viewBox="0 0 19 18"
                                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M15.0417 3H3.95833C3.08388 3 2.375 3.67157 2.375 4.5V15C2.375 15.8284 3.08388 16.5 3.95833 16.5H15.0417C15.9161 16.5 16.625 15.8284 16.625 15V4.5C16.625 3.67157 15.9161 3 15.0417 3Z"
                                                            stroke="#045E5E" strokeLinecap="round"
                                                            strokeLinejoin="round"/>
                                                        <path d="M12.666 1.50012V4.50012" stroke="#045E5E"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M6.33398 1.50012V4.50012" stroke="#045E5E"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M2.375 7.5H16.625" stroke="#045E5E"
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                    </svg>

                                                    <div className="date">
                                                        <span
                                                            className="number"> {question.date_lunar ? question.date_lunar.split("-")[2] : "02"} </span>
                                                        {getMonthName(question.date_lunar ? question.date_lunar.split("-")[1] : "02")}
                                                        <span
                                                            className="number"> {question.date_lunar ? question.date_lunar.split("-")[0] : "1500"} </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )
                        :
                        userListFilter.length > 0 ?
                            userListFilter.map(question => (
                                <div className="container d-flex justify-content-between align-items-center py-2">
                                    <div className="w-100">
                                        <div className="card-question">
                                            <div className="d-flex justify-content-between">
                                                <div className="right">
                                                    <ul className="personal-information">
                                                        <li>
                                                            <svg className="mb-1 hidden-phone" width="21"
                                                                 height="21"
                                                                 viewBox="0 0 21 21" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M17.2712 17.5383C16.8914 16.4751 16.0544 15.5357 14.8901 14.8656C13.7258 14.1956 12.2993 13.8324 10.8317 13.8324C9.36415 13.8324 7.93759 14.1956 6.7733 14.8656C5.609 15.5357 4.77203 16.4751 4.3922 17.5383"
                                                                    stroke="#045E5E" strokeLinecap="round"/>
                                                                <circle cx="10.8324" cy="7.16536" r="3.33333"
                                                                        stroke="#045E5E" strokeLinecap="round"/>
                                                            </svg>
                                                            {question.user?.country?.length > 0 ?
                                                                <ReactCountryFlag className="hidden-desktop"
                                                                                  countryCode={question.user?.country}
                                                                                  svg
                                                                                  style={{
                                                                                      width: '1.4em',
                                                                                      height: '1.4em',
                                                                                  }}
                                                                                  title={question.user?.country}
                                                                />
                                                                : ""}
                                                            {question.user?.name + " " + question.user?.family}

                                                        </li>
                                                        <li className="hidden-phone">
                                                            {question.user?.country?.length > 0 ?
                                                                <>
                                                                    <ReactCountryFlag
                                                                        countryCode={question.user?.country}
                                                                        svg
                                                                        style={{
                                                                            width: '1.4em',
                                                                            height: '1.4em',
                                                                        }}
                                                                        title={countries.getName(question.user?.country, 'ar')}
                                                                        alt={countries.getName(question.user?.country, 'ar')}
                                                                    />
                                                                    {countries.getName(question.user?.country, 'ar')}
                                                                </>
                                                                : ""}
                                                        </li>

                                                        {question.category != null ?
                                                            <li>
                                                                <NavLink
                                                                    to={`/category-faq/${question.category.id}`}
                                                                    title={question.category.title}>
                                                                    <svg className="mb-1" width="20" height="20"
                                                                         viewBox="0 0 20 20" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <rect x="4.16699" y="3.3335" width="11.6667"
                                                                              height="14.1667" rx="2"
                                                                              stroke="#045E5E"/>
                                                                        <path d="M7.5 7.5H12.5" stroke="#045E5E"
                                                                              strokeLinecap="round"/>
                                                                        <path d="M7.5 10.8334H12.5" stroke="#045E5E"
                                                                              strokeLinecap="round"/>
                                                                        <path d="M7.5 14.1666H10.8333"
                                                                              stroke="#045E5E"
                                                                              strokeLinecap="round"/>
                                                                    </svg>
                                                                    {question.category.title}
                                                                </NavLink>
                                                            </li> : ""
                                                        }

                                                        <li title="تعليقاً وتعقيباً">
                                                            <svg width="20" height="20" viewBox="0 0 20 20"
                                                                 fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M16.1036 4.81481C16.6654 5.65555 16.6654 6.82594 16.6654 9.16671C16.6654 11.5075 16.6654 12.6779 16.1036 13.5186C15.8604 13.8826 15.5479 14.1951 15.1839 14.4383C14.4495 14.929 13.4634 14.9911 11.6654 14.9989V15L10.7441 16.8427C10.4369 17.4569 9.56045 17.4569 9.25334 16.8427L8.33203 15V14.9989C6.53395 14.9911 5.54792 14.929 4.81346 14.4383C4.4495 14.1951 4.13699 13.8826 3.8938 13.5186C3.33203 12.6779 3.33203 11.5075 3.33203 9.16671C3.33203 6.82594 3.33203 5.65555 3.8938 4.81481C4.13699 4.45084 4.4495 4.13834 4.81346 3.89514C5.65421 3.33337 6.82459 3.33337 9.16536 3.33337H10.832C13.1728 3.33337 14.3432 3.33337 15.1839 3.89514C15.5479 4.13834 15.8604 4.45084 16.1036 4.81481Z"
                                                                    stroke="#045E5E" strokeLinecap="round"
                                                                    strokeLinejoin="round"/>
                                                                <path d="M7.5 7.5L12.5 7.5" stroke="#045E5E"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path d="M7.5 10.8334H10" stroke="#045E5E"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                            <span className="number">
                                                                {question.comment_count}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <NavLink to={"/qa/" + question.id}
                                                             title={question.title}>
                                                        <h3>{question.title.length > 120 ?
                                                            `${question.title.substring(0, 120)}...` : question.title
                                                        }</h3>
                                                    </NavLink>
                                                </div>
                                                <div className="left hidden-phone">
                                                    <svg className="mb-1 ms-1" width="19" height="18"
                                                         viewBox="0 0 19 18" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M15.0417 3H3.95833C3.08388 3 2.375 3.67157 2.375 4.5V15C2.375 15.8284 3.08388 16.5 3.95833 16.5H15.0417C15.9161 16.5 16.625 15.8284 16.625 15V4.5C16.625 3.67157 15.9161 3 15.0417 3Z"
                                                            stroke="#045E5E" strokeLinecap="round"
                                                            strokeLinejoin="round"/>
                                                        <path d="M12.666 1.50012V4.50012" stroke="#045E5E"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M6.33398 1.50012V4.50012" stroke="#045E5E"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M2.375 7.5H16.625" stroke="#045E5E"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>

                                                    <div className="date">
                                                        <span
                                                            className="number"> {question.date_lunar ? question.date_lunar.split("-")[2] : "02"} </span>
                                                        {getMonthName(question.date_lunar ? question.date_lunar.split("-")[1] : "02")}
                                                        <span
                                                            className="number"> {question.date_lunar ? question.date_lunar.split("-")[0] : "1500"} </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) :
                            <div className="w-100 d-flex justify-content-center mt-3">
                                {newLoading ?
                                    <span className="width-fit not-funded-filter">
                                        تحميل ...
                                        </span>
                                    :
                                    <span className="width-fit not-funded-filter">
                                            لم يتم العثور على النتيجة المطلوبة
                                        </span>
                                }
                            </div>
                    }
                    <div className="more-btn-container d-flex justify-content-center mb-sm-0 mb-5 mt-sm-3 mt-4">
                        {!sub ?
                            totalPages >= page ?
                                totalPages !== page &&
                                <NavLink className="more-btn" to="/last-faq">
                                    المزيد
                                </NavLink> : "لم يتم العثور على النتيجة المطلوبة"
                            : ""
                        }

                        {
                            sub ?
                                totalPages2 >= page2 ?
                                    totalPages2 !== page2 &&
                                    <NavLink className="more-btn"
                                             to={`/last-faq?search=${searchF}&categoryId=${visibilitiSubject12}&categoryName=${visibilitiSubject12Title?.length > 0 ? visibilitiSubject12Title : ""}`}>
                                        المزيد
                                    </NavLink> : "لم يتم العثور على النتيجة المطلوبة"
                                : ""
                        }
                    </div>
                </div>
            </section>
            <section className="back-f2 hidden-desktop">
                <div className="aq-container">
                    <div className="row">
                        <div className="col-12 align-items-center d-flex">
                            <div className="d-inline-block width-fit subject-container my-4 d-block">
                                <h3 className="m-0 min-wid-phone-123">مواضيع الأسئلة:</h3>
                            </div>
                            <div onClick={() => {
                                setHasSub(true)
                            }}
                                 className="btn btn-not-fill height-fit-c d-inline-block color-main-2 border-main-2 width-fit fw-light me-2">
                                {defSub2Title}
                                <svg className="w-h-18 me-1" width="12" height="12" viewBox="0 0 12 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 4.5L6 7.5L9 4.5" stroke="#045E5E" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            </div>

                            <div
                                className={hasSub.length > 0 ? "position-relative hidden-desktop select" : "position-relative hidden-desktop"}>

                                <div
                                    className={hasSub ? "overlay-phone-modal fade show " : "overlay-phone-modal invisible fade "}
                                    onClick={() => setHasSub(false)}></div>
                                <div
                                    className={hasSub ? "alphabet-subjects-container position-absolute fade show visible" : "alphabet-subjects-container position-absolute fade invisible"}>

                                    <div className="d-flex justify-content-between pb-3 c-pointer"
                                         onClick={() => {
                                             setHasSub(false)
                                         }}>
                                            <span className="f-15-w">
                                                مواضيع الأسئلة والأجوبة حسب ترتيب الحروف
                                            </span>
                                        <span>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6 15L12 9L18 15" stroke="#045E5E"
                                                          strokeWidth="2" strokeLinecap="round"
                                                          strokeLinejoin="round"/>
                                                </svg>
                                            </span>
                                    </div>
                                    <div className="d-sm-flex">
                                        <div className="d-block width-100-phone overflow-scroll">
                                            <ul className="list-unstyled p-0 d-flex top-ul gap-3 mb-0">
                                                <li className={hasItem("الف") ? (defSub == "الف") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("الف"))
                                                            setDefSub("الف")
                                                    }}><span>الف</span>
                                                </li>
                                                <li className={hasItem("ب") ? (defSub == "ب") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ب"))
                                                            setDefSub("ب")
                                                    }}><span>ب</span>
                                                </li>
                                                <li className={hasItem("ت") ? (defSub == "ت") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ت"))
                                                            setDefSub("ت")
                                                    }}><span>ت</span>
                                                </li>
                                                <li className={hasItem("ث") ? (defSub == "ث") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ث"))
                                                            setDefSub("ث")
                                                    }}><span>ث</span>
                                                </li>
                                                <li className={hasItem("ج") ? (defSub == "ج") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ج"))
                                                            setDefSub("ج")
                                                    }}><span>ج</span>
                                                </li>
                                                <li className={hasItem("ح") ? (defSub == "ح") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ح"))
                                                            setDefSub("ح")
                                                    }}><span>ح</span>
                                                </li>
                                                <li className={hasItem("خ") ? (defSub == "خ") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("خ"))
                                                            setDefSub("خ")
                                                    }}><span>خ</span>
                                                </li>
                                                <li className={hasItem("د") ? (defSub == "د") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("د"))
                                                            setDefSub("د")
                                                    }}><span>د</span>
                                                </li>
                                                <li className={hasItem("ذ") ? (defSub == "ذ") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ذ"))
                                                            setDefSub("ذ")
                                                    }}><span>ذ</span>
                                                </li>
                                                <li className={hasItem("ر") ? (defSub == "ر") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ر"))
                                                            setDefSub("ر")
                                                    }}><span>ر</span>
                                                </li>
                                                <li className={hasItem("ز") ? (defSub == "ز") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ز"))
                                                            setDefSub("ز")
                                                    }}><span>ز</span>
                                                </li>
                                                <li className={hasItem("س") ? (defSub == "س") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("س"))
                                                            setDefSub("س")
                                                    }}><span>س</span>
                                                </li>
                                                <li className={hasItem("ش") ? (defSub == "ش") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ش"))
                                                            setDefSub("ش")
                                                    }}><span>ش</span>
                                                </li>
                                                <li className={hasItem("ص") ? (defSub == "ص") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ص"))
                                                            setDefSub("ص")
                                                    }}><span>ص</span>
                                                </li>
                                                <li className={hasItem("ض") ? (defSub == "ض") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ض"))
                                                            setDefSub("ض")
                                                    }}><span>ض</span>
                                                </li>
                                                <li className={hasItem("ط") ? (defSub == "ط") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ط"))
                                                            setDefSub("ط")
                                                    }}><span>ط</span>
                                                </li>
                                                <li className={hasItem("ظ") ? (defSub == "ظ") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ظ"))
                                                            setDefSub("ظ")
                                                    }}><span>ظ</span>
                                                </li>
                                                <li className={hasItem("ع") ? (defSub == "ع") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ع"))
                                                            setDefSub("ع")
                                                    }}><span>ع</span>
                                                </li>
                                                <li className={hasItem("غ") ? (defSub == "غ") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("غ"))
                                                            setDefSub("غ")
                                                    }}><span>غ</span>
                                                </li>
                                                <li className={hasItem("ف") ? (defSub == "ف") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ف"))
                                                            setDefSub("ف")
                                                    }}><span>ف</span>
                                                </li>
                                                <li className={hasItem("ق") ? (defSub == "ق") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ق"))
                                                            setDefSub("ق")
                                                    }}><span>ق</span>
                                                </li>
                                                <li className={hasItem("ك") ? (defSub == "ك") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ك"))
                                                            setDefSub("ك")
                                                    }}><span>ك</span>
                                                </li>
                                                <li className={hasItem("ل") ? (defSub == "ل") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ل"))
                                                            setDefSub("ل")
                                                    }}><span>ل</span>
                                                </li>
                                                <li className={hasItem("م") ? (defSub == "م") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("م"))
                                                            setDefSub("م")
                                                    }}><span>م</span>
                                                </li>
                                                <li className={hasItem("ن") ? (defSub == "ن") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ن"))
                                                            setDefSub("ن")
                                                    }}><span>ن</span>
                                                </li>
                                                <li className={hasItem("ه") ? (defSub == "ه") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ه"))
                                                            setDefSub("ه")
                                                    }}><span>ه</span>
                                                </li>
                                                <li className={hasItem("و") ? (defSub == "و") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("و"))
                                                            setDefSub("و")
                                                    }}><span>و</span>
                                                </li>
                                                <li className={hasItem("ي") ? (defSub == "ي") ? "select" : "" : "disable"}
                                                    onClick={() => {
                                                        if (hasItem("ي"))
                                                            setDefSub("ي")
                                                    }}><span>ي</span>
                                                </li>
                                                <li className={hasItem("الأسئلة المتفرقة") ? (defSub == "الأسئلة المتفرقة") ? "width-100-im select" : "width-100-im" : "width-100-im disable"}
                                                    onClick={() => {
                                                        if (hasItem("الأسئلة المتفرقة"))
                                                            setDefSub("الأسئلة المتفرقة")
                                                    }}><span>الأسئلة المتفرقة</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul className="subject-container-div">
                                            {
                                                props.Sub33 ?
                                                    props.Sub33.length > 0 ?
                                                        props.Sub33.map(items =>
                                                            items.Alphabet == defSub ?
                                                                <li onClick={
                                                                    () => {
                                                                        if (defSub2 == items.id) {
                                                                            setDefSub2("");
                                                                            setDefSub2Title("");
                                                                        } else {
                                                                            setDefSub2(items.id);
                                                                            setDefSub2Title(items.title);
                                                                            setHasSub(false)
                                                                        }
                                                                    }
                                                                }
                                                                    className={(visibilitiSubject12 == items.id) ? "select" : ""}
                                                                    title={items.title + " (" + items.count_content + ")"}
                                                                >{items.title} <span
                                                                    className="green-subject"> ({items.count_content})</span>
                                                                </li>
                                                                : ""
                                                        )
                                                        : ""
                                                    : ""
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>
            <section className="back-f1 py-sm-5 py-3">
                <div className="aq-container overflow-hidden">
                    <div className="row">
                        <div className="col-12 col-sm-2 hidden-phone">
                            <div className="bg-white-container">
                                <div className="subject-container">
                                    <h3>مواضيع الأسئلة</h3>
                                    <input type="text" className="search-filter"
                                           onChange={(e) => searchItems(e.target.value)}
                                           placeholder="البحث"/>
                                </div>
                                {
                                    // style: استایل عمومی کامپوننت Scrollbars.
                                    // renderTrackHorizontal: تابعی که استایل ردیف افقی اسکرول‌بار را سفارشی می‌کند.
                                    // renderThumbHorizontal: تابعی که استایل سربالای افقی اسکرول‌بار را سفارشی می‌کند.
                                    // renderTrackVertical: تابعی که استایل ردیف عمودی اسکرول‌بار را سفارشی می‌کند.
                                    // renderThumbVertical: تابعی که استایل سربالای عمودی اسکرول‌بار را سفارشی می‌کند.
                                    // noScrollX: اگر true باشد، امکان اسکرول افقی غیرفعال می‌شود.
                                    // noScrollY: اگر true باشد، امکان اسکرول عمودی غیرفعال می‌شود.
                                    // onScroll: تابعی که هنگامی که اسکرول رخ می‌دهد، فراخوانی می‌شود.
                                    // onScrollStart: تابعی که هنگام شروع اسکرول، فراخوانی می‌شود.
                                    // onScrollStop: تابعی که هنگام پایان اسکرول، فراخوانی می‌شود.
                                    // onScrollFrame: تابعی که در هر فریم اسکرول، فراخوانی می‌شود.
                                    // onScrollStartFrame: تابعی که در هر فریم اسکرول بعد از شروع، فراخوانی می‌شود.
                                    // onScrollStopFrame: تابعی که در هر فریم اسکرول بعد از پایان، فراخوانی می‌شود.
                                    // onUpdate: تابعی که هنگام به‌روزرسانی کامپوننت، فراخوانی می‌شود.
                                    // onUpdateFrame: تابعی که در هر فریم به‌روزرسانی، فراخوانی می‌شود.
                                    // onScrollingChange: تابعی که وضعیت اسکرول را ارسال می‌کند.
                                    // translateContentSizesToHolder: اگر true باشد، سایز محتوا به سایز باکس اسک
                                }
                                <div className="subject-container-scroll hidden-phone">
                                    <Scrollbars

                                        autoHideTimeout={500}
                                        autoHideDuration={200}
                                        renderThumbVertical={({style, ...props}) => (
                                            <div
                                                {...props}
                                                style={{
                                                    ...style,
                                                    backgroundColor: '#B0B0B0',
                                                    borderRadius: '3px',
                                                    cursor: 'pointer',
                                                    width: '2px',
                                                }}
                                            />
                                        )}
                                    >
                                        <div className="content">

                                            {
                                                searchInput?.length > 0 ?
                                                    <>
                                                        {notFunded ?
                                                            <span className="not-funded-filter-1">لم يتم العثور على موضوع
                                                            </span>
                                                            : ""}
                                                        {hasItem1("الف") ?
                                                            <>
                                                                <p>الف</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "الف" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}
                                                        {hasItem1("ب") ?
                                                            <>
                                                                <p>ب</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ب" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}
                                                        {hasItem1("ت") ?
                                                            <>
                                                                <p>ت</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ت" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}
                                                        {hasItem1("ث") ?
                                                            <>
                                                                <p>ث</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ث" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}

                                                        {hasItem1("ج") ?
                                                            <>
                                                                <p>ج</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ج" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}
                                                        {hasItem1("ح") ?
                                                            <>
                                                                <p>ح</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ح" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}
                                                        {hasItem1("خ") ?
                                                            <>
                                                                <p>خ</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "خ" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}

                                                        {hasItem1("د") ?
                                                            <>
                                                                <p>د</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "د" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}
                                                        {hasItem1("ذ") ?
                                                            <>
                                                                <p>ذ</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ذ" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}
                                                        {hasItem1("ر") ?
                                                            <>
                                                                <p>ر</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ر" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}
                                                        {hasItem1("ز") ?
                                                            <>
                                                                <p>ز</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ز" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}
                                                        {hasItem1("س") ?
                                                            <>
                                                                <p>س</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "س" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}
                                                        {hasItem1("ش") ?
                                                            <>
                                                                <p>ش</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ش" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}

                                                        {hasItem1("ص") ?
                                                            <>
                                                                <p>ص</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ص" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}

                                                        {hasItem1("ض") ?
                                                            <>
                                                                <p>ض</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ض" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}

                                                        {hasItem1("ط") ?
                                                            <>
                                                                <p>ط</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ط" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}

                                                        {hasItem1("ظ") ?
                                                            <>
                                                                <p>ظ</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ظ" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}

                                                        {hasItem1("ع") ?
                                                            <>
                                                                <p>ع</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ع" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}

                                                        {hasItem1("غ") ?
                                                            <>
                                                                <p>غ</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "غ" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}

                                                        {hasItem1("ف") ?
                                                            <>
                                                                <p>ف</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ف" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}

                                                        {hasItem1("ق") ?
                                                            <>
                                                                <p>ق</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ق" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}

                                                        {hasItem1("ك") ?
                                                            <>
                                                                <p>ك</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ك" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}

                                                        {hasItem1("ل") ?
                                                            <>
                                                                <p>ل</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ل" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}
                                                        {hasItem1("م") ?
                                                            <>
                                                                <p>م</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "م" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}

                                                        {hasItem1("ن") ?
                                                            <>
                                                                <p>ن</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ن" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}

                                                        {hasItem1("ه") ?
                                                            <>
                                                                <p>ه</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ه" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}

                                                        {hasItem1("و") ?
                                                            <>
                                                                <p>و</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "و" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}
                                                        {hasItem1("ي") ?
                                                            <>
                                                                <p>ي</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "ي" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}
                                                        {hasItem1("الأسئلة المتفرقة") ?
                                                            <>
                                                                <p>الأسئلة المتفرقة</p>
                                                                <ul>
                                                                    {
                                                                        filteredResults?.map(items =>
                                                                            items.Alphabet == "الأسئلة المتفرقة" ?
                                                                                <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                    onClick={() => {
                                                                                        setDefSub2(items.id);
                                                                                        setDefSub2Title(items.title);
                                                                                    }}
                                                                                    title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                    <span
                                                                                        className="green-subject"> ({items.count_content})</span>
                                                                                </li>
                                                                                : ""
                                                                        )
                                                                    }
                                                                </ul>
                                                            </>
                                                            : ""}
                                                    </>


                                                    : props.Sub33?.length > 0 ?
                                                        <>
                                                            {hasItem("الف") ?
                                                                <>
                                                                    <p>الف</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "الف" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}
                                                            {hasItem("ب") ?
                                                                <>
                                                                    <p>ب</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ب" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}
                                                            {hasItem("ت") ?
                                                                <>
                                                                    <p>ت</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ت" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}
                                                            {hasItem("ث") ?
                                                                <>
                                                                    <p>ث</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ث" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}

                                                            {hasItem("ج") ?
                                                                <>
                                                                    <p>ج</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ج" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}
                                                            {hasItem("ح") ?
                                                                <>
                                                                    <p>ح</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ح" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}
                                                            {hasItem("خ") ?
                                                                <>
                                                                    <p>خ</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "خ" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}

                                                            {hasItem("د") ?
                                                                <>
                                                                    <p>د</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "د" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}
                                                            {hasItem("ذ") ?
                                                                <>
                                                                    <p>ذ</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ذ" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}
                                                            {hasItem("ر") ?
                                                                <>
                                                                    <p>ر</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ر" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}
                                                            {hasItem("ز") ?
                                                                <>
                                                                    <p>ز</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ز" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}
                                                            {hasItem("س") ?
                                                                <>
                                                                    <p>س</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "س" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}
                                                            {hasItem("ش") ?
                                                                <>
                                                                    <p>ش</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ش" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}

                                                            {hasItem("ص") ?
                                                                <>
                                                                    <p>ص</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ص" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}

                                                            {hasItem("ض") ?
                                                                <>
                                                                    <p>ض</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ض" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}

                                                            {hasItem("ط") ?
                                                                <>
                                                                    <p>ط</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ط" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}

                                                            {hasItem("ظ") ?
                                                                <>
                                                                    <p>ظ</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ظ" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}

                                                            {hasItem("ع") ?
                                                                <>
                                                                    <p>ع</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ع" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}

                                                            {hasItem("غ") ?
                                                                <>
                                                                    <p>غ</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "غ" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}

                                                            {hasItem("ف") ?
                                                                <>
                                                                    <p>ف</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ف" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}

                                                            {hasItem("ق") ?
                                                                <>
                                                                    <p>ق</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ق" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}

                                                            {hasItem("ك") ?
                                                                <>
                                                                    <p>ك</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ك" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}

                                                            {hasItem("ل") ?
                                                                <>
                                                                    <p>ل</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ل" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}
                                                            {hasItem("م") ?
                                                                <>
                                                                    <p>م</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "م" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}

                                                            {hasItem("ن") ?
                                                                <>
                                                                    <p>ن</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ن" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}

                                                            {hasItem("ه") ?
                                                                <>
                                                                    <p>ه</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ه" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}

                                                            {hasItem("و") ?
                                                                <>
                                                                    <p>و</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "و" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}
                                                            {hasItem("ي") ?
                                                                <>
                                                                    <p>ي</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "ي" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}
                                                            {hasItem("الأسئلة المتفرقة") ?
                                                                <>
                                                                    <p>الأسئلة المتفرقة</p>
                                                                    <ul>
                                                                        {
                                                                            props.Sub33.map(items =>
                                                                                items.Alphabet == "الأسئلة المتفرقة" ?
                                                                                    <li className={(defSub2 == items.id) ? "select" : ""}
                                                                                        onClick={() => {
                                                                                            setDefSub2(items.id);
                                                                                            setDefSub2Title(items.title);
                                                                                        }}
                                                                                        title={items.title + " (" + items.count_content + ")"}>{items.title}
                                                                                        <span
                                                                                            className="green-subject"> ({items.count_content})</span>
                                                                                    </li>
                                                                                    : ""
                                                                            )
                                                                        }
                                                                    </ul>
                                                                </>
                                                                : ""}
                                                        </>
                                                        : ""
                                            }
                                        </div>
                                    </Scrollbars>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-10 col-12">
                            <div className="row">
                                <div className="col-sm col-12">
                                    <div className="subject-container mt-sm-3  mb-sm-4 mt-3 mb-1 d-block">
                                        <h3>أسئلة وأجوبة الموضوع</h3>
                                    </div>
                                    <div className="height-100-sc hidden-phone">
                                        <Scrollbars
                                            autoHideTimeout={500}
                                            autoHideDuration={200}
                                            renderThumbVertical={({style, ...props}) => (
                                                <div
                                                    {...props}
                                                    style={{
                                                        ...style,
                                                        backgroundColor: '#B0B0B0',
                                                        borderRadius: '3px',
                                                        cursor: 'pointer',
                                                        width: '2px',
                                                        top: '15px',
                                                        right: '-12px'
                                                    }}
                                                />
                                            )}
                                        >
                                            {!loadingPage ?
                                                filter3?.length > 0 ?
                                                    filter3.map(question => (
                                                        <div className="w-100 mt-sm-2 mt-3">
                                                            <div className="card-question bg-white">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="right">
                                                                        <ul className="personal-information">
                                                                            <li>
                                                                                <svg className="mb-1 hidden-phone"
                                                                                     width="21"
                                                                                     height="21"
                                                                                     viewBox="0 0 21 21" fill="none"
                                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                                    <path
                                                                                        d="M17.2712 17.5383C16.8914 16.4751 16.0544 15.5357 14.8901 14.8656C13.7258 14.1956 12.2993 13.8324 10.8317 13.8324C9.36415 13.8324 7.93759 14.1956 6.7733 14.8656C5.609 15.5357 4.77203 16.4751 4.3922 17.5383"
                                                                                        stroke="#045E5E"
                                                                                        strokeLinecap="round"/>
                                                                                    <circle cx="10.8324" cy="7.16536"
                                                                                            r="3.33333"
                                                                                            stroke="#045E5E"
                                                                                            strokeLinecap="round"/>
                                                                                </svg>
                                                                                {question.user?.country?.length > 0 ?
                                                                                    <ReactCountryFlag
                                                                                        className="hidden-desktop"
                                                                                        countryCode={question.user?.country}
                                                                                        svg
                                                                                        style={{
                                                                                            width: '1.4em',
                                                                                            height: '1.4em',
                                                                                        }}
                                                                                        title={countries.getName(question.user?.country, 'ar')}
                                                                                        alt={countries.getName(question.user?.country, 'ar')}
                                                                                    />
                                                                                    : ""}
                                                                                {question.user?.name + " " + question.user?.family}
                                                                            </li>

                                                                            <li className="hidden-phone">
                                                                                {question.user?.country?.length > 0 ?
                                                                                    <>
                                                                                        <ReactCountryFlag
                                                                                            countryCode={question.user?.country}
                                                                                            svg
                                                                                            style={{
                                                                                                width: '1.4em',
                                                                                                height: '1.4em',
                                                                                            }}
                                                                                            title={countries.getName(question.user?.country, 'ar')}
                                                                                            alt={countries.getName(question.user?.country, 'ar')}
                                                                                        />
                                                                                        {countries.getName(question.user?.country, 'ar')}
                                                                                    </>

                                                                                    : ""}
                                                                            </li>

                                                                            {question.category != null ?
                                                                                <li>
                                                                                    <NavLink
                                                                                        to={`/category-faq/${question.category.id}`}
                                                                                        title={question.category.title}>
                                                                                        <svg className="mb-1" width="20"
                                                                                             height="20"
                                                                                             viewBox="0 0 20 20"
                                                                                             fill="none"
                                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                                            <rect x="4.16699" y="3.3335"
                                                                                                  width="11.6667"
                                                                                                  height="14.1667"
                                                                                                  rx="2"
                                                                                                  stroke="#045E5E"/>
                                                                                            <path d="M7.5 7.5H12.5"
                                                                                                  stroke="#045E5E"
                                                                                                  strokeLinecap="round"/>
                                                                                            <path d="M7.5 10.8334H12.5"
                                                                                                  stroke="#045E5E"
                                                                                                  strokeLinecap="round"/>
                                                                                            <path
                                                                                                d="M7.5 14.1666H10.8333"
                                                                                                stroke="#045E5E"
                                                                                                strokeLinecap="round"/>
                                                                                        </svg>
                                                                                        {question.category.title}
                                                                                    </NavLink>
                                                                                </li> : ""
                                                                            }
                                                                            <li title="تعليقاً وتعقيباً">
                                                                                <svg width="20" height="20"
                                                                                     viewBox="0 0 20 20"
                                                                                     fill="none"
                                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                                    <path
                                                                                        d="M16.1036 4.81481C16.6654 5.65555 16.6654 6.82594 16.6654 9.16671C16.6654 11.5075 16.6654 12.6779 16.1036 13.5186C15.8604 13.8826 15.5479 14.1951 15.1839 14.4383C14.4495 14.929 13.4634 14.9911 11.6654 14.9989V15L10.7441 16.8427C10.4369 17.4569 9.56045 17.4569 9.25334 16.8427L8.33203 15V14.9989C6.53395 14.9911 5.54792 14.929 4.81346 14.4383C4.4495 14.1951 4.13699 13.8826 3.8938 13.5186C3.33203 12.6779 3.33203 11.5075 3.33203 9.16671C3.33203 6.82594 3.33203 5.65555 3.8938 4.81481C4.13699 4.45084 4.4495 4.13834 4.81346 3.89514C5.65421 3.33337 6.82459 3.33337 9.16536 3.33337H10.832C13.1728 3.33337 14.3432 3.33337 15.1839 3.89514C15.5479 4.13834 15.8604 4.45084 16.1036 4.81481Z"
                                                                                        stroke="#045E5E"
                                                                                        strokeLinecap="round"
                                                                                        strokeLinejoin="round"/>
                                                                                    <path d="M7.5 7.5L12.5 7.5"
                                                                                          stroke="#045E5E"
                                                                                          strokeLinecap="round"
                                                                                          strokeLinejoin="round"/>
                                                                                    <path d="M7.5 10.8334H10"
                                                                                          stroke="#045E5E"
                                                                                          strokeLinecap="round"
                                                                                          strokeLinejoin="round"/>
                                                                                </svg>
                                                                                <span className="number">
                                                                                {question.comment_count}
                                                                            </span>

                                                                            </li>
                                                                        </ul>
                                                                        <NavLink to={"/qa/" + question.id}
                                                                                 title={question.title}>
                                                                            <h3>
                                                                                {question.title.length > 120 ?
                                                                                    `${question.title.substring(0, 120)}...` : question.title
                                                                                }
                                                                            </h3>
                                                                        </NavLink>
                                                                    </div>
                                                                    <div className="left hidden-phone">
                                                                        <svg className="mb-1 ms-1" width="19"
                                                                             height="18"
                                                                             viewBox="0 0 19 18"
                                                                             fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M15.0417 3H3.95833C3.08388 3 2.375 3.67157 2.375 4.5V15C2.375 15.8284 3.08388 16.5 3.95833 16.5H15.0417C15.9161 16.5 16.625 15.8284 16.625 15V4.5C16.625 3.67157 15.9161 3 15.0417 3Z"
                                                                                stroke="#045E5E" strokeLinecap="round"
                                                                                strokeLinejoin="round"/>
                                                                            <path d="M12.666 1.50012V4.50012"
                                                                                  stroke="#045E5E"
                                                                                  strokeLinecap="round"
                                                                                  strokeLinejoin="round"/>
                                                                            <path d="M6.33398 1.50012V4.50012"
                                                                                  stroke="#045E5E"
                                                                                  strokeLinecap="round"
                                                                                  strokeLinejoin="round"/>
                                                                            <path d="M2.375 7.5H16.625" stroke="#045E5E"
                                                                                  strokeLinecap="round"
                                                                                  strokeLinejoin="round"/>
                                                                        </svg>
                                                                        <div className="date">
                                                        <span
                                                            className="number"> {question.date_lunar ? question.date_lunar.split("-")[2] : "02"} </span>
                                                                            {getMonthName(question.date_lunar ? question.date_lunar.split("-")[1] : "02")}
                                                                            <span
                                                                                className="number"> {question.date_lunar ? question.date_lunar.split("-")[0] : "1500"} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) : ""
                                                :
                                                <div className="w-100 d-flex justify-content-center">
                                                    <NavLink className="more-btn mt-3 more-btn-not-fill">
                                                        <img className="image-loading image-loading-2"
                                                             src={imageLoading}
                                                             alt="loading"
                                                             title="loading"/> تحميل...
                                                    </NavLink>
                                                </div>
                                            }
                                        </Scrollbars>
                                    </div>
                                    <div className="height-100-sc hidden-desktop">
                                        {filter3?.length > 0 ?
                                            filter3.map(question => (
                                                <div className="w-100 mt-3">
                                                    <div className="card-question bg-white">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="right">
                                                                <ul className="personal-information">
                                                                    <li>
                                                                        <svg className="mb-1 hidden-phone"
                                                                             width="21"
                                                                             height="21"
                                                                             viewBox="0 0 21 21" fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M17.2712 17.5383C16.8914 16.4751 16.0544 15.5357 14.8901 14.8656C13.7258 14.1956 12.2993 13.8324 10.8317 13.8324C9.36415 13.8324 7.93759 14.1956 6.7733 14.8656C5.609 15.5357 4.77203 16.4751 4.3922 17.5383"
                                                                                stroke="#045E5E"
                                                                                strokeLinecap="round"/>
                                                                            <circle cx="10.8324" cy="7.16536"
                                                                                    r="3.33333"
                                                                                    stroke="#045E5E"
                                                                                    strokeLinecap="round"/>
                                                                        </svg>
                                                                        {question.user?.country?.length > 0 ?
                                                                            <ReactCountryFlag className="hidden-desktop"
                                                                                              countryCode={question.user?.country}
                                                                                              svg
                                                                                              style={{
                                                                                                  width: '1.4em',
                                                                                                  height: '1.4em',
                                                                                              }}
                                                                                              title={countries.getName(question.user?.country, 'ar')}
                                                                                              alt={countries.getName(question.user?.country, 'ar')}
                                                                            />
                                                                            : ""}
                                                                        {question.user?.name + " " + question.user?.family}
                                                                    </li>

                                                                    <li className="hidden-phone">
                                                                        {question.user?.country?.length > 0 ?
                                                                            <>
                                                                                <ReactCountryFlag
                                                                                    countryCode={question.user?.country}
                                                                                    svg
                                                                                    style={{
                                                                                        width: '1.4em',
                                                                                        height: '1.4em',
                                                                                    }}
                                                                                    title={countries.getName(question.user?.country, 'ar')}
                                                                                    alt={countries.getName(question.user?.country, 'ar')}
                                                                                />
                                                                                {countries.getName(question.user?.country, 'ar')}
                                                                            </>

                                                                            : ""}
                                                                    </li>

                                                                    {question.category != null ?
                                                                        <li>
                                                                            <NavLink
                                                                                to={`/category-faq/${question.category.id}`}
                                                                                title={question.category.title}>
                                                                                <svg className="mb-1" width="20"
                                                                                     height="20"
                                                                                     viewBox="0 0 20 20" fill="none"
                                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                                    <rect x="4.16699" y="3.3335"
                                                                                          width="11.6667"
                                                                                          height="14.1667" rx="2"
                                                                                          stroke="#045E5E"/>
                                                                                    <path d="M7.5 7.5H12.5"
                                                                                          stroke="#045E5E"
                                                                                          strokeLinecap="round"/>
                                                                                    <path d="M7.5 10.8334H12.5"
                                                                                          stroke="#045E5E"
                                                                                          strokeLinecap="round"/>
                                                                                    <path d="M7.5 14.1666H10.8333"
                                                                                          stroke="#045E5E"
                                                                                          strokeLinecap="round"/>
                                                                                </svg>
                                                                                {question.category.title}
                                                                            </NavLink>
                                                                        </li> : ""
                                                                    }
                                                                    <li title="تعليقاً وتعقيباً">
                                                                        <svg width="20" height="20"
                                                                             viewBox="0 0 20 20"
                                                                             fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M16.1036 4.81481C16.6654 5.65555 16.6654 6.82594 16.6654 9.16671C16.6654 11.5075 16.6654 12.6779 16.1036 13.5186C15.8604 13.8826 15.5479 14.1951 15.1839 14.4383C14.4495 14.929 13.4634 14.9911 11.6654 14.9989V15L10.7441 16.8427C10.4369 17.4569 9.56045 17.4569 9.25334 16.8427L8.33203 15V14.9989C6.53395 14.9911 5.54792 14.929 4.81346 14.4383C4.4495 14.1951 4.13699 13.8826 3.8938 13.5186C3.33203 12.6779 3.33203 11.5075 3.33203 9.16671C3.33203 6.82594 3.33203 5.65555 3.8938 4.81481C4.13699 4.45084 4.4495 4.13834 4.81346 3.89514C5.65421 3.33337 6.82459 3.33337 9.16536 3.33337H10.832C13.1728 3.33337 14.3432 3.33337 15.1839 3.89514C15.5479 4.13834 15.8604 4.45084 16.1036 4.81481Z"
                                                                                stroke="#045E5E"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"/>
                                                                            <path d="M7.5 7.5L12.5 7.5"
                                                                                  stroke="#045E5E"
                                                                                  strokeLinecap="round"
                                                                                  strokeLinejoin="round"/>
                                                                            <path d="M7.5 10.8334H10"
                                                                                  stroke="#045E5E"
                                                                                  strokeLinecap="round"
                                                                                  strokeLinejoin="round"/>
                                                                        </svg>
                                                                        <span className="number">
                                                                            {question.comment_count}
                                                                        </span>

                                                                    </li>
                                                                </ul>
                                                                <NavLink to={"/qa/" + question.id}
                                                                         title={question.title}>
                                                                    <h3>
                                                                        {question.title.length > 120 ?
                                                                            `${question.title.substring(0, 120)}...` : question.title
                                                                        }
                                                                    </h3>
                                                                </NavLink>
                                                            </div>
                                                            <div className="left hidden-phone">
                                                                <svg className="mb-1 ms-1" width="19" height="18"
                                                                     viewBox="0 0 19 18"
                                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M15.0417 3H3.95833C3.08388 3 2.375 3.67157 2.375 4.5V15C2.375 15.8284 3.08388 16.5 3.95833 16.5H15.0417C15.9161 16.5 16.625 15.8284 16.625 15V4.5C16.625 3.67157 15.9161 3 15.0417 3Z"
                                                                        stroke="#045E5E" strokeLinecap="round"
                                                                        strokeLinejoin="round"/>
                                                                    <path d="M12.666 1.50012V4.50012"
                                                                          stroke="#045E5E"
                                                                          strokeLinecap="round"
                                                                          strokeLinejoin="round"/>
                                                                    <path d="M6.33398 1.50012V4.50012"
                                                                          stroke="#045E5E"
                                                                          strokeLinecap="round"
                                                                          strokeLinejoin="round"/>
                                                                    <path d="M2.375 7.5H16.625" stroke="#045E5E"
                                                                          strokeLinecap="round"
                                                                          strokeLinejoin="round"/>
                                                                </svg>
                                                                <div className="date">
                                                    <span
                                                        className="number"> {question.date_lunar ? question.date_lunar.split("-")[2] : "02"} </span>
                                                                    {getMonthName(question.date_lunar ? question.date_lunar.split("-")[1] : "02")}
                                                                    <span
                                                                        className="number"> {question.date_lunar ? question.date_lunar.split("-")[0] : "1500"} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : ""}
                                    </div>
                                    {filter3?.length >= 10 ?
                                        <div className="d-flex justify-content-end pt-4">
                                            <NavLink className="more-btn"
                                                     to={`/category-faq/${defSub2}`}>
                                                المزيد
                                            </NavLink>
                                        </div>
                                        : ""}
                                </div>
                                {Allsubject.length > 0 ?
                                    <div className="col-sm-3 col-12">
                                        <div className="subject-container mb-sm-4 mt-sm-4 mt-4 mb-1  d-block">
                                            <h3 className="phone-m-0">مواضيع متداولة</h3>
                                        </div>
                                        <div className="phone-scroll">
                                            <div className="phone-scroll-2">
                                                {Allsubject.map(item =>
                                                    <button className="btn fw-light btn-not-fill my-3"
                                                            onClick={() => {
                                                                setDefSub2(item.id);
                                                                setDefSub2Title(item.title);
                                                            }}
                                                            title={item.title}>
                                                        {item.title}
                                                    </button>
                                                )}
                                            </div>
                                        </div>


                                    </div>
                                    : ""}

                                {
                                    subBooks.length > 0 ?
                                        <div className="col-12">
                                            <div className="subject-container mt-4 pt-3  mb-4 d-block">
                                                <h3>الكتب ذات الصلة</h3>
                                            </div>
                                            <div
                                                className="books-module books-module-2 nav-green pb-0 position-relative">
                                                <div className="aq-container book-container px-0 phone-m-0">
                                                    {
                                                        subBooks.length > 0 ?
                                                            <Swiper
                                                                slidesPerView={'auto'}
                                                                spaceBetween={20}
                                                                grabCursor={true}
                                                                dir="rtl"
                                                                breakpoints={{
                                                                    0: {
                                                                        spaceBetween: 15
                                                                    },
                                                                    900: {
                                                                        spaceBetween: 20
                                                                    }
                                                                }}
                                                                modules={[Navigation]}
                                                                navigation={true}
                                                                className="mySwiper">
                                                                {subBooks.map(books =>
                                                                    <SwiperSlide>
                                                                        <div className="book-image-container">
                                                                            <NavLink to={"/books/" + books.id}
                                                                                     title={books.title}>
                                                                                <img className="books-image"
                                                                                     src={books.image}
                                                                                     alt={books.title}
                                                                                     title={books.title}/>
                                                                            </NavLink>
                                                                        </div>
                                                                        <div className="book-data-container">
                                                                            <NavLink to={"/books/" + books.id}
                                                                                     title={books.title}>
                                                                                <h3 className="text-center">
                                                                                    {books.title?.length > 40 ?
                                                                                        `${books.title.substring(0, 40)}...` : books.title
                                                                                    }

                                                                                </h3>
                                                                            </NavLink>
                                                                            <NavLink
                                                                                to={`/books?tab=all&authorId=${books.author[0].id}&authorName=${books.author[0].title}`}
                                                                                reloadDocument
                                                                                title={books?.author[0]?.prefix? books?.author[0]?.prefix + " " + books.author[0].title : books.author[0].title}>
                                                                                <p className="text-center">
                                                                                    {books?.author[0]?.prefix? books?.author[0]?.prefix + " " + books.author[0].title : books.author[0].title}
                                                                                </p>
                                                                            </NavLink>
                                                                        </div>
                                                                    </SwiperSlide>
                                                                )}
                                                            </Swiper>
                                                            : ""
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
};

export default Filter;