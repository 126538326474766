import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import GuidedSayingsTextPageEl from "../components/GuidedSayingsTextPageEl";

const GuidedSayingsTextPage = () => {
    return (
        <div>
            <HeaderPage />
            <GuidedSayingsTextPageEl />
            <Footer />
        </div>
    );
};

export default GuidedSayingsTextPage;
