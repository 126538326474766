import { NavLink } from 'react-router-dom';
import {Swiper, SwiperSlide} from "swiper/react";   
import {Navigation} from "swiper";
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import imageSeminar from '../assets/uploads/seminar.jpg';
import imageSpeach from "../assets/uploads/user.jpg";
import {useEffect, useState} from "react";
import {LazyLoadImage} from 'react-lazy-load-image-component';

const getMonthName = (day) => {
    switch (day) {
        case '01' :
            return ' محرم ';
        case '02' :
            return ' صفر ';
        case '03' :
            return ' ربيع الاول ';
        case '04' :
            return ' ربيع الثاني ';
        case '05' :
            return ' جمادى الاول ';
        case '06' :
            return ' جمادى الثانية ';
        case '07' :
            return ' رجب ';
        case '08' :
            return ' شعبان ';
        case '09' :
            return ' رمضان ';
        case '10' :
            return ' شوال ';
        case '11' :
            return ' ذوالقعدة ';
        case '12' :
            return ' ذوالحجة ';
    }
}

const Seminars = (props) => {
    SwiperCore.use([Autoplay]);

    return (
        <div className="seminars-module position-relative">
            <div className="aq-container pt-4 d-flex justify-content-between align-items-center">
                <div className="title-container mt-5 pe-5 pb-4">
                    <NavLink to="/seminar" title="الندوات العقائدية">
                        <h2>
                            الندوات العقائدية
                        </h2>
                    </NavLink>

                    <p>
                        مكتبة مرئية، صوتية لندوات المركز
                    </p>
                </div>
            </div>
            <div className="aq-container px-5 pt-4">
                <div className="row">
                    <div className="col-12 seminar-slider">
                        {props.data.length > 0 ?
                            <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={40}
                                loop={true}
                                autoplay={{
                                    delay: 2000,
                                    disableOnInteraction: true,
                                    pauseOnMouseEnter: true,
                                }}
                                grabCursor={true}
                                dir="rtl"
                                breakpoints={{
                                    0: {
                                        spaceBetween: 20
                                    },
                                    900: {
                                        spaceBetween: 40
                                    }
                                }}
                                modules={[Navigation]}
                                navigation={true}
                                className="mySwiper">
                                {props.data.map(seminar =>
                               <>
                                   <SwiperSlide>
                                       <div className="seminar-contianer">
                                           <LazyLoadImage className="seminar-image" src={seminar.image}
                                                          alt={seminar.title} title={seminar.title}/>
                                           <div className="seminar-actor-cotainer">
                                               <LazyLoadImage src={seminar.author.image} alt={seminar?.author?.prefix? seminar?.author?.prefix + " " + seminar.author.title : seminar.author.title}
                                                    title={seminar?.author?.prefix? seminar?.author?.prefix + " " + seminar.author.title : seminar.author.title} />
                                           </div>
                                           <div className="px-4">
                                               <div className="speaker-container">
                                                   <NavLink to={"/speaker/" + seminar.author.id} className="speaker-name"
                                                            title={seminar?.author?.prefix? seminar?.author?.prefix + " " + seminar.author.title : seminar.author.title}>
                                                       {seminar?.author?.prefix? seminar?.author?.prefix + " " + seminar.author.title : seminar.author.title}
                                                   </NavLink>
                                                   {/*<NavLink to={`/seminar?categoryId=${seminar.categories[0][0].id}&categoryName=${seminar.categories[0][0].title}`} className="speaker-tag" title={seminar.categories[0][0].title}>{seminar.categories[0][0].title}</NavLink>*/}
                                               </div>

                                               <NavLink to={"/seminar/" + seminar.id} className="speech-name min-h-42" title={seminar.title}>
                                                   {seminar.title.length > 100 ?
                                                       `${seminar.title.substring(0, 100)}...` : seminar.title
                                                   }
                                               </NavLink>
                                               <div className="date-container-news text-right mt-3 pb-1">
                                                   <svg className="mb-1 ms-1" width="19" height="18" viewBox="0 0 19 18"
                                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                                       <path
                                                           d="M15.0417 3H3.95833C3.08388 3 2.375 3.67157 2.375 4.5V15C2.375 15.8284 3.08388 16.5 3.95833 16.5H15.0417C15.9161 16.5 16.625 15.8284 16.625 15V4.5C16.625 3.67157 15.9161 3 15.0417 3Z"
                                                           stroke="#757575" strokeLinecap="round" strokeLinejoin="round" />
                                                       <path d="M12.666 1.50012V4.50012" stroke="#757575" strokeLinecap="round"
                                                             strokeLinejoin="round" />
                                                       <path d="M6.33398 1.50012V4.50012" stroke="#757575"
                                                             strokeLinecap="round" strokeLinejoin="round" />
                                                       <path d="M2.375 7.5H16.625" stroke="#757575" strokeLinecap="round"
                                                             strokeLinejoin="round" />
                                                   </svg>
                                                   <div className="date pb-3 f-12-g f-main-imp">
                                                       <>
                                                        <span className="number ">
                                                            {seminar.date_release?seminar.date_release.split("-")[2]:"02"} </span>
                                                           {getMonthName(seminar.date_release?seminar.date_release.split("-")[1]:"02")}
                                                           <span
                                                               className="number ">
                                                            {seminar.date_release?seminar.date_release.split("-")[0]:"1500"} </span>
                                                       </>

                                                   </div>
                                               </div>
                                           </div>

                                       </div>
                                   </SwiperSlide>

                               </>
                                )}
                                <div className="more-btn-container d-flex justify-content-end mt--52">
                                    <NavLink className="more-btn" to="/seminar">
                                        المزيد
                                    </NavLink>
                                </div>
                            </Swiper>
                            : ""
                        }

                    </div>
                </div>
            </div>

            <div className="aq-container left-line-3"></div>
        </div>
    );
};

export default Seminars;
