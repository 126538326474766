import React, {useEffect, useRef, useState} from 'react';
import {NavLink} from "react-router-dom";
import image from "../assets/uploads/user.jpg";
import ImageUploading from 'react-images-uploading';
import {useValidation} from "@enterwell/react-form-validation";
import {Select, Button} from "antd";
import Cookies from 'universal-cookie';


const LoginModal = (props) => {

    const something = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleSubmitRegisterPage2(event)
        }
    }

    const something1 = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleSubmitLoginPage2(event)
        }
    }

    const something2 = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            handleSubmitLoginPage4(event)
        }
    }

    const handleChangeName = (event) => {
        // 👇 Get input value from "event"
        setName(event.target.value);
    };

    const handleChangeFamily = (event) => {
        // 👇 Get input value from "event"
        setFamily(event.target.value);
    };

    const handleChangeReligion = (event) => {
        // 👇 Get input value from "event"
        setReligion(event.target.value);
    };

    const handleChangePass = (event) => {
        // 👇 Get input value from "event"
        if (event.target.value.length > 0)
            setInputTypeShow1(true)
        else
            setInputTypeShow1(false)

        setPass(event.target.value);
    };

    const handleChangePassCheck = (event) => {
        // 👇 Get input value from "event"
        if (event.target.value.length > 0)
            setInputTypeShow(true)
        else
            setInputTypeShow(false)

        setPassCheck(event.target.value);
    };

    const [images, setImages] = React.useState([]);
    const maxNumber = 1;

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        setUserImage(imageList[0].file)
        setImages(imageList);
    };

    const handleChangeCountry = (value) => {
        setUserCountry(value)
    };

    const emailValidation = (value) => /^.+@\S+\.\S+$/.test(value);
    const email = useValidation('', emailValidation);
    const email2 = useValidation('', emailValidation);

    const [otp, setOtp] = useState("");
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);

    const [passLogin, setPassLogin] = useState(0);

    const [inputType, setInputType] = useState('password');
    const [inputTypeShow, setInputTypeShow] = useState(false);

    const [inputType1, setInputType1] = useState('password');
    const [inputTypeShow1, setInputTypeShow1] = useState(false);

    const [inputType5, setInputType5] = useState('password');
    const [inputTypeShow5, setInputTypeShow5] = useState(false);


    const [inputType6, setInputType6] = useState('password');
    const [inputTypeShow6, setInputTypeShow6] = useState(false);

    const [inputType7, setInputType7] = useState('password');
    const [inputTypeShow7, setInputTypeShow7] = useState(false);

    const [userImage, setUserImage] = useState('');
    const [Name, setName] = useState('');
    const [family, setFamily] = useState('');
    const [userCountry, setUserCountry] = useState('');
    const [religion, setReligion] = useState('');
    const [pass, setPass] = useState('');
    const [passCheck, setPassCheck] = useState('');

    const [pass2, setPass2] = useState('');
    const [passCheck2, setPassCheck2] = useState('');
    // last page err
    const [nameErr, setNameErr] = useState(false);
    const [familyErr, setFamilyErr] = useState(false);
    const [userCountryErr, setUserCountryErr] = useState(false);
    const [religionErr, setReligionErr] = useState(false);
    const [passErr, setPassErr] = useState(false);
    const [passCheckErr, setPassCheckErr] = useState(false);


    const [Changer, setChanger] = useState(false);


    const [code1, setCode1] = useState('');
    const [code2, setCode2] = useState('');
    const [code3, setCode3] = useState('');
    const [code4, setCode4] = useState('');
    const [tabPage, setTabPage] = useState(10);
    const [successMessage, setSuccessMessage] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showBorder, setShowBorder] = useState(false);
    const [showBorder1, setShowBorder1] = useState(false);
    const [userImageSrc, setUserImageSrc] = useState();

    const [vaErr, setVaErr] = useState();
    const [emailPage, setEmailPage] = useState();
    const [showItC, setShowItC] = useState();
    const handleNameChange1 = event => {
        const limit = 1;
        setCode1(event.target.value.slice(0, limit));
        if (event.target.value.length > 0) {
            inputElementRef1.current.focus();
        }
    };


    useEffect(() => {
        if (tabPage == 11 || tabPage == 3) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [seconds, tabPage, Changer]);

    const handleNameChange2 = event => {
        const limit = 1;
        setCode2(event.target.value.slice(0, limit));
        if (event.target.value.length == 1) {
            inputElementRef2.current.focus();
        } else {
            inputElementRef.current.focus();
        }
    };
    const handleNameChange3 = event => {
        const limit = 1;
        setCode3(event.target.value.slice(0, limit));
        if (event.target.value.length == 1) {
            inputElementRef3.current.focus();
        } else {
            inputElementRef1.current.focus();
        }
    };
    const handleNameChange4 = event => {
        const limit = 1;
        setCode4(event.target.value.slice(0, limit));
        if (event.target.value.length != 1) {
            inputElementRef2.current.focus();
        }
    };

    const inputElementRef = useRef(null);
    const inputElementRef1 = useRef(null);
    const inputElementRef2 = useRef(null);
    const inputElementRef3 = useRef(null);

    let handleSubmitRegisterPage1 = async (e) => {
        e.preventDefault();
        if (email.error) {
            setErrorMessage("يرجى إدخال البريد الإلكتروني الصحيح")
            setShowErrorMessage(true);
            setShowBorder(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
                setShowBorder(false);
            }, 5000);
            return false;
        }

        if (!emailValidation(email.value)) {
            setErrorMessage("يرجى إدخال البريد الإلكتروني الصحيح")
            setShowErrorMessage(true);
            setShowBorder(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
                setShowBorder(false);
            }, 5000);
            return false
        }

        setEmailPage(email.value);
        await fetch('https://dashboard.aqaed.net/api/v1/isEmailRegister', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json"
            },
            body: JSON.stringify(
                {
                    email: email.value
                }
            )
        }).then(response => response.json())
            .then(data => {
                if (data['status'] == 200) {
                    setSuccessMessage(data['massage']);
                    setShowSuccessMessage(true);
                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);
                    setTabPage(11);
                 const inputElements = document.querySelectorAll('input');
                    const inputElements1 = document.querySelectorAll('textarea');
                    inputElements1.forEach(input => {
                        input.value = '';
                    });
                    inputElements.forEach(input => {
                        input.value = '';
                    });
                    return () => clearTimeout(timer);
                } else {
                    loginSendCode2()
                    setSuccessMessage("البريد الإلكتروني الذي تم إدخاله مكرر. إذا كنت تريد استرداد حسابك ، فتابع الخطوات");
                    setShowSuccessMessage(true);
                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);

                    setTabPage(11);
                    return () => clearTimeout(timer);
                }


            });
    };
    let handleSubmitRegisterPage2 = async (e) => {
        e.preventDefault();
        let myCode = "";
        if (code1.length == 1 && code2.length == 1 && code3.length == 1 && code4.length == 1)
            myCode = code1 + code2 + code3 + code4;
        else
            return false

        setEmailPage(email.value);
        await fetch('https://dashboard.aqaed.net/api/v1/checkCodeEmail', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json"
            },
            body: JSON.stringify(
                {
                    email: emailPage,
                    code: myCode
                }
            )
        }).then(response => response.json())
            .then(data => {
                if (data['status'] == 200) {
                    setTabPage(12);
                    setSuccessMessage("تم تأكيد بريدك الإلكتروني. يرجى إكمال النموذج لمتابعة التسجيل");
                    setShowSuccessMessage(true);
                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);
                    return () => clearTimeout(timer);
                } else {
                    setErrorMessage("كلمة المرور التي تم إدخالها غير صحيحة");
                    setShowErrorMessage(true);
                    setShowBorder1(true);
                    const timer = setTimeout(() => {
                        setShowErrorMessage(false);
                        setShowBorder1(false);
                    }, 5000);
                    return () => clearTimeout(timer);
                }


            });
    };
    let handleSubmitRegisterPage3 = async (e) => {
        // userImage
        e.preventDefault();
        if (email.error) {
            setErrorMessage("يرجى إدخال البريد الإلكتروني الصحيح")
            setShowErrorMessage(true);
            setShowBorder(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
                setShowBorder(false);
            }, 5000);
            return false;
        }

        if (!emailValidation(email.value)) {
            setErrorMessage("يرجى إدخال البريد الإلكتروني الصحيح")
            setShowErrorMessage(true);
            setShowBorder(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
                setShowBorder(false);
            }, 5000);
            return false
        }

        setEmailPage(email.value);
        // const [Name, setName] = useState('');
        // const [Family, setFamily] = useState('');
        // const [userCountry, setUserCountry] = useState();
        // const [religion, setReligion] = useState();
        // const [pass, setPass] = useState();
        // const [passCheck, setPassCheck] = useState();
        if (Name.length == 0) {
            setErrorMessage("ملء حقل الاسم إلزامي")
            setShowErrorMessage(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
            return false;
        }

        if (family.length == 0) {
            setErrorMessage("ملء حقل اسم العائلة إلزامي")
            setShowErrorMessage(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
            return false;
        }

        if (userCountry.length == 0) {
            setErrorMessage("ملء حقل البلد إلزامي")
            setShowErrorMessage(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
            return false;
        }

        if (pass.length == 0) {
            setErrorMessage("ملء حقل كلمة السر إلزامي")
            setShowErrorMessage(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
            return false;
        }

        if (passCheck.length == 0) {
            setErrorMessage("ملء حقل إعادة كلمة السر إلزامي")
            setShowErrorMessage(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
            return false;
        }

        if (pass < 6) {
            setErrorMessage("عدد الأحرف المسموح بها لا يقل عن 6 أحرف")
            setShowErrorMessage(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
            return false;
        }

        if (passCheck != pass) {
            setErrorMessage("كلمة المرور وتكرار كلمة المرور غير متطابقين")
            setShowErrorMessage(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
            return false;
        }

        let myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Cookie", "mylanguageClient=ar");

        let formdata1 = new FormData();
        // if (userImage)
        //     formdata1.append("avatar", userImage);
        formdata1.append("password", pass);
        formdata1.append("name", Name);
        formdata1.append("family", family);
        formdata1.append("religion", religion);
        formdata1.append("country", userCountry);
        formdata1.append("email", email.value);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata1,
            redirect: 'follow'
        };

        fetch("https://dashboard.aqaed.net/api/v1/register", requestOptions)
            .then(response => response.json())
            .then(result => {
                const cookies = new Cookies();

                if (result.status != "error") {
                    setSuccessMessage("تم تسجيلكم بنجاح");
                    let code = email.value + "m/!@m" + pass;
                    code = btoa(code);
                    cookies.set('myCat', code, {path: '/', maxAge: 31536000});
                    setShowSuccessMessage(true);
                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);
                    window.location.reload(true);
                } else {
                    setErrorMessage(result.massage)
                    setShowErrorMessage(true);
                    const timer = setTimeout(() => {
                        setShowErrorMessage(false);
                    }, 5000);
                }


            });

    };
    let handleSubmitLoginPage1 = async (e) => {
        // userImage
        e.preventDefault();

        if (email2.error) {
            setErrorMessage("يرجى إدخال البريد الإلكتروني الصحيح")
            setShowErrorMessage(true);
            setShowBorder(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
                setShowBorder(false);
            }, 5000);
            return false;
        }

        if (!emailValidation(email2.value)) {
            setErrorMessage("يرجى إدخال البريد الإلكتروني الصحيح")
            setShowErrorMessage(true);
            setShowBorder(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
                setShowBorder(false);
            }, 5000);
            return false
        }


        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");

        var formdata = new FormData();
        formdata.append("email", email2.value);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://dashboard.aqaed.net/api/v1/isEmailLogin", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == "error") {
                    setErrorMessage("لم يتم العثور على المستخدم بهذا البريد الإلكتروني")
                    setShowErrorMessage(true);
                    setShowBorder(true);
                    const timer = setTimeout(() => {
                        setShowErrorMessage(false);
                        setShowBorder(false);
                    }, 5000);
                } else {
                    setUserImageSrc(result.avatar)
                    setTabPage(2);
                }
            });

    };
    let handleSubmitLoginPage2 = async (e) => {
        // userImage
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");

        var formdata = new FormData();
        formdata.append("email", email2.value);
        formdata.append("password", passLogin);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://dashboard.aqaed.net/api/v1/isPassLogin", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == "error") {
                    setErrorMessage("كلمة المرور غير صحيحة")
                    setShowErrorMessage(true);
                    setShowBorder(true);
                    const timer = setTimeout(() => {
                        setShowErrorMessage(false);
                        setShowBorder(false);
                    }, 5000);
                } else {
                    const cookies = new Cookies();
                    setSuccessMessage("تم تسجيل الدخول بنجاح");
                    let code = email2.value + "m/!@m" + passLogin;
                    code = btoa(code);
                    cookies.set('myCat', code, {path: '/', maxAge: 31536000});
                    setShowSuccessMessage(true);
                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);

                    clearTimeout(timer)
                    window.location.reload(true);
                }
            });
    }

    let loginSendCode = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");

        var formdata = new FormData();
        formdata.append("email", email2.value);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://dashboard.aqaed.net/api/v1/sendEmail", requestOptions)
            .then(response => response.json());
    }


    let loginSendCode2 = async () => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");

        var formdata = new FormData();
        formdata.append("email", email.value);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://dashboard.aqaed.net/api/v1/sendEmail", requestOptions)
            .then(response => response.json());
    }


    let handleSubmitLoginPage3 = async (e) => {
        e.preventDefault();

        let myCode = "";
        if (code1.length == 1 && code2.length == 1 && code3.length == 1 && code4.length == 1)
            myCode = code1 + code2 + code3 + code4;
        else
            return false

        await fetch('https://dashboard.aqaed.net/api/v1/checkCodeEmail', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json"
            },
            body: JSON.stringify(
                {
                    email: email2.value,
                    code: myCode
                }
            )
        }).then(response => response.json())
            .then(data => {
                if (data['status'] == 200) {
                    const cookies = new Cookies();
                    setSuccessMessage("تم تسجيل الدخول بنجاح");
                    let code = email.value + "m/!@m" + pass;
                    code = btoa(code);
                    cookies.set('myCat', code, {path: '/', maxAge: 31536000});
                    setShowSuccessMessage(true);
                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);
                    setTabPage(4)
                    clearTimeout(timer)
                } else {
                    setErrorMessage("كلمة المرور التي تم إدخالها غير صحيحة");
                    setShowErrorMessage(true);
                    setShowBorder1(true);
                    const timer = setTimeout(() => {
                        setShowErrorMessage(false);
                        setShowBorder1(false);
                    }, 5000);
                    return () => clearTimeout(timer);
                }
            });
    };


    let handleSubmitLoginPage4 = async (e) => {
        e.preventDefault();


        if (pass2.length == 0) {
            setErrorMessage("ملء حقل كلمة السر إلزامي")
            setShowErrorMessage(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
            return false;
        }

        if (passCheck2.length == 0) {
            setErrorMessage("ملء حقل إعادة كلمة السر إلزامي")
            setShowErrorMessage(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
            return false;
        }

        // if (pass2.length > 6) {
        //     setErrorMessage("عدد الأحرف المسموح بها لا يقل عن 6 أحرف")
        //     setShowErrorMessage(true);
        //     const timer = setTimeout(() => {
        //         setShowErrorMessage(false);
        //     }, 5000);
        //     return false;
        // }

        if (passCheck != pass) {
            setErrorMessage("كلمة المرور وتكرار كلمة المرور غير متطابقين")
            setShowErrorMessage(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
            return false;
        }




        await fetch('https://dashboard.aqaed.net/api/v1/editeRegister', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "accept": "application/json"
            },
            body: JSON.stringify(
                {
                    email: email2.value,
                    password: pass2
                }
            )
        }).then(response => response.json())
            .then(data => {
                    const cookies = new Cookies();
                    setSuccessMessage("تم تسجيل الدخول بنجاح");
                    let code = email.value + "m/!@m" + pass2;
                    code = btoa(code);
                    cookies.set('myCat', code, {path: '/', maxAge: 31536000});
                    setShowSuccessMessage(true);
                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);
                    clearTimeout(timer)
                    window.location.reload(true);
            });
    };

    function emailPageShowStar(emailPage) {
        return emailPage.replace(/(\w{2})[\w.-]+@([\w.]+\w)/, "$1***@$2");
    }

    return (<>

        {showSuccessMessage ?
            <div
                className={showSuccessMessage ? "d-flex position-fixed success-message" : "d-flex position-fixed success-message close"}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12.2531 1.20251C6.37312 1.20251 1.45312 6.12251 1.45312 12.0025C1.45312 17.8825 6.37312 22.8025 12.2531 22.8025C18.1331 22.8025 23.0531 18.0025 23.0531 12.0025C23.0531 6.00251 18.2531 1.20251 12.2531 1.20251ZM10.6916 16.2055L6.85156 12.3655L8.05156 11.1655L10.6916 13.8055L16.4516 8.04553L17.6516 9.24553L10.6916 16.2055Z"
                          fill="white"/>
                    <path opacity="0.01" fillRule="evenodd" clipRule="evenodd"
                          d="M10.6916 16.202L6.85156 12.362L8.05156 11.162L10.6916 13.802L16.4516 8.04199L17.6516 9.24199L10.6916 16.202Z"
                          fill="white"/>
                </svg>
                <span className="me-1 text-white">
                       {successMessage}
                   </span>
            </div>
            : ""}

        {showErrorMessage ?
            <div
                className={errorMessage ? "d-flex bg-color-red  position-fixed success-message" : "d-flex bg-color-red position-fixed success-message close"}>
                <svg className="w-23" width="17" height="16" viewBox="0 0 17 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M8.95 0.794189C4.95 0.794189 1.75 3.99419 1.75 7.99419C1.75 11.9942 4.95 15.1942 8.95 15.1942C12.95 15.1942 16.15 11.9942 16.15 7.99419C16.15 3.99419 12.95 0.794189 8.95 0.794189ZM8.30928 3.98967H9.50928V9.58967H8.30928V3.98967ZM8.95007 12.7906C8.47007 12.7906 8.15007 12.4706 8.15007 11.9906C8.15007 11.5106 8.47007 11.1906 8.95007 11.1906C9.43007 11.1906 9.75007 11.5106 9.75007 11.9906C9.75007 12.4706 9.43007 12.7906 8.95007 12.7906Z"
                          fill="#fff"/>
                    <path opacity="0.01" fillRule="evenodd" clipRule="evenodd"
                          d="M8.30758 3.98886H9.50758V9.58886H8.30758V3.98886ZM8.94837 12.7898C8.46837 12.7898 8.14837 12.4698 8.14837 11.9898C8.14837 11.5098 8.46837 11.1898 8.94837 11.1898C9.42837 11.1898 9.74837 11.5098 9.74837 11.9898C9.74837 12.4698 9.42837 12.7898 8.94837 12.7898Z"
                          fill="#fff"/>
                </svg>
                <span className="me-1 text-white">
                    {errorMessage??"البريد الإلكتروني الذي تم إدخاله غير صحيح!"}
                </span>
            </div>
            : ""}

        {(email.error && email.value.length > 0) ?
            <div
                className={email.error ? "d-flex bg-color-red position-fixed success-message" : "d-flex bg-color-red position-fixed success-message close"}>

                <svg className="w-23" width="17" height="16" viewBox="0 0 17 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M8.95 0.794189C4.95 0.794189 1.75 3.99419 1.75 7.99419C1.75 11.9942 4.95 15.1942 8.95 15.1942C12.95 15.1942 16.15 11.9942 16.15 7.99419C16.15 3.99419 12.95 0.794189 8.95 0.794189ZM8.30928 3.98967H9.50928V9.58967H8.30928V3.98967ZM8.95007 12.7906C8.47007 12.7906 8.15007 12.4706 8.15007 11.9906C8.15007 11.5106 8.47007 11.1906 8.95007 11.1906C9.43007 11.1906 9.75007 11.5106 9.75007 11.9906C9.75007 12.4706 9.43007 12.7906 8.95007 12.7906Z"
                          fill="#fff"/>
                    <path opacity="0.01" fillRule="evenodd" clipRule="evenodd"
                          d="M8.30758 3.98886H9.50758V9.58886H8.30758V3.98886ZM8.94837 12.7898C8.46837 12.7898 8.14837 12.4698 8.14837 11.9898C8.14837 11.5098 8.46837 11.1898 8.94837 11.1898C9.42837 11.1898 9.74837 11.5098 9.74837 11.9898C9.74837 12.4698 9.42837 12.7898 8.94837 12.7898Z"
                          fill="#fff"/>
                </svg>
                <span className="me-1 text-white">
                                البريد الإلكتروني الذي تم إدخاله غير صحيح!

                            </span>

            </div>
            : email.error ?
                "" : ""
        }
        {/* login page1 enter email */}
        {tabPage == 1 ?
            <div className={props.show ? "login-modal" : "login-modal close"}>

                <button className="btn bg-transparent btn-close-modal" onClick={props.close} title="لإغلاق"
                        aria-label="لإغلاق">
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.5 6.5L6.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M6.5 6.5L19.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
                <div className="position-absolute top-center">
                    <svg width="171" height="170" viewBox="0 0 171 170" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="85.5" cy="85" r="83.5833" fill="white" stroke="#C7C7C7" strokeWidth="2.83333"/>
                        <path
                            d="M111.548 113.503C109.987 109.135 106.549 105.275 101.765 102.523C96.982 99.7699 91.1213 98.2778 85.092 98.2778C79.0628 98.2778 73.202 99.7699 68.4187 102.523C63.6354 105.275 60.1969 109.135 58.6364 113.503"
                            stroke="#737373" strokeWidth="3.97581" strokeLinecap="round"/>
                        <circle cx="85.0773" cy="70.8889" r="13.6944" stroke="#737373" strokeWidth="3.97581"
                                strokeLinecap="round"/>
                    </svg>
                </div>
                <p className="mt-150 mb-0">الدخول إلى الحساب</p>
                <p className="disc-14">يمكنكم الدخول إلى حسابكم بالبريد الإلكتروني</p>
                <form onSubmit={handleSubmitLoginPage1}
                      className="text-center h-100 form-container form-container-c-black">
                    <div className="col-8 d-flex mx-auto">
                        <input
                            className={(email2.error || showBorder) ? 'input-border-bottom w-100 my-4 error' : 'input-border-bottom w-100 my-4 no-error'}
                            required type="email"
                            value={email2.value}
                            onChange={email2.onChange}
                            onBlur={email2.onBlur}
                            placeholder="البريد الإلكتروني"/>

                    </div>
                    <div className="d-block">
                        <button type="submit" className="btn border-0 more-btn fw-light" aria-label="request for login">
                            التالي
                        </button>
                        <div className="d-flex align-items-center justify-content-center mt-2">
                            <span className="dont-have-account">لا تمتلك حساباً</span>
                            <button type="button" onClick={() => setTabPage(10)}
                                    className="p-0 btn bg-transparent dont-have-account dont-have-account-color-black">
                                انضم إلينا
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            : ""}

        {/* login page2 enter pass or send code  */}

        {tabPage == 2 ?
            <div className={props.show ? "login-modal" : "login-modal close"}>
                <button className="btn bg-transparent btn-close-modal" onClick={props.close} title="لإغلاق"
                        aria-label="لإغلاق">
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.5 6.5L6.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M6.5 6.5L19.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
                <div className="position-absolute top-center">
                    <svg width="171" height="170" viewBox="0 0 171 170" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="85.5" cy="85" r="83.5833" fill="white" stroke="#C7C7C7" strokeWidth="2.83333"/>
                        <path
                            d="M111.548 113.503C109.987 109.135 106.549 105.275 101.765 102.523C96.982 99.7699 91.1213 98.2778 85.092 98.2778C79.0628 98.2778 73.202 99.7699 68.4187 102.523C63.6354 105.275 60.1969 109.135 58.6364 113.503"
                            stroke="#737373" strokeWidth="3.97581" strokeLinecap="round"/>
                        <circle cx="85.0773" cy="70.8889" r="13.6944" stroke="#737373" strokeWidth="3.97581"
                                strokeLinecap="round"/>
                    </svg>
                </div>
                <p className="mt-150 mb-0">الدخول إلى الحساب</p>
                <form onSubmit={handleSubmitLoginPage2}
                      className="text-center h-100 form-container form-container-c-black mt-3">
                    <div className="col-8 d-block mx-auto ">
                        <div className="position-relative">
                            <input
                                className={showBorder ? "input-border-bottom pass-input w-100 mt-3 error" :
                                    "input-border-bottom pass-input w-100 mt-3"}
                                onKeyDown={(e) => something1(e)}
                                onChange={(event) => {
                                    if (event.target.value.length > 0)
                                        setInputTypeShow5(true)
                                    else
                                        setInputTypeShow5(false)
                                    setPassLogin(event.target.value)
                                }
                                } required type={inputType5}
                                placeholder="كلمة المرور"/>
                            <div
                                className={inputTypeShow5 ? "position-absolute show-icon-pass-right" : "position-absolute show-icon-pass-right d-none"}>
                        <span className={inputType5 == "password" ? "change-input-type top-28" : "change-input-type top-28 d-none"}
                              onClick={() => setInputType5('text')}>
                           <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.75 9C0.75 9 3.75 3 9 3C14.25 3 17.25 9 17.25 9C17.25 9 14.25 15 9 15C3.75 15 0.75 9 0.75 9Z"
                                stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
                                stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                                <span
                                    className={inputType5 != "password" ? "change-input-type top-28" : "change-input-type top-28 d-none"}
                                    onClick={() => setInputType5('password')}>
                       <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.93916 7.94025C7.6579 8.22162 7.49993 8.60318 7.5 9.00102C7.50007 9.39885 7.65818 9.78037 7.93954 10.0616C8.2209 10.3429 8.60247 10.5009 9.0003 10.5008C9.39814 10.5007 9.77965 10.3426 10.0609 10.0613M12.5107 12.5048C11.4587 13.163 10.241 13.5082 9 13.5C6.3 13.5 4.05 12 2.25 9C3.204 7.41 4.284 6.2415 5.49 5.4945M7.635 4.635C8.08428 4.54405 8.54161 4.49882 9 4.5C11.7 4.5 13.95 6 15.75 9C15.2505 9.8325 14.7157 10.5502 14.1465 11.1525M2.25 2.25L15.75 15.75"
                            stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                            </div>

                        </div>

                        <button onClick={() => {
                            setTabPage(3)
                            loginSendCode()
                        }
                        }
                                className="focus-border-0 d-flex gap-1 mt-3 align-items-center btn bg-transparent border-0 p-0 fw-light send-code">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2.83333 2.67163H13.5C14.2333 2.67163 14.8333 3.27163 14.8333 4.00496V12.005C14.8333 12.7383 14.2333 13.3383 13.5 13.3383H2.83333C2.1 13.3383 1.5 12.7383 1.5 12.005V4.00496C1.5 3.27163 2.1 2.67163 2.83333 2.67163Z"
                                    stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.8333 4.00659L8.16667 8.67326L1.5 4.00659" stroke="#B0B0B0"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                            <span className="btn bg-transparent border-0 p-0 fw-light send-code">   المصادقة برمز مؤقّت عبر البريد الإلكتروني</span>
                        </button>

                    </div>
                    <div className="d-block">
                        <button type="submit" className="btn border-0 more-btn fw-light" aria-label="request for login">
                            التالي
                        </button>
                        <div className="d-flex align-items-center justify-content-center mt-2">
                            <span className="dont-have-account">لا تمتلك حساباً</span>
                            <button type="button" onClick={() => setTabPage(10)}
                                    className="p-0 btn bg-transparent dont-have-account dont-have-account-color-black">
                                انضم إلينا
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            : ""}
        {/* login page3 enter send code - - - -  */}

        {tabPage == 3 ?
            <div className={props.show ? "login-modal" : "login-modal close"}>
                <button className="btn bg-transparent btn-close-modal" onClick={props.close} title="لإغلاق"
                        aria-label="لإغلاق">
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.5 6.5L6.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M6.5 6.5L19.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
                <div className="position-absolute top-center">
                    <svg width="171" height="170" viewBox="0 0 171 170" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="85.5" cy="85" r="83.5833" fill="white" stroke="#C7C7C7" strokeWidth="2.83333"/>
                        <path
                            d="M111.548 113.503C109.987 109.135 106.549 105.275 101.765 102.523C96.982 99.7699 91.1213 98.2778 85.092 98.2778C79.0628 98.2778 73.202 99.7699 68.4187 102.523C63.6354 105.275 60.1969 109.135 58.6364 113.503"
                            stroke="#737373" strokeWidth="3.97581" strokeLinecap="round"/>
                        <circle cx="85.0773" cy="70.8889" r="13.6944" stroke="#737373" strokeWidth="3.97581"
                                strokeLinecap="round"/>
                    </svg>
                </div>
                <p className="mt-150 mb-0">الدخول إلى الحساب</p>
                <form onSubmit={handleSubmitLoginPage3}
                      className="text-center h-100 form-container form-container-c-black">
                    <div className="col-8 mt-3 d-block mx-auto">
                        <p className="disc-14 text-end mb-0">
                            أدخِل الرمز المرسل إلى بريدك الإلكتروني
                            <br/>
                            {emailPageShowStar(email2.value)}
                        </p>
                        <div className="d-flex w-100 gap-3 child-4 mt-2">
                            <input
                                className={showBorder1 ? "input-border-bottom text-center error" : "input-border-bottom text-center no-error"}
                                onKeyDown={(e) => something(e)}
                                required type="number" value={code1}
                                onChange={handleNameChange1} maxLength={1}
                                ref={inputElementRef}
                            />
                            <input
                                className={showBorder1 ? "input-border-bottom text-center error" : "input-border-bottom text-center no-error"}
                                onKeyDown={(e) => something(e)}
                                required type="number" value={code2}
                                onChange={handleNameChange2} maxLength={1}
                                ref={inputElementRef1}
                            />
                            <input
                                className={showBorder1 ? "input-border-bottom text-center error" : "input-border-bottom text-center no-error"}
                                onKeyDown={(e) => something(e)}
                                required type="number" value={code3}
                                ref={inputElementRef2} onChange={handleNameChange3} maxLength={1}/>
                            <input
                                className={showBorder1 ? "input-border-bottom text-center error" : "input-border-bottom text-center no-error"}
                                onKeyDown={(e) => something(e)}
                                required type="number" value={code4}
                                ref={inputElementRef3} onChange={handleNameChange4} maxLength={1}/>
                            <input type="submit" className="d-none"/>
                        </div>

                        {seconds > 0 || minutes > 0 ? (
                            <p className="text-gray">
                                {minutes < 10 ? `0${minutes}` : minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                            </p>
                        ) : (
                            <button onClick={(e)=>{
                                e.preventDefault();
                                setMinutes(2);
                                setSeconds(0);
                                setChanger(!Changer);
                                loginSendCode()
                            }} className="focus-border-0 d-flex gap-1 mt-3 align-items-center btn bg-transparent border-0
                             p-0 fw-light send-code">
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.5 2.66821V6.66821H11.5" stroke="#B0B0B0" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path
                                        d="M13.8214 9.99627C13.388 11.2229 12.5677 12.2754 11.4842 12.9953C10.4006 13.7151 9.11235 14.0633 7.81366 13.9873C6.51497 13.9114 5.27615 13.4153 4.28388 12.574C3.29162 11.7328 2.59966 10.5917 2.31229 9.32297C2.02492 8.0542 2.1577 6.72639 2.69062 5.53965C3.22354 4.3529 4.12774 3.37151 5.26695 2.74336C6.40616 2.11521 7.71866 1.87433 9.00668 2.05703C10.2947 2.23972 11.4885 2.83609 12.408 3.75627L15.4947 6.66293"
                                        stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span
                                    className="btn bg-transparent border-0 p-0 fw-light send-code">إعادة إرسال الرمز</span>
                            </button>
                        )}
                        <button onClick={() => setTabPage(1)}
                            className="focus-border-0 d-flex gap-1 mt-1 align-items-center btn bg-transparent border-0 p-0 fw-light send-code">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2.83333 2.67163H13.5C14.2333 2.67163 14.8333 3.27163 14.8333 4.00496V12.005C14.8333 12.7383 14.2333 13.3383 13.5 13.3383H2.83333C2.1 13.3383 1.5 12.7383 1.5 12.005V4.00496C1.5 3.27163 2.1 2.67163 2.83333 2.67163Z"
                                    stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.8333 4.00659L8.16667 8.67326L1.5 4.00659" stroke="#B0B0B0"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                            <span
                                className="btn bg-transparent border-0 p-0 fw-light send-code">تعديل البريد الإلكتروني</span>
                        </button>

                    </div>
                    <div className="d-block mt-4 pt-1">
                        <button type="submit" className="btn border-0 more-btn fw-light" aria-label="request for login">
                            التالي
                        </button>
                        <div className="d-flex align-items-center justify-content-center mt-2">
                            <span className="dont-have-account">لا تمتلك حساباً</span>
                            <button type="button" onClick={() => setTabPage(10)}
                                    className="p-0 btn bg-transparent dont-have-account dont-have-account-color-black">
                                انضم إلينا
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            : ""}


        {/* login page4 add new pass - - - -  */}

        {tabPage == 4 ?
            <div className={props.show ? "login-modal" : "login-modal close"}>
                <button className="btn bg-transparent btn-close-modal" onClick={props.close} title="لإغلاق"
                        aria-label="لإغلاق">
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.5 6.5L6.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M6.5 6.5L19.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
                <div className="position-absolute top-center">
                    <svg width="171" height="170" viewBox="0 0 171 170" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="85.5" cy="85" r="83.5833" fill="white" stroke="#C7C7C7" strokeWidth="2.83333"/>
                        <path
                            d="M111.548 113.503C109.987 109.135 106.549 105.275 101.765 102.523C96.982 99.7699 91.1213 98.2778 85.092 98.2778C79.0628 98.2778 73.202 99.7699 68.4187 102.523C63.6354 105.275 60.1969 109.135 58.6364 113.503"
                            stroke="#737373" strokeWidth="3.97581" strokeLinecap="round"/>
                        <circle cx="85.0773" cy="70.8889" r="13.6944" stroke="#737373" strokeWidth="3.97581"
                                strokeLinecap="round"/>
                    </svg>
                </div>
                <p className="mt-150 mb-0">الدخول إلى الحساب</p>
                <form onSubmit={handleSubmitLoginPage4}
                      className="text-center h-100 form-container form-container-c-black mt-3">
                    <div className="col-8 d-block mx-auto ">
                        <div className="position-relative">
                            <input
                                className={showBorder ? "input-border-bottom pass-input w-100 mt-3 error" :
                                    "input-border-bottom pass-input w-100 mt-3"}
                                onKeyDown={(e) => something2(e)}
                                value={pass2}
                                onChange={(event) => {
                                    setPass2(event.target.value);

                                    if (event.target.value.length > 0)
                                        setInputTypeShow6(true)
                                    else
                                        setInputTypeShow6(false)
                                    setPassLogin(event.target.value)
                                }
                                } required type={inputType6}
                                placeholder="كلمة المرور الجديدة"/>
                            <div
                                className={inputTypeShow6 ? "position-absolute show-icon-pass-right" : "position-absolute show-icon-pass-right d-none"}>
                                <span className={inputType6 == "password" ? "change-input-type top-28" : "change-input-type top-28 d-none"}
                                      onClick={() => setInputType6('text')}>
                                   <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.75 9C0.75 9 3.75 3 9 3C14.25 3 17.25 9 17.25 9C17.25 9 14.25 15 9 15C3.75 15 0.75 9 0.75 9Z"
                                        stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
                                        stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                                <span
                                    className={inputType6 != "password" ? "change-input-type top-28" : "change-input-type top-28 d-none"}
                                    onClick={() => setInputType6('password')}>
                                   <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.93916 7.94025C7.6579 8.22162 7.49993 8.60318 7.5 9.00102C7.50007 9.39885 7.65818 9.78037 7.93954 10.0616C8.2209 10.3429 8.60247 10.5009 9.0003 10.5008C9.39814 10.5007 9.77965 10.3426 10.0609 10.0613M12.5107 12.5048C11.4587 13.163 10.241 13.5082 9 13.5C6.3 13.5 4.05 12 2.25 9C3.204 7.41 4.284 6.2415 5.49 5.4945M7.635 4.635C8.08428 4.54405 8.54161 4.49882 9 4.5C11.7 4.5 13.95 6 15.75 9C15.2505 9.8325 14.7157 10.5502 14.1465 11.1525M2.25 2.25L15.75 15.75"
                                        stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                        </div>

                        <div className="position-relative">
                            <input
                                className={showBorder ? "input-border-bottom pass-input w-100 mt-3 error" :
                                    "input-border-bottom pass-input w-100 mt-3"}
                                onKeyDown={(e) => something2(e)}
                                value={passCheck2}
                                onChange={(event) => {
                                    setPassCheck2(event.target.value);
                                    if (event.target.value.length > 0)
                                        setInputTypeShow7(true)
                                    else
                                        setInputTypeShow7(false)
                                    setPassLogin(event.target.value)
                                }
                                } required type={inputType7}
                                placeholder="كرر كلمة المرور الجديدة"/>
                            <div
                                className={inputTypeShow7 ? "position-absolute show-icon-pass-right" : "position-absolute show-icon-pass-right d-none"}>
                                <span className={inputType7 == "password" ? "change-input-type top-28" : "change-input-type top-28 d-none"}
                                      onClick={() => setInputType7('text')}>
                                   <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.75 9C0.75 9 3.75 3 9 3C14.25 3 17.25 9 17.25 9C17.25 9 14.25 15 9 15C3.75 15 0.75 9 0.75 9Z"
                                        stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
                                        stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                                <span
                                    className={inputType7 != "password" ? "change-input-type top-28" : "change-input-type top-28 d-none"}
                                    onClick={() => setInputType7('password')}>
                                   <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.93916 7.94025C7.6579 8.22162 7.49993 8.60318 7.5 9.00102C7.50007 9.39885 7.65818 9.78037 7.93954 10.0616C8.2209 10.3429 8.60247 10.5009 9.0003 10.5008C9.39814 10.5007 9.77965 10.3426 10.0609 10.0613M12.5107 12.5048C11.4587 13.163 10.241 13.5082 9 13.5C6.3 13.5 4.05 12 2.25 9C3.204 7.41 4.284 6.2415 5.49 5.4945M7.635 4.635C8.08428 4.54405 8.54161 4.49882 9 4.5C11.7 4.5 13.95 6 15.75 9C15.2505 9.8325 14.7157 10.5502 14.1465 11.1525M2.25 2.25L15.75 15.75"
                                        stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </span>
                            </div>
                        </div>


                    </div>
                    <div className="d-block">
                        <button type="submit" className="btn border-0 more-btn fw-light" aria-label="request for login">
                            التالي
                        </button>
                        <div className="d-flex align-items-center justify-content-center mt-2">
                            <span className="dont-have-account">لا تمتلك حساباً</span>
                            <button type="button" onClick={() => setTabPage(10)}
                                    className="p-0 btn bg-transparent dont-have-account dont-have-account-color-black">
                                انضم إلينا
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            : ""}

        {/* register page1 enter email */}
        {tabPage == 10 ?
            <div className={props.show ? "login-modal login-modal-h-big-1" : "login-modal login-modal-h-big-1 close"}>

                <button className="btn bg-transparent btn-close-modal" onClick={props.close} title="لإغلاق"
                        aria-label="لإغلاق">
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.5 6.5L6.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M6.5 6.5L19.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
                <p className="mt-150 mt-4 pt-3 mb-0">إنشاء حساب</p>
                <p className="disc-14">
                    يمكنكم التسجيل بالبريد الإلكتروني لإنشاء
                    <br/>
                    حسابكم
                    الخاص على الموقع
                </p>
                <form className="text-center form-container form-container-c-black mt-0"
                      onSubmit={handleSubmitRegisterPage3}>
                    <div className="col-8 d-flex mx-auto pt-4 mt-0">
                        <input
                            className={(email.error || showBorder) ? 'input-border-bottom w-100 error' : 'input-border-bottom w-100 no-error'}
                            required type="email"
                            value={email.value}
                            onChange={email.onChange}
                            onBlur={email.onBlur}
                            placeholder="البريد الإلكتروني"/>
                    </div>
                    <div className="col-8 d-flex mx-auto">
                        <input className="input-border-bottom w-100" onChange={handleChangeName} required type="text"
                               maxLength="100"
                               placeholder="الاسم"/>
                    </div>
                    <div className="col-8 d-flex mx-auto ">
                        <input className="input-border-bottom w-100" onChange={handleChangeFamily} required type="text"
                               maxLength="100"
                               placeholder="اسم العائلة"/>
                    </div>

                    <div className="col-8 d-flex mx-auto my-2">
                        <Select
                            showSearch
                            placeholder="الدولة"
                            onChange={handleChangeCountry}
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={[
                                {
                                    value: "AD",
                                    label: "أندورا",
                                },
                                {
                                    value: "AE",
                                    label: "الامارات العربية المتحدة",
                                },
                                {
                                    value: "AF",
                                    label: "أفغانستان",
                                },
                                {
                                    value: "AG",
                                    label: "أنتيجوا وبربودا",
                                },
                                {
                                    value: "AI",
                                    label: "أنجويلا",
                                },
                                {
                                    value: "AL",
                                    label: "ألبانيا",
                                },
                                {
                                    value: "AM",
                                    label: "أرمينيا",
                                },
                                {
                                    value: "AO",
                                    label: "أنجولا",
                                },
                                {
                                    value: "AQ",
                                    label: "القطب الجنوبي",
                                },
                                {
                                    value: "AR",
                                    label: "الأرجنتين",
                                },
                                {
                                    value: "AS",
                                    label: "ساموا الأمريكية",
                                },
                                {
                                    value: "AT",
                                    label: "النمسا",
                                },
                                {
                                    value: "AU",
                                    label: "أستراليا",
                                },
                                {
                                    value: "AW",
                                    label: "آروبا",
                                },
                                {
                                    value: "AX",
                                    label: "جزر أولان",
                                },
                                {
                                    value: "AZ",
                                    label: "أذربيجان",
                                },
                                {
                                    value: "BA",
                                    label: "البوسنة والهرسك",
                                },
                                {
                                    value: "BB",
                                    label: "بربادوس",
                                },
                                {
                                    value: "BD",
                                    label: "بنجلاديش",
                                },
                                {
                                    value: "BE",
                                    label: "بلجيكا",
                                },
                                {
                                    value: "BF",
                                    label: "بوركينا فاسو",
                                },
                                {
                                    value: "BG",
                                    label: "بلغاريا",
                                },
                                {
                                    value: "BH",
                                    label: "البحرين",
                                },
                                {
                                    value: "BI",
                                    label: "بوروندي",
                                },
                                {
                                    value: "BJ",
                                    label: "بنين",
                                },
                                {
                                    value: "BL",
                                    label: "سان بارتيلمي",
                                },
                                {
                                    value: "BM",
                                    label: "برمودا",
                                },
                                {
                                    value: "BN",
                                    label: "بروناي",
                                },
                                {
                                    value: "BO",
                                    label: "بوليفيا",
                                },
                                {
                                    value: "BQ",
                                    label: "بونير",
                                },
                                {
                                    value: "BR",
                                    label: "البرازيل",
                                },
                                {
                                    value: "BS",
                                    label: "الباهاما",
                                },
                                {
                                    value: "BT",
                                    label: "بوتان",
                                },
                                {
                                    value: "BV",
                                    label: "جزيرة بوفيه",
                                },
                                {
                                    value: "BW",
                                    label: "بتسوانا",
                                },
                                {
                                    value: "BY",
                                    label: "روسيا البيضاء",
                                },
                                {
                                    value: "BZ",
                                    label: "بليز",
                                },
                                {
                                    value: "CA",
                                    label: "كندا",
                                },
                                {
                                    value: "CC",
                                    label: "جزر كوكوس",
                                },
                                {
                                    value: "CD",
                                    label: "جمهورية الكونغو الديمقراطية",
                                },
                                {
                                    value: "CF",
                                    label: "جمهورية افريقيا الوسطى",
                                },
                                {
                                    value: "CG",
                                    label: "الكونغو - برازافيل",
                                },
                                {
                                    value: "CH",
                                    label: "سويسرا",
                                },
                                {
                                    value: "CI",
                                    label: "ساحل العاج",
                                },
                                {
                                    value: "CK",
                                    label: "جزر كوك",
                                },
                                {
                                    value: "CL",
                                    label: "شيلي",
                                },
                                {
                                    value: "CM",
                                    label: "الكاميرون",
                                },
                                {
                                    value: "CN",
                                    label: "الصين",
                                },
                                {
                                    value: "CO",
                                    label: "كولومبيا",
                                },
                                {
                                    value: "CR",
                                    label: "كوستاريكا",
                                },
                                {
                                    value: "CU",
                                    label: "كوبا",
                                },
                                {
                                    value: "CV",
                                    label: "الرأس الأخضر",
                                },
                                {
                                    value: "CW",
                                    label: "كوراساو",
                                },
                                {
                                    value: "CX",
                                    label: "جزيرة الكريسماس",
                                },
                                {
                                    value: "CY",
                                    label: "قبرص",
                                },
                                {
                                    value: "CZ",
                                    label: "جمهورية التشيك",
                                },
                                {
                                    value: "DE",
                                    label: "ألمانيا",
                                },
                                {
                                    value: "DJ",
                                    label: "جيبوتي",
                                },
                                {
                                    value: "DK",
                                    label: "الدانمرك",
                                },
                                {
                                    value: "DM",
                                    label: "دومينيكا",
                                },
                                {
                                    value: "DO",
                                    label: "جمهورية الدومينيك",
                                },
                                {
                                    value: "DZ",
                                    label: "الجزائر",
                                },
                                {
                                    value: "EC",
                                    label: "الاكوادور",
                                },
                                {
                                    value: "EE",
                                    label: "استونيا",
                                },
                                {
                                    value: "EG",
                                    label: "مصر",
                                },
                                {
                                    value: "EH",
                                    label: "الصحراء الغربية",
                                },
                                {
                                    value: "ER",
                                    label: "اريتريا",
                                },
                                {
                                    value: "ES",
                                    label: "أسبانيا",
                                },
                                {
                                    value: "ET",
                                    label: "اثيوبيا",
                                },
                                {
                                    value: "FI",
                                    label: "فنلندا",
                                },
                                {
                                    value: "FJ",
                                    label: "فيجي",
                                },
                                {
                                    value: "FK",
                                    label: "جزر فوكلاند",
                                },
                                {
                                    value: "FM",
                                    label: "ميكرونيزيا",
                                },
                                {
                                    value: "FO",
                                    label: "جزر فارو",
                                },
                                {
                                    value: "FR",
                                    label: "فرنسا",
                                },
                                {
                                    value: "GA",
                                    label: "الجابون",
                                },
                                {
                                    value: "GB",
                                    label: "المملكة المتحدة",
                                },
                                {
                                    value: "GD",
                                    label: "جرينادا",
                                },
                                {
                                    value: "GE",
                                    label: "جورجيا",
                                },
                                {
                                    value: "GF",
                                    label: "غويانا",
                                },
                                {
                                    value: "GG",
                                    label: "غيرنزي",
                                },
                                {
                                    value: "GH",
                                    label: "غانا",
                                },
                                {
                                    value: "GI",
                                    label: "جبل طارق",
                                },
                                {
                                    value: "GL",
                                    label: "جرينلاند",
                                },
                                {
                                    value: "GM",
                                    label: "غامبيا",
                                },
                                {
                                    value: "GN",
                                    label: "غينيا",
                                },
                                {
                                    value: "GP",
                                    label: "جوادلوب",
                                },
                                {
                                    value: "GQ",
                                    label: "غينيا الاستوائية",
                                },
                                {
                                    value: "GR",
                                    label: "اليونان",
                                },
                                {
                                    value: "GS",
                                    label: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
                                },
                                {
                                    value: "GT",
                                    label: "جواتيمالا",
                                },
                                {
                                    value: "GU",
                                    label: "جوام",
                                },
                                {
                                    value: "GW",
                                    label: "غينيا بيساو",
                                },
                                {
                                    value: "GY",
                                    label: "غيانا",
                                },
                                {
                                    value: "HK",
                                    label: "هونج كونج الصينية",
                                },
                                {
                                    value: "HM",
                                    label: "جزيرة هيرد وماكدونالد",
                                },
                                {
                                    value: "HN",
                                    label: "هندوراس",
                                },
                                {
                                    value: "HR",
                                    label: "كرواتيا",
                                },
                                {
                                    value: "HT",
                                    label: "هايتي",
                                },
                                {
                                    value: "HU",
                                    label: "المجر",
                                },
                                {
                                    value: "ID",
                                    label: "اندونيسيا",
                                },
                                {
                                    value: "IE",
                                    label: "أيرلندا",
                                },
                                {
                                    value: "IL",
                                    label: "اسرائيل",
                                },
                                {
                                    value: "IM",
                                    label: "جزيرة مان",
                                },
                                {
                                    value: "IN",
                                    label: "الهند",
                                },
                                {
                                    value: "IO",
                                    label: "المحيط الهندي البريطاني",
                                },
                                {
                                    value: "IQ",
                                    label: "العراق",
                                },
                                {
                                    value: "IR",
                                    label: "ايران",
                                },
                                {
                                    value: "IS",
                                    label: "أيسلندا",
                                },
                                {
                                    value: "IT",
                                    label: "ايطاليا",
                                },
                                {
                                    value: "JE",
                                    label: "جيرسي",
                                },
                                {
                                    value: "JM",
                                    label: "جامايكا",
                                },
                                {
                                    value: "JO",
                                    label: "الأردن",
                                },
                                {
                                    value: "JP",
                                    label: "اليابان",
                                },
                                {
                                    value: "KE",
                                    label: "كينيا",
                                },
                                {
                                    value: "KG",
                                    label: "قرغيزستان",
                                },
                                {
                                    value: "KH",
                                    label: "كمبوديا",
                                },
                                {
                                    value: "KI",
                                    label: "كيريباتي",
                                },
                                {
                                    value: "KM",
                                    label: "جزر القمر",
                                },
                                {
                                    value: "KN",
                                    label: "سانت كيتس ونيفيس",
                                },
                                {
                                    value: "KP",
                                    label: "كوريا الشمالية",
                                },
                                {
                                    value: "KR",
                                    label: "كوريا الجنوبية",
                                },
                                {
                                    value: "KW",
                                    label: "الكويت",
                                },
                                {
                                    value: "KY",
                                    label: "جزر الكايمن",
                                },
                                {
                                    value: "KZ",
                                    label: "كازاخستان",
                                },
                                {
                                    value: "LA",
                                    label: "لاوس",
                                },
                                {
                                    value: "LB",
                                    label: "لبنان",
                                },
                                {
                                    value: "LC",
                                    label: "سانت لوسيا",
                                },
                                {
                                    value: "LI",
                                    label: "ليختنشتاين",
                                },
                                {
                                    value: "LK",
                                    label: "سريلانكا",
                                },
                                {
                                    value: "LR",
                                    label: "ليبيريا",
                                },
                                {
                                    value: "LS",
                                    label: "ليسوتو",
                                },
                                {
                                    value: "LT",
                                    label: "ليتوانيا",
                                },
                                {
                                    value: "LU",
                                    label: "لوكسمبورج",
                                },
                                {
                                    value: "LV",
                                    label: "لاتفيا",
                                },
                                {
                                    value: "LY",
                                    label: "ليبيا",
                                },
                                {
                                    value: "MA",
                                    label: "المغرب",
                                },
                                {
                                    value: "MC",
                                    label: "موناكو",
                                },
                                {
                                    value: "MD",
                                    label: "مولدافيا",
                                },
                                {
                                    value: "ME",
                                    label: "الجبل الأسود",
                                },
                                {
                                    value: "MF",
                                    label: "سانت مارتين",
                                },
                                {
                                    value: "MG",
                                    label: "مدغشقر",
                                },
                                {
                                    value: "MH",
                                    label: "جزر المارشال",
                                },
                                {
                                    value: "MK",
                                    label: "مقدونيا",
                                },
                                {
                                    value: "ML",
                                    label: "مالي",
                                },
                                {
                                    value: "MM",
                                    label: "ميانمار",
                                },
                                {
                                    value: "MN",
                                    label: "منغوليا",
                                },
                                {
                                    value: "MO",
                                    label: "ماكاو الصينية",
                                },
                                {
                                    value: "MP",
                                    label: "جزر ماريانا الشمالية",
                                },
                                {
                                    value: "MQ",
                                    label: "مارتينيك",
                                },
                                {
                                    value: "MR",
                                    label: "موريتانيا",
                                },
                                {
                                    value: "MS",
                                    label: "مونتسرات",
                                },
                                {
                                    value: "MT",
                                    label: "مالطا",
                                },
                                {
                                    value: "MU",
                                    label: "موريشيوس",
                                },
                                {
                                    value: "MV",
                                    label: "جزر الملديف",
                                },
                                {
                                    value: "MW",
                                    label: "ملاوي",
                                },
                                {
                                    value: "MX",
                                    label: "المكسيك",
                                },
                                {
                                    value: "MY",
                                    label: "ماليزيا",
                                },
                                {
                                    value: "MZ",
                                    label: "موزمبيق",
                                },
                                {
                                    value: "NA",
                                    label: "ناميبيا",
                                },
                                {
                                    value: "NC",
                                    label: "كاليدونيا الجديدة",
                                },
                                {
                                    value: "NE",
                                    label: "النيجر",
                                },
                                {
                                    value: "NF",
                                    label: "جزيرة نورفوك",
                                },
                                {
                                    value: "NG",
                                    label: "نيجيريا",
                                },
                                {
                                    value: "NI",
                                    label: "نيكاراجوا",
                                },
                                {
                                    value: "NL",
                                    label: "هولندا",
                                },
                                {
                                    value: "NO",
                                    label: "النرويج",
                                },
                                {
                                    value: "NP",
                                    label: "نيبال",
                                },
                                {
                                    value: "NR",
                                    label: "نورو",
                                },
                                {
                                    value: "NU",
                                    label: "نيوي",
                                },
                                {
                                    value: "NZ",
                                    label: "نيوزيلاندا",
                                },
                                {
                                    value: "OM",
                                    label: "عمان",
                                },
                                {
                                    value: "PA",
                                    label: "بنما",
                                },
                                {
                                    value: "PE",
                                    label: "بيرو",
                                },
                                {
                                    value: "PF",
                                    label: "بولينيزيا الفرنسية",
                                },
                                {
                                    value: "PG",
                                    label: "بابوا غينيا الجديدة",
                                },
                                {
                                    value: "PH",
                                    label: "الفيلبين",
                                },
                                {
                                    value: "PK",
                                    label: "باكستان",
                                },
                                {
                                    value: "PL",
                                    label: "بولندا",
                                },
                                {
                                    value: "PM",
                                    label: "سانت بيير وميكولون",
                                },
                                {
                                    value: "PN",
                                    label: "بتكايرن",
                                },
                                {
                                    value: "PR",
                                    label: "بورتوريكو",
                                },
                                {
                                    value: "PS",
                                    label: "فلسطين",
                                },
                                {
                                    value: "PT",
                                    label: "البرتغال",
                                },
                                {
                                    value: "PW",
                                    label: "بالاو",
                                },
                                {
                                    value: "PY",
                                    label: "باراجواي",
                                },
                                {
                                    value: "QA",
                                    label: "قطر",
                                },
                                {
                                    value: "RE",
                                    label: "روينيون",
                                },
                                {
                                    value: "RO",
                                    label: "رومانيا",
                                },
                                {
                                    value: "RS",
                                    label: "صربيا",
                                },
                                {
                                    value: "RU",
                                    label: "روسيا",
                                },
                                {
                                    value: "RW",
                                    label: "رواندا",
                                },
                                {
                                    value: "SA",
                                    label: "المملكة العربية السعودية",
                                },
                                {
                                    value: "SB",
                                    label: "جزر سليمان",
                                },
                                {
                                    value: "SC",
                                    label: "سيشل",
                                },
                                {
                                    value: "SD",
                                    label: "السودان",
                                },
                                {
                                    value: "SE",
                                    label: "السويد",
                                },
                                {
                                    value: "SG",
                                    label: "سنغافورة",
                                },
                                {
                                    value: "SH",
                                    label: "سانت هيلنا",
                                },
                                {
                                    value: "SI",
                                    label: "سلوفينيا",
                                },
                                {
                                    value: "SJ",
                                    label: "سفالبارد وجان مايان",
                                },
                                {
                                    value: "SK",
                                    label: "سلوفاكيا",
                                },
                                {
                                    value: "SL",
                                    label: "سيراليون",
                                },
                                {
                                    value: "SM",
                                    label: "سان مارينو",
                                },
                                {
                                    value: "SN",
                                    label: "السنغال",
                                },
                                {
                                    value: "SO",
                                    label: "الصومال",
                                },
                                {
                                    value: "SR",
                                    label: "سورينام",
                                },
                                {
                                    value: "SS",
                                    label: "جنوب السودان",
                                },
                                {
                                    value: "ST",
                                    label: "ساو تومي وبرينسيبي",
                                },
                                {
                                    value: "SV",
                                    label: "السلفادور",
                                },
                                {
                                    value: "SX",
                                    label: "سينت مارتن",
                                },
                                {
                                    value: "SY",
                                    label: "سوريا",
                                },
                                {
                                    value: "SZ",
                                    label: "سوازيلاند",
                                },
                                {
                                    value: "TC",
                                    label: "جزر الترك وجايكوس",
                                },
                                {
                                    value: "TD",
                                    label: "تشاد",
                                },
                                {
                                    value: "TF",
                                    label: "المقاطعات الجنوبية الفرنسية",
                                },
                                {
                                    value: "TG",
                                    label: "توجو",
                                },
                                {
                                    value: "TH",
                                    label: "تايلند",
                                },
                                {
                                    value: "TJ",
                                    label: "طاجكستان",
                                },
                                {
                                    value: "TK",
                                    label: "توكيلو",
                                },
                                {
                                    value: "TL",
                                    label: "تيمور الشرقية",
                                },
                                {
                                    value: "TM",
                                    label: "تركمانستان",
                                },
                                {
                                    value: "TN",
                                    label: "تونس",
                                },
                                {
                                    value: "TO",
                                    label: "تونجا",
                                },
                                {
                                    value: "TR",
                                    label: "تركيا",
                                },
                                {
                                    value: "TT",
                                    label: "ترينيداد وتوباغو",
                                },
                                {
                                    value: "TV",
                                    label: "توفالو",
                                },
                                {
                                    value: "TW",
                                    label: "تايوان",
                                },
                                {
                                    value: "TZ",
                                    label: "تانزانيا",
                                },
                                {
                                    value: "UA",
                                    label: "أوكرانيا",
                                },
                                {
                                    value: "UG",
                                    label: "أوغندا",
                                },
                                {
                                    value: "UM",
                                    label: "جزر الولايات المتحدة البعيدة الصغيرة",
                                },
                                {
                                    value: "US",
                                    label: "الولايات المتحدة الأمريكية",
                                },
                                {
                                    value: "UY",
                                    label: "أورجواي",
                                },
                                {
                                    value: "UZ",
                                    label: "أوزبكستان",
                                },
                                {
                                    value: "VA",
                                    label: "الفاتيكان",
                                },
                                {
                                    value: "VC",
                                    label: "سانت فنسنت وغرنادين",
                                },
                                {
                                    value: "VE",
                                    label: "فنزويلا",
                                },
                                {
                                    value: "VG",
                                    label: "جزر فرجين البريطانية",
                                },
                                {
                                    value: "VI",
                                    label: "جزر فرجين الأمريكية",
                                },
                                {
                                    value: "VN",
                                    label: "فيتنام",
                                },
                                {
                                    value: "VU",
                                    label: "فانواتو",
                                },
                                {
                                    value: "WF",
                                    label: "جزر والس وفوتونا",
                                },
                                {
                                    value: "WS",
                                    label: "ساموا",
                                },
                                {
                                    value: "XK",
                                    label: "كوسوفو",
                                },
                                {
                                    value: "YE",
                                    label: "اليمن",
                                },
                                {
                                    value: "YT",
                                    label: "مايوت",
                                },
                                {
                                    value: "ZA",
                                    label: "جمهورية جنوب افريقيا",
                                },
                                {
                                    value: "ZM",
                                    label: "زامبيا",
                                },
                                {
                                    value: "ZW",
                                    label: "زيمبابوي",
                                }
                            ]}/>
                    </div>
                    <div className="col-8 d-flex mx-auto mt-3">
                        <input className="input-border-bottom w-100" onChange={handleChangeReligion} required
                               type="text" maxLength="100"
                               placeholder="الدين و المدهب"/>
                    </div>
                    <div className="col-8 d-flex mx-auto">
                        <input className="input-border-bottom pass-input w-100" onChange={handleChangePass} required
                               type={inputType1} maxLength="20"
                               placeholder="كلمة السر"/>
                        <div
                            className={inputTypeShow1 ? "position-absolute show-icon-pass-right" : "position-absolute show-icon-pass-right d-none"}>
                        <span className={inputType1 == "password" ? "change-input-type" : "change-input-type d-none"}
                              onClick={() => setInputType1('text')}>
                           <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.75 9C0.75 9 3.75 3 9 3C14.25 3 17.25 9 17.25 9C17.25 9 14.25 15 9 15C3.75 15 0.75 9 0.75 9Z"
                                stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
                                stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                            <span
                                className={inputType1 != "password" ? "change-input-type" : "change-input-type d-none"}
                                onClick={() => setInputType1('password')}>
                       <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.93916 7.94025C7.6579 8.22162 7.49993 8.60318 7.5 9.00102C7.50007 9.39885 7.65818 9.78037 7.93954 10.0616C8.2209 10.3429 8.60247 10.5009 9.0003 10.5008C9.39814 10.5007 9.77965 10.3426 10.0609 10.0613M12.5107 12.5048C11.4587 13.163 10.241 13.5082 9 13.5C6.3 13.5 4.05 12 2.25 9C3.204 7.41 4.284 6.2415 5.49 5.4945M7.635 4.635C8.08428 4.54405 8.54161 4.49882 9 4.5C11.7 4.5 13.95 6 15.75 9C15.2505 9.8325 14.7157 10.5502 14.1465 11.1525M2.25 2.25L15.75 15.75"
                            stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                        </div>
                    </div>
                    <div className="col-8 d-flex mx-auto pb-1">
                        <input className="input-border-bottom pass-input w-100" onChange={handleChangePassCheck}
                               required
                               type={inputType} maxLength="20"
                               placeholder="إعادة كلمة السر"/>
                        <div
                            className={inputTypeShow ? "position-absolute show-icon-pass-right" : "position-absolute show-icon-pass-right d-none"}>
                        <span className={inputType == "password" ? "change-input-type" : "change-input-type d-none"}
                              onClick={() => setInputType('text')}>
                           <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.75 9C0.75 9 3.75 3 9 3C14.25 3 17.25 9 17.25 9C17.25 9 14.25 15 9 15C3.75 15 0.75 9 0.75 9Z"
                                stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
                                stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                            <span className={inputType != "password" ? "change-input-type" : "change-input-type d-none"}
                                  onClick={() => setInputType('password')}>
                       <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.93916 7.94025C7.6579 8.22162 7.49993 8.60318 7.5 9.00102C7.50007 9.39885 7.65818 9.78037 7.93954 10.0616C8.2209 10.3429 8.60247 10.5009 9.0003 10.5008C9.39814 10.5007 9.77965 10.3426 10.0609 10.0613M12.5107 12.5048C11.4587 13.163 10.241 13.5082 9 13.5C6.3 13.5 4.05 12 2.25 9C3.204 7.41 4.284 6.2415 5.49 5.4945M7.635 4.635C8.08428 4.54405 8.54161 4.49882 9 4.5C11.7 4.5 13.95 6 15.75 9C15.2505 9.8325 14.7157 10.5502 14.1465 11.1525M2.25 2.25L15.75 15.75"
                            stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                        </div>
                    </div>
                    <div className="d-block">
                        <button type="submit" className="btn border-0 more-btn fw-light" aria-label="request for login">
                            إنشاء الحساب
                        </button>
                        <div className="d-flex align-items-center justify-content-center mt-2">
                            <span className="dont-have-account">هل لديكم حساب بالفعل؟</span>
                            <button type="button" onClick={() => setTabPage(1)}
                                    className="p-0 btn bg-transparent dont-have-account dont-have-account-color-black">
                                تسجيل الدخول إلى حسابكم
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            : ""}

        {/* register page2 enter code - - - - */}
        {tabPage == 11 ?
            <div className={props.show ? "login-modal" : "login-modal close"}>

                <button className="btn bg-transparent btn-close-modal" onClick={props.close} title="لإغلاق"
                        aria-label="لإغلاق">
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.5 6.5L6.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M6.5 6.5L19.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>

                <p className="mt-150 mt-4 mb-0">إنشاء حساب</p>
                <p className="disc-14">
                    يمكنكم التسجيل بالبريد الإلكتروني لإنشاء
                    <br/>
                    حسابكم الخاص على الموقع
                </p>
                <form onSubmit={handleSubmitRegisterPage2}
                      className="text-center mt-4 pt-1 h-100 form-container form-container-c-black">
                    <div className="col-8 mt-3 d-block mx-auto">
                        <p className="disc-14 text-end mb-0">
                            أدخِل الرمز المرسل إلى بريدك الإلكتروني
                            <br/>
                            {emailPageShowStar(emailPage)}
                        </p>
                        <div className="d-flex w-100 gap-3 child-4 mt-2">
                            <input
                                className={showBorder1 ? "input-border-bottom text-center error" : "input-border-bottom text-center no-error"}
                                required type="number" value={code1}
                                onChange={handleNameChange1} maxLength={1} onKeyDown={(e) => something(e)}
                                ref={inputElementRef}
                            />
                            <input
                                className={showBorder1 ? "input-border-bottom text-center error" : "input-border-bottom text-center no-error"}
                                required type="number" value={code2}
                                onChange={handleNameChange2} maxLength={1} onKeyDown={(e) => something(e)}
                                ref={inputElementRef1}
                            />
                            <input
                                className={showBorder1 ? "input-border-bottom text-center error" : "input-border-bottom text-center no-error"}
                                required type="number" value={code3} onKeyDown={(e) => something(e)}
                                ref={inputElementRef2} onChange={handleNameChange3} maxLength={1}/>
                            <input
                                className={showBorder1 ? "input-border-bottom text-center error" : "input-border-bottom text-center no-error"}
                                required type="number" value={code4} onKeyDown={(e) => something(e)}
                                ref={inputElementRef3}
                                onChange={handleNameChange4} maxLength={1}/>
                        </div>
                        {seconds > 0 || minutes > 0 ? (
                            <p className="text-gray" >
                                {minutes < 10 ? `0${minutes}` : minutes}:
                                {seconds < 10 ? `0${seconds}` : seconds}
                            </p>
                        ) : (
                            <button onClick={(e)=>{
                                e.preventDefault();
                                setMinutes(2);
                                setSeconds(0);
                                setChanger(!Changer);
                                loginSendCode()
                            }} className="focus-border-0 d-flex gap-1 mt-3 align-items-center btn bg-transparent border-0
                             p-0 fw-light send-code">
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.5 2.66821V6.66821H11.5" stroke="#B0B0B0" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    <path
                                        d="M13.8214 9.99627C13.388 11.2229 12.5677 12.2754 11.4842 12.9953C10.4006 13.7151 9.11235 14.0633 7.81366 13.9873C6.51497 13.9114 5.27615 13.4153 4.28388 12.574C3.29162 11.7328 2.59966 10.5917 2.31229 9.32297C2.02492 8.0542 2.1577 6.72639 2.69062 5.53965C3.22354 4.3529 4.12774 3.37151 5.26695 2.74336C6.40616 2.11521 7.71866 1.87433 9.00668 2.05703C10.2947 2.23972 11.4885 2.83609 12.408 3.75627L15.4947 6.66293"
                                        stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span
                                    className="btn bg-transparent border-0 p-0 fw-light send-code">إعادة إرسال الرمز</span>
                            </button>
                        )}

                        <button onClick={() => setTabPage(1)}
                                className="focus-border-0 d-flex gap-1 mt-1 align-items-center btn bg-transparent border-0 p-0 fw-light send-code">
                            <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2.83333 2.67163H13.5C14.2333 2.67163 14.8333 3.27163 14.8333 4.00496V12.005C14.8333 12.7383 14.2333 13.3383 13.5 13.3383H2.83333C2.1 13.3383 1.5 12.7383 1.5 12.005V4.00496C1.5 3.27163 2.1 2.67163 2.83333 2.67163Z"
                                    stroke="#B0B0B0" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.8333 4.00659L8.16667 8.67326L1.5 4.00659" stroke="#B0B0B0"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                            <span
                                className="btn bg-transparent border-0 p-0 fw-light send-code">تعديل البريد الإلكتروني</span>
                        </button>

                    </div>
                    <div className="d-block mt-4 pt-2">
                        <button type="submit" className="btn border-0 more-btn fw-light" aria-label="request for login">
                            التالي
                        </button>
                        <div className="d-flex align-items-center justify-content-center mt-2">
                            <span className="dont-have-account">هل لديكم حساب بالفعل؟ً</span>
                            <button type="button" onClick={() => setTabPage(1)}
                                    className="p-0 btn bg-transparent dont-have-account dont-have-account-color-black">
                                تسجيل الدخول إلى حسابكم
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            : ""}

        {/* register page3 enter data - - - - */}
        {tabPage == 12 ?
            <div className={props.show ? "login-modal login-modal-h-big" : "login-modal login-modal-h-big close"}>

                <button className="btn bg-transparent btn-close-modal" onClick={props.close} title="لإغلاق"
                        aria-label="لإغلاق">
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.5 6.5L6.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M6.5 6.5L19.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
                <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url">
                    {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                            <div className="image-uploader"
                                 style={isDragging ? {color: 'red'} : undefined}
                                 onClick={onImageUpload}
                                 {...dragProps}
                            >
                                <svg width="170" height="170" viewBox="0 0 170 170" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="85" cy="85" r="83.5833" fill="white" stroke="#C7C7C7"
                                            strokeWidth="2.83333"/>
                                    <path
                                        d="M111.048 113.503C109.487 109.135 106.049 105.275 101.265 102.523C96.482 99.7699 90.6213 98.2778 84.592 98.2778C78.5628 98.2778 72.702 99.7699 67.9187 102.523C63.1354 105.275 59.6969 109.135 58.1364 113.503"
                                        stroke="#737373" strokeWidth="3.97581" strokeLinecap="round"/>
                                    <circle cx="84.5773" cy="70.8889" r="13.6944" stroke="#737373" strokeWidth="3.97581"
                                            strokeLinecap="round"/>
                                    <circle cx="141.855" cy="147.855" r="15.8549" fill="#D7D7D7"/>
                                    <path
                                        d="M134.586 146.122C134.586 145.295 135.256 144.625 136.083 144.625V144.625C136.65 144.625 137.168 144.305 137.422 143.798L138.085 142.472C138.134 142.373 138.159 142.323 138.185 142.279C138.481 141.764 139.016 141.433 139.609 141.399C139.66 141.396 139.716 141.396 139.827 141.396H143.879C143.99 141.396 144.045 141.396 144.097 141.399C144.689 141.433 145.225 141.764 145.521 142.279C145.546 142.323 145.571 142.373 145.621 142.472L146.284 143.798C146.537 144.305 147.056 144.625 147.623 144.625V144.625C148.449 144.625 149.12 145.295 149.12 146.122V150.162C149.12 151.571 149.12 152.275 148.858 152.818C148.598 153.358 148.163 153.793 147.624 154.053C147.08 154.314 146.376 154.314 144.967 154.314H138.738C137.33 154.314 136.625 154.314 136.082 154.053C135.543 153.793 135.107 153.358 134.848 152.818C134.586 152.275 134.586 151.571 134.586 150.162V146.122Z"
                                        stroke="#838383" strokeWidth="0.880829"/>
                                    <circle cx="141.855" cy="148.662" r="2.78929" stroke="#838383"
                                            strokeWidth="0.880829"/>
                                    <g clipPath="url(#clip0_674_27291)">
                                        <rect x="143.617" y="150.497" width="6.1658" height="6.1658" rx="3.0829"
                                              fill="#D9D9D9"/>
                                        <path d="M146.703 151.333V155.829" stroke="#838383" strokeWidth="0.880829"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M144.453 153.58H148.949" stroke="#838383" strokeWidth="0.880829"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_674_27291">
                                            <rect x="143.617" y="150.497" width="6.1658" height="6.1658" rx="3.0829"
                                                  fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>


                                {imageList.map((image, index) => (
                                    <div key={index} className="image-item">
                                        <img src={image['data_url']} alt="" width="100"/>
                                        <div className="image-item__btn-wrapper">
                                            <div className="d-block position-relative"
                                                 onClick={() => onImageRemove(index)}>
                                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="15.8549" cy="15.8549" r="15.8549" fill="white"/>
                                                    <path d="M10 12.25H22" stroke="#045E5E" strokeWidth="0.75"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.5 15.25V19.75" stroke="#045E5E" strokeWidth="0.75"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M17.5 15.25V19.75" stroke="#045E5E" strokeWidth="0.75"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path
                                                        d="M10.75 12.25L11.5 21.25C11.5 21.6478 11.658 22.0294 11.9393 22.3107C12.2206 22.592 12.6022 22.75 13 22.75H19C19.3978 22.75 19.7794 22.592 20.0607 22.3107C20.342 22.0294 20.5 21.6478 20.5 21.25L21.25 12.25"
                                                        stroke="#045E5E" strokeWidth="0.75" strokeLinecap="round"
                                                        strokeLinejoin="round"/>
                                                    <path
                                                        d="M13.75 12.25V10C13.75 9.80109 13.829 9.61032 13.9697 9.46967C14.1103 9.32902 14.3011 9.25 14.5 9.25H17.5C17.6989 9.25 17.8897 9.32902 18.0303 9.46967C18.171 9.61032 18.25 9.80109 18.25 10V12.25"
                                                        stroke="#045E5E" strokeWidth="0.75" strokeLinecap="round"
                                                        strokeLinejoin="round"/>
                                                </svg>

                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    )}
                </ImageUploading>
                <form className="text-center h-100 form-container form-container-c-black mt-150"
                      onSubmit={handleSubmitRegisterPage3}>
                    <div className="col-8 d-flex mx-auto pt-4">
                        <input className="input-border-bottom w-100" onChange={handleChangeName} required type="text"
                               maxLength="100"
                               placeholder="الاسم"/>
                    </div>
                    <div className="col-8 d-flex mx-auto ">
                        <input className="input-border-bottom w-100" onChange={handleChangeFamily} required type="text"
                               maxLength="100"
                               placeholder="اسم العائلة"/>
                    </div>

                    <div className="col-8 d-flex mx-auto my-2">
                        <Select
                            showSearch
                            placeholder="الدولة"
                            onChange={handleChangeCountry}
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={[
                                {
                                    value: "AD",
                                    label: "أندورا",
                                },
                                {
                                    value: "AE",
                                    label: "الامارات العربية المتحدة",
                                },
                                {
                                    value: "AF",
                                    label: "أفغانستان",
                                },
                                {
                                    value: "AG",
                                    label: "أنتيجوا وبربودا",
                                },
                                {
                                    value: "AI",
                                    label: "أنجويلا",
                                },
                                {
                                    value: "AL",
                                    label: "ألبانيا",
                                },
                                {
                                    value: "AM",
                                    label: "أرمينيا",
                                },
                                {
                                    value: "AO",
                                    label: "أنجولا",
                                },
                                {
                                    value: "AQ",
                                    label: "القطب الجنوبي",
                                },
                                {
                                    value: "AR",
                                    label: "الأرجنتين",
                                },
                                {
                                    value: "AS",
                                    label: "ساموا الأمريكية",
                                },
                                {
                                    value: "AT",
                                    label: "النمسا",
                                },
                                {
                                    value: "AU",
                                    label: "أستراليا",
                                },
                                {
                                    value: "AW",
                                    label: "آروبا",
                                },
                                {
                                    value: "AX",
                                    label: "جزر أولان",
                                },
                                {
                                    value: "AZ",
                                    label: "أذربيجان",
                                },
                                {
                                    value: "BA",
                                    label: "البوسنة والهرسك",
                                },
                                {
                                    value: "BB",
                                    label: "بربادوس",
                                },
                                {
                                    value: "BD",
                                    label: "بنجلاديش",
                                },
                                {
                                    value: "BE",
                                    label: "بلجيكا",
                                },
                                {
                                    value: "BF",
                                    label: "بوركينا فاسو",
                                },
                                {
                                    value: "BG",
                                    label: "بلغاريا",
                                },
                                {
                                    value: "BH",
                                    label: "البحرين",
                                },
                                {
                                    value: "BI",
                                    label: "بوروندي",
                                },
                                {
                                    value: "BJ",
                                    label: "بنين",
                                },
                                {
                                    value: "BL",
                                    label: "سان بارتيلمي",
                                },
                                {
                                    value: "BM",
                                    label: "برمودا",
                                },
                                {
                                    value: "BN",
                                    label: "بروناي",
                                },
                                {
                                    value: "BO",
                                    label: "بوليفيا",
                                },
                                {
                                    value: "BQ",
                                    label: "بونير",
                                },
                                {
                                    value: "BR",
                                    label: "البرازيل",
                                },
                                {
                                    value: "BS",
                                    label: "الباهاما",
                                },
                                {
                                    value: "BT",
                                    label: "بوتان",
                                },
                                {
                                    value: "BV",
                                    label: "جزيرة بوفيه",
                                },
                                {
                                    value: "BW",
                                    label: "بتسوانا",
                                },
                                {
                                    value: "BY",
                                    label: "روسيا البيضاء",
                                },
                                {
                                    value: "BZ",
                                    label: "بليز",
                                },
                                {
                                    value: "CA",
                                    label: "كندا",
                                },
                                {
                                    value: "CC",
                                    label: "جزر كوكوس",
                                },
                                {
                                    value: "CD",
                                    label: "جمهورية الكونغو الديمقراطية",
                                },
                                {
                                    value: "CF",
                                    label: "جمهورية افريقيا الوسطى",
                                },
                                {
                                    value: "CG",
                                    label: "الكونغو - برازافيل",
                                },
                                {
                                    value: "CH",
                                    label: "سويسرا",
                                },
                                {
                                    value: "CI",
                                    label: "ساحل العاج",
                                },
                                {
                                    value: "CK",
                                    label: "جزر كوك",
                                },
                                {
                                    value: "CL",
                                    label: "شيلي",
                                },
                                {
                                    value: "CM",
                                    label: "الكاميرون",
                                },
                                {
                                    value: "CN",
                                    label: "الصين",
                                },
                                {
                                    value: "CO",
                                    label: "كولومبيا",
                                },
                                {
                                    value: "CR",
                                    label: "كوستاريكا",
                                },
                                {
                                    value: "CU",
                                    label: "كوبا",
                                },
                                {
                                    value: "CV",
                                    label: "الرأس الأخضر",
                                },
                                {
                                    value: "CW",
                                    label: "كوراساو",
                                },
                                {
                                    value: "CX",
                                    label: "جزيرة الكريسماس",
                                },
                                {
                                    value: "CY",
                                    label: "قبرص",
                                },
                                {
                                    value: "CZ",
                                    label: "جمهورية التشيك",
                                },
                                {
                                    value: "DE",
                                    label: "ألمانيا",
                                },
                                {
                                    value: "DJ",
                                    label: "جيبوتي",
                                },
                                {
                                    value: "DK",
                                    label: "الدانمرك",
                                },
                                {
                                    value: "DM",
                                    label: "دومينيكا",
                                },
                                {
                                    value: "DO",
                                    label: "جمهورية الدومينيك",
                                },
                                {
                                    value: "DZ",
                                    label: "الجزائر",
                                },
                                {
                                    value: "EC",
                                    label: "الاكوادور",
                                },
                                {
                                    value: "EE",
                                    label: "استونيا",
                                },
                                {
                                    value: "EG",
                                    label: "مصر",
                                },
                                {
                                    value: "EH",
                                    label: "الصحراء الغربية",
                                },
                                {
                                    value: "ER",
                                    label: "اريتريا",
                                },
                                {
                                    value: "ES",
                                    label: "أسبانيا",
                                },
                                {
                                    value: "ET",
                                    label: "اثيوبيا",
                                },
                                {
                                    value: "FI",
                                    label: "فنلندا",
                                },
                                {
                                    value: "FJ",
                                    label: "فيجي",
                                },
                                {
                                    value: "FK",
                                    label: "جزر فوكلاند",
                                },
                                {
                                    value: "FM",
                                    label: "ميكرونيزيا",
                                },
                                {
                                    value: "FO",
                                    label: "جزر فارو",
                                },
                                {
                                    value: "FR",
                                    label: "فرنسا",
                                },
                                {
                                    value: "GA",
                                    label: "الجابون",
                                },
                                {
                                    value: "GB",
                                    label: "المملكة المتحدة",
                                },
                                {
                                    value: "GD",
                                    label: "جرينادا",
                                },
                                {
                                    value: "GE",
                                    label: "جورجيا",
                                },
                                {
                                    value: "GF",
                                    label: "غويانا",
                                },
                                {
                                    value: "GG",
                                    label: "غيرنزي",
                                },
                                {
                                    value: "GH",
                                    label: "غانا",
                                },
                                {
                                    value: "GI",
                                    label: "جبل طارق",
                                },
                                {
                                    value: "GL",
                                    label: "جرينلاند",
                                },
                                {
                                    value: "GM",
                                    label: "غامبيا",
                                },
                                {
                                    value: "GN",
                                    label: "غينيا",
                                },
                                {
                                    value: "GP",
                                    label: "جوادلوب",
                                },
                                {
                                    value: "GQ",
                                    label: "غينيا الاستوائية",
                                },
                                {
                                    value: "GR",
                                    label: "اليونان",
                                },
                                {
                                    value: "GS",
                                    label: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
                                },
                                {
                                    value: "GT",
                                    label: "جواتيمالا",
                                },
                                {
                                    value: "GU",
                                    label: "جوام",
                                },
                                {
                                    value: "GW",
                                    label: "غينيا بيساو",
                                },
                                {
                                    value: "GY",
                                    label: "غيانا",
                                },
                                {
                                    value: "HK",
                                    label: "هونج كونج الصينية",
                                },
                                {
                                    value: "HM",
                                    label: "جزيرة هيرد وماكدونالد",
                                },
                                {
                                    value: "HN",
                                    label: "هندوراس",
                                },
                                {
                                    value: "HR",
                                    label: "كرواتيا",
                                },
                                {
                                    value: "HT",
                                    label: "هايتي",
                                },
                                {
                                    value: "HU",
                                    label: "المجر",
                                },
                                {
                                    value: "ID",
                                    label: "اندونيسيا",
                                },
                                {
                                    value: "IE",
                                    label: "أيرلندا",
                                },
                                {
                                    value: "IL",
                                    label: "اسرائيل",
                                },
                                {
                                    value: "IM",
                                    label: "جزيرة مان",
                                },
                                {
                                    value: "IN",
                                    label: "الهند",
                                },
                                {
                                    value: "IO",
                                    label: "المحيط الهندي البريطاني",
                                },
                                {
                                    value: "IQ",
                                    label: "العراق",
                                },
                                {
                                    value: "IR",
                                    label: "ايران",
                                },
                                {
                                    value: "IS",
                                    label: "أيسلندا",
                                },
                                {
                                    value: "IT",
                                    label: "ايطاليا",
                                },
                                {
                                    value: "JE",
                                    label: "جيرسي",
                                },
                                {
                                    value: "JM",
                                    label: "جامايكا",
                                },
                                {
                                    value: "JO",
                                    label: "الأردن",
                                },
                                {
                                    value: "JP",
                                    label: "اليابان",
                                },
                                {
                                    value: "KE",
                                    label: "كينيا",
                                },
                                {
                                    value: "KG",
                                    label: "قرغيزستان",
                                },
                                {
                                    value: "KH",
                                    label: "كمبوديا",
                                },
                                {
                                    value: "KI",
                                    label: "كيريباتي",
                                },
                                {
                                    value: "KM",
                                    label: "جزر القمر",
                                },
                                {
                                    value: "KN",
                                    label: "سانت كيتس ونيفيس",
                                },
                                {
                                    value: "KP",
                                    label: "كوريا الشمالية",
                                },
                                {
                                    value: "KR",
                                    label: "كوريا الجنوبية",
                                },
                                {
                                    value: "KW",
                                    label: "الكويت",
                                },
                                {
                                    value: "KY",
                                    label: "جزر الكايمن",
                                },
                                {
                                    value: "KZ",
                                    label: "كازاخستان",
                                },
                                {
                                    value: "LA",
                                    label: "لاوس",
                                },
                                {
                                    value: "LB",
                                    label: "لبنان",
                                },
                                {
                                    value: "LC",
                                    label: "سانت لوسيا",
                                },
                                {
                                    value: "LI",
                                    label: "ليختنشتاين",
                                },
                                {
                                    value: "LK",
                                    label: "سريلانكا",
                                },
                                {
                                    value: "LR",
                                    label: "ليبيريا",
                                },
                                {
                                    value: "LS",
                                    label: "ليسوتو",
                                },
                                {
                                    value: "LT",
                                    label: "ليتوانيا",
                                },
                                {
                                    value: "LU",
                                    label: "لوكسمبورج",
                                },
                                {
                                    value: "LV",
                                    label: "لاتفيا",
                                },
                                {
                                    value: "LY",
                                    label: "ليبيا",
                                },
                                {
                                    value: "MA",
                                    label: "المغرب",
                                },
                                {
                                    value: "MC",
                                    label: "موناكو",
                                },
                                {
                                    value: "MD",
                                    label: "مولدافيا",
                                },
                                {
                                    value: "ME",
                                    label: "الجبل الأسود",
                                },
                                {
                                    value: "MF",
                                    label: "سانت مارتين",
                                },
                                {
                                    value: "MG",
                                    label: "مدغشقر",
                                },
                                {
                                    value: "MH",
                                    label: "جزر المارشال",
                                },
                                {
                                    value: "MK",
                                    label: "مقدونيا",
                                },
                                {
                                    value: "ML",
                                    label: "مالي",
                                },
                                {
                                    value: "MM",
                                    label: "ميانمار",
                                },
                                {
                                    value: "MN",
                                    label: "منغوليا",
                                },
                                {
                                    value: "MO",
                                    label: "ماكاو الصينية",
                                },
                                {
                                    value: "MP",
                                    label: "جزر ماريانا الشمالية",
                                },
                                {
                                    value: "MQ",
                                    label: "مارتينيك",
                                },
                                {
                                    value: "MR",
                                    label: "موريتانيا",
                                },
                                {
                                    value: "MS",
                                    label: "مونتسرات",
                                },
                                {
                                    value: "MT",
                                    label: "مالطا",
                                },
                                {
                                    value: "MU",
                                    label: "موريشيوس",
                                },
                                {
                                    value: "MV",
                                    label: "جزر الملديف",
                                },
                                {
                                    value: "MW",
                                    label: "ملاوي",
                                },
                                {
                                    value: "MX",
                                    label: "المكسيك",
                                },
                                {
                                    value: "MY",
                                    label: "ماليزيا",
                                },
                                {
                                    value: "MZ",
                                    label: "موزمبيق",
                                },
                                {
                                    value: "NA",
                                    label: "ناميبيا",
                                },
                                {
                                    value: "NC",
                                    label: "كاليدونيا الجديدة",
                                },
                                {
                                    value: "NE",
                                    label: "النيجر",
                                },
                                {
                                    value: "NF",
                                    label: "جزيرة نورفوك",
                                },
                                {
                                    value: "NG",
                                    label: "نيجيريا",
                                },
                                {
                                    value: "NI",
                                    label: "نيكاراجوا",
                                },
                                {
                                    value: "NL",
                                    label: "هولندا",
                                },
                                {
                                    value: "NO",
                                    label: "النرويج",
                                },
                                {
                                    value: "NP",
                                    label: "نيبال",
                                },
                                {
                                    value: "NR",
                                    label: "نورو",
                                },
                                {
                                    value: "NU",
                                    label: "نيوي",
                                },
                                {
                                    value: "NZ",
                                    label: "نيوزيلاندا",
                                },
                                {
                                    value: "OM",
                                    label: "عمان",
                                },
                                {
                                    value: "PA",
                                    label: "بنما",
                                },
                                {
                                    value: "PE",
                                    label: "بيرو",
                                },
                                {
                                    value: "PF",
                                    label: "بولينيزيا الفرنسية",
                                },
                                {
                                    value: "PG",
                                    label: "بابوا غينيا الجديدة",
                                },
                                {
                                    value: "PH",
                                    label: "الفيلبين",
                                },
                                {
                                    value: "PK",
                                    label: "باكستان",
                                },
                                {
                                    value: "PL",
                                    label: "بولندا",
                                },
                                {
                                    value: "PM",
                                    label: "سانت بيير وميكولون",
                                },
                                {
                                    value: "PN",
                                    label: "بتكايرن",
                                },
                                {
                                    value: "PR",
                                    label: "بورتوريكو",
                                },
                                {
                                    value: "PS",
                                    label: "فلسطين",
                                },
                                {
                                    value: "PT",
                                    label: "البرتغال",
                                },
                                {
                                    value: "PW",
                                    label: "بالاو",
                                },
                                {
                                    value: "PY",
                                    label: "باراجواي",
                                },
                                {
                                    value: "QA",
                                    label: "قطر",
                                },
                                {
                                    value: "RE",
                                    label: "روينيون",
                                },
                                {
                                    value: "RO",
                                    label: "رومانيا",
                                },
                                {
                                    value: "RS",
                                    label: "صربيا",
                                },
                                {
                                    value: "RU",
                                    label: "روسيا",
                                },
                                {
                                    value: "RW",
                                    label: "رواندا",
                                },
                                {
                                    value: "SA",
                                    label: "المملكة العربية السعودية",
                                },
                                {
                                    value: "SB",
                                    label: "جزر سليمان",
                                },
                                {
                                    value: "SC",
                                    label: "سيشل",
                                },
                                {
                                    value: "SD",
                                    label: "السودان",
                                },
                                {
                                    value: "SE",
                                    label: "السويد",
                                },
                                {
                                    value: "SG",
                                    label: "سنغافورة",
                                },
                                {
                                    value: "SH",
                                    label: "سانت هيلنا",
                                },
                                {
                                    value: "SI",
                                    label: "سلوفينيا",
                                },
                                {
                                    value: "SJ",
                                    label: "سفالبارد وجان مايان",
                                },
                                {
                                    value: "SK",
                                    label: "سلوفاكيا",
                                },
                                {
                                    value: "SL",
                                    label: "سيراليون",
                                },
                                {
                                    value: "SM",
                                    label: "سان مارينو",
                                },
                                {
                                    value: "SN",
                                    label: "السنغال",
                                },
                                {
                                    value: "SO",
                                    label: "الصومال",
                                },
                                {
                                    value: "SR",
                                    label: "سورينام",
                                },
                                {
                                    value: "SS",
                                    label: "جنوب السودان",
                                },
                                {
                                    value: "ST",
                                    label: "ساو تومي وبرينسيبي",
                                },
                                {
                                    value: "SV",
                                    label: "السلفادور",
                                },
                                {
                                    value: "SX",
                                    label: "سينت مارتن",
                                },
                                {
                                    value: "SY",
                                    label: "سوريا",
                                },
                                {
                                    value: "SZ",
                                    label: "سوازيلاند",
                                },
                                {
                                    value: "TC",
                                    label: "جزر الترك وجايكوس",
                                },
                                {
                                    value: "TD",
                                    label: "تشاد",
                                },
                                {
                                    value: "TF",
                                    label: "المقاطعات الجنوبية الفرنسية",
                                },
                                {
                                    value: "TG",
                                    label: "توجو",
                                },
                                {
                                    value: "TH",
                                    label: "تايلند",
                                },
                                {
                                    value: "TJ",
                                    label: "طاجكستان",
                                },
                                {
                                    value: "TK",
                                    label: "توكيلو",
                                },
                                {
                                    value: "TL",
                                    label: "تيمور الشرقية",
                                },
                                {
                                    value: "TM",
                                    label: "تركمانستان",
                                },
                                {
                                    value: "TN",
                                    label: "تونس",
                                },
                                {
                                    value: "TO",
                                    label: "تونجا",
                                },
                                {
                                    value: "TR",
                                    label: "تركيا",
                                },
                                {
                                    value: "TT",
                                    label: "ترينيداد وتوباغو",
                                },
                                {
                                    value: "TV",
                                    label: "توفالو",
                                },
                                {
                                    value: "TW",
                                    label: "تايوان",
                                },
                                {
                                    value: "TZ",
                                    label: "تانزانيا",
                                },
                                {
                                    value: "UA",
                                    label: "أوكرانيا",
                                },
                                {
                                    value: "UG",
                                    label: "أوغندا",
                                },
                                {
                                    value: "UM",
                                    label: "جزر الولايات المتحدة البعيدة الصغيرة",
                                },
                                {
                                    value: "US",
                                    label: "الولايات المتحدة الأمريكية",
                                },
                                {
                                    value: "UY",
                                    label: "أورجواي",
                                },
                                {
                                    value: "UZ",
                                    label: "أوزبكستان",
                                },
                                {
                                    value: "VA",
                                    label: "الفاتيكان",
                                },
                                {
                                    value: "VC",
                                    label: "سانت فنسنت وغرنادين",
                                },
                                {
                                    value: "VE",
                                    label: "فنزويلا",
                                },
                                {
                                    value: "VG",
                                    label: "جزر فرجين البريطانية",
                                },
                                {
                                    value: "VI",
                                    label: "جزر فرجين الأمريكية",
                                },
                                {
                                    value: "VN",
                                    label: "فيتنام",
                                },
                                {
                                    value: "VU",
                                    label: "فانواتو",
                                },
                                {
                                    value: "WF",
                                    label: "جزر والس وفوتونا",
                                },
                                {
                                    value: "WS",
                                    label: "ساموا",
                                },
                                {
                                    value: "XK",
                                    label: "كوسوفو",
                                },
                                {
                                    value: "YE",
                                    label: "اليمن",
                                },
                                {
                                    value: "YT",
                                    label: "مايوت",
                                },
                                {
                                    value: "ZA",
                                    label: "جمهورية جنوب افريقيا",
                                },
                                {
                                    value: "ZM",
                                    label: "زامبيا",
                                },
                                {
                                    value: "ZW",
                                    label: "زيمبابوي",
                                }
                            ]}/>
                    </div>
                    <div className="col-8 d-flex mx-auto mt-3">
                        <input className="input-border-bottom w-100" onChange={handleChangeReligion} required
                               type="text" maxLength="100"
                               placeholder="الدين و المدهب"/>
                    </div>
                    <div className="col-8 d-flex mx-auto">
                        <input className="input-border-bottom pass-input w-100" onChange={handleChangePass} required
                               type={inputType1} maxLength="20"
                               placeholder="كلمة السر"/>
                        <div
                            className={inputTypeShow1 ? "position-absolute show-icon-pass-right" : "position-absolute show-icon-pass-right d-none"}>
                        <span className={inputType1 == "password" ? "change-input-type" : "change-input-type d-none"}
                              onClick={() => setInputType1('text')}>
                           <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.75 9C0.75 9 3.75 3 9 3C14.25 3 17.25 9 17.25 9C17.25 9 14.25 15 9 15C3.75 15 0.75 9 0.75 9Z"
                                stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
                                stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                            <span
                                className={inputType1 != "password" ? "change-input-type" : "change-input-type d-none"}
                                onClick={() => setInputType1('password')}>
                       <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.93916 7.94025C7.6579 8.22162 7.49993 8.60318 7.5 9.00102C7.50007 9.39885 7.65818 9.78037 7.93954 10.0616C8.2209 10.3429 8.60247 10.5009 9.0003 10.5008C9.39814 10.5007 9.77965 10.3426 10.0609 10.0613M12.5107 12.5048C11.4587 13.163 10.241 13.5082 9 13.5C6.3 13.5 4.05 12 2.25 9C3.204 7.41 4.284 6.2415 5.49 5.4945M7.635 4.635C8.08428 4.54405 8.54161 4.49882 9 4.5C11.7 4.5 13.95 6 15.75 9C15.2505 9.8325 14.7157 10.5502 14.1465 11.1525M2.25 2.25L15.75 15.75"
                            stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                        </div>
                    </div>
                    <div className="col-8 d-flex mx-auto pb-1">
                        <input className="input-border-bottom pass-input w-100" onChange={handleChangePassCheck}
                               required
                               type={inputType} maxLength="20"
                               placeholder="إعادة كلمة السر"/>
                        <div
                            className={inputTypeShow ? "position-absolute show-icon-pass-right" : "position-absolute show-icon-pass-right d-none"}>
                        <span className={inputType == "password" ? "change-input-type" : "change-input-type d-none"}
                              onClick={() => setInputType('text')}>
                           <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.75 9C0.75 9 3.75 3 9 3C14.25 3 17.25 9 17.25 9C17.25 9 14.25 15 9 15C3.75 15 0.75 9 0.75 9Z"
                                stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
                                stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                            <span className={inputType != "password" ? "change-input-type" : "change-input-type d-none"}
                                  onClick={() => setInputType('password')}>
                       <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.93916 7.94025C7.6579 8.22162 7.49993 8.60318 7.5 9.00102C7.50007 9.39885 7.65818 9.78037 7.93954 10.0616C8.2209 10.3429 8.60247 10.5009 9.0003 10.5008C9.39814 10.5007 9.77965 10.3426 10.0609 10.0613M12.5107 12.5048C11.4587 13.163 10.241 13.5082 9 13.5C6.3 13.5 4.05 12 2.25 9C3.204 7.41 4.284 6.2415 5.49 5.4945M7.635 4.635C8.08428 4.54405 8.54161 4.49882 9 4.5C11.7 4.5 13.95 6 15.75 9C15.2505 9.8325 14.7157 10.5502 14.1465 11.1525M2.25 2.25L15.75 15.75"
                            stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                        </div>
                    </div>
                    <div className="d-block">
                        <button type="submit" className="btn border-0 more-btn fw-light" aria-label="request for login">
                            توثيق الحساب
                        </button>
                        <div className="d-flex align-items-center justify-content-center mt-2">
                            <span className="dont-have-account">هل لديكم حساب بالفعل؟</span>
                            <button type="button" onClick={() => setTabPage(1)}
                                    className="p-0 btn bg-transparent dont-have-account dont-have-account-color-black">
                                تسجيل الدخول إلى حسابكم
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            : ""}

        <div className={props.show ? "overlay-modal" : "overlay-modal close"}></div>
    </>);
};

export default LoginModal;
