import React, {useEffect, useState} from "react"
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";
import image from "../assets/uploads/slider1.jpg";
import {NavLink} from "react-router-dom";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import SwiperCore, {Autoplay} from 'swiper';
import {LazyLoadImage} from 'react-lazy-load-image-component';

const getMonthName = (day) => {
    switch (day) {
        case '01' :
            return ' محرم ';
        case '02' :
            return ' صفر ';
        case '03' :
            return ' ربيع الاول ';
        case '04' :
            return ' ربيع الثاني ';
        case '05' :
            return ' جمادى الاول ';
        case '06' :
            return ' جمادى الثانية ';
        case '07' :
            return ' رجب ';
        case '08' :
            return ' شعبان ';
        case '09' :
            return ' رمضان ';
        case '10' :
            return ' شوال ';
        case '11' :
            return ' ذوالقعدة ';
        case '12' :
            return ' ذوالحجة ';
    }
}
let time = [];
const News = (props) => {
    SwiperCore.use([Autoplay]);
    return (
        <div className="news-module position-relative">
            <div className="aq-container pt-4">
                <div className="title-container mt-5 pe-5 pb-4">
                    <NavLink to="/news" title="أحدث الأخبار والتقارير">
                        <h2>
                            أحدث الأخبار والتقارير
                        </h2>
                    </NavLink>
                    <p>
                        نشرة إخبارية تسلّط الضوء على نشاطات المركز المتنوّعة
                    </p>
                </div>
            </div>
            <div className="aq-container news-container px-5">
                {props.data.length > 0 && (

                    <Swiper
                        loop={true}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: true,
                            pauseOnMouseEnter: true,
                        }}
                        slidesPerView={"auto"}
                        spaceBetween={50}
                        grabCursor={true}
                        dir="rtl"
                        breakpoints={{
                            0: {
                                spaceBetween: 20
                            },
                            900: {
                                spaceBetween: 50
                            }
                        }}
                        modules={[Navigation]}
                        navigation={true}
                        className="mySwiper">
                        {props.data.map(user => (
                            <SwiperSlide>
                                <NavLink to={"/news/" + user.id} title={user.title}>
                                    <LazyLoadImage src={user.image_news} alt={user.title} title={user.title}/>
                                    <div className="slider-container-news">
                                        <h3>
                                            {user.title.length > 100 ?
                                                `${user.title.substring(0, 100)}...` : user.title
                                            }
                                        </h3>
                                        <div className="change-height">

                                            <p>
                                                {user.body.replace(/<[^>]+>/g, '').replace("&nbsp;", "").replace("&nbsp;", "")?.length > 110 ?
                                                    `${user.body.replace(/<[^>]+>/g, '').replace("&nbsp;", "").replace("&nbsp;", "")?.substring(0, 110)}...` : user?.body.replace(/<[^>]+>/g, '').replace("&nbsp;", "").replace("&nbsp;", "")
                                                }
                                            </p>
                                            <div className="date-container-news">
                                                <svg className="mb-1 ms-1" width="19" height="18" viewBox="0 0 19 18"
                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M15.0417 3H3.95833C3.08388 3 2.375 3.67157 2.375 4.5V15C2.375 15.8284 3.08388 16.5 3.95833 16.5H15.0417C15.9161 16.5 16.625 15.8284 16.625 15V4.5C16.625 3.67157 15.9161 3 15.0417 3Z"
                                                        stroke="#fff" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M12.666 1.50012V4.50012" stroke="#fff"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M6.33398 1.50012V4.50012" stroke="#fff"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M2.375 7.5H16.625" stroke="#fff" strokeLinecap="round"
                                                          strokeLinejoin="round"/>
                                                </svg>

                                                <div className="date text-white news-date">
                                                    <span
                                                        className="number text-white"> {user.date_release ? user.date_release.split("-")[2] : "02"}
                                                    </span> {getMonthName(user.date_release ? user.date_release.split("-")[1] : "02")}
                                                    <span
                                                        className="number text-white"> {user.date_release ? user.date_release.split("-")[0] : "1500"} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            </SwiperSlide>
                        ))}


                        <div className="more-btn-container d-flex justify-content-end mt--5">
                            <NavLink className="more-btn" to="/news">
                                المزيد
                            </NavLink>
                        </div>
                    </Swiper>
                )}
            </div>
            <div className="aq-container left-line-3"></div>
            <div className="aq-container left-line-4"></div>
        </div>
    );
};

export default News;
