import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import SeminarTextPage from "../components/SeminarTextPage";
import NewsTextPage from "../components/NewsTextPage";

const NewsText = () => {
    return (
        <div>
            <HeaderPage />
            <NewsTextPage />
            <Footer />
        </div>
    );
};

export default NewsText;
