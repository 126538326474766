import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import FaqTextPage from "../components/FaqTextPage";

const FaqText = () => {
    return (
        <>
            <HeaderPage />
            <FaqTextPage />
            <Footer />
        </>

    );
};

export default FaqText;
