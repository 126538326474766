import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import GuidedTextPage from "../components/GuideTextPage";

const GuidedText = () => {
    return (
        <div>
            <HeaderPage />
            <GuidedTextPage />
            <Footer />
        </div>
    );
};

export default GuidedText;
