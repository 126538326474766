import {NavLink, useParams, useSearchParams} from "react-router-dom";
import Select from 'react-select';
import ReactCountryFlag from "react-country-flag";
import {Scrollbars} from 'react-custom-scrollbars';
import faqFiles from "../assets/files/faq_2021.03.12_03.08.01.7z";
import image from "../assets/uploads/book.jpg";
import countries from "i18n-iso-countries";
import {useEffect, useState} from "react";
import React from "react";
import {getVisibilityWatcher} from "web-vitals/dist/modules/lib/getVisibilityWatcher";
import axios from "axios";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import img from "../assets/uploads/user.jpg";
import {useRef} from "react";
import {Helmet} from "react-helmet";
import Cookies from "universal-cookie";
import imageLoading from "../assets/svg/infinit-black.svg";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import {useUser} from "../services/UserContext";


const getMonthName = (day) => {
    switch (day) {
        case '01' :
            return ' محرم ';
        case '02' :
            return ' صفر ';
        case '03' :
            return ' ربيع الاول ';
        case '04' :
            return ' ربيع الثاني ';
        case '05' :
            return ' جمادى الاول ';
        case '06' :
            return ' جمادى الثانية ';
        case '07' :
            return ' رجب ';
        case '08' :
            return ' شعبان ';
        case '09' :
            return ' رمضان ';
        case '10' :
            return ' شوال ';
        case '11' :
            return ' ذوالقعدة ';
        case '12' :
            return ' ذوالحجة ';
    }
}

const years = [
    {value: '1445', label: '1445'}, {value: '1444', label: '1444'},
    {value: '1443', label: '1443'},
    {value: '1442', label: '1442'},
    {value: '1441', label: '1441'},
    {value: '1440', label: '1440'},
    {value: '1439', label: '1439'},
    {value: '1438', label: '1438'},
    {value: '1437', label: '1437'},
    {value: '1436', label: '1436'},
    {value: '1435', label: '1435'},
    {value: '1434', label: '1434'},
    {value: '1433', label: '1433'},
    {value: '1432', label: '1432'},
    {value: '1431', label: '1431'},
    {value: '1430', label: '1430'},
    {value: '1429', label: '1429'},
    {value: '1428', label: '1428'},
]

const month = [
    {value: '01', label: 'محرم'},
    {value: '02', label: 'صفر'},
    {value: '03', label: 'ربيع الاول'},
    {value: '04', label: 'ربيع الثاني'},
    {value: '05', label: 'جمادى الاول'},
    {value: '06', label: 'جمادى الثانية'},
    {value: '07', label: 'رجب'},
    {value: '08', label: 'شعبان'},
    {value: '09', label: 'رمضان'},
    {value: '10', label: 'شوال'},
    {value: '11', label: 'ذوالقعدة'},
    {value: '12', label: 'ذوالحجة'}
]

const CategoryFaqArchive = (props) => {
    const {id} = useParams();
    const [getQuestion, setQuestion] = useState([]);
    const [searchParams] = useSearchParams();
    const [totalPages, setTotalPages] = useState(1);
    const [phoneSearch, setPhoneSearch] = useState(false);
    const [page, setPage] = useState((searchParams.get('page') != null) ? parseInt(searchParams.get('page')) : 1);

    const [totalPages2, setTotalPages2] = useState(1);
    const [phoneSearch2, setPhoneSearch2] = useState(false);
    const [page2, setPage2] = useState(1);
    const [userList, setUserList] = useState([]);
    const [userListFilter, setUserListFilter] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchF, setSearchF] = useState('');
    const [subFilter, setSubFilter] = useState('');
    const [subFilterTitle, setSubFilterTitle] = useState();
    const [monthFilter, setMonthFilter] = useState('');
    const [monthFilterTitle, setMonthFilterTitle] = useState();
    const [yearFilter, setYearFilter] = useState('');
    const [yearFilterTitle, setYearFilterTitle] = useState();
    const [sub, setSub] = useState(false);
    const [sub2, setSub2] = useState(false);
    const [options2, setOptions] = useState(false);
    const [focus, setFocus] = useState(false);
    const [subject, setSubject] = useState(false);
    const [month2, setMonth2] = useState(false);
    const [year2, setYear2] = useState(false);
    const [newLoading, setNewLoading] = useState(false);
    const [visibilitiSubject, setVisibilitiSubject] = useState(false);
    const [totalSearch, setTotalSearch] = useState(0);


    const [about, setAbout] = useState(false);
    const [showLogin1, setShowLogin1] = useState(false);

    const [Allsubject, setAllsubject] = useState("");
    const [hasSub, setHasSub] = useState(false);

    const [visibilitiSubject12, setVisibilitiSubject12] = useState("");
    const [visibilitiSubject12Title, setVisibilitiSubject12Title] = useState(false);

    const [userListFilter1, setUserListFilter1] = useState([]);
    const [subBooks, setSubBooks] = useState([]);
    const [defSub2, setDefSub2] = useState('');
    const [defSub2Title, setDefSub2Title] = useState('');
    const [filter3, setFilter3] = useState([]);

    const [loadingPage, setLoadingPage] = useState(false);


    const [defSub, setDefSub] = useState('الف');

    const [emailIsLogin, setEmailIsLogin] = useState(false);
    const [passIsLogin, setPassIsLogin] = useState(false);
    const [nameIsLogin, setNameIsLogin] = useState(false);
    const [lastNameIsLogin, setLastNameIsLogin] = useState(false);
    const [CountryIsLogin, setCountryIsLogin] = useState(false);
    const [religionIsLogin, setReligionIsLogin] = useState(false);
    const [avatarIsLogin, setAvatarIsLogin] = useState(false);
    const [myLoading, setMyLoading] = useState(false);
    const { userData, setLoginData } = useUser();

    const hasItem = (item) => {
        let bol = false;
        if (props.Sub33?.length > 0) {
            for (const value of props.Sub33) {
                if (value.Alphabet == item) {
                    bol = true;
                    break;
                }
            }
            return bol;
        }
    }

    const hasItem1 = (item) => {
        let bol = false;
        if (filteredResults?.length > 0) {
            for (const value of filteredResults) {
                if (value.Alphabet == item) {
                    bol = true;
                    break;
                }
            }
            return bol;
        }
    }

    useEffect(() => {
        const getUserList = () => {
            if (!sub) {
                setLoading(true);
                fetch(`https://dashboard.aqaed.net/api/v1/archiveQuestionAnswer/12?page=${page}`)
                    .then(res => res.json())
                    .then(res => {
                        setTotalPages(res.meta.last_page);
                        setUserList([...userList, ...res.data]);
                        setLoading(false);
                    });
            }
        }
        getUserList();
    }, [page, sub]);


    useEffect(() => {
        const getUserList = () => {
            if (defSub2.length == 0) {
                fetch(`https://dashboard.aqaed.net/api/v1/getCategoriesItemsQuestion?field=lft&orderType=ASC`)
                    .then(res => res.json())
                    .then(res => {
                        setData22(res.data)
                        setDataBack22(res.data)
                        setLoading222(false)
                        setLoadingPage(true)
                        setDefSub2(res.data[0].id);
                        setDefSub2Title(res.data[0].title);
                        setSubBooks(res.data[0].books);

                        let results = []
                        res.data.forEach((item) => {
                            if (item.parent == defSub2) {
                                results.push({
                                    id: item.id,
                                    title: item.title,
                                });

                            }
                        });
                        setAllsubject(results)

                        fetch(`https://dashboard.aqaed.net/api/v1/archiveQuestionAnswer/20000?field=order&orderType=asc&category=${res.data[0].id}`)
                            .then(res => res.json())
                            .then(res => {
                                setLoadingPage(false)
                                setFilter3(res.data);
                            });
                    });
            } else {
                setLoadingPage(true)
                fetch(`https://dashboard.aqaed.net/api/v1/archiveQuestionAnswer/20000?field=order&orderType=asc&category=${defSub2}`)
                    .then(res => res.json())
                    .then(res => {
                        setFilter3(res.data);
                        setLoadingPage(false)
                    });

                fetch(`https://dashboard.aqaed.net/api/v1/getCategoriesItemsQuestion?field=lft&orderType=ASC`)
                    .then(res => res.json())
                    .then(res => {
                        setData22(res.data)
                        setDataBack22(res.data)
                        setLoading222(false)
                        res.data.forEach(item => {
                                if (item.id == defSub2)
                                    setSubBooks(item.books);
                            }
                        )

                        let results = []
                        res.data.forEach((item) => {
                            if (item.parent == defSub2) {
                                results.push({
                                    id: item.id,
                                    title: item.title,
                                });

                            }
                        });
                        setAllsubject(results)

                    });
            }

        }
        getUserList();
    }, [defSub2]);


    useEffect(() => {
        const getUserList = () => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("email", emailIsLogin);
            formdata.append("password", passIsLogin);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            setNameIsLogin(userData?.name);
            setLastNameIsLogin(userData?.family);
            setCountryIsLogin(userData?.country);
            setReligionIsLogin(userData?.previous_religion);
            setAvatarIsLogin(userData?.avatar);
        };
        const cookies = new Cookies();
        // setSuccessMessage("تم تسجيل الدخول بنجاح");
        // let code = email.value + "m/!@m" + pass;
        if ((cookies.get('myCat') != undefined)) {
            let login = atob(cookies.get('myCat'));
            let arr = login.split("m/!@m");
            setEmailIsLogin(arr[0]);
            setPassIsLogin(arr[1]);
            getUserList();
        }

    }, [emailIsLogin,userData]);


    useEffect(() => {
        if (yearFilter == "" && monthFilter == "" && visibilitiSubject12 == "" && searchF == "" && subFilter == "") {
            setSub(false)
        } else {
            setNewLoading(true)
        }
        const getUserList = () => {
            if (sub) {
                setPage(1)
                fetch(`https://dashboard.aqaed.net/api/v1/archiveQuestionAnswer/10?field=order&orderType=asc&page=${page2}&search=${searchF}&category=${visibilitiSubject12}&month=${monthFilter}&year=${yearFilter}&country=${subFilter}`)
                    .then(res => res.json())
                    .then(res => {
                        setTotalPages2(res.meta.last_page);
                        setTotalSearch(res.meta.total);
                        setUserList([...[], ...[]])
                        if (res.data.length > 0) {
                            setUserListFilter([...userListFilter, ...res.data]);
                        } else {
                            setUserListFilter([...[], ...res.data]);
                        }
                        setNewLoading(false)
                        setMyLoading(true);
                    });
            }
        }
        getUserList();
    }, [sub2, page2]);

    useEffect(() => {
        if(searchParams.get('categoryId')) {
            setVisibilitiSubject12(parseInt(searchParams.get('categoryId')))
            setVisibilitiSubject12Title(searchParams.get('categoryName'))
            setShowLogin1(false)
            setUserListFilter([])
            setSub(true);
            setSub2(!sub2);
            setPage2(1);
        }

        fetch(`https://dashboard.aqaed.net/api/v1/getCategoriesSingle/${id}`)
            .then(res => res.json())
            .then(res => {
                setVisibilitiSubject12(id)
                setVisibilitiSubject12Title(res.data.title)
                setShowLogin1(false)
                setUserListFilter([])
                setSub(true);
                setSub2(!sub2);
                setPage2(1);
                }
            );

        if(searchParams.get('search')) {
            setSearchF(searchParams.get('search'));
            setMyLoading(false);
            setSub(true);
            setUserListFilter([]);
            setSub2(!sub2);
        }

            async function fetchData() {
            // Fetch data
            const {data} = await axios.get("https://dashboard.aqaed.net/api/v1/getCountryQuestion");
            const results = []
            const data2 = data;
            // Store results in the results array
            data2.forEach((value) => {
                results.push({
                    value: value,
                    label: countries.getName(value, 'ar'),
                });
            });
            // Update the options state
            setOptions(results)
        }

        // Trigger the fetch
        fetchData();
    }, []);

    const [filteredResults, setFilteredResults] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [notFunded, setNotFunded] = useState(false);


    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchValue !== '') {
            const filteredData = props.Sub33.filter((item) => {
                return Object.values(item.title).join('').includes(searchValue)
            })
            if (filteredData.length == 0) {
                setNotFunded(true)
            } else {
                setNotFunded(false)
            }
            setFilteredResults(filteredData)
        }
    }

    const filterSearch = event => {
        setSearchF(event.target.value);
    };

    const handleChangeSubject = event => {
        event ?
            setSubFilter(event.value) :
            setSubFilter("")
    };

    const handleChangeMonth = event => {
        event ?
            setMonthFilter(event.value) :
            setMonthFilter("")
    };


    const [data22, setData22] = useState([])
    const [dataBack22, setDataBack22] = useState([])
    const [loading222, setLoading222] = useState(false)

    const searchItems222 = (searchValue) => {
        if (searchValue.length > 0) {
            const newData = dataBack22.filter((item) => {
                return Object.values(item.title).join('').includes(searchValue)
            })
            setData22(newData);
        } else {
            setData22(dataBack22);
        }
    }

    const handleChangeYear = event => {
        event ?
            setYearFilter(event.value) :
            setYearFilter("")
    };

    const submitFilter = event => {
        setMyLoading(false);
        event.preventDefault();
        setSub(true);
        setUserListFilter([]);
        setSub2(!sub2);
        setPage2(1);

    };

    const wrapperRef = useRef(null);

    useEffect(() => {
        /**
         * if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (window.innerWidth >= 900)
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    setVisibilitiSubject(false)
                }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <>

            <>
                <div className={showLogin1 ? "modal-faq-cat" : "modal-faq-cat modal-close"}>
                    <div className="header-green d-sm-flex d-block justify-content-between align-items-center">
                        <i className="sub-box-close hidden-desktop" onClick={() => {
                            setShowLogin1(false)
                        }}>
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.5 6.5L6.5 19.5" stroke="white" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path d="M6.5 6.5L19.5 19.5" stroke="white" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </i>
                        <div className="title phone-center mb-3 mb-md-0">
                            جميع مواضيع الأسئلة والأجوبة
                        </div>
                        <div className="filter-subjects">
                            <form className="botton-submit1 big-form">
                                <div className="search-sub-menu">
                                    <div
                                        className="input-group search-input-group  position-relative">
                                    <span className="input-group-btn input-group-btn-22 btn-search">
                                        <button className="btn" type='submit' onClick={(event) => {
                                            event.preventDefault()
                                        }}>
                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M9.66667 15.8333C13.3486 15.8333 16.3333 12.8486 16.3333 9.16667C16.3333 5.48477 13.3486 2.5 9.66667 2.5C5.98477 2.5 3 5.48477 3 9.16667C3 12.8486 5.98477 15.8333 9.66667 15.8333Z"
                                                    stroke="#FFFFFFCC" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                                <path d="M17.999 17.4999L14.374 13.8749" stroke="#FFFFFFCC"
                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                           </svg>
                                        </button>
                                    </span>
                                        <input
                                            className="search-text-box-1 form-control form-control-2  form-control-45"
                                            onChange={(e) => searchItems222(e.target.value)}
                                            placeholder="البحث ..."/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className={data22.length > 0 ? "all-subjects-faq-list" : "all-subjects-faq-list"}>
                        <Scrollbars
                            autoHideTimeout={500}
                            autoHideDuration={200}
                            renderThumbVertical={({style, ...props}) => (
                                <div
                                    {...props}
                                    style={{
                                        ...style,
                                        backgroundColor: '#B0B0B0',
                                        borderRadius: '3px',
                                        cursor: 'pointer',
                                        width: '2px',
                                        right: '-10px'
                                    }}
                                />
                            )}
                        >
                            {
                                !loading222 ?
                                    data22.length > 0 ?
                                        data22.map((item, key) =>
                                            <NavLink
                                                className={visibilitiSubject12 == item.id ? "btn btn-not-fill m-1 fw-light g-b-se selected" :
                                                    "btn btn-not-fill m-1 fw-light"} onClick={(event) => {
                                                window.scrollTo(0, 0)
                                                if (visibilitiSubject12 == item.id) {
                                                    event.preventDefault();
                                                    setVisibilitiSubject12("")
                                                    setVisibilitiSubject12Title("")
                                                    setShowLogin1(false)
                                                    setUserListFilter([])
                                                    setSub(true);
                                                    setSub2(!sub2);
                                                    setPage2(1);
                                                } else {
                                                    event.preventDefault();
                                                    setVisibilitiSubject12(item.id)
                                                    setVisibilitiSubject12Title(item.title)
                                                    setShowLogin1(false)
                                                    setUserListFilter([])
                                                    setSub(true);
                                                    setSub2(!sub2);
                                                    setPage2(1);

                                                }

                                            }}
                                                title={item.title + " (" + item.count_content + ")"}>{item.title} <span className="green-subject"> ({item.count_content})</span></NavLink>
                                        )
                                        : "لم يتم العثور على الفئة المطلوبة"
                                    : "تحميل..."
                            }
                        </Scrollbars>

                    </div>
                </div>
                <div onClick={() => {
                    setShowLogin1(false)
                }} className={showLogin1 ? "overlay-modal" : "overlay-modal close"}></div>
            </>
            <button aria-label="show all categories"
                    className="floating-btn floating-btn-2 position-fixed btn bg-transparent top-32" onClick={() => {
                setShowLogin1(true)
            }}>
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="25" cy="25" r="25" fill="#cddfdf" fillOpacity="1"/>
                    <rect x="17.418" y="16.3336" width="15.1667" height="18.4167" rx="2" stroke="#045E5E"
                          strokeWidth="2"/>
                    <path d="M21.75 21.75H28.25" stroke="#045E5E" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M21.75 26.0833H28.25" stroke="#045E5E" strokeWidth="2" strokeLinecap="round"/>
                    <path d="M21.75 30.4167H26.0833" stroke="#045E5E" strokeWidth="2" strokeLinecap="round"/>
                </svg>
                <span className="hidden-phone">مشاهدة جميع الأسئلة و الأجوبة</span>
            </button>
            <Helmet>
                <title>الأسئلة والأجوبة العقائدية</title>
                <meta name="description"
                      content="موسوعة معرفية شاملة تحتوي على آلاف الأسئلة العقائدية مع أجوبتها بترتيب موضوعي الفبائيً"/>
                <link rel="canonical" href="https://aqaed.net/faq"/>
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:site" content="@aqaedcom"/>
                <meta name="twitter:creator" content="گروه ایده‌پرداز ترسیم"/>
                <meta name="twitter:title" content="الأسئلة والأجوبة العقائدية"/>
                <meta name="twitter:description"
                      content="موسوعة معرفية شاملة تحتوي على آلاف الأسئلة العقائدية مع أجوبتها بترتيب موضوعي الفبائيً"/>
                <meta name="twitter:image" content="/logo.svg"/>
                <meta property="og:type" content="article"/>
                <meta property="og:title" content="الأسئلة والأجوبة العقائدية"/>
                <meta property="og:site_name" content="مركز الأبحاث العقائدية"/>
                <meta property="og:locale" content="ar_sa"/>
                <meta property="og:image" content="/logo.svg"/>
            </Helmet>
            <section className="aq-menu-head filter-container-mt border-rad-top-10 back-main-2">
                <div className="aq-container">
                    <div className="row">
                        <div className="w-200 width-0-phone"></div>
                        <div className="w-100-200 width-100-phone d-flex justify-content-between">
                            <div className="filter-container w-100">
                                <div
                                    className="aq-container position-relative d-flex justify-content-between mb-3 align-items-center">
                                    <div className="question-container d-flex justify-content-end align-items-center">
                                        <svg className="show-o-container" width="50" height="50" viewBox="0 0 50 50"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="25" cy="25" r="25" fill="#045E5E"/>
                                            <circle cx="25.311" cy="36.6153" r="1.05125" stroke="white"
                                                    strokeWidth="1.69624"/>
                                            <path
                                                d="M25.3075 30.9226V27.9394C25.3075 25.9541 26.5779 24.1916 28.4613 23.5638V23.5638C30.3447 22.936 31.615 21.1734 31.615 19.1882V18.1092C31.615 14.7352 28.8798 12 25.5058 12H25.3075C21.824 12 19 14.824 19 18.3075V18.3075"
                                                stroke="white" strokeWidth="1.69624"/>
                                        </svg>

                                        <div className="question-container-data">
                                            <h1 className="faq-title m-0"> الأسئلة والأجوبة العقائدية </h1>
                                            <small className="faq-desc d-block">
                                                موسوعة معرفية شاملة تحتوي على آلاف الأسئلة العقائدية مع أجوبتها بترتيب
                                                موضوعي الفبائي
                                            </small>
                                        </div>
                                    </div>

                                </div>
                                <form className="botton-submit1 question-container-data">
                                    <div className="search-sub-menu child-m-s-0">
                                        <div
                                            className={phoneSearch ? "input-group search-input-group search-input-group-2 position-relative w-input open-s" : focus ? "input-group search-input-group search-input-group-2 position-relative w-input focus-hover" : "input-group search-input-group search-input-group-2 position-relative w-input"}>
                                            <span className="input-group-btn input-group-btn-22 btn-search"
                                                  onClick={() => setPhoneSearch(true)}>
                                                <button className="btn" type="button" onClick={(event => {
                                                    event.preventDefault()
                                                })}>
                                                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M9.66667 15.8333C13.3486 15.8333 16.3333 12.8486 16.3333 9.16667C16.3333 5.48477 13.3486 2.5 9.66667 2.5C5.98477 2.5 3 5.48477 3 9.16667C3 12.8486 5.98477 15.8333 9.66667 15.8333Z"
                                                            stroke="#FFFFFFCC" strokeLinecap="round"
                                                            strokeLinejoin="round"/>
                                                        <path d="M17.999 17.4999L14.374 13.8749" stroke="#FFFFFFCC"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </button>
                                            </span>
                                            <input className="search-text-box-1 form-control form-control-2"
                                                   onFocus={() => setFocus(true)}
                                                   onBlur={() => setFocus(false)}
                                                   value={searchF}
                                                   onChange={filterSearch}
                                                   placeholder="البحث ..."/>
                                            <svg className="close-input" onClick={() => setPhoneSearch(false)}
                                                 width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19.5 6.5L6.5 19.5" stroke="#DEFFFF" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path d="M6.5 6.5L19.5 19.5" stroke="#DEFFFF" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                            </svg>
                                        </div>

                                        <div ref={wrapperRef}
                                             className={visibilitiSubject12Title.length > 0 ? "position-relative hidden-phone item-selected"
                                                 : "position-relative hidden-phone"}>
                                            <div
                                                className="select-by-filter d-flex align-items-center c-pointer h-100 ">
                                                <p onClick={() => {
                                                    setVisibilitiSubject(true)
                                                }} className="m-0 d-inline px-2 select">
                                                    {
                                                        visibilitiSubject12Title.length > 0 ? visibilitiSubject12Title :
                                                            "مواضيع الأسئلة والأجوبة حسب ترتيب الحروف"}
                                                </p>
                                                {!(visibilitiSubject12Title.length > 0) ?
                                                    <svg onClick={() => {
                                                        setVisibilitiSubject(true)
                                                    }} className="ms-2" width="19" height="19" viewBox="0 0 20 20"
                                                         fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 7.5L10 12.5L15 7.5" stroke="white"
                                                              strokeOpacity="0.8"
                                                              strokeWidth="2" strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                    </svg>
                                                    :
                                                    <svg onClick={() => {
                                                        setVisibilitiSubject12Title("")
                                                        setVisibilitiSubject12("")
                                                    }} height="20" width="20" viewBox="0 0 20 20" aria-hidden="true"
                                                         focusable="false" className="fill-08">
                                                        <path
                                                            d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                                    </svg>
                                                }
                                            </div>
                                            <div
                                                className={visibilitiSubject ? "alphabet-subjects-container position-absolute fade show visible" : "alphabet-subjects-container position-absolute fade invisible"}>
                                                <div className="d-flex justify-content-between pb-3 c-pointer"
                                                     onClick={() => {
                                                         setVisibilitiSubject(false)
                                                     }}>
                                                    <span className="f-15-w">
                                                        مواضيع الأسئلة والأجوبة حسب ترتيب الحروف
                                                    </span>
                                                    <span>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6 15L12 9L18 15" stroke="#045E5E"
                                                                  strokeWidth="2" strokeLinecap="round"
                                                                  strokeLinejoin="round"/>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div className="d-sm-flex">
                                                    <div className="new-tab-more">
                                                        <div className="d-flex w-100 justify-content-evenly">
                                                            <ul className="list-unstyled p-0 first-c mb-0">
                                                                <li className={hasItem("الف") ? (defSub == "الف") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("الف"))
                                                                            setDefSub("الف")
                                                                    }}><span>الف</span>
                                                                </li>
                                                                <li className={hasItem("ب") ? (defSub == "ب") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ب"))
                                                                            setDefSub("ب")
                                                                    }}><span>ب</span>
                                                                </li>
                                                                <li className={hasItem("ت") ? (defSub == "ت") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ت"))
                                                                            setDefSub("ت")
                                                                    }}><span>ت</span>
                                                                </li>
                                                                <li className={hasItem("ث") ? (defSub == "ث") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ث"))
                                                                            setDefSub("ث")
                                                                    }}><span>ث</span>
                                                                </li>
                                                                <li className={hasItem("ج") ? (defSub == "ج") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ج"))
                                                                            setDefSub("ج")
                                                                    }}><span>ج</span>
                                                                </li>
                                                                <li className={hasItem("ح") ? (defSub == "ح") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ح"))
                                                                            setDefSub("ح")
                                                                    }}><span>ح</span>
                                                                </li>
                                                                <li className={hasItem("خ") ? (defSub == "خ") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("خ"))
                                                                            setDefSub("خ")
                                                                    }}><span>خ</span>
                                                                </li>
                                                                <li className={hasItem("د") ? (defSub == "د") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("د"))
                                                                            setDefSub("د")
                                                                    }}><span>د</span>
                                                                </li>
                                                                <li className={hasItem("ذ") ? (defSub == "ذ") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ذ"))
                                                                            setDefSub("ذ")
                                                                    }}><span>ذ</span>
                                                                </li>
                                                                <li className={hasItem("ر") ? (defSub == "ر") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ر"))
                                                                            setDefSub("ر")
                                                                    }}><span>ر</span>
                                                                </li>
                                                                <li className={hasItem("ز") ? (defSub == "ز") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ز"))
                                                                            setDefSub("ز")
                                                                    }}><span>ز</span>
                                                                </li>
                                                                <li className={hasItem("س") ? (defSub == "س") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("س"))
                                                                            setDefSub("س")
                                                                    }}><span>س</span>
                                                                </li>
                                                                <li className={hasItem("ش") ? (defSub == "ش") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ش"))
                                                                            setDefSub("ش")
                                                                    }}><span>ش</span>
                                                                </li>
                                                                <li className={hasItem("ص") ? (defSub == "ص") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ص"))
                                                                            setDefSub("ص")
                                                                    }}><span>ص</span>
                                                                </li>
                                                            </ul>
                                                            <ul className="list-unstyled p-0 second-c mb-0">
                                                                <li className={hasItem("ض") ? (defSub == "ض") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ض"))
                                                                            setDefSub("ض")
                                                                    }}><span>ض</span>
                                                                </li>
                                                                <li className={hasItem("ط") ? (defSub == "ط") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ط"))
                                                                            setDefSub("ط")
                                                                    }}><span>ط</span>
                                                                </li>
                                                                <li className={hasItem("ظ") ? (defSub == "ظ") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ظ"))
                                                                            setDefSub("ظ")
                                                                    }}><span>ظ</span>
                                                                </li>
                                                                <li className={hasItem("ع") ? (defSub == "ع") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ع"))
                                                                            setDefSub("ع")
                                                                    }}><span>ع</span>
                                                                </li>
                                                                <li className={hasItem("غ") ? (defSub == "غ") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("غ"))
                                                                            setDefSub("غ")
                                                                    }}><span>غ</span>
                                                                </li>
                                                                <li className={hasItem("ف") ? (defSub == "ف") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ف"))
                                                                            setDefSub("ف")
                                                                    }}><span>ف</span>
                                                                </li>
                                                                <li className={hasItem("ق") ? (defSub == "ق") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ق"))
                                                                            setDefSub("ق")
                                                                    }}><span>ق</span>
                                                                </li>
                                                                <li className={hasItem("ك") ? (defSub == "ك") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ك"))
                                                                            setDefSub("ك")
                                                                    }}><span>ك</span>
                                                                </li>
                                                                <li className={hasItem("ل") ? (defSub == "ل") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ل"))
                                                                            setDefSub("ل")
                                                                    }}><span>ل</span>
                                                                </li>
                                                                <li className={hasItem("م") ? (defSub == "م") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("م"))
                                                                            setDefSub("م")
                                                                    }}><span>م</span>
                                                                </li>
                                                                <li className={hasItem("ن") ? (defSub == "ن") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ن"))
                                                                            setDefSub("ن")
                                                                    }}><span>ن</span>
                                                                </li>
                                                                <li className={hasItem("ه") ? (defSub == "ه") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ه"))
                                                                            setDefSub("ه")
                                                                    }}><span>ه</span>
                                                                </li>
                                                                <li className={hasItem("و") ? (defSub == "و") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("و"))
                                                                            setDefSub("و")
                                                                    }}><span>و</span>
                                                                </li>
                                                                <li className={hasItem("ي") ? (defSub == "ي") ? "select" : "" : "disable"}
                                                                    onClick={() => {
                                                                        if (hasItem("ي"))
                                                                            setDefSub("ي")
                                                                    }}><span>ي</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="fw-light">
                                                            <div className={hasItem("الأسئلة المتفرقة") ? (defSub == "الأسئلة المتفرقة") ? "select" : "" : "disable"}
                                                                 onClick={() => {
                                                                     if (hasItem("الأسئلة المتفرقة"))
                                                                         setDefSub("الأسئلة المتفرقة")
                                                                 }}><span className="font-little">الأسئلة المتفرقة</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <ul className="subject-container-div">
                                                        {
                                                            props.Sub33 ?
                                                                props.Sub33.length > 0 ?
                                                                    props.Sub33.map(items =>
                                                                        items.Alphabet == defSub ?
                                                                            <li
                                                                                onClick={
                                                                                    () => {
                                                                                        if (visibilitiSubject12 == items.id) {
                                                                                            setVisibilitiSubject12Title("")
                                                                                            setVisibilitiSubject12("")
                                                                                        } else {
                                                                                            setVisibilitiSubject12Title(items.title)
                                                                                            setVisibilitiSubject12(items.id)
                                                                                            setVisibilitiSubject(false)
                                                                                        }
                                                                                    }
                                                                                }
                                                                                className={(visibilitiSubject12 == items.id) ? "select" : ""}
                                                                                title={items.title + " (" + items.count_content + ")"}
                                                                            > <span>
                                                                                   {items.title}
                                                                                <span className="green-subject"> ({items.count_content})</span>
                                                                            </span>
                                                                            </li>
                                                                            : ""
                                                                    )
                                                                    : ""
                                                                : ""
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>


                                        <div
                                            className={visibilitiSubject12Title.length > 0 ? "position-relative hidden-desktop select" : "position-relative hidden-desktop"}>
                                            <div
                                                className="select-by-filter d-flex align-items-center c-pointer h-100">
                                                <p onClick={() => {
                                                    setVisibilitiSubject(true)
                                                }} className="m-0 d-inline px-2">
                                                    {
                                                        visibilitiSubject12Title.length > 0 ? visibilitiSubject12Title
                                                        :
                                                        "مواضيع الأسئلة والأجوبة حسب ترتيب الحروف"
                                                    }
                                                </p>
                                                {!(visibilitiSubject12Title.length > 0) ?
                                                    <svg onClick={() => {
                                                        setVisibilitiSubject(true)
                                                    }} className="ms-2" width="19" height="19" viewBox="0 0 20 20"
                                                         fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M5 7.5L10 12.5L15 7.5" stroke="white"
                                                              strokeOpacity="0.8"
                                                              strokeWidth="2" strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                    </svg>
                                                    :
                                                    <svg onClick={() => {
                                                        setVisibilitiSubject12Title("")
                                                        setVisibilitiSubject12("")
                                                    }} className="ms-2" width="26" height="26" viewBox="0 0 26 26"
                                                         fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19.5 6.5L6.5 19.5" stroke="white"
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                        <path d="M6.5 6.5L19.5 19.5" stroke="white"
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                    </svg>

                                                }
                                            </div>
                                            <div
                                                className={visibilitiSubject ? "overlay-phone-modal fade show " : "overlay-phone-modal invisible fade "}
                                                onClick={() => setVisibilitiSubject(false)}></div>
                                            <div
                                                className={visibilitiSubject ? "alphabet-subjects-container position-absolute fade show visible" : "alphabet-subjects-container position-absolute fade invisible"}>

                                                <div className="d-flex justify-content-between pb-3 c-pointer"
                                                     onClick={() => {
                                                         setVisibilitiSubject(false)
                                                     }}>
                                                    <span className="f-15-w">
                                                        مواضيع الأسئلة والأجوبة حسب ترتيب الحروف
                                                    </span>
                                                    <span>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M6 15L12 9L18 15" stroke="#045E5E"
                                                                  strokeWidth="2" strokeLinecap="round"
                                                                  strokeLinejoin="round"/>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div className="d-sm-flex">
                                                    <div className="d-block width-100-phone overflow-scroll">
                                                        <ul className="list-unstyled p-0 d-flex top-ul gap-3 mb-0">
                                                            <li className={hasItem("الف") ? (defSub == "الف") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("الف"))
                                                                        setDefSub("الف")
                                                                }}><span>الف</span>
                                                            </li>
                                                            <li className={hasItem("ب") ? (defSub == "ب") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ب"))
                                                                        setDefSub("ب")
                                                                }}><span>ب</span>
                                                            </li>
                                                            <li className={hasItem("ت") ? (defSub == "ت") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ت"))
                                                                        setDefSub("ت")
                                                                }}><span>ت</span>
                                                            </li>
                                                            <li className={hasItem("ث") ? (defSub == "ث") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ث"))
                                                                        setDefSub("ث")
                                                                }}><span>ث</span>
                                                            </li>
                                                            <li className={hasItem("ج") ? (defSub == "ج") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ج"))
                                                                        setDefSub("ج")
                                                                }}><span>ج</span>
                                                            </li>
                                                            <li className={hasItem("ح") ? (defSub == "ح") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ح"))
                                                                        setDefSub("ح")
                                                                }}><span>ح</span>
                                                            </li>
                                                            <li className={hasItem("خ") ? (defSub == "خ") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("خ"))
                                                                        setDefSub("خ")
                                                                }}><span>خ</span>
                                                            </li>
                                                            <li className={hasItem("د") ? (defSub == "د") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("د"))
                                                                        setDefSub("د")
                                                                }}><span>د</span>
                                                            </li>
                                                            <li className={hasItem("ذ") ? (defSub == "ذ") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ذ"))
                                                                        setDefSub("ذ")
                                                                }}><span>ذ</span>
                                                            </li>
                                                            <li className={hasItem("ر") ? (defSub == "ر") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ر"))
                                                                        setDefSub("ر")
                                                                }}><span>ر</span>
                                                            </li>
                                                            <li className={hasItem("ز") ? (defSub == "ز") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ز"))
                                                                        setDefSub("ز")
                                                                }}><span>ز</span>
                                                            </li>
                                                            <li className={hasItem("س") ? (defSub == "س") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("س"))
                                                                        setDefSub("س")
                                                                }}><span>س</span>
                                                            </li>
                                                            <li className={hasItem("ش") ? (defSub == "ش") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ش"))
                                                                        setDefSub("ش")
                                                                }}><span>ش</span>
                                                            </li>
                                                            <li className={hasItem("ص") ? (defSub == "ص") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ص"))
                                                                        setDefSub("ص")
                                                                }}><span>ص</span>
                                                            </li>
                                                            <li className={hasItem("ض") ? (defSub == "ض") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ض"))
                                                                        setDefSub("ض")
                                                                }}><span>ض</span>
                                                            </li>
                                                            <li className={hasItem("ط") ? (defSub == "ط") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ط"))
                                                                        setDefSub("ط")
                                                                }}><span>ط</span>
                                                            </li>
                                                            <li className={hasItem("ظ") ? (defSub == "ظ") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ظ"))
                                                                        setDefSub("ظ")
                                                                }}><span>ظ</span>
                                                            </li>
                                                            <li className={hasItem("ع") ? (defSub == "ع") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ع"))
                                                                        setDefSub("ع")
                                                                }}><span>ع</span>
                                                            </li>
                                                            <li className={hasItem("غ") ? (defSub == "غ") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("غ"))
                                                                        setDefSub("غ")
                                                                }}><span>غ</span>
                                                            </li>
                                                            <li className={hasItem("ف") ? (defSub == "ف") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ف"))
                                                                        setDefSub("ف")
                                                                }}><span>ف</span>
                                                            </li>
                                                            <li className={hasItem("ق") ? (defSub == "ق") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ق"))
                                                                        setDefSub("ق")
                                                                }}><span>ق</span>
                                                            </li>
                                                            <li className={hasItem("ك") ? (defSub == "ك") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ك"))
                                                                        setDefSub("ك")
                                                                }}><span>ك</span>
                                                            </li>
                                                            <li className={hasItem("ل") ? (defSub == "ل") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ل"))
                                                                        setDefSub("ل")
                                                                }}><span>ل</span>
                                                            </li>
                                                            <li className={hasItem("م") ? (defSub == "م") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("م"))
                                                                        setDefSub("م")
                                                                }}><span>م</span>
                                                            </li>
                                                            <li className={hasItem("ن") ? (defSub == "ن") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ن"))
                                                                        setDefSub("ن")
                                                                }}><span>ن</span>
                                                            </li>
                                                            <li className={hasItem("ه") ? (defSub == "ه") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ه"))
                                                                        setDefSub("ه")
                                                                }}><span>ه</span>
                                                            </li>
                                                            <li className={hasItem("و") ? (defSub == "و") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("و"))
                                                                        setDefSub("و")
                                                                }}><span>و</span>
                                                            </li>
                                                            <li className={hasItem("ي") ? (defSub == "ي") ? "select" : "" : "disable"}
                                                                onClick={() => {
                                                                    if (hasItem("ي"))
                                                                        setDefSub("ي")
                                                                }}><span>ي</span>
                                                            </li>
                                                            <li className={hasItem("الأسئلة المتفرقة") ? (defSub == "الأسئلة المتفرقة") ? "width-100-im select" : "width-100-im" : "width-100-im disable"}
                                                                onClick={() => {
                                                                    if (hasItem("الأسئلة المتفرقة"))
                                                                        setDefSub("الأسئلة المتفرقة")
                                                                }}><span>الأسئلة المتفرقة</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <ul className="subject-container-div">
                                                        {
                                                            props.Sub33 ?
                                                                props.Sub33.length > 0 ?
                                                                    props.Sub33.map((items, key) =>
                                                                        items.Alphabet == defSub ?
                                                                            <li onClick={
                                                                                () => {
                                                                                    if (visibilitiSubject12 == items.id) {
                                                                                        setVisibilitiSubject12Title("")
                                                                                        setVisibilitiSubject12("")
                                                                                    } else {
                                                                                        setVisibilitiSubject12Title(items.title)
                                                                                        setVisibilitiSubject12(items.id)
                                                                                        setVisibilitiSubject(false)
                                                                                    }
                                                                                }
                                                                            }
                                                                                className={(visibilitiSubject12 == items.id) ? "select" : ""}
                                                                            title={items.title + " (" + items.count_content + ")"}
                                                                            >{items.title}              <span className="green-subject"> ({items.count_content})</span></li>
                                                                            : ""
                                                                    )
                                                                    : ""
                                                                : ""
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={submitFilter}
                                             className="select-by-filter d-flex align-items-center c-pointer hidden-desktop d-flex-imp">
                                            <p className="m-0 d-inline px-2">تنفيذ</p>
                                        </div>
                                        <button onClick={submitFilter} title="تنفيذ"
                                                className="more-btn border-0-im fw-light color-white hidden-phone">
                                            تنفيذ
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="question-container-sec">
                <div className="phone-m-9 question-answer-container py-sm-5 py-0 position-relative">
                    {
                        (yearFilter != "" || monthFilter != "" || visibilitiSubject12 != "" || searchF != "" || subFilter != "") && sub ?
                            <div className="container phone-m-t-5">
                                <span className="search-result">

                              تمّ العثور على
                                       <b> {myLoading ? totalSearch :
                                           <img className="image-loading image-loading-2" src={imageLoading}
                                                alt="loading"
                                                title="loading"/>} </b>
                                    نتيجة للبحث عن
                                    <span> {searchF} </span> {searchF != "" && (visibilitiSubject12 != "" || subFilter != "" || yearFilter != "" || monthFilter != "") ? " - " : ""}
                                    <span> {visibilitiSubject12Title} </span> {visibilitiSubject12 != "" && (subFilter != "" || yearFilter != "" || monthFilter != "") ? " - " : ""}
                                    <span> {countries.getName(subFilter, 'ar')}</span> {subFilter != "" && (yearFilter != "" || monthFilter != "") ? " - " : ""}
                                    <span
                                        className="number">{yearFilter}</span> {yearFilter != "" && (monthFilter != "") ? " - " : ""}
                                    <span> {getMonthName(monthFilter)} </span>
                            .
                                </span>

                            </div>
                            : ""
                    }
                    {!sub ?
                        userList.length > 0 && (
                            userList.map((question, key) => (

                                <div
                                    className={key == 0 ? "gh-padding container d-flex justify-content-between align-items-center py-2 phone-m-t-5 phone-mt-0-imp" : "gh-padding container d-flex justify-content-between align-items-center py-2"}>
                                    <div className="w-100">
                                        <div className="card-question">
                                            <div className="d-flex justify-content-between">
                                                <div className="right">
                                                    <ul className="personal-information">
                                                        <li>
                                                            <svg className="mb-1 hidden-phone" width="21" height="21"
                                                                 viewBox="0 0 21 21" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M17.2712 17.5383C16.8914 16.4751 16.0544 15.5357 14.8901 14.8656C13.7258 14.1956 12.2993 13.8324 10.8317 13.8324C9.36415 13.8324 7.93759 14.1956 6.7733 14.8656C5.609 15.5357 4.77203 16.4751 4.3922 17.5383"
                                                                    stroke="#045E5E" strokeLinecap="round"/>
                                                                <circle cx="10.8324" cy="7.16536" r="3.33333"
                                                                        stroke="#045E5E" strokeLinecap="round"/>
                                                            </svg>
                                                            {question.user?.country?.length > 0 ?
                                                                <ReactCountryFlag className="hidden-desktop"
                                                                                  countryCode={question.user?.country}
                                                                                  svg
                                                                                  style={{
                                                                                      width: '1.4em',
                                                                                      height: '1.4em',
                                                                                  }}
                                                                                  title={question.user?.country}
                                                                                  alt={question.user?.country}
                                                                />
                                                                : ""}
                                                            {question.user?.name + " " + question.user?.family}

                                                        </li>

                                                            <li className="hidden-phone">
                                                                {question.user?.country?.length > 0 ?
                                                                    <>
                                                                        <ReactCountryFlag
                                                                            countryCode={question.user?.country}
                                                                            svg
                                                                            style={{
                                                                                width: '1.4em',
                                                                                height: '1.4em',
                                                                            }}
                                                                            title={question.user?.country}
                                                                            alt={question.user?.country}
                                                                        />
                                                                        {countries.getName(question.user?.country, 'ar')}
                                                                    </>
                                                                    : ""}
                                                            </li>

                                                        {question.category != null ?
                                                            <li>
                                                                <NavLink to={`/category-faq/${question.category.id}`} reloadDocument
                                                                         title={question.category.title}>
                                                                    <svg className="mb-1" width="20" height="20"
                                                                         viewBox="0 0 20 20" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <rect x="4.16699" y="3.3335" width="11.6667"
                                                                              height="14.1667" rx="2" stroke="#045E5E"/>
                                                                        <path d="M7.5 7.5H12.5" stroke="#045E5E"
                                                                              strokeLinecap="round"/>
                                                                        <path d="M7.5 10.8334H12.5" stroke="#045E5E"
                                                                              strokeLinecap="round"/>
                                                                        <path d="M7.5 14.1666H10.8333" stroke="#045E5E"
                                                                              strokeLinecap="round"/>
                                                                    </svg>
                                                                    {question.category.title}
                                                                </NavLink>
                                                            </li> : ""
                                                        }

                                                        <li title="تعليقاً وتعقيباً">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M16.1036 4.81481C16.6654 5.65555 16.6654 6.82594 16.6654 9.16671C16.6654 11.5075 16.6654 12.6779 16.1036 13.5186C15.8604 13.8826 15.5479 14.1951 15.1839 14.4383C14.4495 14.929 13.4634 14.9911 11.6654 14.9989V15L10.7441 16.8427C10.4369 17.4569 9.56045 17.4569 9.25334 16.8427L8.33203 15V14.9989C6.53395 14.9911 5.54792 14.929 4.81346 14.4383C4.4495 14.1951 4.13699 13.8826 3.8938 13.5186C3.33203 12.6779 3.33203 11.5075 3.33203 9.16671C3.33203 6.82594 3.33203 5.65555 3.8938 4.81481C4.13699 4.45084 4.4495 4.13834 4.81346 3.89514C5.65421 3.33337 6.82459 3.33337 9.16536 3.33337H10.832C13.1728 3.33337 14.3432 3.33337 15.1839 3.89514C15.5479 4.13834 15.8604 4.45084 16.1036 4.81481Z"
                                                                    stroke="#045E5E" strokeLinecap="round"
                                                                    strokeLinejoin="round"/>
                                                                <path d="M7.5 7.5L12.5 7.5" stroke="#045E5E"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path d="M7.5 10.8334H10" stroke="#045E5E"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                            <span className="number">
                                                    {question.comment_count}
                                                </span>

                                                        </li>
                                                    </ul>
                                                    <NavLink to={"/qa/" + question.id} title={question.title}>
                                                        <h3>{question.title.length > 120 ?
                                                            `${question.title.substring(0, 120)}...` : question.title
                                                        }</h3>
                                                    </NavLink>
                                                </div>
                                                <div className="left hidden-phone">
                                                    <svg className="mb-1 ms-1" width="19" height="18"
                                                         viewBox="0 0 19 18"
                                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M15.0417 3H3.95833C3.08388 3 2.375 3.67157 2.375 4.5V15C2.375 15.8284 3.08388 16.5 3.95833 16.5H15.0417C15.9161 16.5 16.625 15.8284 16.625 15V4.5C16.625 3.67157 15.9161 3 15.0417 3Z"
                                                            stroke="#045E5E" strokeLinecap="round"
                                                            strokeLinejoin="round"/>
                                                        <path d="M12.666 1.50012V4.50012" stroke="#045E5E"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M6.33398 1.50012V4.50012" stroke="#045E5E"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M2.375 7.5H16.625" stroke="#045E5E"
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                    </svg>

                                                    <div className="date">
                                                        <span
                                                            className="number"> {question.date_lunar ? question.date_lunar.split("-")[2] : "02"} </span>
                                                        {getMonthName(question.date_lunar ? question.date_lunar.split("-")[1] : "02")}
                                                        <span
                                                            className="number"> {question.date_lunar ? question.date_lunar.split("-")[0] : "1500"} </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )
                        :
                        userListFilter.length > 0 ?
                            userListFilter.map(question => (
                                <div className="container d-flex justify-content-between align-items-center py-2">
                                    <div className="w-100">
                                        <div className="card-question">
                                            <div className="d-flex justify-content-between">
                                                <div className="right">
                                                    <ul className="personal-information">
                                                        <li>
                                                            <svg className="mb-1 hidden-phone" width="21"
                                                                 height="21"
                                                                 viewBox="0 0 21 21" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M17.2712 17.5383C16.8914 16.4751 16.0544 15.5357 14.8901 14.8656C13.7258 14.1956 12.2993 13.8324 10.8317 13.8324C9.36415 13.8324 7.93759 14.1956 6.7733 14.8656C5.609 15.5357 4.77203 16.4751 4.3922 17.5383"
                                                                    stroke="#045E5E" strokeLinecap="round"/>
                                                                <circle cx="10.8324" cy="7.16536" r="3.33333"
                                                                        stroke="#045E5E" strokeLinecap="round"/>
                                                            </svg>
                                                            {question.user?.country?.length > 0 ?
                                                                <ReactCountryFlag className="hidden-desktop"
                                                                                  countryCode={question.user?.country}
                                                                                  svg
                                                                                  style={{
                                                                                      width: '1.4em',
                                                                                      height: '1.4em',
                                                                                  }}
                                                                                  title={question.user?.country}
                                                                />
                                                                : ""}
                                                            {question.user?.name + " " + question.user?.family}

                                                        </li>

                                                            <li className="hidden-phone">
                                                                {question.user?.country?.length > 0 ?
                                                                    <>
                                                                <ReactCountryFlag
                                                                    countryCode={question.user?.country}
                                                                    svg
                                                                    style={{
                                                                        width: '1.4em',
                                                                        height: '1.4em',
                                                                    }}
                                                                    title={countries.getName(question.user?.country, 'ar')}
                                                                    alt={countries.getName(question.user?.country, 'ar')}
                                                                />
                                                                {countries.getName(question.user?.country, 'ar')}
                                                                    </>
                                                                    : ""}
                                                            </li>

                                                        {question.category != null ?
                                                            <li>
                                                                <NavLink to={`/category-faq/${question.category.id}`} reloadDocument
                                                                         title={question.category.title}>
                                                                    <svg className="mb-1" width="20" height="20"
                                                                         viewBox="0 0 20 20" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <rect x="4.16699" y="3.3335" width="11.6667"
                                                                              height="14.1667" rx="2"
                                                                              stroke="#045E5E"/>
                                                                        <path d="M7.5 7.5H12.5" stroke="#045E5E"
                                                                              strokeLinecap="round"/>
                                                                        <path d="M7.5 10.8334H12.5" stroke="#045E5E"
                                                                              strokeLinecap="round"/>
                                                                        <path d="M7.5 14.1666H10.8333"
                                                                              stroke="#045E5E"
                                                                              strokeLinecap="round"/>
                                                                    </svg>
                                                                    {question.category.title}
                                                                </NavLink>
                                                            </li> : ""
                                                        }

                                                        <li title="تعليقاً وتعقيباً">
                                                            <svg width="20" height="20" viewBox="0 0 20 20"
                                                                 fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M16.1036 4.81481C16.6654 5.65555 16.6654 6.82594 16.6654 9.16671C16.6654 11.5075 16.6654 12.6779 16.1036 13.5186C15.8604 13.8826 15.5479 14.1951 15.1839 14.4383C14.4495 14.929 13.4634 14.9911 11.6654 14.9989V15L10.7441 16.8427C10.4369 17.4569 9.56045 17.4569 9.25334 16.8427L8.33203 15V14.9989C6.53395 14.9911 5.54792 14.929 4.81346 14.4383C4.4495 14.1951 4.13699 13.8826 3.8938 13.5186C3.33203 12.6779 3.33203 11.5075 3.33203 9.16671C3.33203 6.82594 3.33203 5.65555 3.8938 4.81481C4.13699 4.45084 4.4495 4.13834 4.81346 3.89514C5.65421 3.33337 6.82459 3.33337 9.16536 3.33337H10.832C13.1728 3.33337 14.3432 3.33337 15.1839 3.89514C15.5479 4.13834 15.8604 4.45084 16.1036 4.81481Z"
                                                                    stroke="#045E5E" strokeLinecap="round"
                                                                    strokeLinejoin="round"/>
                                                                <path d="M7.5 7.5L12.5 7.5" stroke="#045E5E"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path d="M7.5 10.8334H10" stroke="#045E5E"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                            </svg>
                                                            <span className="number">
                                                                {question.comment_count}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                    <NavLink to={"/qa/" + question.id}
                                                             title={question.title}>
                                                        <h3>{question.title.length > 120 ?
                                                            `${question.title.substring(0, 120)}...` : question.title
                                                        }</h3>
                                                    </NavLink>
                                                </div>
                                                <div className="left hidden-phone">
                                                    <svg className="mb-1 ms-1" width="19" height="18"
                                                         viewBox="0 0 19 18" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M15.0417 3H3.95833C3.08388 3 2.375 3.67157 2.375 4.5V15C2.375 15.8284 3.08388 16.5 3.95833 16.5H15.0417C15.9161 16.5 16.625 15.8284 16.625 15V4.5C16.625 3.67157 15.9161 3 15.0417 3Z"
                                                            stroke="#045E5E" strokeLinecap="round"
                                                            strokeLinejoin="round"/>
                                                        <path d="M12.666 1.50012V4.50012" stroke="#045E5E"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M6.33398 1.50012V4.50012" stroke="#045E5E"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M2.375 7.5H16.625" stroke="#045E5E"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>

                                                    <div className="date">
                                                        <span
                                                            className="number"> {question.date_lunar ? question.date_lunar.split("-")[2] : "02"} </span>
                                                        {getMonthName(question.date_lunar ? question.date_lunar.split("-")[1] : "02")}
                                                        <span
                                                            className="number"> {question.date_lunar ? question.date_lunar.split("-")[0] : "1500"} </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) :
                            <div className="w-100 d-flex justify-content-center mt-3">
                                {newLoading ?
                                    <span className="width-fit not-funded-filter">
                                        تحميل ...
                                        </span>
                                    :
                                    <span className="width-fit not-funded-filter">
                                            لم يتم العثور على النتيجة المطلوبة
                                        </span>
                                }
                            </div>
                    }
                    <div className="more-btn-container d-flex justify-content-center align-items-center mb-sm-0 mb-5 mt-sm-3 mt-4">
                        {!sub ?
                            totalPages >= page ?
                                totalPages !== page &&
                                <>
                                    <NavLink className={!loading?"more-btn" : "more-btn-not-fill"} to="#"
                                             onClick={() => setPage(page + 1)}>
                                        {loading ?  <>
                                            <img className="image-loading image-loading-2" src={imageLoading}
                                                 alt="loading"
                                                 title="loading"/>  تحميل...
                                        </> : 'المزيد'}
                                    </NavLink>
                                    <p className="mb-0 pe-3 number-font-page">{page} <span>/ {totalPages}</span></p>
                                </> : "لم يتم العثور على النتيجة المطلوبة"
                            : ""
                        }

                        {
                            sub ?
                                totalPages2 >= page2 ?
                                    totalPages2 !== page2 &&
                                    <>
                                        <button className={!newLoading?"more-btn fw-light btn" : "more-btn-not-fill fw-light"}
                                                onClick={() => setPage2(page2 + 1)}>
                                            {newLoading ?
                                                <>
                                                    <img className="image-loading image-loading-2" src={imageLoading}
                                                         alt="loading"
                                                         title="loading"/>  تحميل...
                                                </>
                                                : 'المزيد'}
                                        </button>
                                        <p className="mb-0 pe-3 number-font-page">{page2} <span>/ {totalPages2}</span></p>
                                    </> : "لم يتم العثور على النتيجة المطلوبة"
                                : ""
                        }
                    </div>
                </div>
            </section>
        </>

    );
};

export default CategoryFaqArchive;