import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import FaqTextPage from "../components/FaqTextPage";
import FaqTextPage2 from "../components/FaqTextPage2";

const FaqText = () => {
    return (
        <>
            <HeaderPage />
            <FaqTextPage2 />
            <Footer />
        </>

    );
};

export default FaqText;
