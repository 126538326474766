import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import {NavLink} from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import FavoriteTextPage from "../components/FavoriteTextPage";
let login = true;

const Favorite = () => {
    return (
        <div>
            <HeaderPage />
            <FavoriteTextPage />
            <Footer />
        </div>

    );
};
export default Favorite;
