import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import GuidedArchive from "../components/GuidedArchive";

const Guided = () => {
    return (
        <div>
            <HeaderPage />
            <GuidedArchive />
            <Footer />
        </div>
    );
};

export default Guided;
