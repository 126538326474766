import {useState, useEffect} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import axios from "axios";

import "./App.css";
import "./responsive.css"
import Home from "./page/Home";
import Guided from "./page/Guided";
import Faq from "./page/Faq";
import FaqText from "./page/FaqText";
import GuidedText from "./page/GuidedText";
import Speaker from "./page/Speaker";
import Seminar from "./page/Seminar";
import SeminarText from "./page/SeminarText";
import News from "./page/News";
import NewsText from "./page/NewsText";
import Books from "./page/Books";
import BookText from "./page/BookText";
import Contribute from "./page/Contribute";
import About from "./page/About";
import ContactUs from "./page/ContactUs";
import ShiiteSites from "./page/ShiiteSites";
import UserFaq from "./page/UserFaq";
import Favorite from "./page/Favorite";
import Search from "./page/Search";
import GuidedSayings from "./page/GuidedSayings";
import GuidedSayingsTextPage from "./page/GuidedSayingsTextPage";
import ContributePage from "./page/ContributePage";
import NotFound from "./page/NotFound";
import ShiitesDispersion from "./page/ShiitesDispersion";
import ScrollToTop from "./components/ScrollToTop";
import Category from "./page/Category";
import Author from "./page/Author";
import Tags from "./page/Tags";
import LastFaq from "./page/LastFaq";
import FaqText2 from "./page/FaqText2";
import BookTextOld from "./page/BookTextOld";
import CategoryFaq from "./page/CategoryFaq";
import {UserProvider} from "./services/UserContext";


const App = () => {
    return (

        <div className="App">
            <UserProvider>
                <ScrollToTop/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/faq" element={<Faq/>}/>
                    <Route path="/last-faq" element={<LastFaq/>}/>
                    <Route path="/category-faq/:id" element={<CategoryFaq/>}/>
                    <Route path="/qa/:id" element={<FaqText/>}/>
                    <Route path="/faq/:id" element={<FaqText2/>}/>
                    <Route path="/guided" element={<Guided/>}/>
                    <Route path="/guided/:id" element={<GuidedText/>}/>
                    <Route path="/seminar" element={<Seminar/>}/>
                    <Route path="/seminar/:id" element={<SeminarText/>}/>
                    <Route path="/speaker/:id" element={<Speaker/>}/>
                    <Route path="/news" element={<News/>}/>
                    <Route path="/news/:id" element={<NewsText/>}/>
                    <Route path="/books" element={<Books/>}/>
                    <Route path="/books/:id" element={<BookText/>}/>
                    <Route path="/book/:id" element={<BookTextOld/>}/>
                    {/*مساهمات*/}
                    <Route path="/guided-contribute" element={<Contribute/>}/>
                    <Route path="/guided-contribute/:id" element={<ContributePage/>}/>
                    {/*مقابلات*/}
                    <Route path="/guided-sayings" element={<GuidedSayings/>}/>
                    <Route path="/guided-sayings/:id" element={<GuidedSayingsTextPage/>}/>
                    <Route path="/contact" element={<ContactUs/>}/>
                    <Route path="/about" element={<About/>}/>
                    {/*سایت های شیعی*/}
                    <Route path="/shiites-sites" element={<ShiiteSites/>}/>
                    <Route path="/shiites-dispersion" element={<ShiitesDispersion/>}/>
                    <Route path="/user-faq" element={<UserFaq/>}/>
                    <Route path="/user-favorite" element={<Favorite/>}/>
                    <Route path="/search/:id" element={<Search/>}/>
                    <Route path="/categories/:id" element={<Category/>}/>
                    <Route path="/author/:id" element={<Author/>}/>
                    <Route path="/tags/:id" element={<Tags/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </UserProvider>
        </div>

    );
};


export default App;
