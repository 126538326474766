import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import BooksArchive from "../components/BooksArchive";

const Books = () => {
    return (
        <div>
            <HeaderPage />
            <BooksArchive />
            <Footer />
        </div>
    );
};

export default Books;
