import {NavLink} from "react-router-dom";
import React, {useState} from "react";
import LoginModal from "./LoginModal";
import {useEffect} from "react";
import Cookies from "universal-cookie";
import {useUser} from "../services/UserContext";

const GuidedNeedToLogin = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [emailIsLogin, setEmailIsLogin] = useState(false);
    const [passIsLogin, setPassIsLogin] = useState(false);
    const [idIsLogin, setIdIsLogin] = useState(false);
    const [nameIsLogin, setNameIsLogin] = useState(false);
    const [lastNameIsLogin, setLastNameIsLogin] = useState(false);
    const [CountryIsLogin, setCountryIsLogin] = useState(false);
    const [religionIsLogin, setReligionIsLogin] = useState(false);
    const { userData, setLoginData } = useUser();
    useEffect(() => {
        const getUserList = () => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("email", emailIsLogin);
            formdata.append("password", passIsLogin);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            setIdIsLogin(userData?.id);
            setNameIsLogin(userData?.name);
            setLastNameIsLogin(userData?.family);
            setCountryIsLogin(userData?.country);
            setReligionIsLogin(userData?.previous_religion);
        };
        const cookies = new Cookies();
        // setSuccessMessage("تم تسجيل الدخول بنجاح");
        // let code = email.value + "m/!@m" + pass;
        if ((cookies.get('myCat') != undefined)) {
            let login = atob(cookies.get('myCat'));
            let arr = login.split("m/!@m");
            setEmailIsLogin(arr[0]);
            setPassIsLogin(arr[1]);
            getUserList();
        }

    }, [emailIsLogin,userData]);
    return (
        <div className="green-box">
            <div className="container">
                <div className="d-flex justify-content-center mt-5">
                    <div className="login-is-rew text-center">
                        <h2>
                            أرسل سوالاً عقائدياً
                        </h2>
                        <small>
                            إن كان عندك سؤال أو استفسار بشأن عقائد مذهب أهل البيت(عليهم السلام)، يمكنك ذكره هنا، وستجد الإجابة الوافية إن شاء الله تعالى
                        </small>
                    </div>
                </div>
                <div className="d-flex justify-content-center mb-4 f-15 mt-4">
                    <div className="more-btn-container d-flex justify-content-center mb-1 mt-2 ">
                            <button onClick={()=>{
                                setShowLogin(true)}
                            } type="submit" aria-label="إرسال المعلومات"
                                    title="إرسال المعلومات"
                                    className=" hover-svg-f  more-btn d-flex align-items-center
                                                        mt-custom-s border-0 hover-red fw-light color-white h-green-hover">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M16.4382 17.0382C16.0584 15.975 15.2214 15.0356 14.0571 14.3655C12.8928 13.6955 11.4663 13.3323 9.9987 13.3323C8.53114 13.3323 7.10458 13.6955 5.94029 14.3655C4.77599 15.0356 3.93903 15.975 3.55919 17.0382"
                                        stroke="#045E5E" stroke-linecap="round"/>
                                    <circle cx="9.9974" cy="6.66536" r="3.33333"
                                            stroke="#045E5E" stroke-linecap="round"/>
                                </svg>
                                <span
                                    className="select-pos">
                                                         الدخول إلى الحساب / سجّل</span>

                            </button>
                    </div>
                </div>
            </div>
            {showLogin?
            <LoginModal show={showLogin} close={() => setShowLogin(false)}/>
                :""}
        </div>
    );
};

export default GuidedNeedToLogin;
