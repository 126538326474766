import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import Filter from "../components/Filter";
import GuideMostVisit from "../components/GuideMostVisit";
import GuideSubjectMostVisit from "../components/GuideSubjectMostVisit";
import GuidedForm from "../components/GuidedForm";
import GuidedNeedToLogin from "../components/GuidedNeedToLogin";
import {NavLink, useNavigate, Navigate} from "react-router-dom";

import Select from "react-select";
import ReactCountryFlag from "react-country-flag";
import {Scrollbars} from "react-custom-scrollbars";
import image from "../assets/uploads/book.jpg";
import {useEffect, useRef, useState} from "react";
import Cookies from "universal-cookie";
import countries from "i18n-iso-countries";
import React from "react";

let login = true;

const getMonthName = (day) => {
    switch (day) {
        case '01' :
            return ' محرم ';
        case '02' :
            return ' صفر ';
        case '03' :
            return ' ربيع الاول ';
        case '04' :
            return ' ربيع الثاني ';
        case '05' :
            return ' جمادى الاول ';
        case '06' :
            return ' جمادى الثانية ';
        case '07' :
            return ' رجب ';
        case '08' :
            return ' شعبان ';
        case '09' :
            return ' رمضان ';
        case '10' :
            return ' شوال ';
        case '11' :
            return ' ذوالقعدة ';
        case '12' :
            return ' ذوالحجة ';
    }
}

const Faq = () => {
    const [emailIsLogin, setEmailIsLogin] = useState(false);
    const [passIsLogin, setPassIsLogin] = useState(false);
    const [idIsLogin, setIdIsLogin] = useState(false);
    const [userList, setUserList] = useState([]);
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        const getUserList = () => {
            if (idIsLogin != undefined) {
                fetch(`https://dashboard.aqaed.net/api/v1/archiveQuestionAnswer?field=order&orderType=asc&user_id=${idIsLogin}`)
                    .then(res => res.json())
                    .then(res => {
                        setUserList(res.data);
                        setLoading(true)
                    });
            }
        }
        getUserList();
    }, [idIsLogin]);

    useEffect(() => {
        const getUserList = () => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("email", emailIsLogin);
            formdata.append("password", passIsLogin);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            fetch(`https://dashboard.aqaed.net/api/v1/login`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    setIdIsLogin(result?.data?.id);
                });
        };
        const cookies = new Cookies();
        // setSuccessMessage("تم تسجيل الدخول بنجاح");
        // let code = email.value + "m/!@m" + pass;
        if ((cookies.get('myCat') != undefined)) {
            let login = atob(cookies.get('myCat'));
            let arr = login.split("m/!@m");
            setEmailIsLogin(arr[0]);
            setPassIsLogin(arr[1]);
            getUserList();
        }

    }, [emailIsLogin]);

    return (

     <div>
         <HeaderPage/>
         <section className="aq-menu-head filter-container-mt back-main-2">
             <div className="aq-container">
                 <div className="row">
                     <div className="w-200 width-0-phone"></div>
                     <div className="w-100-200 width-100-phone d-flex justify-content-between">
                         <div className="filter-container">
                             <div
                                 className="aq-container position-relative d-flex justify-content-between mb-0 align-items-center">
                                 <div className="question-container d-flex justify-content-end align-items-center">
                                     <svg className="show-o-container" width="50" height="50" viewBox="0 0 50 50"
                                          fill="none" xmlns="http://www.w3.org/2000/svg">
                                         <circle cx="25" cy="25" r="25" fill="#045E5E"/>
                                         <circle cx="25.311" cy="36.6153" r="1.05125" stroke="white"
                                                 strokeWidth="1.69624"/>
                                         <path
                                             d="M25.3075 30.9226V27.9394C25.3075 25.9541 26.5779 24.1916 28.4613 23.5638V23.5638C30.3447 22.936 31.615 21.1734 31.615 19.1882V18.1092C31.615 14.7352 28.8798 12 25.5058 12H25.3075C21.824 12 19 14.824 19 18.3075V18.3075"
                                             stroke="white" strokeWidth="1.69624"/>
                                     </svg>

                                     <div className="question-container-data">
                                         <h1 className="faq-title m-0"> مفضّلة الأسئلة والأجوبة </h1>
                                         <small className="faq-desc d-block">
                                             ترى هنا ما أرسلته من أسئلة وتعليقات
                                         </small>
                                     </div>
                                 </div>

                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </section>
         <section className="question-container-sec">
             <div className="question-answer-container py-5 position-relative">
                 {userList.length > 0 ?
                     userList.map((question, key) => (

                         <div
                             className={key == 0 ? "container d-flex justify-content-between align-items-center py-2 phone-m-t-5" : "container d-flex justify-content-between align-items-center py-2"}>
                             <div className="w-100">
                                 <div className="card-question">
                                     <div className="d-flex justify-content-between">
                                         <div className="right">
                                             <ul className="personal-information">
                                                 <li>
                                                     <svg className="mb-1 hidden-phone" width="21" height="21"
                                                          viewBox="0 0 21 21" fill="none"
                                                          xmlns="http://www.w3.org/2000/svg">
                                                         <path
                                                             d="M17.2712 17.5383C16.8914 16.4751 16.0544 15.5357 14.8901 14.8656C13.7258 14.1956 12.2993 13.8324 10.8317 13.8324C9.36415 13.8324 7.93759 14.1956 6.7733 14.8656C5.609 15.5357 4.77203 16.4751 4.3922 17.5383"
                                                             stroke="#045E5E" strokeLinecap="round"/>
                                                         <circle cx="10.8324" cy="7.16536" r="3.33333"
                                                                 stroke="#045E5E" strokeLinecap="round"/>
                                                     </svg>
                                                     {question.user?.country?.length>0?
                                                     <ReactCountryFlag className="hidden-desktop"
                                                                       countryCode={question.user?.country}
                                                                       svg
                                                                       style={{
                                                                           width: '1.4em',
                                                                           height: '1.4em',
                                                                       }}
                                                                       title={question.user?.country}
                                                                       alt={question.user?.country}
                                                     />
                                                         :""}
                                                     {question.user?.name + " " + question.user?.family}

                                                 </li>
                                                 <li className="hidden-phone">
                                                     {question.user?.country?.length>0?
                                                     <ReactCountryFlag
                                                         countryCode={question.user?.country}
                                                         svg
                                                         style={{
                                                             width: '1.4em',
                                                             height: '1.4em',
                                                         }}
                                                         title={question.user?.country}
                                                         alt={question.user?.country}
                                                     />
                                                         :""}
                                                     {countries.getName(question.user?.country, 'ar')}
                                                 </li>
                                                 {question.category != null ?
                                                     <li>
                                                         <NavLink to={`/category-faq/${question.category.id}`}
                                                                  title={question.category.title}>
                                                             <svg className="mb-1" width="20" height="20"
                                                                  viewBox="0 0 20 20" fill="none"
                                                                  xmlns="http://www.w3.org/2000/svg">
                                                                 <rect x="4.16699" y="3.3335" width="11.6667"
                                                                       height="14.1667" rx="2" stroke="#045E5E"/>
                                                                 <path d="M7.5 7.5H12.5" stroke="#045E5E"
                                                                       strokeLinecap="round"/>
                                                                 <path d="M7.5 10.8334H12.5" stroke="#045E5E"
                                                                       strokeLinecap="round"/>
                                                                 <path d="M7.5 14.1666H10.8333" stroke="#045E5E"
                                                                       strokeLinecap="round"/>
                                                             </svg>
                                                             {question.category.title}

                                                         </NavLink>
                                                     </li> : ""
                                                 }

                                                 <li title="تعليقاً وتعقيباً">
                                                     <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                          xmlns="http://www.w3.org/2000/svg">
                                                         <path
                                                             d="M16.1036 4.81481C16.6654 5.65555 16.6654 6.82594 16.6654 9.16671C16.6654 11.5075 16.6654 12.6779 16.1036 13.5186C15.8604 13.8826 15.5479 14.1951 15.1839 14.4383C14.4495 14.929 13.4634 14.9911 11.6654 14.9989V15L10.7441 16.8427C10.4369 17.4569 9.56045 17.4569 9.25334 16.8427L8.33203 15V14.9989C6.53395 14.9911 5.54792 14.929 4.81346 14.4383C4.4495 14.1951 4.13699 13.8826 3.8938 13.5186C3.33203 12.6779 3.33203 11.5075 3.33203 9.16671C3.33203 6.82594 3.33203 5.65555 3.8938 4.81481C4.13699 4.45084 4.4495 4.13834 4.81346 3.89514C5.65421 3.33337 6.82459 3.33337 9.16536 3.33337H10.832C13.1728 3.33337 14.3432 3.33337 15.1839 3.89514C15.5479 4.13834 15.8604 4.45084 16.1036 4.81481Z"
                                                             stroke="#045E5E" strokeLinecap="round"
                                                             strokeLinejoin="round"/>
                                                         <path d="M7.5 7.5L12.5 7.5" stroke="#045E5E"
                                                               strokeLinecap="round" strokeLinejoin="round"/>
                                                         <path d="M7.5 10.8334H10" stroke="#045E5E"
                                                               strokeLinecap="round" strokeLinejoin="round"/>
                                                     </svg>
                                                     <span className="number">
                                                    {question.comment_count}
                                                </span>

                                                 </li>
                                             </ul>
                                             <NavLink to={"/qa/" + question.id} title={question.title}>
                                                 <h3>{question.title.length > 120 ?
                                                     `${question.title.substring(0, 120)}...` : question.title
                                                 }</h3>
                                             </NavLink>
                                         </div>
                                         <div className="left hidden-phone">
                                             <svg className="mb-1 ms-1" width="19" height="18"
                                                  viewBox="0 0 19 18"
                                                  fill="none" xmlns="http://www.w3.org/2000/svg">
                                                 <path
                                                     d="M15.0417 3H3.95833C3.08388 3 2.375 3.67157 2.375 4.5V15C2.375 15.8284 3.08388 16.5 3.95833 16.5H15.0417C15.9161 16.5 16.625 15.8284 16.625 15V4.5C16.625 3.67157 15.9161 3 15.0417 3Z"
                                                     stroke="#045E5E" strokeLinecap="round"
                                                     strokeLinejoin="round"/>
                                                 <path d="M12.666 1.50012V4.50012" stroke="#045E5E"
                                                       strokeLinecap="round" strokeLinejoin="round"/>
                                                 <path d="M6.33398 1.50012V4.50012" stroke="#045E5E"
                                                       strokeLinecap="round" strokeLinejoin="round"/>
                                                 <path d="M2.375 7.5H16.625" stroke="#045E5E"
                                                       strokeLinecap="round"
                                                       strokeLinejoin="round"/>
                                             </svg>

                                             <div className="date">
                                                        <span
                                                            className="number"> {question.date_lunar ? question.date_lunar.split("-")[2] : "02"} </span>
                                                 {getMonthName(question.date_lunar ? question.date_lunar.split("-")[1] : "02")}
                                                 <span
                                                     className="number"> {question.date_lunar ? question.date_lunar.split("-")[0] : "1500"} </span>
                                             </div>

                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     ))
                     : <div className="d-flex justify-content-center w-100  py-2">
                             <span className="width-fit not-funded-filter">
                                لم يتم العثور على النتيجة المطلوبة
                            </span>
                     </div>}
             </div>
         </section>
         {
             login ? <GuidedForm/> : <GuidedNeedToLogin/>
         }
         <Footer/>
     </div>


    );
};
export default Faq;
