import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import {NavLink} from "react-router-dom";
import image from "../assets/uploads/book.jpg";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {useEffect, useState} from 'react';
import SwiperCore, {Autoplay} from 'swiper';
import {LazyLoadImage} from "react-lazy-load-image-component";
import React from "react";


const Books = () => {
    const [getCount, setCount] = useState(1);
    const [getBooks, setBooks] = useState([]);
    const [getGuidedBooks, setGuidedBooks] = useState([]);
    SwiperCore.use([Autoplay]);

    const handleClick = (id, e) => {
        setCount(id)
    };

    const fetchData = () => {
        fetch("https://dashboard.aqaed.net/api/v1/lastBooks/30")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setBooks(data.data)
            });
        fetch("https://dashboard.aqaed.net/api/v1/getAllBookMustabasrs?limit=10")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setGuidedBooks(data.data)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div className="books-module books-module-2 pb-34 position-relative">
            <div className="aq-container pt-4 d-flex justify-content-between align-items-center">
                <div className="title-container mt-5 pe-5 pb-4">
                    <NavLink to="/books" title="آخر الإصدارات والكتب">
                        <h2>
                            آخر الإصدارات والكتب
                        </h2>
                    </NavLink>

                    <p>
                        مكتبة الكترونية للكتب والمقالات والبحوث العقائدية
                    </p>
                </div>
                <div className="btn-navigation ps-5 navi-container-faq navi-container-media-2 float-left">
                    <nav className="nav tab-aqua navi fw-child-100">

                        {/*<button className={(getCount == 3)?"":"inactive"} data-toggle="tab" onClick={event => handleClick(3,event)} title="   كتب المستبصرين">*/}
                        {/*    كتب المستبصرين*/}
                        {/*</button>*/}

                        <button className={(getCount == 2) ? "" : "inactive"} data-toggle="tab"
                                onClick={event => handleClick(2, event)} title="المكتبة العقائدية">
                            المكتبة العقائدية
                        </button>

                        <button className={(getCount == 1) ? "" : "inactive"} data-toggle=".book-list-3"
                                onClick={event => handleClick(1, event)} title="الإصدارات">
                            الإصدارات
                        </button>
                        <div className="animation start-home"></div>
                        <div className="animation1"></div>
                    </nav>
                </div>
            </div>

            <div className={(getCount == 1) ? "aq-container book-container px-5 visible show" :
                "aq-container book-container px-5 visually-hidden fade"}>
                {getBooks.length > 0 ?
                    <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={50}
                        grabCursor={true}
                        loop={false}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: true,
                            pauseOnMouseEnter: true,
                        }}
                        dir="rtl"
                        breakpoints={{
                            0: {
                                spaceBetween: 15
                            },
                            900: {
                                spaceBetween: 50
                            }
                        }}
                        modules={[Navigation]}
                        navigation={true}
                        className="mySwiper">
                        {getBooks.map(booksPublication =>
                            booksPublication.type == "publication" ?
                                <SwiperSlide>
                                    <div className="only-home-page">
                                        <div className="book-image-container">
                                            <NavLink to={"/books/" + booksPublication.id}
                                                     title={booksPublication.title}>
                                                <LazyLoadImage className="books-image"  src={booksPublication.image}
                                                               alt={booksPublication.title} title={booksPublication.title}/>
                                            </NavLink>
                                        </div>
                                        <div className="book-data-container">
                                            <NavLink to={"/books/" + booksPublication.id}
                                                     title={booksPublication.title}>
                                                <h3>
                                                    {booksPublication.title?.length > 40 ?
                                                        `${booksPublication.title.substring(0, 40)}...` : booksPublication.title
                                                    }
                                                </h3>
                                            </NavLink>
                                            <NavLink
                                                to={`/books?tab=all&authorId=${booksPublication.author[0].id}&authorName=${booksPublication?.author[0]?.extra?.prefix ? booksPublication?.author[0]?.extra?.prefix + " " + booksPublication?.author[0]?.title : booksPublication?.author[0]?.title}`}
                                                reloadDocument
                                                title={booksPublication?.author[0]?.extra?.prefix ? booksPublication?.author[0]?.extra?.prefix + " " + booksPublication?.author[0]?.title : booksPublication?.author[0]?.title}>
                                                <p>
                                                    {booksPublication?.author[0]?.extra?.prefix ? booksPublication?.author[0]?.extra?.prefix + " " + booksPublication?.author[0]?.title : booksPublication?.author[0]?.title}
                                                </p>
                                            </NavLink>
                                        </div>
                                    </div>
                                </SwiperSlide> : ""
                        )}
                        <div className="more-btn-container d-flex justify-content-end mt--52">
                            <NavLink className="more-btn" to="/books?tab=publication">
                                المزيد
                            </NavLink>
                        </div>
                    </Swiper>
                    : ""}
            </div>

            <div
                className={(getCount == 2) ? "aq-container book-container px-5 visible show" : "aq-container book-container px-5 visually-hidden fade"}>
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={50}
                    grabCursor={true}
                    dir="rtl"
                    loop={false}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: true,
                        pauseOnMouseEnter: true,
                    }}
                    breakpoints={{
                        0: {
                            spaceBetween: 15
                        },
                        900: {
                            spaceBetween: 50
                        }
                    }}
                    modules={[Navigation]}
                    navigation={true}
                    className="mySwiper">
                    {getBooks.map(booksBook =>
                        booksBook.type == "book" ?
                            <SwiperSlide>
                                <div className="only-home-page">
                                    <div className="book-image-container">
                                        <NavLink to={"/books/" + booksBook.id} title={booksBook.title}>
                                            <LazyLoadImage className="books-image"  src={booksBook.image}
                                                           alt={booksBook.title} title={booksBook.title}/>
                                        </NavLink>
                                    </div>
                                    <div className="book-data-container">
                                        <NavLink to={"/books/" + booksBook.id} title={booksBook.title}>
                                            <h3>
                                                {booksBook.title?.length > 40 ?
                                                    `${booksBook.title.substring(0, 40)}...` : booksBook.title
                                                }
                                            </h3>
                                        </NavLink>
                                        <NavLink
                                            to={`/books?tab=all&authorId=${booksBook.author[0].id}&authorName=${booksBook?.author[0]?.extra?.prefix ? booksBook?.author[0]?.extra?.prefix + " " + booksBook?.author[0]?.title : booksBook?.author[0]?.title}`}
                                            reloadDocument
                                            title={booksBook?.author[0]?.extra?.prefix ? booksBook?.author[0]?.extra?.prefix + " " + booksBook?.author[0]?.title : booksBook?.author[0]?.title}>
                                            <p>
                                                {booksBook?.author[0]?.extra?.prefix ? booksBook?.author[0]?.extra?.prefix + " " + booksBook?.author[0]?.title : booksBook?.author[0]?.title}
                                            </p>
                                        </NavLink>
                                    </div>
                                </div>
                            </SwiperSlide> : ""
                    )}
                    <div className="more-btn-container d-flex justify-content-end mt--52">
                        <NavLink className="more-btn" to="/books?tab=aqBooks">
                            المزيد
                        </NavLink>
                    </div>
                </Swiper>
            </div>

            <div
                className={(getCount == 3) ? "aq-container book-container px-5 visible show" : "aq-container book-container px-5 visually-hidden fade"}>
                {getGuidedBooks.length > 0 ?
                    <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={50}
                        grabCursor={true}
                        dir="rtl"
                        loop={true}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: true,
                            pauseOnMouseEnter: true,
                        }}
                        breakpoints={{
                            0: {
                                spaceBetween: 15
                            },
                            900: {
                                spaceBetween: 50
                            }
                        }}
                        modules={[Navigation]}
                        navigation={true}
                        className="mySwiper">
                        {getGuidedBooks.map(guidedBooks =>
                            <SwiperSlide>
                                <div className="book-image-container">
                                    <NavLink to={"/books/" + guidedBooks.id} title={guidedBooks.title}>
                                        <LazyLoadImage className="books-image"  src={guidedBooks.image}
                                                       alt={guidedBooks.title} title={guidedBooks.title}/>
                                    </NavLink>
                                </div>
                                <div className="book-data-container">
                                    <NavLink to={"/books/" + guidedBooks.id} title={guidedBooks.title}>
                                        <h3>
                                            {guidedBooks.title?.length > 40 ?
                                                `${guidedBooks.title.substring(0, 40)}...` : guidedBooks.title
                                            }
                                        </h3>
                                    </NavLink>
                                    <NavLink
                                        to={`/books?tab=all&authorId=${guidedBooks.author[0].id}&authorName=${guidedBooks?.author[0]?.extra?.prefix ? guidedBooks?.author[0]?.extra?.prefix + " " + guidedBooks?.author[0]?.title : guidedBooks?.author[0]?.title}`}
                                        reloadDocument
                                        title={guidedBooks?.author[0]?.extra?.prefix ? guidedBooks?.author[0]?.extra?.prefix + " " + guidedBooks?.author[0]?.title : guidedBooks?.author[0]?.title}>
                                        <p>
                                            {guidedBooks?.author[0]?.extra?.prefix ? guidedBooks?.author[0]?.extra?.prefix + " " + guidedBooks?.author[0]?.title : guidedBooks?.author[0]?.title}
                                        </p>
                                    </NavLink>
                                </div>
                            </SwiperSlide>
                        )}
                        <div className="more-btn-container d-flex justify-content-end mt--52">
                            <NavLink className="more-btn" to="/books?tab=guided">
                                المزيد
                            </NavLink>
                        </div>
                    </Swiper>
                    : ""}
            </div>

            <div className="aq-container left-line-3"></div>
            <div className="aq-container left-line-4"></div>
        </div>
    );
};

export default Books;