import HeaderPage from "../components/HeaderPage";
import Footer from "../components/Footer";
import SeminarTextPage from "../components/SeminarTextPage";

const SeminarText = ({history}) => {
    return (
        <div>
            <HeaderPage />
            <SeminarTextPage />
            <Footer />
        </div>
    );
};

export default SeminarText;
