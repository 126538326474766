import React, {useRef, useState} from 'react';
import {NavLink} from "react-router-dom";
import image from "../assets/uploads/user.jpg";
import ImageUploading from 'react-images-uploading';
import {Select} from "antd";
import {useValidation} from "@enterwell/react-form-validation";
import {useEffect} from "react";
import Cookies from "universal-cookie";
import countries from "i18n-iso-countries";

const EditProfileModal = (props) => {

    const handleChangeName = (event) => {
        // 👇 Get input value from "event"
        setName(event.target.value);
    };

    const handleChangeFamily = (event) => {
        // 👇 Get input value from "event"
        setFamily(event.target.value);
    };

    const handleChangeReligion = (event) => {
        // 👇 Get input value from "event"
        setReligion(event.target.value);
    };

    const handleChangePass = (event) => {
        // 👇 Get input value from "event"
        if (event.target.value.length > 0)
            setInputTypeShow1(true)
        else
            setInputTypeShow1(false)

        setPass(event.target.value);
    };

    const handleChangePassCheck = (event) => {
        // 👇 Get input value from "event"
        if (event.target.value.length > 0)
            setInputTypeShow(true)
        else
            setInputTypeShow(false)

        setPassCheck(event.target.value);
    };

    const [images, setImages] = React.useState([]);
    const maxNumber = 1;

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        setUserImage(imageList[0]?.file)
        setImages(imageList);
    };

    const handleChangeCountry = (value) => {
        setUserCountry(value)
    };

    const emailValidation = (value) => /^.+@\S+\.\S+$/.test(value);
    const email = useValidation('', emailValidation);
    const email2 = useValidation('', emailValidation);

    const [otp, setOtp] = useState("");
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);

    const [passLogin, setPassLogin] = useState(0);

    const [inputType, setInputType] = useState('password');
    const [inputTypeShow, setInputTypeShow] = useState(false);

    const [inputType1, setInputType1] = useState('password');
    const [inputTypeShow1, setInputTypeShow1] = useState(false);

    const [inputType5, setInputType5] = useState('password');
    const [inputTypeShow5, setInputTypeShow5] = useState(false);

    const [userImage, setUserImage] = useState('');
    const [Name, setName] = useState(props?.userName);
    const [family, setFamily] = useState(props?.userLastName);
    const [userCountry, setUserCountry] = useState(props?.userCountry);
    const [religion, setReligion] = useState(props?.userReligionIsLogin);
    const [img, setImg] = useState(props?.userImage);
    const [pass, setPass] = useState('');
    const [passCheck, setPassCheck] = useState('');


    // last page err
    const [nameErr, setNameErr] = useState(false);
    const [familyErr, setFamilyErr] = useState(false);
    const [userCountryErr, setUserCountryErr] = useState(false);
    const [religionErr, setReligionErr] = useState(false);
    const [passErr, setPassErr] = useState(false);
    const [passCheckErr, setPassCheckErr] = useState(false);


    const [Changer, setChanger] = useState(false);


    const [code1, setCode1] = useState('');
    const [code2, setCode2] = useState('');
    const [code3, setCode3] = useState('');
    const [code4, setCode4] = useState('');
    const [tabPage, setTabPage] = useState(10);
    const [successMessage, setSuccessMessage] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showBorder, setShowBorder] = useState(false);
    const [showBorder1, setShowBorder1] = useState(false);
    const [userImageSrc, setUserImageSrc] = useState();

    const [vaErr, setVaErr] = useState();
    const [emailPage, setEmailPage] = useState();
    const [showItC, setShowItC] = useState();
    const handleNameChange1 = event => {
        const limit = 1;
        setCode1(event.target.value.slice(0, limit));
        if (event.target.value.length > 0) {
            inputElementRef1.current.focus();
        }
    };

    useEffect(() => {
        setName(props?.userName);
        setFamily(props?.userLastName);
        setUserCountry(props?.userCountry);
        setReligion(props?.userReligionIsLogin);
        setImg(props?.userImage);
    }, [props.userName]);


    useEffect(() => {
        if (tabPage == 11 || tabPage == 3) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                }

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(interval);
                    } else {
                        setSeconds(59);
                        setMinutes(minutes - 1);
                    }
                }
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [seconds, tabPage, Changer]);

    const handleNameChange2 = event => {
        const limit = 1;
        setCode2(event.target.value.slice(0, limit));
        if (event.target.value.length == 1) {
            inputElementRef2.current.focus();
        } else {
            inputElementRef.current.focus();
        }
    };
    const handleNameChange3 = event => {
        const limit = 1;
        setCode3(event.target.value.slice(0, limit));
        if (event.target.value.length == 1) {
            inputElementRef3.current.focus();
        } else {
            inputElementRef1.current.focus();
        }
    };
    const handleNameChange4 = event => {
        const limit = 1;
        setCode4(event.target.value.slice(0, limit));
        if (event.target.value.length != 1) {
            inputElementRef2.current.focus();
        }
    };

    const inputElementRef = useRef(null);
    const inputElementRef1 = useRef(null);
    const inputElementRef2 = useRef(null);
    const inputElementRef3 = useRef(null);

    let handleSubmitRegisterPage3 = async (e) => {
        // userImage
        e.preventDefault();

        // const [Name, setName] = useState('');
        // const [Family, setFamily] = useState('');
        // const [userCountry, setUserCountry] = useState();
        // const [religion, setReligion] = useState();
        // const [pass, setPass] = useState();
        // const [passCheck, setPassCheck] = useState();
        if (Name.length == 0) {
            setErrorMessage("ملء حقل الاسم إلزامي")
            setShowErrorMessage(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
            return false;
        }

        if (family.length == 0) {
            setErrorMessage("ملء حقل اسم العائلة إلزامي")
            setShowErrorMessage(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
            return false;
        }

        if (userCountry.length == 0) {
            setErrorMessage("ملء حقل البلد إلزامي")
            setShowErrorMessage(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
            return false;
        }

        if (pass.length != 0 || passCheck.length != 0) {

            if (passCheck.length == 0) {
                setErrorMessage("ملء حقل إعادة كلمة السر إلزامي")
                setShowErrorMessage(true);
                const timer = setTimeout(() => {
                    setShowErrorMessage(false);
                }, 5000);
                return false;
            }

            if (pass.length < 6) {
                setErrorMessage("عدد الأحرف المسموح بها لا يقل عن 6 أحرف")
                setShowErrorMessage(true);
                const timer = setTimeout(() => {
                    setShowErrorMessage(false);
                }, 5000);
                return false;
            }

            if (passCheck != pass) {
                setErrorMessage("كلمة المرور وتكرار كلمة المرور غير متطابقين")
                setShowErrorMessage(true);
                const timer = setTimeout(() => {
                    setShowErrorMessage(false);
                }, 5000);
                return false;
            }

        }

        let myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Cookie", "mylanguageClient=ar");

        let formdata1 = new FormData();
        // if (userImage)
        //     formdata1.append("avatar", userImage);
        formdata1.append("password", pass);
        formdata1.append("name", Name);
        formdata1.append("family", family);
        formdata1.append("religion", religion);
        if (userCountry)
            formdata1.append("country", userCountry);
        formdata1.append("email", props?.userEmailIsLogin);

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata1,
            redirect: 'follow'
        };

        fetch("https://dashboard.aqaed.net/api/v1/editeRegister", requestOptions)
            .then(response => response.text())
            .then(result => {
                const cookies = new Cookies();
                setSuccessMessage("تم تسجيلكم بنجاح");
                if (pass.length > 0) {
                    let code = props?.userEmailIsLogin + "m/!@m" + pass;
                    code = btoa(code);
                    cookies.set('myCat', code, {path: '/', maxAge: 31536000});
                }
                setShowSuccessMessage(true);
                const timer = setTimeout(() => {
                    setShowSuccessMessage(false);
                }, 5000);
                window.location.reload(true);
            });

    };

    return (<>
        {showSuccessMessage ?
            <div
                className={showSuccessMessage ? "d-flex position-fixed success-message" : "d-flex position-fixed success-message close"}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M12.2531 1.20251C6.37312 1.20251 1.45312 6.12251 1.45312 12.0025C1.45312 17.8825 6.37312 22.8025 12.2531 22.8025C18.1331 22.8025 23.0531 18.0025 23.0531 12.0025C23.0531 6.00251 18.2531 1.20251 12.2531 1.20251ZM10.6916 16.2055L6.85156 12.3655L8.05156 11.1655L10.6916 13.8055L16.4516 8.04553L17.6516 9.24553L10.6916 16.2055Z"
                          fill="white"/>
                    <path opacity="0.01" fillRule="evenodd" clipRule="evenodd"
                          d="M10.6916 16.202L6.85156 12.362L8.05156 11.162L10.6916 13.802L16.4516 8.04199L17.6516 9.24199L10.6916 16.202Z"
                          fill="white"/>
                </svg>
                <span className="me-1 text-white">
                       {successMessage}
                   </span>
            </div>
            : ""}

        {showErrorMessage ?
            <div
                className={errorMessage ? "d-flex bg-color-red  position-fixed success-message" : "d-flex bg-color-red position-fixed success-message close"}>
                <svg className="w-23" width="17" height="16" viewBox="0 0 17 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M8.95 0.794189C4.95 0.794189 1.75 3.99419 1.75 7.99419C1.75 11.9942 4.95 15.1942 8.95 15.1942C12.95 15.1942 16.15 11.9942 16.15 7.99419C16.15 3.99419 12.95 0.794189 8.95 0.794189ZM8.30928 3.98967H9.50928V9.58967H8.30928V3.98967ZM8.95007 12.7906C8.47007 12.7906 8.15007 12.4706 8.15007 11.9906C8.15007 11.5106 8.47007 11.1906 8.95007 11.1906C9.43007 11.1906 9.75007 11.5106 9.75007 11.9906C9.75007 12.4706 9.43007 12.7906 8.95007 12.7906Z"
                          fill="#fff"/>
                    <path opacity="0.01" fillRule="evenodd" clipRule="evenodd"
                          d="M8.30758 3.98886H9.50758V9.58886H8.30758V3.98886ZM8.94837 12.7898C8.46837 12.7898 8.14837 12.4698 8.14837 11.9898C8.14837 11.5098 8.46837 11.1898 8.94837 11.1898C9.42837 11.1898 9.74837 11.5098 9.74837 11.9898C9.74837 12.4698 9.42837 12.7898 8.94837 12.7898Z"
                          fill="#fff"/>
                </svg>
                <span className="me-1 text-white">
                    {errorMessage ?? "البريد الإلكتروني الذي تم إدخاله غير صحيح!"}
                </span>
            </div>
            : ""}
        <div className={props.show ? "login-modal login-modal-big" : "login-modal login-modal-big close"}>

            <button className="btn bg-transparent btn-close-modal" onClick={props.close} title="لإغلاق"
                    aria-label="لإغلاق">
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.5 6.5L6.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M6.5 6.5L19.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            </button>
            <div className="row">
                {/*<div className="col-md-4 phone-mt-32 position-relative">*/}
                {/*    <ImageUploading*/}
                {/*        multiple*/}
                {/*        value={images}*/}
                {/*        onChange={onChange}*/}
                {/*        maxNumber={maxNumber}*/}
                {/*        dataURLKey="data_url"*/}
                {/*    >*/}
                {/*        {({*/}
                {/*              imageList,*/}
                {/*              onImageUpload,*/}
                {/*              onImageRemoveAll,*/}
                {/*              onImageUpdate,*/}
                {/*              onImageRemove,*/}
                {/*              isDragging,*/}
                {/*              dragProps,*/}
                {/*          }) => (*/}
                {/*            // write your building UI*/}
                {/*            <div className="upload__image-wrapper">*/}
                {/*                <div className="image-uploader"*/}
                {/*                     style={isDragging ? {color: 'red'} : undefined}*/}
                {/*                     onClick={onImageUpload}*/}
                {/*                     {...dragProps}*/}
                {/*                >*/}

                {/*                    {img?*/}
                {/*                        <div className="image-item ">*/}
                {/*                            <img width={147} height={147} className="user-image-change transform-0-imp" src={img} alt={Name} title={Name} />*/}
                {/*                            <div className="image-item__btn-wrapper">*/}
                {/*                                <div className="d-block position-relative" onClick={() => setImg("")}>*/}
                {/*                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*                                        <circle cx="15.8549" cy="15.8549" r="15.8549" fill="white"/>*/}
                {/*                                        <path d="M10 12.25H22" stroke="#045E5E" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*                                        <path d="M14.5 15.25V19.75" stroke="#045E5E" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*                                        <path d="M17.5 15.25V19.75" stroke="#045E5E" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*                                        <path d="M10.75 12.25L11.5 21.25C11.5 21.6478 11.658 22.0294 11.9393 22.3107C12.2206 22.592 12.6022 22.75 13 22.75H19C19.3978 22.75 19.7794 22.592 20.0607 22.3107C20.342 22.0294 20.5 21.6478 20.5 21.25L21.25 12.25" stroke="#045E5E" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*                                        <path d="M13.75 12.25V10C13.75 9.80109 13.829 9.61032 13.9697 9.46967C14.1103 9.32902 14.3011 9.25 14.5 9.25H17.5C17.6989 9.25 17.8897 9.32902 18.0303 9.46967C18.171 9.61032 18.25 9.80109 18.25 10V12.25" stroke="#045E5E" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*                                    </svg>*/}

                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}

                {/*                        :*/}
                {/*                        <svg width="170" height="170" viewBox="0 0 170 170" fill="none"*/}
                {/*                             xmlns="http://www.w3.org/2000/svg">*/}
                {/*                            <circle cx="85" cy="85" r="83.5833" fill="white" stroke="#C7C7C7"*/}
                {/*                                    strokeWidth="2.83333"/>*/}
                {/*                            <path*/}
                {/*                                d="M111.048 113.503C109.487 109.135 106.049 105.275 101.265 102.523C96.482 99.7699 90.6213 98.2778 84.592 98.2778C78.5628 98.2778 72.702 99.7699 67.9187 102.523C63.1354 105.275 59.6969 109.135 58.1364 113.503"*/}
                {/*                                stroke="#737373" strokeWidth="3.97581" strokeLinecap="round"/>*/}
                {/*                            <circle cx="84.5773" cy="70.8889" r="13.6944" stroke="#737373"*/}
                {/*                                    strokeWidth="3.97581" strokeLinecap="round"/>*/}
                {/*                            <circle cx="141.855" cy="147.855" r="15.8549" fill="#D7D7D7"/>*/}
                {/*                            <path*/}
                {/*                                d="M134.586 146.122C134.586 145.295 135.256 144.625 136.083 144.625V144.625C136.65 144.625 137.168 144.305 137.422 143.798L138.085 142.472C138.134 142.373 138.159 142.323 138.185 142.279C138.481 141.764 139.016 141.433 139.609 141.399C139.66 141.396 139.716 141.396 139.827 141.396H143.879C143.99 141.396 144.045 141.396 144.097 141.399C144.689 141.433 145.225 141.764 145.521 142.279C145.546 142.323 145.571 142.373 145.621 142.472L146.284 143.798C146.537 144.305 147.056 144.625 147.623 144.625V144.625C148.449 144.625 149.12 145.295 149.12 146.122V150.162C149.12 151.571 149.12 152.275 148.858 152.818C148.598 153.358 148.163 153.793 147.624 154.053C147.08 154.314 146.376 154.314 144.967 154.314H138.738C137.33 154.314 136.625 154.314 136.082 154.053C135.543 153.793 135.107 153.358 134.848 152.818C134.586 152.275 134.586 151.571 134.586 150.162V146.122Z"*/}
                {/*                                stroke="#838383" strokeWidth="0.880829"/>*/}
                {/*                            <circle cx="141.855" cy="148.662" r="2.78929" stroke="#838383"*/}
                {/*                                    strokeWidth="0.880829"/>*/}
                {/*                            <g clipPath="url(#clip0_674_27291)">*/}
                {/*                                <rect x="143.617" y="150.497" width="6.1658" height="6.1658" rx="3.0829"*/}
                {/*                                      fill="#D9D9D9"/>*/}
                {/*                                <path d="M146.703 151.333V155.829" stroke="#838383"*/}
                {/*                                      strokeWidth="0.880829" strokeLinecap="round"*/}
                {/*                                      strokeLinejoin="round"/>*/}
                {/*                                <path d="M144.453 153.58H148.949" stroke="#838383"*/}
                {/*                                      strokeWidth="0.880829" strokeLinecap="round"*/}
                {/*                                      strokeLinejoin="round"/>*/}
                {/*                            </g>*/}
                {/*                            <defs>*/}
                {/*                                <clipPath id="clip0_674_27291">*/}
                {/*                                    <rect x="143.617" y="150.497" width="6.1658" height="6.1658"*/}
                {/*                                          rx="3.0829" fill="white"/>*/}
                {/*                                </clipPath>*/}
                {/*                            </defs>*/}
                {/*                        </svg>*/}
                {/*                    }*/}


                {/*                    {imageList.map((image, index) => (*/}
                {/*                        <div key={index} className="image-item">*/}
                {/*                            <img src={image['data_url']} alt="" width="100"/>*/}
                {/*                            <div className="image-item__btn-wrapper">*/}
                {/*                                <div className="d-block position-relative" onClick={() => onImageRemove(index)}>*/}
                {/*                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*                                        <circle cx="15.8549" cy="15.8549" r="15.8549" fill="white"/>*/}
                {/*                                        <path d="M10 12.25H22" stroke="#045E5E" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*                                        <path d="M14.5 15.25V19.75" stroke="#045E5E" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*                                        <path d="M17.5 15.25V19.75" stroke="#045E5E" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*                                        <path d="M10.75 12.25L11.5 21.25C11.5 21.6478 11.658 22.0294 11.9393 22.3107C12.2206 22.592 12.6022 22.75 13 22.75H19C19.3978 22.75 19.7794 22.592 20.0607 22.3107C20.342 22.0294 20.5 21.6478 20.5 21.25L21.25 12.25" stroke="#045E5E" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*                                        <path d="M13.75 12.25V10C13.75 9.80109 13.829 9.61032 13.9697 9.46967C14.1103 9.32902 14.3011 9.25 14.5 9.25H17.5C17.6989 9.25 17.8897 9.32902 18.0303 9.46967C18.171 9.61032 18.25 9.80109 18.25 10V12.25" stroke="#045E5E" strokeWidth="0.75" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*                                    </svg>*/}

                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    ))}*/}
                {/*                </div>*/}

                {/*            </div>*/}
                {/*        )}*/}
                {/*    </ImageUploading>*/}
                {/*    <p className="m-0 max-width-30 position-relative name-input-user d-flex align-items-center">*/}
                {/*        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*            <circle cx="16.5034" cy="15.8549" r="15.8549" fill="#045E5E"/>*/}
                {/*            <path d="M9.23438 14.1223C9.23438 13.2955 9.90463 12.6252 10.7314 12.6252V12.6252C11.2985 12.6252 11.8168 12.3049 12.0704 11.7977L12.7332 10.4721C12.7828 10.3729 12.8076 10.3233 12.8333 10.2787C13.1293 9.76407 13.6647 9.43319 14.2574 9.39853C14.3088 9.39552 14.3643 9.39552 14.4751 9.39552H18.5273C18.6382 9.39552 18.6936 9.39552 18.745 9.39853C19.3377 9.43319 19.8731 9.76407 20.1692 10.2787C20.1948 10.3233 20.2196 10.3729 20.2692 10.4721L20.932 11.7977C21.1856 12.3049 21.704 12.6252 22.271 12.6252V12.6252C23.0978 12.6252 23.7681 13.2955 23.7681 14.1223V18.1619C23.7681 19.5706 23.7681 20.275 23.5064 20.8184C23.2466 21.3577 22.8115 21.7929 22.2721 22.0527C21.7287 22.3143 21.0243 22.3143 19.6156 22.3143H13.3869C11.9781 22.3143 11.2737 22.3143 10.7303 22.0527C10.191 21.7929 9.7558 21.3577 9.49606 20.8184C9.23438 20.275 9.23438 19.5706 9.23438 18.1619V14.1223Z" stroke="white" stroke-width="0.880829"/>*/}
                {/*            <circle cx="16.5031" cy="16.6624" r="2.78929" stroke="white" stroke-width="0.880829"/>*/}
                {/*            <g clip-path="url(#clip0_623_12350)">*/}
                {/*                <rect x="18.2656" y="18.4974" width="6.1658" height="6.1658" rx="3.0829" fill="#045E5E"/>*/}
                {/*                <path d="M21.3438 19.3329V23.8288" stroke="white" stroke-width="0.880829" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*                <path d="M19.1016 21.5803H23.5975" stroke="white" stroke-width="0.880829" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*            </g>*/}
                {/*            <defs>*/}
                {/*                <clipPath id="clip0_623_12350">*/}
                {/*                    <rect x="18.2656" y="18.4974" width="6.1658" height="6.1658" rx="3.0829" fill="white"/>*/}
                {/*                </clipPath>*/}
                {/*            </defs>*/}
                {/*        </svg>*/}
                {/*        <span className="w-100-30">*/}
                {/*            {Name + " " + family}*/}
                {/*        </span>*/}

                {/*    </p>*/}
                {/*</div>*/}
                <div className="col-md-12 px-sm-5  mt-sm-3 mt-0 phone-px-2 position-relative">
                    <p className="mt-150 text-end mt-5 phone-center mb-0">تحرير المعلومات</p>
                    <p className="disc-14 text-end phone-center mb-0">يمكنكم تغيير المعلومات الخاصة بكم</p>
                    <form className="row  text-center h-100 form-container mt-2 form-container-c-black"
                          onSubmit={handleSubmitRegisterPage3}>
                        <div className="col-md-6 mt-sm-3 mt-4 d-inline-flex ">
                            <input className="input-border-bottom w-100" required value={Name ?? ""}
                                   onChange={handleChangeName} type="text" maxLength="100"
                                   placeholder="الاسم"/>
                        </div>
                        <div className="col-md-6 mt-sm-3 mt-4 d-inline-flex ">
                            <input className="input-border-bottom w-100" required value={family}
                                   onChange={handleChangeFamily} type="text" maxLength="100"
                                   placeholder="اسم العائلة"/>
                        </div>
                        <div className="col-md-6 d-flex mx-auto mt-sm-3 mt-4 child-3-m-0">
                            <Select
                                showSearch
                                placeholder={countries.getName(userCountry ?? "ir", 'ar')}
                                onChange={handleChangeCountry}
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                options={[
                                    {
                                        value: "AD",
                                        label: "أندورا",
                                    },
                                    {
                                        value: "AE",
                                        label: "الامارات العربية المتحدة",
                                    },
                                    {
                                        value: "AF",
                                        label: "أفغانستان",
                                    },
                                    {
                                        value: "AG",
                                        label: "أنتيجوا وبربودا",
                                    },
                                    {
                                        value: "AI",
                                        label: "أنجويلا",
                                    },
                                    {
                                        value: "AL",
                                        label: "ألبانيا",
                                    },
                                    {
                                        value: "AM",
                                        label: "أرمينيا",
                                    },
                                    {
                                        value: "AO",
                                        label: "أنجولا",
                                    },
                                    {
                                        value: "AQ",
                                        label: "القطب الجنوبي",
                                    },
                                    {
                                        value: "AR",
                                        label: "الأرجنتين",
                                    },
                                    {
                                        value: "AS",
                                        label: "ساموا الأمريكية",
                                    },
                                    {
                                        value: "AT",
                                        label: "النمسا",
                                    },
                                    {
                                        value: "AU",
                                        label: "أستراليا",
                                    },
                                    {
                                        value: "AW",
                                        label: "آروبا",
                                    },
                                    {
                                        value: "AX",
                                        label: "جزر أولان",
                                    },
                                    {
                                        value: "AZ",
                                        label: "أذربيجان",
                                    },
                                    {
                                        value: "BA",
                                        label: "البوسنة والهرسك",
                                    },
                                    {
                                        value: "BB",
                                        label: "بربادوس",
                                    },
                                    {
                                        value: "BD",
                                        label: "بنجلاديش",
                                    },
                                    {
                                        value: "BE",
                                        label: "بلجيكا",
                                    },
                                    {
                                        value: "BF",
                                        label: "بوركينا فاسو",
                                    },
                                    {
                                        value: "BG",
                                        label: "بلغاريا",
                                    },
                                    {
                                        value: "BH",
                                        label: "البحرين",
                                    },
                                    {
                                        value: "BI",
                                        label: "بوروندي",
                                    },
                                    {
                                        value: "BJ",
                                        label: "بنين",
                                    },
                                    {
                                        value: "BL",
                                        label: "سان بارتيلمي",
                                    },
                                    {
                                        value: "BM",
                                        label: "برمودا",
                                    },
                                    {
                                        value: "BN",
                                        label: "بروناي",
                                    },
                                    {
                                        value: "BO",
                                        label: "بوليفيا",
                                    },
                                    {
                                        value: "BQ",
                                        label: "بونير",
                                    },
                                    {
                                        value: "BR",
                                        label: "البرازيل",
                                    },
                                    {
                                        value: "BS",
                                        label: "الباهاما",
                                    },
                                    {
                                        value: "BT",
                                        label: "بوتان",
                                    },
                                    {
                                        value: "BV",
                                        label: "جزيرة بوفيه",
                                    },
                                    {
                                        value: "BW",
                                        label: "بتسوانا",
                                    },
                                    {
                                        value: "BY",
                                        label: "روسيا البيضاء",
                                    },
                                    {
                                        value: "BZ",
                                        label: "بليز",
                                    },
                                    {
                                        value: "CA",
                                        label: "كندا",
                                    },
                                    {
                                        value: "CC",
                                        label: "جزر كوكوس",
                                    },
                                    {
                                        value: "CD",
                                        label: "جمهورية الكونغو الديمقراطية",
                                    },
                                    {
                                        value: "CF",
                                        label: "جمهورية افريقيا الوسطى",
                                    },
                                    {
                                        value: "CG",
                                        label: "الكونغو - برازافيل",
                                    },
                                    {
                                        value: "CH",
                                        label: "سويسرا",
                                    },
                                    {
                                        value: "CI",
                                        label: "ساحل العاج",
                                    },
                                    {
                                        value: "CK",
                                        label: "جزر كوك",
                                    },
                                    {
                                        value: "CL",
                                        label: "شيلي",
                                    },
                                    {
                                        value: "CM",
                                        label: "الكاميرون",
                                    },
                                    {
                                        value: "CN",
                                        label: "الصين",
                                    },
                                    {
                                        value: "CO",
                                        label: "كولومبيا",
                                    },
                                    {
                                        value: "CR",
                                        label: "كوستاريكا",
                                    },
                                    {
                                        value: "CU",
                                        label: "كوبا",
                                    },
                                    {
                                        value: "CV",
                                        label: "الرأس الأخضر",
                                    },
                                    {
                                        value: "CW",
                                        label: "كوراساو",
                                    },
                                    {
                                        value: "CX",
                                        label: "جزيرة الكريسماس",
                                    },
                                    {
                                        value: "CY",
                                        label: "قبرص",
                                    },
                                    {
                                        value: "CZ",
                                        label: "جمهورية التشيك",
                                    },
                                    {
                                        value: "DE",
                                        label: "ألمانيا",
                                    },
                                    {
                                        value: "DJ",
                                        label: "جيبوتي",
                                    },
                                    {
                                        value: "DK",
                                        label: "الدانمرك",
                                    },
                                    {
                                        value: "DM",
                                        label: "دومينيكا",
                                    },
                                    {
                                        value: "DO",
                                        label: "جمهورية الدومينيك",
                                    },
                                    {
                                        value: "DZ",
                                        label: "الجزائر",
                                    },
                                    {
                                        value: "EC",
                                        label: "الاكوادور",
                                    },
                                    {
                                        value: "EE",
                                        label: "استونيا",
                                    },
                                    {
                                        value: "EG",
                                        label: "مصر",
                                    },
                                    {
                                        value: "EH",
                                        label: "الصحراء الغربية",
                                    },
                                    {
                                        value: "ER",
                                        label: "اريتريا",
                                    },
                                    {
                                        value: "ES",
                                        label: "أسبانيا",
                                    },
                                    {
                                        value: "ET",
                                        label: "اثيوبيا",
                                    },
                                    {
                                        value: "FI",
                                        label: "فنلندا",
                                    },
                                    {
                                        value: "FJ",
                                        label: "فيجي",
                                    },
                                    {
                                        value: "FK",
                                        label: "جزر فوكلاند",
                                    },
                                    {
                                        value: "FM",
                                        label: "ميكرونيزيا",
                                    },
                                    {
                                        value: "FO",
                                        label: "جزر فارو",
                                    },
                                    {
                                        value: "FR",
                                        label: "فرنسا",
                                    },
                                    {
                                        value: "GA",
                                        label: "الجابون",
                                    },
                                    {
                                        value: "GB",
                                        label: "المملكة المتحدة",
                                    },
                                    {
                                        value: "GD",
                                        label: "جرينادا",
                                    },
                                    {
                                        value: "GE",
                                        label: "جورجيا",
                                    },
                                    {
                                        value: "GF",
                                        label: "غويانا",
                                    },
                                    {
                                        value: "GG",
                                        label: "غيرنزي",
                                    },
                                    {
                                        value: "GH",
                                        label: "غانا",
                                    },
                                    {
                                        value: "GI",
                                        label: "جبل طارق",
                                    },
                                    {
                                        value: "GL",
                                        label: "جرينلاند",
                                    },
                                    {
                                        value: "GM",
                                        label: "غامبيا",
                                    },
                                    {
                                        value: "GN",
                                        label: "غينيا",
                                    },
                                    {
                                        value: "GP",
                                        label: "جوادلوب",
                                    },
                                    {
                                        value: "GQ",
                                        label: "غينيا الاستوائية",
                                    },
                                    {
                                        value: "GR",
                                        label: "اليونان",
                                    },
                                    {
                                        value: "GS",
                                        label: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
                                    },
                                    {
                                        value: "GT",
                                        label: "جواتيمالا",
                                    },
                                    {
                                        value: "GU",
                                        label: "جوام",
                                    },
                                    {
                                        value: "GW",
                                        label: "غينيا بيساو",
                                    },
                                    {
                                        value: "GY",
                                        label: "غيانا",
                                    },
                                    {
                                        value: "HK",
                                        label: "هونج كونج الصينية",
                                    },
                                    {
                                        value: "HM",
                                        label: "جزيرة هيرد وماكدونالد",
                                    },
                                    {
                                        value: "HN",
                                        label: "هندوراس",
                                    },
                                    {
                                        value: "HR",
                                        label: "كرواتيا",
                                    },
                                    {
                                        value: "HT",
                                        label: "هايتي",
                                    },
                                    {
                                        value: "HU",
                                        label: "المجر",
                                    },
                                    {
                                        value: "ID",
                                        label: "اندونيسيا",
                                    },
                                    {
                                        value: "IE",
                                        label: "أيرلندا",
                                    },
                                    {
                                        value: "IL",
                                        label: "اسرائيل",
                                    },
                                    {
                                        value: "IM",
                                        label: "جزيرة مان",
                                    },
                                    {
                                        value: "IN",
                                        label: "الهند",
                                    },
                                    {
                                        value: "IO",
                                        label: "المحيط الهندي البريطاني",
                                    },
                                    {
                                        value: "IQ",
                                        label: "العراق",
                                    },
                                    {
                                        value: "IR",
                                        label: "ايران",
                                    },
                                    {
                                        value: "IS",
                                        label: "أيسلندا",
                                    },
                                    {
                                        value: "IT",
                                        label: "ايطاليا",
                                    },
                                    {
                                        value: "JE",
                                        label: "جيرسي",
                                    },
                                    {
                                        value: "JM",
                                        label: "جامايكا",
                                    },
                                    {
                                        value: "JO",
                                        label: "الأردن",
                                    },
                                    {
                                        value: "JP",
                                        label: "اليابان",
                                    },
                                    {
                                        value: "KE",
                                        label: "كينيا",
                                    },
                                    {
                                        value: "KG",
                                        label: "قرغيزستان",
                                    },
                                    {
                                        value: "KH",
                                        label: "كمبوديا",
                                    },
                                    {
                                        value: "KI",
                                        label: "كيريباتي",
                                    },
                                    {
                                        value: "KM",
                                        label: "جزر القمر",
                                    },
                                    {
                                        value: "KN",
                                        label: "سانت كيتس ونيفيس",
                                    },
                                    {
                                        value: "KP",
                                        label: "كوريا الشمالية",
                                    },
                                    {
                                        value: "KR",
                                        label: "كوريا الجنوبية",
                                    },
                                    {
                                        value: "KW",
                                        label: "الكويت",
                                    },
                                    {
                                        value: "KY",
                                        label: "جزر الكايمن",
                                    },
                                    {
                                        value: "KZ",
                                        label: "كازاخستان",
                                    },
                                    {
                                        value: "LA",
                                        label: "لاوس",
                                    },
                                    {
                                        value: "LB",
                                        label: "لبنان",
                                    },
                                    {
                                        value: "LC",
                                        label: "سانت لوسيا",
                                    },
                                    {
                                        value: "LI",
                                        label: "ليختنشتاين",
                                    },
                                    {
                                        value: "LK",
                                        label: "سريلانكا",
                                    },
                                    {
                                        value: "LR",
                                        label: "ليبيريا",
                                    },
                                    {
                                        value: "LS",
                                        label: "ليسوتو",
                                    },
                                    {
                                        value: "LT",
                                        label: "ليتوانيا",
                                    },
                                    {
                                        value: "LU",
                                        label: "لوكسمبورج",
                                    },
                                    {
                                        value: "LV",
                                        label: "لاتفيا",
                                    },
                                    {
                                        value: "LY",
                                        label: "ليبيا",
                                    },
                                    {
                                        value: "MA",
                                        label: "المغرب",
                                    },
                                    {
                                        value: "MC",
                                        label: "موناكو",
                                    },
                                    {
                                        value: "MD",
                                        label: "مولدافيا",
                                    },
                                    {
                                        value: "ME",
                                        label: "الجبل الأسود",
                                    },
                                    {
                                        value: "MF",
                                        label: "سانت مارتين",
                                    },
                                    {
                                        value: "MG",
                                        label: "مدغشقر",
                                    },
                                    {
                                        value: "MH",
                                        label: "جزر المارشال",
                                    },
                                    {
                                        value: "MK",
                                        label: "مقدونيا",
                                    },
                                    {
                                        value: "ML",
                                        label: "مالي",
                                    },
                                    {
                                        value: "MM",
                                        label: "ميانمار",
                                    },
                                    {
                                        value: "MN",
                                        label: "منغوليا",
                                    },
                                    {
                                        value: "MO",
                                        label: "ماكاو الصينية",
                                    },
                                    {
                                        value: "MP",
                                        label: "جزر ماريانا الشمالية",
                                    },
                                    {
                                        value: "MQ",
                                        label: "مارتينيك",
                                    },
                                    {
                                        value: "MR",
                                        label: "موريتانيا",
                                    },
                                    {
                                        value: "MS",
                                        label: "مونتسرات",
                                    },
                                    {
                                        value: "MT",
                                        label: "مالطا",
                                    },
                                    {
                                        value: "MU",
                                        label: "موريشيوس",
                                    },
                                    {
                                        value: "MV",
                                        label: "جزر الملديف",
                                    },
                                    {
                                        value: "MW",
                                        label: "ملاوي",
                                    },
                                    {
                                        value: "MX",
                                        label: "المكسيك",
                                    },
                                    {
                                        value: "MY",
                                        label: "ماليزيا",
                                    },
                                    {
                                        value: "MZ",
                                        label: "موزمبيق",
                                    },
                                    {
                                        value: "NA",
                                        label: "ناميبيا",
                                    },
                                    {
                                        value: "NC",
                                        label: "كاليدونيا الجديدة",
                                    },
                                    {
                                        value: "NE",
                                        label: "النيجر",
                                    },
                                    {
                                        value: "NF",
                                        label: "جزيرة نورفوك",
                                    },
                                    {
                                        value: "NG",
                                        label: "نيجيريا",
                                    },
                                    {
                                        value: "NI",
                                        label: "نيكاراجوا",
                                    },
                                    {
                                        value: "NL",
                                        label: "هولندا",
                                    },
                                    {
                                        value: "NO",
                                        label: "النرويج",
                                    },
                                    {
                                        value: "NP",
                                        label: "نيبال",
                                    },
                                    {
                                        value: "NR",
                                        label: "نورو",
                                    },
                                    {
                                        value: "NU",
                                        label: "نيوي",
                                    },
                                    {
                                        value: "NZ",
                                        label: "نيوزيلاندا",
                                    },
                                    {
                                        value: "OM",
                                        label: "عمان",
                                    },
                                    {
                                        value: "PA",
                                        label: "بنما",
                                    },
                                    {
                                        value: "PE",
                                        label: "بيرو",
                                    },
                                    {
                                        value: "PF",
                                        label: "بولينيزيا الفرنسية",
                                    },
                                    {
                                        value: "PG",
                                        label: "بابوا غينيا الجديدة",
                                    },
                                    {
                                        value: "PH",
                                        label: "الفيلبين",
                                    },
                                    {
                                        value: "PK",
                                        label: "باكستان",
                                    },
                                    {
                                        value: "PL",
                                        label: "بولندا",
                                    },
                                    {
                                        value: "PM",
                                        label: "سانت بيير وميكولون",
                                    },
                                    {
                                        value: "PN",
                                        label: "بتكايرن",
                                    },
                                    {
                                        value: "PR",
                                        label: "بورتوريكو",
                                    },
                                    {
                                        value: "PS",
                                        label: "فلسطين",
                                    },
                                    {
                                        value: "PT",
                                        label: "البرتغال",
                                    },
                                    {
                                        value: "PW",
                                        label: "بالاو",
                                    },
                                    {
                                        value: "PY",
                                        label: "باراجواي",
                                    },
                                    {
                                        value: "QA",
                                        label: "قطر",
                                    },
                                    {
                                        value: "RE",
                                        label: "روينيون",
                                    },
                                    {
                                        value: "RO",
                                        label: "رومانيا",
                                    },
                                    {
                                        value: "RS",
                                        label: "صربيا",
                                    },
                                    {
                                        value: "RU",
                                        label: "روسيا",
                                    },
                                    {
                                        value: "RW",
                                        label: "رواندا",
                                    },
                                    {
                                        value: "SA",
                                        label: "المملكة العربية السعودية",
                                    },
                                    {
                                        value: "SB",
                                        label: "جزر سليمان",
                                    },
                                    {
                                        value: "SC",
                                        label: "سيشل",
                                    },
                                    {
                                        value: "SD",
                                        label: "السودان",
                                    },
                                    {
                                        value: "SE",
                                        label: "السويد",
                                    },
                                    {
                                        value: "SG",
                                        label: "سنغافورة",
                                    },
                                    {
                                        value: "SH",
                                        label: "سانت هيلنا",
                                    },
                                    {
                                        value: "SI",
                                        label: "سلوفينيا",
                                    },
                                    {
                                        value: "SJ",
                                        label: "سفالبارد وجان مايان",
                                    },
                                    {
                                        value: "SK",
                                        label: "سلوفاكيا",
                                    },
                                    {
                                        value: "SL",
                                        label: "سيراليون",
                                    },
                                    {
                                        value: "SM",
                                        label: "سان مارينو",
                                    },
                                    {
                                        value: "SN",
                                        label: "السنغال",
                                    },
                                    {
                                        value: "SO",
                                        label: "الصومال",
                                    },
                                    {
                                        value: "SR",
                                        label: "سورينام",
                                    },
                                    {
                                        value: "SS",
                                        label: "جنوب السودان",
                                    },
                                    {
                                        value: "ST",
                                        label: "ساو تومي وبرينسيبي",
                                    },
                                    {
                                        value: "SV",
                                        label: "السلفادور",
                                    },
                                    {
                                        value: "SX",
                                        label: "سينت مارتن",
                                    },
                                    {
                                        value: "SY",
                                        label: "سوريا",
                                    },
                                    {
                                        value: "SZ",
                                        label: "سوازيلاند",
                                    },
                                    {
                                        value: "TC",
                                        label: "جزر الترك وجايكوس",
                                    },
                                    {
                                        value: "TD",
                                        label: "تشاد",
                                    },
                                    {
                                        value: "TF",
                                        label: "المقاطعات الجنوبية الفرنسية",
                                    },
                                    {
                                        value: "TG",
                                        label: "توجو",
                                    },
                                    {
                                        value: "TH",
                                        label: "تايلند",
                                    },
                                    {
                                        value: "TJ",
                                        label: "طاجكستان",
                                    },
                                    {
                                        value: "TK",
                                        label: "توكيلو",
                                    },
                                    {
                                        value: "TL",
                                        label: "تيمور الشرقية",
                                    },
                                    {
                                        value: "TM",
                                        label: "تركمانستان",
                                    },
                                    {
                                        value: "TN",
                                        label: "تونس",
                                    },
                                    {
                                        value: "TO",
                                        label: "تونجا",
                                    },
                                    {
                                        value: "TR",
                                        label: "تركيا",
                                    },
                                    {
                                        value: "TT",
                                        label: "ترينيداد وتوباغو",
                                    },
                                    {
                                        value: "TV",
                                        label: "توفالو",
                                    },
                                    {
                                        value: "TW",
                                        label: "تايوان",
                                    },
                                    {
                                        value: "TZ",
                                        label: "تانزانيا",
                                    },
                                    {
                                        value: "UA",
                                        label: "أوكرانيا",
                                    },
                                    {
                                        value: "UG",
                                        label: "أوغندا",
                                    },
                                    {
                                        value: "UM",
                                        label: "جزر الولايات المتحدة البعيدة الصغيرة",
                                    },
                                    {
                                        value: "US",
                                        label: "الولايات المتحدة الأمريكية",
                                    },
                                    {
                                        value: "UY",
                                        label: "أورجواي",
                                    },
                                    {
                                        value: "UZ",
                                        label: "أوزبكستان",
                                    },
                                    {
                                        value: "VA",
                                        label: "الفاتيكان",
                                    },
                                    {
                                        value: "VC",
                                        label: "سانت فنسنت وغرنادين",
                                    },
                                    {
                                        value: "VE",
                                        label: "فنزويلا",
                                    },
                                    {
                                        value: "VG",
                                        label: "جزر فرجين البريطانية",
                                    },
                                    {
                                        value: "VI",
                                        label: "جزر فرجين الأمريكية",
                                    },
                                    {
                                        value: "VN",
                                        label: "فيتنام",
                                    },
                                    {
                                        value: "VU",
                                        label: "فانواتو",
                                    },
                                    {
                                        value: "WF",
                                        label: "جزر والس وفوتونا",
                                    },
                                    {
                                        value: "WS",
                                        label: "ساموا",
                                    },
                                    {
                                        value: "XK",
                                        label: "كوسوفو",
                                    },
                                    {
                                        value: "YE",
                                        label: "اليمن",
                                    },
                                    {
                                        value: "YT",
                                        label: "مايوت",
                                    },
                                    {
                                        value: "ZA",
                                        label: "جمهورية جنوب افريقيا",
                                    },
                                    {
                                        value: "ZM",
                                        label: "زامبيا",
                                    },
                                    {
                                        value: "ZW",
                                        label: "زيمبابوي",
                                    }
                                ]}/>
                        </div>
                        <div className="col-md-6 mt-sm-3 mt-4 d-inline-flex">
                            <input className="input-border-bottom w-100" required value={religion}
                                   onChange={handleChangeReligion} type="text" maxLength="100"
                                   placeholder="الدين و المدهب"/>
                        </div>
                        <div className="col-md-6 mt-sm-3 mt-4 d-flex mx-auto">
                            <input className="input-border-bottom pass-input w-100" onChange={handleChangePass}
                                   type={inputType1} maxLength="20"
                                   placeholder="كلمة السر"/>
                            <div
                                className={inputTypeShow1 ? "position-absolute show-icon-pass-right" : "position-absolute show-icon-pass-right d-none"}>
                        <span className={inputType1 == "password" ? "change-input-type" : "change-input-type d-none"}
                              onClick={() => setInputType1('text')}>
                           <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.75 9C0.75 9 3.75 3 9 3C14.25 3 17.25 9 17.25 9C17.25 9 14.25 15 9 15C3.75 15 0.75 9 0.75 9Z"
                                stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
                                stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                                <span
                                    className={inputType1 != "password" ? "change-input-type" : "change-input-type d-none"}
                                    onClick={() => setInputType1('password')}>
                       <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.93916 7.94025C7.6579 8.22162 7.49993 8.60318 7.5 9.00102C7.50007 9.39885 7.65818 9.78037 7.93954 10.0616C8.2209 10.3429 8.60247 10.5009 9.0003 10.5008C9.39814 10.5007 9.77965 10.3426 10.0609 10.0613M12.5107 12.5048C11.4587 13.163 10.241 13.5082 9 13.5C6.3 13.5 4.05 12 2.25 9C3.204 7.41 4.284 6.2415 5.49 5.4945M7.635 4.635C8.08428 4.54405 8.54161 4.49882 9 4.5C11.7 4.5 13.95 6 15.75 9C15.2505 9.8325 14.7157 10.5502 14.1465 11.1525M2.25 2.25L15.75 15.75"
                            stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                            </div>
                        </div>
                        <div className="col-md-6 mt-sm-3 mt-4 d-flex mx-auto">
                            <input className="input-border-bottom pass-input w-100" onChange={handleChangePassCheck}
                                   type={inputType} maxLength="20"
                                   placeholder="إعادة كلمة السر"/>
                            <div
                                className={inputTypeShow ? "position-absolute show-icon-pass-right" : "position-absolute show-icon-pass-right d-none"}>
                        <span className={inputType == "password" ? "change-input-type" : "change-input-type d-none"}
                              onClick={() => setInputType('text')}>
                           <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.75 9C0.75 9 3.75 3 9 3C14.25 3 17.25 9 17.25 9C17.25 9 14.25 15 9 15C3.75 15 0.75 9 0.75 9Z"
                                stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path
                                d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
                                stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                                <span
                                    className={inputType != "password" ? "change-input-type" : "change-input-type d-none"}
                                    onClick={() => setInputType('password')}>
                       <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.93916 7.94025C7.6579 8.22162 7.49993 8.60318 7.5 9.00102C7.50007 9.39885 7.65818 9.78037 7.93954 10.0616C8.2209 10.3429 8.60247 10.5009 9.0003 10.5008C9.39814 10.5007 9.77965 10.3426 10.0609 10.0613M12.5107 12.5048C11.4587 13.163 10.241 13.5082 9 13.5C6.3 13.5 4.05 12 2.25 9C3.204 7.41 4.284 6.2415 5.49 5.4945M7.635 4.635C8.08428 4.54405 8.54161 4.49882 9 4.5C11.7 4.5 13.95 6 15.75 9C15.2505 9.8325 14.7157 10.5502 14.1465 11.1525M2.25 2.25L15.75 15.75"
                            stroke="#B5B5B5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                            </div>
                        </div>
                        <div className="d-flex mt-sm-3 mt-4 justify-content-end">
                            <div className="d-block">
                                <button type="submit" className="btn border-0 more-btn fw-light"
                                        aria-label="request for login">
                                    تنفيذ
                                </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>


        <div className={props.show ? "overlay-modal" : "overlay-modal close"}></div>
    </>);
};

export default EditProfileModal;
