import ReactCountryFlag from "react-country-flag";
import countries from "i18n-iso-countries";
import React, {useState} from "react";
import {useEffect} from "react";
import Cookies from "universal-cookie";
import {Select} from "antd";
import {useUser} from "../services/UserContext";

const Forms = (props) => {

    const [getFormTab, setFormTab] = useState(1);
    const [successMessage, setSuccessMessage] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [emailIsLogin, setEmailIsLogin] = useState(false);
    const [passIsLogin, setPassIsLogin] = useState(false);
    const [idIsLogin, setIdIsLogin] = useState(false);
    const [nameIsLogin, setNameIsLogin] = useState(false);
    const [lastNameIsLogin, setLastNameIsLogin] = useState(false);
    const [CountryIsLogin, setCountryIsLogin] = useState(false);
    const [religionIsLogin, setReligionIsLogin] = useState(false);
    const [getMaxHeight, setMaxHeight] = useState(false);
    const [getShowBtn, setShowBtn] = useState(false);
    const [getContributeTitle, setContributeTitle] = useState('');
    const [getContributeBody, setContributeBody] = useState('');
    const [getContributePublic, setContributePublic] = useState(true);
    const { userData, setLoginData } = useUser();

    const handleSubmitContribute = (event) => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        var formdata = new FormData();
        formdata.append("title", getContributeTitle);
        formdata.append("body", getContributeBody);
        formdata.append("public", getContributePublic);
        formdata.append("user_id", parseInt(idIsLogin));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch("https://dashboard.aqaed.net/api/v1/addArticle", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result['status'] == 200) {
                    setSuccessMessage("تم إرسال المعلومات بنجاح");
                    setShowSuccessMessage(true);
                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);

                    setContributeTitle("")
                    setContributeBody("")
                    setContributePublic("")


                    const inputElements = document.querySelectorAll('input');
                    const inputElements1 = document.querySelectorAll('textarea');
                    inputElements1.forEach(input => {
                        input.value = '';
                    });
                    inputElements.forEach(input => {
                        input.value = '';
                    });
                    return () => clearTimeout(timer);
                } else {
                    setErrorMessage("للأسف ، لم تتم معالجة طلبك. يرجى المحاولة مرة أخرى لاحقًا")
                    setShowErrorMessage(true);
                    const timer = setTimeout(() => {
                        setShowErrorMessage(false);
                    }, 5000);
                    return () => clearTimeout(timer);
                }

            });
    }
    useEffect(() => {
        const getUserList = () => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("email", emailIsLogin);
            formdata.append("password", passIsLogin);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            setIdIsLogin(userData?.id);
            setNameIsLogin(userData?.name);
            setLastNameIsLogin(userData?.family);
            setCountryIsLogin(userData?.country);
            setReligionIsLogin(userData?.previous_religion);
        };
        const cookies = new Cookies();
        // setSuccessMessage("تم تسجيل الدخول بنجاح");
        // let code = email.value + "m/!@m" + pass;
        if ((cookies.get('myCat') != undefined)) {
            let login = atob(cookies.get('myCat'));
            let arr = login.split("m/!@m");
            setEmailIsLogin(arr[0]);
            setPassIsLogin(arr[1]);
            getUserList();
        }

    }, [emailIsLogin,userData]);

    useEffect(() => {
        setFormTab(props.page)
    }, [props.page]);
    const [sub, setSub] = useState([]);
    const [defSub, setDefSub] = useState('');
    const [defSubTitle, setDefSubTitle] = useState('');

    const [getContributePublicUser, setContributePublicUser] = useState(true);
    const [getContributePublicFAQ, setContributePublicFAQ] = useState(true);
    const [getQuestion, setQuestion] = useState("");

    useEffect(() => {
        const getUserList = () => {
            fetch(`https://dashboard.aqaed.net/api/v1/getCategoriesItemsQuestion?field=lft&orderType=ASC`)
                .then(res => res.json())
                .then(res => {
                    setDefSub(res.data[0].id);
                    setDefSubTitle(res.data[0].title);


                    const results = []
                    const data2 = res.data;
                    // Store results in the results array
                    data2.forEach((value) => {
                        results.push({
                            value: value.id,
                            label: value.title,
                        });
                    });
                    // Update the options state
                    setSub(results)

                });
        }
        getUserList();
    }, []);

    useEffect(() => {
        const getUserList = () => {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("email", emailIsLogin);
            formdata.append("password", passIsLogin);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };
            setIdIsLogin(userData?.id);
            setNameIsLogin(userData?.name);
            setLastNameIsLogin(userData?.family);
            setCountryIsLogin(userData?.country);
            setReligionIsLogin(userData?.previous_religion);

        };
        const cookies = new Cookies();
        // setSuccessMessage("تم تسجيل الدخول بنجاح");
        // let code = email.value + "m/!@m" + pass;
        if ((cookies.get('myCat') != undefined)) {
            let login = atob(cookies.get('myCat'));
            let arr = login.split("m/!@m");
            setEmailIsLogin(arr[0]);
            setPassIsLogin(arr[1]);
            getUserList();
        }

    }, [emailIsLogin,userData]);

    const handleChangeSub = (value) => {
        setDefSub(value)
    };

    const sendQuestion = (event) => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Cookie", "mylanguageClient=ar");

        var formdata = new FormData();
        formdata.append("user_id", parseInt(idIsLogin));
        formdata.append("question", getQuestion);
        getContributePublicFAQ ?
            formdata.append("private", "yes")
            :
            formdata.append("private", "no")
        formdata.append("category", defSubTitle);
        getContributePublicUser ?
            formdata.append("show_name_and_family", "no") :
            formdata.append("show_name_and_family", "yes")

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://dashboard.aqaed.net/api/v1/addQA", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result['status'] == 200) {
                    setSuccessMessage("تم إرسال المعلومات بنجاح");
                    setShowSuccessMessage(true);
                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);
                    setQuestion("");
                    const inputElements = document.querySelectorAll('input');
                    const inputElements1 = document.querySelectorAll('textarea');


                    setQuestion("");
                    setDefSubTitle("");
                    setQuestion("");
                    inputElements1.forEach(input => {
                        input.value = '';
                    });
                    inputElements.forEach(input => {
                        input.value = '';
                    });
                    return () => clearTimeout(timer);
                } else {
                    setErrorMessage("للأسف ، لم تتم معالجة طلبك. يرجى المحاولة مرة أخرى لاحقًا")
                    setShowErrorMessage(true);
                    const timer = setTimeout(() => {
                        setShowErrorMessage(false);
                    }, 5000);
                    return () => clearTimeout(timer);
                }

            });
    }


    const [getInstitutionsTitle, setInstitutionsTitle] = useState('');
    const [getInstitutionsCountry, setInstitutionsCountry] = useState('');
    const [getInstitutionsBody, setInstitutionsBody] = useState('');
    const [getInstitutionsCity, setInstitutionsCity] = useState('');
    const [getInstitutionsAddress, setInstitutionsAddress] = useState('');
    const [getInstitutionsFacs, setInstitutionsFacs] = useState('');
    const [getInstitutionsEmail, setInstitutionsEmail] = useState('');
    const [getInstitutionsManager, setInstitutionsManager] = useState('');
    const [getName, setName] = useState("");
    const [getEmail, setEmail] = useState("");
    const [getTitle, setTitle] = useState("");
    const [getBody, setBody] = useState("");
    const [getReligion, setReligion] = useState("");
    const [getCountry, setCountry] = useState("");
    const [getTypeInput, setTypeInput] = useState('');
    const [getTypeInput1, setTypeInput1] = useState('');
    const [getShiaTitle, setShiaTitle] = useState('');
    const [getShiaCountry, setShiaCountry] = useState('');
    const [getShiaBody, setShiaBody] = useState('');
    const [getShiaEntryDate, setShiaEntryDate] = useState('');
    const [getShiaNumber, setShiaNumber] = useState('');
    const [getPlaceResidence, setPlaceResidence] = useState('');
    const [getNeeded, setNeeded] = useState('');


    const [getGuidedCountry, setGuidedCountry] = useState('');
    const [getGuidedHistory, setGuidedHistory] = useState('');
    const [getGuidedScienceRanking, setGuidedScienceRanking] = useState('');
    const [getGuidedDateBirth, setGuidedDateBirth] = useState('');
    const [getGuidedPlaceBirth, setGuidedPlaceBirth] = useState('');
    const [getGuidedClairvoyantWritings, setGuidedClairvoyantWritings] = useState('');
    const [getGuidedDescription, setGuidedDescription] = useState('');
    const [getGuidedPublic, setGuidedPublic] = useState(false);

    const handleSubmitGuided = (event) => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Cookie", "mylanguageClient=ar");
        if (getGuidedCountry.length == 0 || getGuidedPlaceBirth.length == 0) {
            setErrorMessage("حقل الموقع إلزامي")
            setShowErrorMessage(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
        var formdata = new FormData();
        formdata.append("country_astbasar", getGuidedCountry);
        formdata.append("history_astbasar", getGuidedHistory);
        formdata.append("science_ranking", getGuidedScienceRanking);
        formdata.append("date_birth", getGuidedDateBirth);
        formdata.append("place_birth", getGuidedPlaceBirth);
        formdata.append("user_id", parseInt(idIsLogin));
        formdata.append("clairvoyant_writings", getGuidedClairvoyantWritings);
        formdata.append("description", getGuidedDescription);
        formdata.append("public", getGuidedPublic);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://dashboard.aqaed.net/api/v1/addMustabsar", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result['status'] == 200) {
                    setSuccessMessage("تم إرسال المعلومات بنجاح");
                    setShowSuccessMessage(true);
                    const inputElements = document.querySelectorAll('input');
                    const inputElements1 = document.querySelectorAll('textarea');
                    inputElements1.forEach(input => {
                        input.value = '';
                    });
                    setGuidedCountry("")
                    setGuidedHistory('')
                    setGuidedScienceRanking('')
                    setGuidedDateBirth('')
                    setGuidedPlaceBirth('')
                    setGuidedClairvoyantWritings('')
                    setGuidedDescription('')
                    inputElements.forEach(input => {
                        input.value = '';
                    });

                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);

                    }, 5000);

                    return () => clearTimeout(timer);
                } else {

                    setGuidedCountry('');
                    setGuidedHistory('');
                    setGuidedScienceRanking('');
                    setGuidedDateBirth('');
                    setGuidedPlaceBirth('');
                    setGuidedClairvoyantWritings('');
                    setGuidedDescription('');

                    setErrorMessage("للأسف ، لم تتم معالجة طلبك. يرجى المحاولة مرة أخرى لاحقًا")
                    setShowErrorMessage(true);
                    const timer = setTimeout(() => {
                        setShowErrorMessage(false);
                    }, 5000);
                    return () => clearTimeout(timer);
                }
            });
    }

    const handleChangeCountry25 = (value) => {
        setGuidedCountry(value)
    };

    const handleChangeCountry27 = (value) => {
        setGuidedPlaceBirth(value)
    };


    const handleChangeCountry22 = (value) => {
        setInstitutionsCountry(value)
    };
    const handleChangeCountry21 = (value) => {
        setShiaCountry(value)
    };
    const handleSubmitInstitutions = (event) => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Cookie", "mylanguageClient=ar");

        if (getInstitutionsCountry.length == 0) {
            setErrorMessage("حقل الموقع إلزامي")
            setShowErrorMessage(true);
            const timer = setTimeout(() => {
                setShowErrorMessage(false);
            }, 5000);
            return () => clearTimeout(timer);
        }

        var formdata = new FormData();
        formdata.append("title", getInstitutionsTitle);
        getInstitutionsCountry.length > 0 ?
            formdata.append("country", getInstitutionsCountry) :
            formdata.append("country", "IR")
        formdata.append("body", getInstitutionsBody);
        formdata.append("user", parseInt(idIsLogin));
        formdata.append("city", getInstitutionsCity);
        formdata.append("address", getInstitutionsAddress);
        formdata.append("facs", getInstitutionsFacs);
        formdata.append("email", getInstitutionsEmail);
        formdata.append("manager", getInstitutionsManager);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://dashboard.aqaed.net/api/v1/addInstitutes", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result['status'] == 200) {
                    setSuccessMessage("تم إرسال المعلومات بنجاح");
                    setShowSuccessMessage(true);
                    setInstitutionsBody('');
                    setInstitutionsCity('');
                    setInstitutionsAddress('');
                    setInstitutionsFacs('');
                    setInstitutionsEmail('');
                    setInstitutionsManager('');

                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);
                    const inputElements = document.querySelectorAll('input');
                    const inputElements1 = document.querySelectorAll('textarea');
                    inputElements1.forEach(input => {
                        input.value = '';
                    });
                    inputElements.forEach(input => {
                        input.value = '';
                    });
                    return () => clearTimeout(timer);
                } else {
                    setErrorMessage("للأسف ، لم تتم معالجة طلبك. يرجى المحاولة مرة أخرى لاحقًا")
                    setShowErrorMessage(true);
                    const timer = setTimeout(() => {
                        setShowErrorMessage(false);
                    }, 5000);
                    return () => clearTimeout(timer);
                }

            });
    }
    const handleSubmitShia = (event) => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Cookie", "mylanguageClient=ar");

        var formdata = new FormData();
        formdata.append("title", getShiaTitle);
        formdata.append("body", getShiaBody);
        getShiaCountry.length > 0 ?
            formdata.append("country", getShiaCountry) :
            formdata.append("country", "IR")
        formdata.append("user", parseInt(idIsLogin));
        formdata.append("entry_date", getShiaEntryDate);
        formdata.append("number", getShiaNumber);
        formdata.append("place_residence", getPlaceResidence);
        formdata.append("needed", getNeeded);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://dashboard.aqaed.net/api/v1/addShia", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result['status'] == 200) {
                    setSuccessMessage("تم إرسال المعلومات بنجاح");
                    setShowSuccessMessage(true);
                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);


                    setShiaTitle('');
                    setShiaCountry('');
                    setShiaBody('');
                    setShiaEntryDate('');
                    setShiaNumber('');
                    setPlaceResidence('');
                    setNeeded('');


                    const inputElements = document.querySelectorAll('input');
                    const inputElements1 = document.querySelectorAll('textarea');
                    inputElements1.forEach(input => {
                        input.value = '';
                    });
                    inputElements.forEach(input => {
                        input.value = '';
                    });
                    return () => clearTimeout(timer);
                } else {
                    setErrorMessage("للأسف ، لم تتم معالجة طلبك. يرجى المحاولة مرة أخرى لاحقًا")
                    setShowErrorMessage(true);
                    const timer = setTimeout(() => {
                        setShowErrorMessage(false);
                    }, 5000);
                    return () => clearTimeout(timer);
                }

            });
    }
    const [getSiteName, setSiteName] = useState('');
    const [getSiteBody, setSiteBody] = useState('');
    const [getSiteLink, setSiteLink] = useState('');
    const [getSiteLanguage, setSiteLanguage] = useState('');
    const [getSiteSocFacebook, setSiteSocFacebook] = useState('');
    const [getSiteSocYoutube, setSiteSocYoutube] = useState('');
    const [getSiteSocTwitter, setSiteSocTwitter] = useState('');
    const [getSiteSocInstagram, setSiteSocInstagram] = useState('');
    const [getSiteSocWhatsapp, setSiteSocWhatsapp] = useState('');
    const [getSiteSocTelegram, setSiteSocTelegram] = useState('');
    const handleSubmitSite = (event) => {
        event.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Cookie", "mylanguageClient=ar");

        var formdata = new FormData();
        formdata.append("title", getSiteName);
        formdata.append("body", getSiteBody);
        formdata.append("user", parseInt(idIsLogin));
        formdata.append("link_site", getSiteLink);
        formdata.append("languages", getSiteLanguage);
        formdata.append("facebook", getSiteSocFacebook);
        formdata.append("youtube", getSiteSocYoutube);
        formdata.append("twitter", getSiteSocTwitter);
        formdata.append("instagram", getSiteSocInstagram);
        formdata.append("whatsapp", getSiteSocWhatsapp);
        formdata.append("telegram", getSiteSocTelegram);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://dashboard.aqaed.net/api/v1/addSite", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result['status'] == 200) {
                    setSuccessMessage("تم إرسال المعلومات بنجاح");
                    setShowSuccessMessage(true);
                    setSiteName('');
                    setSiteBody('');
                    setSiteLink('');
                    setSiteLanguage('');
                    setSiteSocFacebook('');
                    setSiteSocYoutube('');
                    setSiteSocTwitter('');
                    setSiteSocInstagram('');
                    setSiteSocWhatsapp('');
                    setSiteSocTelegram('');

                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);
                    const inputElements = document.querySelectorAll('input');
                    const inputElements1 = document.querySelectorAll('textarea');
                    inputElements1.forEach(input => {
                        input.value = '';
                    });
                    inputElements.forEach(input => {
                        input.value = '';
                    });
                    return () => clearTimeout(timer);
                } else {
                    setErrorMessage("للأسف ، لم تتم معالجة طلبك. يرجى المحاولة مرة أخرى لاحقًا")
                    setShowErrorMessage(true);
                    const timer = setTimeout(() => {
                        setShowErrorMessage(false);
                    }, 5000);
                    return () => clearTimeout(timer);
                }

            });
    }
    let handleSubmitContactUs = async (e) => {
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Cookie", "mylanguageClient=ar");

        var formdata = new FormData();
        formdata.append("name", getName);
        formdata.append("email", getEmail);
        formdata.append("title", getTitle);
        formdata.append("body", getBody);
        formdata.append("religion", getReligion);
        formdata.append("country", getCountry);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch("https://dashboard.aqaed.net/api/v1/addContactUses", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result['status'] == 200) {

                    setName("");
                    setEmail("");
                    setTitle("");
                    setBody("");
                    setReligion("");

                    setSuccessMessage("تم إرسال المعلومات بنجاح");
                    setShowSuccessMessage(true);
                    const timer = setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 5000);
                    const inputElements = document.querySelectorAll('input');
                    const inputElements1 = document.querySelectorAll('textarea');
                    inputElements1.forEach(input => {
                        input.value = '';
                    });
                    inputElements.forEach(input => {
                        input.value = '';
                    });
                    return () => clearTimeout(timer);
                } else {
                    setErrorMessage("للأسف ، لم تتم معالجة طلبك. يرجى المحاولة مرة أخرى لاحقًا")
                    setShowErrorMessage(true);
                    const timer = setTimeout(() => {
                        setShowErrorMessage(false);
                    }, 5000);
                    return () => clearTimeout(timer);
                }
            });
    };
    const handleChangeCountry = (value) => {
        setCountry(value)
    };
    return (
        <>
            {showSuccessMessage ?
                <div
                    className={showSuccessMessage ? "d-flex position-fixed success-message" : "d-flex position-fixed success-message close"}>
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M12.2531 1.20251C6.37312 1.20251 1.45312 6.12251 1.45312 12.0025C1.45312 17.8825 6.37312 22.8025 12.2531 22.8025C18.1331 22.8025 23.0531 18.0025 23.0531 12.0025C23.0531 6.00251 18.2531 1.20251 12.2531 1.20251ZM10.6916 16.2055L6.85156 12.3655L8.05156 11.1655L10.6916 13.8055L16.4516 8.04553L17.6516 9.24553L10.6916 16.2055Z"
                              fill="white"/>
                        <path opacity="0.01" fillRule="evenodd" clipRule="evenodd"
                              d="M10.6916 16.202L6.85156 12.362L8.05156 11.162L10.6916 13.802L16.4516 8.04199L17.6516 9.24199L10.6916 16.202Z"
                              fill="white"/>
                    </svg>
                    <span className="me-1 text-white">
                       {successMessage}
                   </span>
                </div>
                : ""}

            {showErrorMessage ?
                <div
                    className={errorMessage ? "d-flex bg-color-red  position-fixed success-message" : "d-flex bg-color-red position-fixed success-message close"}>
                    <svg className="w-23" width="17" height="16" viewBox="0 0 17 16" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M8.95 0.794189C4.95 0.794189 1.75 3.99419 1.75 7.99419C1.75 11.9942 4.95 15.1942 8.95 15.1942C12.95 15.1942 16.15 11.9942 16.15 7.99419C16.15 3.99419 12.95 0.794189 8.95 0.794189ZM8.30928 3.98967H9.50928V9.58967H8.30928V3.98967ZM8.95007 12.7906C8.47007 12.7906 8.15007 12.4706 8.15007 11.9906C8.15007 11.5106 8.47007 11.1906 8.95007 11.1906C9.43007 11.1906 9.75007 11.5106 9.75007 11.9906C9.75007 12.4706 9.43007 12.7906 8.95007 12.7906Z"
                              fill="#fff"/>
                        <path opacity="0.01" fillRule="evenodd" clipRule="evenodd"
                              d="M8.30758 3.98886H9.50758V9.58886H8.30758V3.98886ZM8.94837 12.7898C8.46837 12.7898 8.14837 12.4698 8.14837 11.9898C8.14837 11.5098 8.46837 11.1898 8.94837 11.1898C9.42837 11.1898 9.74837 11.5098 9.74837 11.9898C9.74837 12.4698 9.42837 12.7898 8.94837 12.7898Z"
                              fill="#fff"/>
                    </svg>
                    <span className="me-1 text-white">
                    {errorMessage ?? "البريد الإلكتروني الذي تم إدخاله غير صحيح!"}
                </span>
                </div>
                : ""}
            <div className={props.show ? "forms-module d-flex" : "forms-module d-flex close"}>
                <button onClick={props.close} className="btn bg-transparent btn-close-modal change-close-space"
                        title="لإغلاق"
                        aria-label="لإغلاق">
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19.5 6.5L6.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M6.5 6.5L19.5 19.5" stroke="#990000" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>
                <div className="header-tab">
                    <ul className="form-right-side">
                        <li>
                            <button type="button"
                                    className={getFormTab == 1 ? "btn bg-transparent fw-light selected" : "btn bg-transparent fw-light"}
                                    onClick={() => setFormTab(1)}>
                                إرسال الأسئلة العقائدية

                            </button>
                        </li>
                        <li>
                            <button type="button"
                                    className={getFormTab == 2 ? "btn bg-transparent fw-light selected" : "btn bg-transparent fw-light"}
                                    onClick={() => setFormTab(2)}>
                                مساهمات المستبصرين
                            </button>
                        </li>
                        <li>
                            <button type="button"
                                    className={getFormTab == 3 ? "btn bg-transparent fw-light selected" : "btn bg-transparent fw-light"}
                                    onClick={() => setFormTab(3)}>
                                إعلان الاستبصار
                            </button>
                        </li>
                        <li>
                            <button type="button"
                                    className={getFormTab == 4 ? "btn bg-transparent fw-light selected" : "btn bg-transparent fw-light"}
                                    onClick={() => setFormTab(4)}> معلوماتك بشأن الشيعة في العالم
                            </button>
                        </li>
                        <li>
                            <button type="button"
                                    className={getFormTab == 5 ? "btn bg-transparent fw-light selected" : "btn bg-transparent fw-light"}
                                    onClick={() => setFormTab(5)}>عرِّف بمؤسسة شيعية
                            </button>
                        </li>
                        <li>
                            <button type="button"
                                    className={getFormTab == 6 ? "btn bg-transparent fw-light selected" : "btn bg-transparent fw-light"}
                                    onClick={() => setFormTab(6)}>أضف موقعاً شيعياً
                            </button>
                        </li>
                        <li>
                            <button type="button"
                                    className={getFormTab == 7 ? "btn bg-transparent fw-light selected" : "btn bg-transparent fw-light"}
                                    onClick={() => setFormTab(7)}>تواصَل معناً
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="form-body position-relative">
                    {getFormTab == 1 ?
                        <div className="form-body-item ">
                            <div className="form-title mt-3 pt-1">
                                أرسل سوالاً عقائدياً
                            </div>
                            <div className="form-disc">
                                إن كان عندك سؤال أو استفسار بشأن عقائد مذهب أهل البيت(عليهم السلام)، يمكنك ذكره
                                هنا، وستجد الإجابة الوافية إن شاء الله تعالى
                            </div>
                            <div className="user-name-container mt-5 c-999 d-sm-flex d-inline-block">
                                <svg className=" ms-sm-2 phone-m-auto ms-0 d-sm-inline-block d-block w-35"
                                     width="35" height="43" viewBox="0 0 56 55"
                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="28.0237" cy="27.4924" r="27.4924" fill="#045E5E"/>
                                    <path
                                        d="M37.7361 37.4626C37.1431 35.8027 35.8363 34.3359 34.0185 33.2897C32.2007 32.2436 29.9734 31.6765 27.6821 31.6765C25.3908 31.6765 23.1635 32.2436 21.3457 33.2897C19.5279 34.3359 18.2211 35.8026 17.6281 37.4626"
                                        stroke="white" strokeOpacity="0.9" strokeWidth="1.54887"
                                        strokeLinecap="round"/>
                                    <circle cx="27.6809" cy="21.2673" r="5.20433" stroke="white"
                                            strokeOpacity="0.9"
                                            strokeWidth="1.54887" strokeLinecap="round"/>
                                </svg>
                                <span className="f-16" title="محمد ابراهيم سليماني">
                                            {
                                                nameIsLogin + " " + lastNameIsLogin
                                            }
                                        </span>
                                <div
                                    className="d-flex justify-content-center align-items-center gap-3 religion-container-11">
                                    <div
                                        className="d-flex align-items-center phone-jus-center phone-m-b-2 me-sm-5 me-0">
                                        <ReactCountryFlag
                                            countryCode={CountryIsLogin}
                                            svg
                                            style={{
                                                width: '1.6em',
                                                height: '1.6em',
                                                borderRadius: '8px'
                                            }}
                                            title={countries.getName(CountryIsLogin, 'ar')}
                                            alt={countries.getName(CountryIsLogin, 'ar')}
                                        />
                                        <span className="pe-2">{countries.getName(CountryIsLogin, 'ar')}</span>
                                    </div>
                                    <div className="date-container-news text-right me-sm-5 me-0 pb-0">
                                        <svg className="ms-2" width="26" height="26" viewBox="0 0 26 26" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M21.137 21.298H7.52466C6.62485 19.7033 6.47368 17.8228 7.14314 16.1351C8.3021 13.2247 10.9368 10.9189 14.3272 10.0883C17.7177 10.9189 20.3524 13.2247 21.5114 16.1351C22.188 17.8228 22.0368 19.7033 21.137 21.298Z"
                                                stroke="#737373" strokeWidth="0.9" stroke-miterlimit="10"
                                                strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M8.58166 6.38051H4.86723C4.62248 5.94195 4.57929 5.43031 4.76645 4.97182C5.08318 4.17444 5.80303 3.54984 6.73164 3.31727C7.66025 3.54319 8.3801 4.17444 8.69683 4.97182C8.8696 5.43031 8.82641 5.9486 8.58166 6.38051Z"
                                                stroke="#737373" strokeWidth="0.9" stroke-miterlimit="10"
                                                strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M7.14342 16.135C7.47456 15.2978 7.93526 14.5137 8.48954 13.7961V6.38051H4.95508V21.298H7.51775C6.61793 19.7033 6.46676 17.8228 7.14342 16.135Z"
                                                stroke="#737373" strokeWidth="0.9" stroke-miterlimit="10"
                                                strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M14.3262 10.0883V8.2743" stroke="#737373"
                                                  stroke-miterlimit="10"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M6.72656 3.31728V1.5099" stroke="#737373" strokeWidth="0.9"
                                                  stroke-miterlimit="10" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path d="M22.9658 21.298H3.30664V24.2749H22.9658V21.298Z"
                                                  stroke="#737373"
                                                  strokeWidth="0.9" stroke-miterlimit="10" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                        </svg>
                                        {religionIsLogin}
                                    </div>
                                </div>
                                <div className="date-container-news text-right me-sm-5 me-0 pb-0">
                                    <svg className="ms-2" width="22" height="20" viewBox="0 0 24 22" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4.32292 4.49247H19.6562C20.7104 4.49247 21.5729 5.27997 21.5729 6.24247V16.7425C21.5729 17.705 20.7104 18.4925 19.6562 18.4925H4.32292C3.26875 18.4925 2.40625 17.705 2.40625 16.7425V6.24247C2.40625 5.27997 3.26875 4.49247 4.32292 4.49247Z"
                                            stroke="#949494" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M21.5729 6.24243L11.9896 12.3674L2.40625 6.24243"
                                              stroke="#949494"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <span className="f-12-g">
                                        {emailIsLogin}
                                    </span>
                                </div>
                            </div>
                            <form className="row h-100 form-container form-container-c-black"
                                  onSubmit={sendQuestion}>
                                <div className="col-md-4 child-m-0-in ">
                                    {sub?.length > 0 ?
                                        <Select
                                            showSearch
                                            placeholder="موضوع"
                                            defaultValue={defSub}
                                            onChange={handleChangeSub}
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            options={sub}
                                        />
                                        : ""}
                                </div>
                                <div className="col-12">
                                    <textarea className="w-100 place-main" onChange={(e) => {
                                        setQuestion(e.target.value)
                                    }} placeholder="السؤال" required></textarea>
                                </div>
                                <div className="col-12">
                                    <div className="radio-container">
                                        <label className="radio-label mt-0" title="السؤال عام لا بأس بنشره">
                                            <input type="radio"
                                                   onChange={(e) => {
                                                       e.target.value ?
                                                           setContributePublicFAQ(true)
                                                           : setContributePublicFAQ(false)
                                                   }}
                                                   value="no" checked={getContributePublicFAQ ? true : false}/>
                                            <span className="col-black color-label-f2 back-fff-t">السؤال عام لا بأس بنشره</span>
                                        </label>
                                        <label className="radio-label mt-1" title="السؤال خاص لا ارغب أن ينشر">
                                            <input type="radio" checked={getContributePublicFAQ ? false : true}
                                                   onChange={(e) => {
                                                       e.target.value ?
                                                           setContributePublicFAQ(false)
                                                           : setContributePublicFAQ(true)
                                                   }} value="yes"/>
                                            <span className="col-black color-label-f2 back-fff-t">السؤال خاص لا ارغب أن ينشر</span>
                                        </label>
                                    </div>
                                </div>
                                <div
                                    className="col-12 push-bottom-in-form d-flex justify-content-between align-items-center mt-4">
                                    <div className="checkbox" onClick={() => {
                                        setContributePublicUser(!getContributePublicUser)
                                    }}>
                                        {getContributePublicUser ?
                                            <input className="checkbox-pop checkbox-pop-2"
                                                   checked={true} type="checkbox"/>
                                            :
                                            <input className="checkbox-pop checkbox-pop-2"
                                                   checked={false} type="checkbox"/>
                                        }

                                        <label htmlFor="check2" className="color-label-f2 c-pointer">
                                            <span className="ms-0"></span>
                                            عدم عرض معلوماتي الخاصّة على الموقع
                                        </label>
                                    </div>
                                    <button aria-label=" إرسال السؤال" type="submit" title=" إرسال السؤال" className="more-btn btn fw-light
                        h-green-hover hidden-phone">
                                        إرسال السؤال
                                    </button>
                                </div>
                                <div className="col-12 d-flex justify-content-center hidden-desktop phone-flex
                                         align-items-center mt-4">
                                    <button aria-label=" إرسال السؤال" type="submit" title=" إرسال السؤال"
                                            className="more-btn btn fw-light ">
                                        إرسال السؤال
                                    </button>
                                </div>

                            </form>
                        </div>

                        : getFormTab == 2 ?

                            <div className="form-body-item">
                                <div className="form-title mt-3 pt-1">
                                    ساهم في مساهمات المستبصرين
                                </div>
                                <div className="form-disc">
                                    هذه النافذه مختصة بالمستبصرين لكي يكتبوا فيها: سيرتهم الذاتية، ورحلتهم إلى مذهب أهل
                                    البيت (عليهم‌السلام)، وما تجود به أقلامهم من مقالات علمية حول مذهب أهل البيت
                                    (عليهم‌السلام) ورد الشبهات، ومداخلات حوارية، ومقترحات لها صلة بواقع المسلمين عموماً
                                    وأتباع مذهب أهل البيت (عليهم‌السلام) خصوصاً.
                                </div>
                                <div className="user-name-container mt-5 c-999 d-sm-flex d-inline-block">
                                    <svg className=" ms-sm-2 phone-m-auto ms-0 d-sm-inline-block d-block w-35"
                                         width="35"
                                         height="43" viewBox="0 0 56 55"
                                         fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="28.0237" cy="27.4924" r="27.4924" fill="#045E5E"/>
                                        <path
                                            d="M37.7361 37.4626C37.1431 35.8027 35.8363 34.3359 34.0185 33.2897C32.2007 32.2436 29.9734 31.6765 27.6821 31.6765C25.3908 31.6765 23.1635 32.2436 21.3457 33.2897C19.5279 34.3359 18.2211 35.8026 17.6281 37.4626"
                                            stroke="white" strokeOpacity="0.9" strokeWidth="1.54887"
                                            strokeLinecap="round"/>
                                        <circle cx="27.6809" cy="21.2673" r="5.20433" stroke="white" strokeOpacity="0.9"
                                                strokeWidth="1.54887" strokeLinecap="round"/>
                                    </svg>
                                    <span className="f-16" title="محمد ابراهيم سليماني">
                                    {
                                        nameIsLogin + " " + lastNameIsLogin
                                    }
                                </span>
                                    <div
                                        className="d-flex justify-content-center align-items-center gap-3 religion-container-11">
                                        {CountryIsLogin?.length > 0 ?

                                            <div
                                                className="d-flex align-items-center phone-jus-center me-sm-5 me-0">
                                                <ReactCountryFlag
                                                    countryCode={CountryIsLogin}
                                                    svg
                                                    style={{
                                                        width: '1.6em',
                                                        height: '1.6em',
                                                        borderRadius: '8px'
                                                    }}
                                                    title={countries.getName(CountryIsLogin, 'ar')}
                                                    alt={countries.getName(CountryIsLogin, 'ar')}
                                                />
                                                <span className="pe-2">{countries.getName(CountryIsLogin, 'ar')}</span>
                                            </div>
                                            : ""}
                                        <div className="date-container-news text-right me-sm-5 me-0 pb-0">
                                            <svg className="ms-2" width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M21.137 21.298H7.52466C6.62485 19.7033 6.47368 17.8228 7.14314 16.1351C8.3021 13.2247 10.9368 10.9189 14.3272 10.0883C17.7177 10.9189 20.3524 13.2247 21.5114 16.1351C22.188 17.8228 22.0368 19.7033 21.137 21.298Z"
                                                    stroke="#737373" strokeWidth="0.9" stroke-miterlimit="10"
                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                                <path
                                                    d="M8.58166 6.38051H4.86723C4.62248 5.94195 4.57929 5.43031 4.76645 4.97182C5.08318 4.17444 5.80303 3.54984 6.73164 3.31727C7.66025 3.54319 8.3801 4.17444 8.69683 4.97182C8.8696 5.43031 8.82641 5.9486 8.58166 6.38051Z"
                                                    stroke="#737373" strokeWidth="0.9" stroke-miterlimit="10"
                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                                <path
                                                    d="M7.14342 16.135C7.47456 15.2978 7.93526 14.5137 8.48954 13.7961V6.38051H4.95508V21.298H7.51775C6.61793 19.7033 6.46676 17.8228 7.14342 16.135Z"
                                                    stroke="#737373" strokeWidth="0.9" stroke-miterlimit="10"
                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M14.3262 10.0883V8.2743" stroke="#737373"
                                                      stroke-miterlimit="10"
                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M6.72656 3.31728V1.5099" stroke="#737373" strokeWidth="0.9"
                                                      stroke-miterlimit="10" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                                <path d="M22.9658 21.298H3.30664V24.2749H22.9658V21.298Z"
                                                      stroke="#737373"
                                                      strokeWidth="0.9" stroke-miterlimit="10" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                            </svg>
                                            {religionIsLogin}
                                        </div>
                                    </div>
                                    <div className="date-container-news text-right me-sm-5 me-0 pb-0">
                                        <svg className="ms-2" width="22" height="20" viewBox="0 0 24 22" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4.32292 4.49247H19.6562C20.7104 4.49247 21.5729 5.27997 21.5729 6.24247V16.7425C21.5729 17.705 20.7104 18.4925 19.6562 18.4925H4.32292C3.26875 18.4925 2.40625 17.705 2.40625 16.7425V6.24247C2.40625 5.27997 3.26875 4.49247 4.32292 4.49247Z"
                                                stroke="#949494" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M21.5729 6.24243L11.9896 12.3674L2.40625 6.24243" stroke="#949494"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                        <span className="f-12-g">
                                        {emailIsLogin}
                                    </span>
                                    </div>
                                </div>
                                <form className=" h-100 form-container form-container-c-black"
                                      onSubmit={handleSubmitContribute}>
                                    <div className="col-md-4">
                                        <input className="w-100" onChange={(e) => {
                                            setContributeTitle(e.target.value)
                                        }} value={getContributeTitle} type="text"
                                               placeholder="الموضوع"
                                               required maxLength="500"/>
                                    </div>
                                    <div className="col-md-12">
                                        <input className="w-100" onChange={(e) => {
                                            setContributeBody(e.target.value)
                                        }} value={getContributeBody} type="text"
                                               placeholder="المساهمة" required
                                               maxLength="1000"/>
                                    </div>
                                    <div
                                        className="col-12 push-bottom-in-form d-sm-flex d-block justify-content-between align-items-center mt-5">
                                        <div className="checkbox c-pointer" onClick={() => {
                                            setContributePublic(!getContributePublic)
                                        }}>
                                            {getContributePublic ?
                                                <input className="checkbox-pop checkbox-pop-2"
                                                       checked={true} type="checkbox"/>
                                                :
                                                <input className="checkbox-pop checkbox-pop-2"
                                                       checked={false} type="checkbox"/>
                                            }

                                            <label htmlFor="check2" className="color-label-f2 c-pointer">
                                                <span className="ms-0"></span>
                                                ترغب بعرض هذه المعلومات في الموقع
                                            </label>
                                        </div>
                                        <button type="submit" aria-label="إرسال المعلومات"
                                                title="إرسال المعلومات"
                                                className="more-btn border-0 mt-4 mt-sm-0
                                                             fw-light ">
                                            إرسال المعلومات
                                        </button>

                                    </div>

                                </form>
                            </div>

                            : getFormTab == 3 ?

                                <div className="form-body-item ">
                                    <div className="form-title mt-3 pt-1">
                                        اتصال المستبصرين بالمركز
                                    </div>
                                    <div className="form-disc">
                                        هذه النافذة خاصّة بالمستبصرين الأعزّاء؛ لكي يعلنوا فيها عن اعتناقهم لمذهب أهل
                                        البيت(عليهم‌ السلام).
                                    </div>
                                    <div className="user-name-container mt-5 c-999 d-sm-flex d-inline-block">
                                        <svg className=" ms-sm-2 phone-m-auto ms-0 d-sm-inline-block d-block w-35"
                                             width="35" height="43" viewBox="0 0 56 55"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="28.0237" cy="27.4924" r="27.4924" fill="#045E5E"/>
                                            <path
                                                d="M37.7361 37.4626C37.1431 35.8027 35.8363 34.3359 34.0185 33.2897C32.2007 32.2436 29.9734 31.6765 27.6821 31.6765C25.3908 31.6765 23.1635 32.2436 21.3457 33.2897C19.5279 34.3359 18.2211 35.8026 17.6281 37.4626"
                                                stroke="white" strokeOpacity="0.9" strokeWidth="1.54887"
                                                strokeLinecap="round"/>
                                            <circle cx="27.6809" cy="21.2673" r="5.20433" stroke="white"
                                                    strokeOpacity="0.9"
                                                    strokeWidth="1.54887" strokeLinecap="round"/>
                                        </svg>
                                        <span className="f-16" title="محمد ابراهيم سليماني">
                                    {
                                        nameIsLogin + " " + lastNameIsLogin
                                    }
                                </span>
                                        <div
                                            className="d-flex justify-content-center align-items-center gap-3 religion-container-11">
                                            <div
                                                className="d-flex align-items-center phone-jus-center phone-m-b-2 me-sm-5 me-0">
                                                <ReactCountryFlag
                                                    countryCode={CountryIsLogin}
                                                    svg
                                                    style={{
                                                        width: '1.6em',
                                                        height: '1.6em',
                                                        borderRadius: '8px'
                                                    }}
                                                    title={countries.getName(CountryIsLogin, 'ar')}
                                                    alt={countries.getName(CountryIsLogin, 'ar')}
                                                />
                                                <span className="pe-2">{countries.getName(CountryIsLogin, 'ar')}</span>
                                            </div>
                                            <div className="date-container-news text-right me-sm-5 me-0 pb-0">
                                                <svg className="ms-2" width="26" height="26" viewBox="0 0 26 26"
                                                     fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M21.137 21.298H7.52466C6.62485 19.7033 6.47368 17.8228 7.14314 16.1351C8.3021 13.2247 10.9368 10.9189 14.3272 10.0883C17.7177 10.9189 20.3524 13.2247 21.5114 16.1351C22.188 17.8228 22.0368 19.7033 21.137 21.298Z"
                                                        stroke="#737373" strokeWidth="0.9" stroke-miterlimit="10"
                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path
                                                        d="M8.58166 6.38051H4.86723C4.62248 5.94195 4.57929 5.43031 4.76645 4.97182C5.08318 4.17444 5.80303 3.54984 6.73164 3.31727C7.66025 3.54319 8.3801 4.17444 8.69683 4.97182C8.8696 5.43031 8.82641 5.9486 8.58166 6.38051Z"
                                                        stroke="#737373" strokeWidth="0.9" stroke-miterlimit="10"
                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path
                                                        d="M7.14342 16.135C7.47456 15.2978 7.93526 14.5137 8.48954 13.7961V6.38051H4.95508V21.298H7.51775C6.61793 19.7033 6.46676 17.8228 7.14342 16.135Z"
                                                        stroke="#737373" strokeWidth="0.9" stroke-miterlimit="10"
                                                        strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M14.3262 10.0883V8.2743" stroke="#737373"
                                                          stroke-miterlimit="10"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M6.72656 3.31728V1.5099" stroke="#737373" strokeWidth="0.9"
                                                          stroke-miterlimit="10" strokeLinecap="round"
                                                          strokeLinejoin="round"/>
                                                    <path d="M22.9658 21.298H3.30664V24.2749H22.9658V21.298Z"
                                                          stroke="#737373"
                                                          strokeWidth="0.9" stroke-miterlimit="10" strokeLinecap="round"
                                                          strokeLinejoin="round"/>
                                                </svg>
                                                {religionIsLogin}
                                            </div>
                                        </div>
                                        <div className="date-container-news text-right me-sm-5 me-0 pb-0">
                                            <svg className="ms-2" width="22" height="20" viewBox="0 0 24 22" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M4.32292 4.49247H19.6562C20.7104 4.49247 21.5729 5.27997 21.5729 6.24247V16.7425C21.5729 17.705 20.7104 18.4925 19.6562 18.4925H4.32292C3.26875 18.4925 2.40625 17.705 2.40625 16.7425V6.24247C2.40625 5.27997 3.26875 4.49247 4.32292 4.49247Z"
                                                    stroke="#949494" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M21.5729 6.24243L11.9896 12.3674L2.40625 6.24243"
                                                      stroke="#949494"
                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            <span className="f-12-g">
                                        {emailIsLogin}
                                    </span>
                                        </div>
                                    </div>
                                    <form className="row h-100 form-container form-container-c-black"
                                          onSubmit={handleSubmitGuided}>
                                        <div className="col-md-4 child-m-0-in">
                                            <Select
                                                showSearch
                                                placeholder="مكان الاستبصار"
                                                onChange={handleChangeCountry25}
                                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                filterSort={(optionA, optionB) =>
                                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                }
                                                options={[
                                                    {
                                                        value: "AD",
                                                        label: "أندورا",
                                                    },
                                                    {
                                                        value: "AE",
                                                        label: "الامارات العربية المتحدة",
                                                    },
                                                    {
                                                        value: "AF",
                                                        label: "أفغانستان",
                                                    },
                                                    {
                                                        value: "AG",
                                                        label: "أنتيجوا وبربودا",
                                                    },
                                                    {
                                                        value: "AI",
                                                        label: "أنجويلا",
                                                    },
                                                    {
                                                        value: "AL",
                                                        label: "ألبانيا",
                                                    },
                                                    {
                                                        value: "AM",
                                                        label: "أرمينيا",
                                                    },
                                                    {
                                                        value: "AO",
                                                        label: "أنجولا",
                                                    },
                                                    {
                                                        value: "AQ",
                                                        label: "القطب الجنوبي",
                                                    },
                                                    {
                                                        value: "AR",
                                                        label: "الأرجنتين",
                                                    },
                                                    {
                                                        value: "AS",
                                                        label: "ساموا الأمريكية",
                                                    },
                                                    {
                                                        value: "AT",
                                                        label: "النمسا",
                                                    },
                                                    {
                                                        value: "AU",
                                                        label: "أستراليا",
                                                    },
                                                    {
                                                        value: "AW",
                                                        label: "آروبا",
                                                    },
                                                    {
                                                        value: "AX",
                                                        label: "جزر أولان",
                                                    },
                                                    {
                                                        value: "AZ",
                                                        label: "أذربيجان",
                                                    },
                                                    {
                                                        value: "BA",
                                                        label: "البوسنة والهرسك",
                                                    },
                                                    {
                                                        value: "BB",
                                                        label: "بربادوس",
                                                    },
                                                    {
                                                        value: "BD",
                                                        label: "بنجلاديش",
                                                    },
                                                    {
                                                        value: "BE",
                                                        label: "بلجيكا",
                                                    },
                                                    {
                                                        value: "BF",
                                                        label: "بوركينا فاسو",
                                                    },
                                                    {
                                                        value: "BG",
                                                        label: "بلغاريا",
                                                    },
                                                    {
                                                        value: "BH",
                                                        label: "البحرين",
                                                    },
                                                    {
                                                        value: "BI",
                                                        label: "بوروندي",
                                                    },
                                                    {
                                                        value: "BJ",
                                                        label: "بنين",
                                                    },
                                                    {
                                                        value: "BL",
                                                        label: "سان بارتيلمي",
                                                    },
                                                    {
                                                        value: "BM",
                                                        label: "برمودا",
                                                    },
                                                    {
                                                        value: "BN",
                                                        label: "بروناي",
                                                    },
                                                    {
                                                        value: "BO",
                                                        label: "بوليفيا",
                                                    },
                                                    {
                                                        value: "BQ",
                                                        label: "بونير",
                                                    },
                                                    {
                                                        value: "BR",
                                                        label: "البرازيل",
                                                    },
                                                    {
                                                        value: "BS",
                                                        label: "الباهاما",
                                                    },
                                                    {
                                                        value: "BT",
                                                        label: "بوتان",
                                                    },
                                                    {
                                                        value: "BV",
                                                        label: "جزيرة بوفيه",
                                                    },
                                                    {
                                                        value: "BW",
                                                        label: "بتسوانا",
                                                    },
                                                    {
                                                        value: "BY",
                                                        label: "روسيا البيضاء",
                                                    },
                                                    {
                                                        value: "BZ",
                                                        label: "بليز",
                                                    },
                                                    {
                                                        value: "CA",
                                                        label: "كندا",
                                                    },
                                                    {
                                                        value: "CC",
                                                        label: "جزر كوكوس",
                                                    },
                                                    {
                                                        value: "CD",
                                                        label: "جمهورية الكونغو الديمقراطية",
                                                    },
                                                    {
                                                        value: "CF",
                                                        label: "جمهورية افريقيا الوسطى",
                                                    },
                                                    {
                                                        value: "CG",
                                                        label: "الكونغو - برازافيل",
                                                    },
                                                    {
                                                        value: "CH",
                                                        label: "سويسرا",
                                                    },
                                                    {
                                                        value: "CI",
                                                        label: "ساحل العاج",
                                                    },
                                                    {
                                                        value: "CK",
                                                        label: "جزر كوك",
                                                    },
                                                    {
                                                        value: "CL",
                                                        label: "شيلي",
                                                    },
                                                    {
                                                        value: "CM",
                                                        label: "الكاميرون",
                                                    },
                                                    {
                                                        value: "CN",
                                                        label: "الصين",
                                                    },
                                                    {
                                                        value: "CO",
                                                        label: "كولومبيا",
                                                    },
                                                    {
                                                        value: "CR",
                                                        label: "كوستاريكا",
                                                    },
                                                    {
                                                        value: "CU",
                                                        label: "كوبا",
                                                    },
                                                    {
                                                        value: "CV",
                                                        label: "الرأس الأخضر",
                                                    },
                                                    {
                                                        value: "CW",
                                                        label: "كوراساو",
                                                    },
                                                    {
                                                        value: "CX",
                                                        label: "جزيرة الكريسماس",
                                                    },
                                                    {
                                                        value: "CY",
                                                        label: "قبرص",
                                                    },
                                                    {
                                                        value: "CZ",
                                                        label: "جمهورية التشيك",
                                                    },
                                                    {
                                                        value: "DE",
                                                        label: "ألمانيا",
                                                    },
                                                    {
                                                        value: "DJ",
                                                        label: "جيبوتي",
                                                    },
                                                    {
                                                        value: "DK",
                                                        label: "الدانمرك",
                                                    },
                                                    {
                                                        value: "DM",
                                                        label: "دومينيكا",
                                                    },
                                                    {
                                                        value: "DO",
                                                        label: "جمهورية الدومينيك",
                                                    },
                                                    {
                                                        value: "DZ",
                                                        label: "الجزائر",
                                                    },
                                                    {
                                                        value: "EC",
                                                        label: "الاكوادور",
                                                    },
                                                    {
                                                        value: "EE",
                                                        label: "استونيا",
                                                    },
                                                    {
                                                        value: "EG",
                                                        label: "مصر",
                                                    },
                                                    {
                                                        value: "EH",
                                                        label: "الصحراء الغربية",
                                                    },
                                                    {
                                                        value: "ER",
                                                        label: "اريتريا",
                                                    },
                                                    {
                                                        value: "ES",
                                                        label: "أسبانيا",
                                                    },
                                                    {
                                                        value: "ET",
                                                        label: "اثيوبيا",
                                                    },
                                                    {
                                                        value: "FI",
                                                        label: "فنلندا",
                                                    },
                                                    {
                                                        value: "FJ",
                                                        label: "فيجي",
                                                    },
                                                    {
                                                        value: "FK",
                                                        label: "جزر فوكلاند",
                                                    },
                                                    {
                                                        value: "FM",
                                                        label: "ميكرونيزيا",
                                                    },
                                                    {
                                                        value: "FO",
                                                        label: "جزر فارو",
                                                    },
                                                    {
                                                        value: "FR",
                                                        label: "فرنسا",
                                                    },
                                                    {
                                                        value: "GA",
                                                        label: "الجابون",
                                                    },
                                                    {
                                                        value: "GB",
                                                        label: "المملكة المتحدة",
                                                    },
                                                    {
                                                        value: "GD",
                                                        label: "جرينادا",
                                                    },
                                                    {
                                                        value: "GE",
                                                        label: "جورجيا",
                                                    },
                                                    {
                                                        value: "GF",
                                                        label: "غويانا",
                                                    },
                                                    {
                                                        value: "GG",
                                                        label: "غيرنزي",
                                                    },
                                                    {
                                                        value: "GH",
                                                        label: "غانا",
                                                    },
                                                    {
                                                        value: "GI",
                                                        label: "جبل طارق",
                                                    },
                                                    {
                                                        value: "GL",
                                                        label: "جرينلاند",
                                                    },
                                                    {
                                                        value: "GM",
                                                        label: "غامبيا",
                                                    },
                                                    {
                                                        value: "GN",
                                                        label: "غينيا",
                                                    },
                                                    {
                                                        value: "GP",
                                                        label: "جوادلوب",
                                                    },
                                                    {
                                                        value: "GQ",
                                                        label: "غينيا الاستوائية",
                                                    },
                                                    {
                                                        value: "GR",
                                                        label: "اليونان",
                                                    },
                                                    {
                                                        value: "GS",
                                                        label: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
                                                    },
                                                    {
                                                        value: "GT",
                                                        label: "جواتيمالا",
                                                    },
                                                    {
                                                        value: "GU",
                                                        label: "جوام",
                                                    },
                                                    {
                                                        value: "GW",
                                                        label: "غينيا بيساو",
                                                    },
                                                    {
                                                        value: "GY",
                                                        label: "غيانا",
                                                    },
                                                    {
                                                        value: "HK",
                                                        label: "هونج كونج الصينية",
                                                    },
                                                    {
                                                        value: "HM",
                                                        label: "جزيرة هيرد وماكدونالد",
                                                    },
                                                    {
                                                        value: "HN",
                                                        label: "هندوراس",
                                                    },
                                                    {
                                                        value: "HR",
                                                        label: "كرواتيا",
                                                    },
                                                    {
                                                        value: "HT",
                                                        label: "هايتي",
                                                    },
                                                    {
                                                        value: "HU",
                                                        label: "المجر",
                                                    },
                                                    {
                                                        value: "ID",
                                                        label: "اندونيسيا",
                                                    },
                                                    {
                                                        value: "IE",
                                                        label: "أيرلندا",
                                                    },
                                                    {
                                                        value: "IL",
                                                        label: "اسرائيل",
                                                    },
                                                    {
                                                        value: "IM",
                                                        label: "جزيرة مان",
                                                    },
                                                    {
                                                        value: "IN",
                                                        label: "الهند",
                                                    },
                                                    {
                                                        value: "IO",
                                                        label: "المحيط الهندي البريطاني",
                                                    },
                                                    {
                                                        value: "IQ",
                                                        label: "العراق",
                                                    },
                                                    {
                                                        value: "IR",
                                                        label: "ايران",
                                                    },
                                                    {
                                                        value: "IS",
                                                        label: "أيسلندا",
                                                    },
                                                    {
                                                        value: "IT",
                                                        label: "ايطاليا",
                                                    },
                                                    {
                                                        value: "JE",
                                                        label: "جيرسي",
                                                    },
                                                    {
                                                        value: "JM",
                                                        label: "جامايكا",
                                                    },
                                                    {
                                                        value: "JO",
                                                        label: "الأردن",
                                                    },
                                                    {
                                                        value: "JP",
                                                        label: "اليابان",
                                                    },
                                                    {
                                                        value: "KE",
                                                        label: "كينيا",
                                                    },
                                                    {
                                                        value: "KG",
                                                        label: "قرغيزستان",
                                                    },
                                                    {
                                                        value: "KH",
                                                        label: "كمبوديا",
                                                    },
                                                    {
                                                        value: "KI",
                                                        label: "كيريباتي",
                                                    },
                                                    {
                                                        value: "KM",
                                                        label: "جزر القمر",
                                                    },
                                                    {
                                                        value: "KN",
                                                        label: "سانت كيتس ونيفيس",
                                                    },
                                                    {
                                                        value: "KP",
                                                        label: "كوريا الشمالية",
                                                    },
                                                    {
                                                        value: "KR",
                                                        label: "كوريا الجنوبية",
                                                    },
                                                    {
                                                        value: "KW",
                                                        label: "الكويت",
                                                    },
                                                    {
                                                        value: "KY",
                                                        label: "جزر الكايمن",
                                                    },
                                                    {
                                                        value: "KZ",
                                                        label: "كازاخستان",
                                                    },
                                                    {
                                                        value: "LA",
                                                        label: "لاوس",
                                                    },
                                                    {
                                                        value: "LB",
                                                        label: "لبنان",
                                                    },
                                                    {
                                                        value: "LC",
                                                        label: "سانت لوسيا",
                                                    },
                                                    {
                                                        value: "LI",
                                                        label: "ليختنشتاين",
                                                    },
                                                    {
                                                        value: "LK",
                                                        label: "سريلانكا",
                                                    },
                                                    {
                                                        value: "LR",
                                                        label: "ليبيريا",
                                                    },
                                                    {
                                                        value: "LS",
                                                        label: "ليسوتو",
                                                    },
                                                    {
                                                        value: "LT",
                                                        label: "ليتوانيا",
                                                    },
                                                    {
                                                        value: "LU",
                                                        label: "لوكسمبورج",
                                                    },
                                                    {
                                                        value: "LV",
                                                        label: "لاتفيا",
                                                    },
                                                    {
                                                        value: "LY",
                                                        label: "ليبيا",
                                                    },
                                                    {
                                                        value: "MA",
                                                        label: "المغرب",
                                                    },
                                                    {
                                                        value: "MC",
                                                        label: "موناكو",
                                                    },
                                                    {
                                                        value: "MD",
                                                        label: "مولدافيا",
                                                    },
                                                    {
                                                        value: "ME",
                                                        label: "الجبل الأسود",
                                                    },
                                                    {
                                                        value: "MF",
                                                        label: "سانت مارتين",
                                                    },
                                                    {
                                                        value: "MG",
                                                        label: "مدغشقر",
                                                    },
                                                    {
                                                        value: "MH",
                                                        label: "جزر المارشال",
                                                    },
                                                    {
                                                        value: "MK",
                                                        label: "مقدونيا",
                                                    },
                                                    {
                                                        value: "ML",
                                                        label: "مالي",
                                                    },
                                                    {
                                                        value: "MM",
                                                        label: "ميانمار",
                                                    },
                                                    {
                                                        value: "MN",
                                                        label: "منغوليا",
                                                    },
                                                    {
                                                        value: "MO",
                                                        label: "ماكاو الصينية",
                                                    },
                                                    {
                                                        value: "MP",
                                                        label: "جزر ماريانا الشمالية",
                                                    },
                                                    {
                                                        value: "MQ",
                                                        label: "مارتينيك",
                                                    },
                                                    {
                                                        value: "MR",
                                                        label: "موريتانيا",
                                                    },
                                                    {
                                                        value: "MS",
                                                        label: "مونتسرات",
                                                    },
                                                    {
                                                        value: "MT",
                                                        label: "مالطا",
                                                    },
                                                    {
                                                        value: "MU",
                                                        label: "موريشيوس",
                                                    },
                                                    {
                                                        value: "MV",
                                                        label: "جزر الملديف",
                                                    },
                                                    {
                                                        value: "MW",
                                                        label: "ملاوي",
                                                    },
                                                    {
                                                        value: "MX",
                                                        label: "المكسيك",
                                                    },
                                                    {
                                                        value: "MY",
                                                        label: "ماليزيا",
                                                    },
                                                    {
                                                        value: "MZ",
                                                        label: "موزمبيق",
                                                    },
                                                    {
                                                        value: "NA",
                                                        label: "ناميبيا",
                                                    },
                                                    {
                                                        value: "NC",
                                                        label: "كاليدونيا الجديدة",
                                                    },
                                                    {
                                                        value: "NE",
                                                        label: "النيجر",
                                                    },
                                                    {
                                                        value: "NF",
                                                        label: "جزيرة نورفوك",
                                                    },
                                                    {
                                                        value: "NG",
                                                        label: "نيجيريا",
                                                    },
                                                    {
                                                        value: "NI",
                                                        label: "نيكاراجوا",
                                                    },
                                                    {
                                                        value: "NL",
                                                        label: "هولندا",
                                                    },
                                                    {
                                                        value: "NO",
                                                        label: "النرويج",
                                                    },
                                                    {
                                                        value: "NP",
                                                        label: "نيبال",
                                                    },
                                                    {
                                                        value: "NR",
                                                        label: "نورو",
                                                    },
                                                    {
                                                        value: "NU",
                                                        label: "نيوي",
                                                    },
                                                    {
                                                        value: "NZ",
                                                        label: "نيوزيلاندا",
                                                    },
                                                    {
                                                        value: "OM",
                                                        label: "عمان",
                                                    },
                                                    {
                                                        value: "PA",
                                                        label: "بنما",
                                                    },
                                                    {
                                                        value: "PE",
                                                        label: "بيرو",
                                                    },
                                                    {
                                                        value: "PF",
                                                        label: "بولينيزيا الفرنسية",
                                                    },
                                                    {
                                                        value: "PG",
                                                        label: "بابوا غينيا الجديدة",
                                                    },
                                                    {
                                                        value: "PH",
                                                        label: "الفيلبين",
                                                    },
                                                    {
                                                        value: "PK",
                                                        label: "باكستان",
                                                    },
                                                    {
                                                        value: "PL",
                                                        label: "بولندا",
                                                    },
                                                    {
                                                        value: "PM",
                                                        label: "سانت بيير وميكولون",
                                                    },
                                                    {
                                                        value: "PN",
                                                        label: "بتكايرن",
                                                    },
                                                    {
                                                        value: "PR",
                                                        label: "بورتوريكو",
                                                    },
                                                    {
                                                        value: "PS",
                                                        label: "فلسطين",
                                                    },
                                                    {
                                                        value: "PT",
                                                        label: "البرتغال",
                                                    },
                                                    {
                                                        value: "PW",
                                                        label: "بالاو",
                                                    },
                                                    {
                                                        value: "PY",
                                                        label: "باراجواي",
                                                    },
                                                    {
                                                        value: "QA",
                                                        label: "قطر",
                                                    },
                                                    {
                                                        value: "RE",
                                                        label: "روينيون",
                                                    },
                                                    {
                                                        value: "RO",
                                                        label: "رومانيا",
                                                    },
                                                    {
                                                        value: "RS",
                                                        label: "صربيا",
                                                    },
                                                    {
                                                        value: "RU",
                                                        label: "روسيا",
                                                    },
                                                    {
                                                        value: "RW",
                                                        label: "رواندا",
                                                    },
                                                    {
                                                        value: "SA",
                                                        label: "المملكة العربية السعودية",
                                                    },
                                                    {
                                                        value: "SB",
                                                        label: "جزر سليمان",
                                                    },
                                                    {
                                                        value: "SC",
                                                        label: "سيشل",
                                                    },
                                                    {
                                                        value: "SD",
                                                        label: "السودان",
                                                    },
                                                    {
                                                        value: "SE",
                                                        label: "السويد",
                                                    },
                                                    {
                                                        value: "SG",
                                                        label: "سنغافورة",
                                                    },
                                                    {
                                                        value: "SH",
                                                        label: "سانت هيلنا",
                                                    },
                                                    {
                                                        value: "SI",
                                                        label: "سلوفينيا",
                                                    },
                                                    {
                                                        value: "SJ",
                                                        label: "سفالبارد وجان مايان",
                                                    },
                                                    {
                                                        value: "SK",
                                                        label: "سلوفاكيا",
                                                    },
                                                    {
                                                        value: "SL",
                                                        label: "سيراليون",
                                                    },
                                                    {
                                                        value: "SM",
                                                        label: "سان مارينو",
                                                    },
                                                    {
                                                        value: "SN",
                                                        label: "السنغال",
                                                    },
                                                    {
                                                        value: "SO",
                                                        label: "الصومال",
                                                    },
                                                    {
                                                        value: "SR",
                                                        label: "سورينام",
                                                    },
                                                    {
                                                        value: "SS",
                                                        label: "جنوب السودان",
                                                    },
                                                    {
                                                        value: "ST",
                                                        label: "ساو تومي وبرينسيبي",
                                                    },
                                                    {
                                                        value: "SV",
                                                        label: "السلفادور",
                                                    },
                                                    {
                                                        value: "SX",
                                                        label: "سينت مارتن",
                                                    },
                                                    {
                                                        value: "SY",
                                                        label: "سوريا",
                                                    },
                                                    {
                                                        value: "SZ",
                                                        label: "سوازيلاند",
                                                    },
                                                    {
                                                        value: "TC",
                                                        label: "جزر الترك وجايكوس",
                                                    },
                                                    {
                                                        value: "TD",
                                                        label: "تشاد",
                                                    },
                                                    {
                                                        value: "TF",
                                                        label: "المقاطعات الجنوبية الفرنسية",
                                                    },
                                                    {
                                                        value: "TG",
                                                        label: "توجو",
                                                    },
                                                    {
                                                        value: "TH",
                                                        label: "تايلند",
                                                    },
                                                    {
                                                        value: "TJ",
                                                        label: "طاجكستان",
                                                    },
                                                    {
                                                        value: "TK",
                                                        label: "توكيلو",
                                                    },
                                                    {
                                                        value: "TL",
                                                        label: "تيمور الشرقية",
                                                    },
                                                    {
                                                        value: "TM",
                                                        label: "تركمانستان",
                                                    },
                                                    {
                                                        value: "TN",
                                                        label: "تونس",
                                                    },
                                                    {
                                                        value: "TO",
                                                        label: "تونجا",
                                                    },
                                                    {
                                                        value: "TR",
                                                        label: "تركيا",
                                                    },
                                                    {
                                                        value: "TT",
                                                        label: "ترينيداد وتوباغو",
                                                    },
                                                    {
                                                        value: "TV",
                                                        label: "توفالو",
                                                    },
                                                    {
                                                        value: "TW",
                                                        label: "تايوان",
                                                    },
                                                    {
                                                        value: "TZ",
                                                        label: "تانزانيا",
                                                    },
                                                    {
                                                        value: "UA",
                                                        label: "أوكرانيا",
                                                    },
                                                    {
                                                        value: "UG",
                                                        label: "أوغندا",
                                                    },
                                                    {
                                                        value: "UM",
                                                        label: "جزر الولايات المتحدة البعيدة الصغيرة",
                                                    },
                                                    {
                                                        value: "US",
                                                        label: "الولايات المتحدة الأمريكية",
                                                    },
                                                    {
                                                        value: "UY",
                                                        label: "أورجواي",
                                                    },
                                                    {
                                                        value: "UZ",
                                                        label: "أوزبكستان",
                                                    },
                                                    {
                                                        value: "VA",
                                                        label: "الفاتيكان",
                                                    },
                                                    {
                                                        value: "VC",
                                                        label: "سانت فنسنت وغرنادين",
                                                    },
                                                    {
                                                        value: "VE",
                                                        label: "فنزويلا",
                                                    },
                                                    {
                                                        value: "VG",
                                                        label: "جزر فرجين البريطانية",
                                                    },
                                                    {
                                                        value: "VI",
                                                        label: "جزر فرجين الأمريكية",
                                                    },
                                                    {
                                                        value: "VN",
                                                        label: "فيتنام",
                                                    },
                                                    {
                                                        value: "VU",
                                                        label: "فانواتو",
                                                    },
                                                    {
                                                        value: "WF",
                                                        label: "جزر والس وفوتونا",
                                                    },
                                                    {
                                                        value: "WS",
                                                        label: "ساموا",
                                                    },
                                                    {
                                                        value: "XK",
                                                        label: "كوسوفو",
                                                    },
                                                    {
                                                        value: "YE",
                                                        label: "اليمن",
                                                    },
                                                    {
                                                        value: "YT",
                                                        label: "مايوت",
                                                    },
                                                    {
                                                        value: "ZA",
                                                        label: "جمهورية جنوب افريقيا",
                                                    },
                                                    {
                                                        value: "ZM",
                                                        label: "زامبيا",
                                                    },
                                                    {
                                                        value: "ZW",
                                                        label: "زيمبابوي",
                                                    }
                                                ]}/>
                                        </div>
                                        <div className="col-md-4">
                                            <input className="w-100 icon-black" onChange={(e) => {
                                                setGuidedHistory(e.target.value)
                                            }} onFocus={() => {
                                                setTypeInput("date")
                                            }}
                                                   onBlur={() => {
                                                       setTypeInput("text")
                                                   }}
                                                   value={getGuidedHistory} type={getTypeInput}
                                                   placeholder="تاريخ الاستبصار"
                                                   required
                                                   maxLength="500"/>
                                        </div>
                                        <div className="col-md-4 child-m-0-in">
                                            <Select
                                                showSearch
                                                placeholder="مكان الولادة"
                                                onChange={handleChangeCountry27}
                                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                filterSort={(optionA, optionB) =>
                                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                }
                                                options={[
                                                    {
                                                        value: "AD",
                                                        label: "أندورا",
                                                    },
                                                    {
                                                        value: "AE",
                                                        label: "الامارات العربية المتحدة",
                                                    },
                                                    {
                                                        value: "AF",
                                                        label: "أفغانستان",
                                                    },
                                                    {
                                                        value: "AG",
                                                        label: "أنتيجوا وبربودا",
                                                    },
                                                    {
                                                        value: "AI",
                                                        label: "أنجويلا",
                                                    },
                                                    {
                                                        value: "AL",
                                                        label: "ألبانيا",
                                                    },
                                                    {
                                                        value: "AM",
                                                        label: "أرمينيا",
                                                    },
                                                    {
                                                        value: "AO",
                                                        label: "أنجولا",
                                                    },
                                                    {
                                                        value: "AQ",
                                                        label: "القطب الجنوبي",
                                                    },
                                                    {
                                                        value: "AR",
                                                        label: "الأرجنتين",
                                                    },
                                                    {
                                                        value: "AS",
                                                        label: "ساموا الأمريكية",
                                                    },
                                                    {
                                                        value: "AT",
                                                        label: "النمسا",
                                                    },
                                                    {
                                                        value: "AU",
                                                        label: "أستراليا",
                                                    },
                                                    {
                                                        value: "AW",
                                                        label: "آروبا",
                                                    },
                                                    {
                                                        value: "AX",
                                                        label: "جزر أولان",
                                                    },
                                                    {
                                                        value: "AZ",
                                                        label: "أذربيجان",
                                                    },
                                                    {
                                                        value: "BA",
                                                        label: "البوسنة والهرسك",
                                                    },
                                                    {
                                                        value: "BB",
                                                        label: "بربادوس",
                                                    },
                                                    {
                                                        value: "BD",
                                                        label: "بنجلاديش",
                                                    },
                                                    {
                                                        value: "BE",
                                                        label: "بلجيكا",
                                                    },
                                                    {
                                                        value: "BF",
                                                        label: "بوركينا فاسو",
                                                    },
                                                    {
                                                        value: "BG",
                                                        label: "بلغاريا",
                                                    },
                                                    {
                                                        value: "BH",
                                                        label: "البحرين",
                                                    },
                                                    {
                                                        value: "BI",
                                                        label: "بوروندي",
                                                    },
                                                    {
                                                        value: "BJ",
                                                        label: "بنين",
                                                    },
                                                    {
                                                        value: "BL",
                                                        label: "سان بارتيلمي",
                                                    },
                                                    {
                                                        value: "BM",
                                                        label: "برمودا",
                                                    },
                                                    {
                                                        value: "BN",
                                                        label: "بروناي",
                                                    },
                                                    {
                                                        value: "BO",
                                                        label: "بوليفيا",
                                                    },
                                                    {
                                                        value: "BQ",
                                                        label: "بونير",
                                                    },
                                                    {
                                                        value: "BR",
                                                        label: "البرازيل",
                                                    },
                                                    {
                                                        value: "BS",
                                                        label: "الباهاما",
                                                    },
                                                    {
                                                        value: "BT",
                                                        label: "بوتان",
                                                    },
                                                    {
                                                        value: "BV",
                                                        label: "جزيرة بوفيه",
                                                    },
                                                    {
                                                        value: "BW",
                                                        label: "بتسوانا",
                                                    },
                                                    {
                                                        value: "BY",
                                                        label: "روسيا البيضاء",
                                                    },
                                                    {
                                                        value: "BZ",
                                                        label: "بليز",
                                                    },
                                                    {
                                                        value: "CA",
                                                        label: "كندا",
                                                    },
                                                    {
                                                        value: "CC",
                                                        label: "جزر كوكوس",
                                                    },
                                                    {
                                                        value: "CD",
                                                        label: "جمهورية الكونغو الديمقراطية",
                                                    },
                                                    {
                                                        value: "CF",
                                                        label: "جمهورية افريقيا الوسطى",
                                                    },
                                                    {
                                                        value: "CG",
                                                        label: "الكونغو - برازافيل",
                                                    },
                                                    {
                                                        value: "CH",
                                                        label: "سويسرا",
                                                    },
                                                    {
                                                        value: "CI",
                                                        label: "ساحل العاج",
                                                    },
                                                    {
                                                        value: "CK",
                                                        label: "جزر كوك",
                                                    },
                                                    {
                                                        value: "CL",
                                                        label: "شيلي",
                                                    },
                                                    {
                                                        value: "CM",
                                                        label: "الكاميرون",
                                                    },
                                                    {
                                                        value: "CN",
                                                        label: "الصين",
                                                    },
                                                    {
                                                        value: "CO",
                                                        label: "كولومبيا",
                                                    },
                                                    {
                                                        value: "CR",
                                                        label: "كوستاريكا",
                                                    },
                                                    {
                                                        value: "CU",
                                                        label: "كوبا",
                                                    },
                                                    {
                                                        value: "CV",
                                                        label: "الرأس الأخضر",
                                                    },
                                                    {
                                                        value: "CW",
                                                        label: "كوراساو",
                                                    },
                                                    {
                                                        value: "CX",
                                                        label: "جزيرة الكريسماس",
                                                    },
                                                    {
                                                        value: "CY",
                                                        label: "قبرص",
                                                    },
                                                    {
                                                        value: "CZ",
                                                        label: "جمهورية التشيك",
                                                    },
                                                    {
                                                        value: "DE",
                                                        label: "ألمانيا",
                                                    },
                                                    {
                                                        value: "DJ",
                                                        label: "جيبوتي",
                                                    },
                                                    {
                                                        value: "DK",
                                                        label: "الدانمرك",
                                                    },
                                                    {
                                                        value: "DM",
                                                        label: "دومينيكا",
                                                    },
                                                    {
                                                        value: "DO",
                                                        label: "جمهورية الدومينيك",
                                                    },
                                                    {
                                                        value: "DZ",
                                                        label: "الجزائر",
                                                    },
                                                    {
                                                        value: "EC",
                                                        label: "الاكوادور",
                                                    },
                                                    {
                                                        value: "EE",
                                                        label: "استونيا",
                                                    },
                                                    {
                                                        value: "EG",
                                                        label: "مصر",
                                                    },
                                                    {
                                                        value: "EH",
                                                        label: "الصحراء الغربية",
                                                    },
                                                    {
                                                        value: "ER",
                                                        label: "اريتريا",
                                                    },
                                                    {
                                                        value: "ES",
                                                        label: "أسبانيا",
                                                    },
                                                    {
                                                        value: "ET",
                                                        label: "اثيوبيا",
                                                    },
                                                    {
                                                        value: "FI",
                                                        label: "فنلندا",
                                                    },
                                                    {
                                                        value: "FJ",
                                                        label: "فيجي",
                                                    },
                                                    {
                                                        value: "FK",
                                                        label: "جزر فوكلاند",
                                                    },
                                                    {
                                                        value: "FM",
                                                        label: "ميكرونيزيا",
                                                    },
                                                    {
                                                        value: "FO",
                                                        label: "جزر فارو",
                                                    },
                                                    {
                                                        value: "FR",
                                                        label: "فرنسا",
                                                    },
                                                    {
                                                        value: "GA",
                                                        label: "الجابون",
                                                    },
                                                    {
                                                        value: "GB",
                                                        label: "المملكة المتحدة",
                                                    },
                                                    {
                                                        value: "GD",
                                                        label: "جرينادا",
                                                    },
                                                    {
                                                        value: "GE",
                                                        label: "جورجيا",
                                                    },
                                                    {
                                                        value: "GF",
                                                        label: "غويانا",
                                                    },
                                                    {
                                                        value: "GG",
                                                        label: "غيرنزي",
                                                    },
                                                    {
                                                        value: "GH",
                                                        label: "غانا",
                                                    },
                                                    {
                                                        value: "GI",
                                                        label: "جبل طارق",
                                                    },
                                                    {
                                                        value: "GL",
                                                        label: "جرينلاند",
                                                    },
                                                    {
                                                        value: "GM",
                                                        label: "غامبيا",
                                                    },
                                                    {
                                                        value: "GN",
                                                        label: "غينيا",
                                                    },
                                                    {
                                                        value: "GP",
                                                        label: "جوادلوب",
                                                    },
                                                    {
                                                        value: "GQ",
                                                        label: "غينيا الاستوائية",
                                                    },
                                                    {
                                                        value: "GR",
                                                        label: "اليونان",
                                                    },
                                                    {
                                                        value: "GS",
                                                        label: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
                                                    },
                                                    {
                                                        value: "GT",
                                                        label: "جواتيمالا",
                                                    },
                                                    {
                                                        value: "GU",
                                                        label: "جوام",
                                                    },
                                                    {
                                                        value: "GW",
                                                        label: "غينيا بيساو",
                                                    },
                                                    {
                                                        value: "GY",
                                                        label: "غيانا",
                                                    },
                                                    {
                                                        value: "HK",
                                                        label: "هونج كونج الصينية",
                                                    },
                                                    {
                                                        value: "HM",
                                                        label: "جزيرة هيرد وماكدونالد",
                                                    },
                                                    {
                                                        value: "HN",
                                                        label: "هندوراس",
                                                    },
                                                    {
                                                        value: "HR",
                                                        label: "كرواتيا",
                                                    },
                                                    {
                                                        value: "HT",
                                                        label: "هايتي",
                                                    },
                                                    {
                                                        value: "HU",
                                                        label: "المجر",
                                                    },
                                                    {
                                                        value: "ID",
                                                        label: "اندونيسيا",
                                                    },
                                                    {
                                                        value: "IE",
                                                        label: "أيرلندا",
                                                    },
                                                    {
                                                        value: "IL",
                                                        label: "اسرائيل",
                                                    },
                                                    {
                                                        value: "IM",
                                                        label: "جزيرة مان",
                                                    },
                                                    {
                                                        value: "IN",
                                                        label: "الهند",
                                                    },
                                                    {
                                                        value: "IO",
                                                        label: "المحيط الهندي البريطاني",
                                                    },
                                                    {
                                                        value: "IQ",
                                                        label: "العراق",
                                                    },
                                                    {
                                                        value: "IR",
                                                        label: "ايران",
                                                    },
                                                    {
                                                        value: "IS",
                                                        label: "أيسلندا",
                                                    },
                                                    {
                                                        value: "IT",
                                                        label: "ايطاليا",
                                                    },
                                                    {
                                                        value: "JE",
                                                        label: "جيرسي",
                                                    },
                                                    {
                                                        value: "JM",
                                                        label: "جامايكا",
                                                    },
                                                    {
                                                        value: "JO",
                                                        label: "الأردن",
                                                    },
                                                    {
                                                        value: "JP",
                                                        label: "اليابان",
                                                    },
                                                    {
                                                        value: "KE",
                                                        label: "كينيا",
                                                    },
                                                    {
                                                        value: "KG",
                                                        label: "قرغيزستان",
                                                    },
                                                    {
                                                        value: "KH",
                                                        label: "كمبوديا",
                                                    },
                                                    {
                                                        value: "KI",
                                                        label: "كيريباتي",
                                                    },
                                                    {
                                                        value: "KM",
                                                        label: "جزر القمر",
                                                    },
                                                    {
                                                        value: "KN",
                                                        label: "سانت كيتس ونيفيس",
                                                    },
                                                    {
                                                        value: "KP",
                                                        label: "كوريا الشمالية",
                                                    },
                                                    {
                                                        value: "KR",
                                                        label: "كوريا الجنوبية",
                                                    },
                                                    {
                                                        value: "KW",
                                                        label: "الكويت",
                                                    },
                                                    {
                                                        value: "KY",
                                                        label: "جزر الكايمن",
                                                    },
                                                    {
                                                        value: "KZ",
                                                        label: "كازاخستان",
                                                    },
                                                    {
                                                        value: "LA",
                                                        label: "لاوس",
                                                    },
                                                    {
                                                        value: "LB",
                                                        label: "لبنان",
                                                    },
                                                    {
                                                        value: "LC",
                                                        label: "سانت لوسيا",
                                                    },
                                                    {
                                                        value: "LI",
                                                        label: "ليختنشتاين",
                                                    },
                                                    {
                                                        value: "LK",
                                                        label: "سريلانكا",
                                                    },
                                                    {
                                                        value: "LR",
                                                        label: "ليبيريا",
                                                    },
                                                    {
                                                        value: "LS",
                                                        label: "ليسوتو",
                                                    },
                                                    {
                                                        value: "LT",
                                                        label: "ليتوانيا",
                                                    },
                                                    {
                                                        value: "LU",
                                                        label: "لوكسمبورج",
                                                    },
                                                    {
                                                        value: "LV",
                                                        label: "لاتفيا",
                                                    },
                                                    {
                                                        value: "LY",
                                                        label: "ليبيا",
                                                    },
                                                    {
                                                        value: "MA",
                                                        label: "المغرب",
                                                    },
                                                    {
                                                        value: "MC",
                                                        label: "موناكو",
                                                    },
                                                    {
                                                        value: "MD",
                                                        label: "مولدافيا",
                                                    },
                                                    {
                                                        value: "ME",
                                                        label: "الجبل الأسود",
                                                    },
                                                    {
                                                        value: "MF",
                                                        label: "سانت مارتين",
                                                    },
                                                    {
                                                        value: "MG",
                                                        label: "مدغشقر",
                                                    },
                                                    {
                                                        value: "MH",
                                                        label: "جزر المارشال",
                                                    },
                                                    {
                                                        value: "MK",
                                                        label: "مقدونيا",
                                                    },
                                                    {
                                                        value: "ML",
                                                        label: "مالي",
                                                    },
                                                    {
                                                        value: "MM",
                                                        label: "ميانمار",
                                                    },
                                                    {
                                                        value: "MN",
                                                        label: "منغوليا",
                                                    },
                                                    {
                                                        value: "MO",
                                                        label: "ماكاو الصينية",
                                                    },
                                                    {
                                                        value: "MP",
                                                        label: "جزر ماريانا الشمالية",
                                                    },
                                                    {
                                                        value: "MQ",
                                                        label: "مارتينيك",
                                                    },
                                                    {
                                                        value: "MR",
                                                        label: "موريتانيا",
                                                    },
                                                    {
                                                        value: "MS",
                                                        label: "مونتسرات",
                                                    },
                                                    {
                                                        value: "MT",
                                                        label: "مالطا",
                                                    },
                                                    {
                                                        value: "MU",
                                                        label: "موريشيوس",
                                                    },
                                                    {
                                                        value: "MV",
                                                        label: "جزر الملديف",
                                                    },
                                                    {
                                                        value: "MW",
                                                        label: "ملاوي",
                                                    },
                                                    {
                                                        value: "MX",
                                                        label: "المكسيك",
                                                    },
                                                    {
                                                        value: "MY",
                                                        label: "ماليزيا",
                                                    },
                                                    {
                                                        value: "MZ",
                                                        label: "موزمبيق",
                                                    },
                                                    {
                                                        value: "NA",
                                                        label: "ناميبيا",
                                                    },
                                                    {
                                                        value: "NC",
                                                        label: "كاليدونيا الجديدة",
                                                    },
                                                    {
                                                        value: "NE",
                                                        label: "النيجر",
                                                    },
                                                    {
                                                        value: "NF",
                                                        label: "جزيرة نورفوك",
                                                    },
                                                    {
                                                        value: "NG",
                                                        label: "نيجيريا",
                                                    },
                                                    {
                                                        value: "NI",
                                                        label: "نيكاراجوا",
                                                    },
                                                    {
                                                        value: "NL",
                                                        label: "هولندا",
                                                    },
                                                    {
                                                        value: "NO",
                                                        label: "النرويج",
                                                    },
                                                    {
                                                        value: "NP",
                                                        label: "نيبال",
                                                    },
                                                    {
                                                        value: "NR",
                                                        label: "نورو",
                                                    },
                                                    {
                                                        value: "NU",
                                                        label: "نيوي",
                                                    },
                                                    {
                                                        value: "NZ",
                                                        label: "نيوزيلاندا",
                                                    },
                                                    {
                                                        value: "OM",
                                                        label: "عمان",
                                                    },
                                                    {
                                                        value: "PA",
                                                        label: "بنما",
                                                    },
                                                    {
                                                        value: "PE",
                                                        label: "بيرو",
                                                    },
                                                    {
                                                        value: "PF",
                                                        label: "بولينيزيا الفرنسية",
                                                    },
                                                    {
                                                        value: "PG",
                                                        label: "بابوا غينيا الجديدة",
                                                    },
                                                    {
                                                        value: "PH",
                                                        label: "الفيلبين",
                                                    },
                                                    {
                                                        value: "PK",
                                                        label: "باكستان",
                                                    },
                                                    {
                                                        value: "PL",
                                                        label: "بولندا",
                                                    },
                                                    {
                                                        value: "PM",
                                                        label: "سانت بيير وميكولون",
                                                    },
                                                    {
                                                        value: "PN",
                                                        label: "بتكايرن",
                                                    },
                                                    {
                                                        value: "PR",
                                                        label: "بورتوريكو",
                                                    },
                                                    {
                                                        value: "PS",
                                                        label: "فلسطين",
                                                    },
                                                    {
                                                        value: "PT",
                                                        label: "البرتغال",
                                                    },
                                                    {
                                                        value: "PW",
                                                        label: "بالاو",
                                                    },
                                                    {
                                                        value: "PY",
                                                        label: "باراجواي",
                                                    },
                                                    {
                                                        value: "QA",
                                                        label: "قطر",
                                                    },
                                                    {
                                                        value: "RE",
                                                        label: "روينيون",
                                                    },
                                                    {
                                                        value: "RO",
                                                        label: "رومانيا",
                                                    },
                                                    {
                                                        value: "RS",
                                                        label: "صربيا",
                                                    },
                                                    {
                                                        value: "RU",
                                                        label: "روسيا",
                                                    },
                                                    {
                                                        value: "RW",
                                                        label: "رواندا",
                                                    },
                                                    {
                                                        value: "SA",
                                                        label: "المملكة العربية السعودية",
                                                    },
                                                    {
                                                        value: "SB",
                                                        label: "جزر سليمان",
                                                    },
                                                    {
                                                        value: "SC",
                                                        label: "سيشل",
                                                    },
                                                    {
                                                        value: "SD",
                                                        label: "السودان",
                                                    },
                                                    {
                                                        value: "SE",
                                                        label: "السويد",
                                                    },
                                                    {
                                                        value: "SG",
                                                        label: "سنغافورة",
                                                    },
                                                    {
                                                        value: "SH",
                                                        label: "سانت هيلنا",
                                                    },
                                                    {
                                                        value: "SI",
                                                        label: "سلوفينيا",
                                                    },
                                                    {
                                                        value: "SJ",
                                                        label: "سفالبارد وجان مايان",
                                                    },
                                                    {
                                                        value: "SK",
                                                        label: "سلوفاكيا",
                                                    },
                                                    {
                                                        value: "SL",
                                                        label: "سيراليون",
                                                    },
                                                    {
                                                        value: "SM",
                                                        label: "سان مارينو",
                                                    },
                                                    {
                                                        value: "SN",
                                                        label: "السنغال",
                                                    },
                                                    {
                                                        value: "SO",
                                                        label: "الصومال",
                                                    },
                                                    {
                                                        value: "SR",
                                                        label: "سورينام",
                                                    },
                                                    {
                                                        value: "SS",
                                                        label: "جنوب السودان",
                                                    },
                                                    {
                                                        value: "ST",
                                                        label: "ساو تومي وبرينسيبي",
                                                    },
                                                    {
                                                        value: "SV",
                                                        label: "السلفادور",
                                                    },
                                                    {
                                                        value: "SX",
                                                        label: "سينت مارتن",
                                                    },
                                                    {
                                                        value: "SY",
                                                        label: "سوريا",
                                                    },
                                                    {
                                                        value: "SZ",
                                                        label: "سوازيلاند",
                                                    },
                                                    {
                                                        value: "TC",
                                                        label: "جزر الترك وجايكوس",
                                                    },
                                                    {
                                                        value: "TD",
                                                        label: "تشاد",
                                                    },
                                                    {
                                                        value: "TF",
                                                        label: "المقاطعات الجنوبية الفرنسية",
                                                    },
                                                    {
                                                        value: "TG",
                                                        label: "توجو",
                                                    },
                                                    {
                                                        value: "TH",
                                                        label: "تايلند",
                                                    },
                                                    {
                                                        value: "TJ",
                                                        label: "طاجكستان",
                                                    },
                                                    {
                                                        value: "TK",
                                                        label: "توكيلو",
                                                    },
                                                    {
                                                        value: "TL",
                                                        label: "تيمور الشرقية",
                                                    },
                                                    {
                                                        value: "TM",
                                                        label: "تركمانستان",
                                                    },
                                                    {
                                                        value: "TN",
                                                        label: "تونس",
                                                    },
                                                    {
                                                        value: "TO",
                                                        label: "تونجا",
                                                    },
                                                    {
                                                        value: "TR",
                                                        label: "تركيا",
                                                    },
                                                    {
                                                        value: "TT",
                                                        label: "ترينيداد وتوباغو",
                                                    },
                                                    {
                                                        value: "TV",
                                                        label: "توفالو",
                                                    },
                                                    {
                                                        value: "TW",
                                                        label: "تايوان",
                                                    },
                                                    {
                                                        value: "TZ",
                                                        label: "تانزانيا",
                                                    },
                                                    {
                                                        value: "UA",
                                                        label: "أوكرانيا",
                                                    },
                                                    {
                                                        value: "UG",
                                                        label: "أوغندا",
                                                    },
                                                    {
                                                        value: "UM",
                                                        label: "جزر الولايات المتحدة البعيدة الصغيرة",
                                                    },
                                                    {
                                                        value: "US",
                                                        label: "الولايات المتحدة الأمريكية",
                                                    },
                                                    {
                                                        value: "UY",
                                                        label: "أورجواي",
                                                    },
                                                    {
                                                        value: "UZ",
                                                        label: "أوزبكستان",
                                                    },
                                                    {
                                                        value: "VA",
                                                        label: "الفاتيكان",
                                                    },
                                                    {
                                                        value: "VC",
                                                        label: "سانت فنسنت وغرنادين",
                                                    },
                                                    {
                                                        value: "VE",
                                                        label: "فنزويلا",
                                                    },
                                                    {
                                                        value: "VG",
                                                        label: "جزر فرجين البريطانية",
                                                    },
                                                    {
                                                        value: "VI",
                                                        label: "جزر فرجين الأمريكية",
                                                    },
                                                    {
                                                        value: "VN",
                                                        label: "فيتنام",
                                                    },
                                                    {
                                                        value: "VU",
                                                        label: "فانواتو",
                                                    },
                                                    {
                                                        value: "WF",
                                                        label: "جزر والس وفوتونا",
                                                    },
                                                    {
                                                        value: "WS",
                                                        label: "ساموا",
                                                    },
                                                    {
                                                        value: "XK",
                                                        label: "كوسوفو",
                                                    },
                                                    {
                                                        value: "YE",
                                                        label: "اليمن",
                                                    },
                                                    {
                                                        value: "YT",
                                                        label: "مايوت",
                                                    },
                                                    {
                                                        value: "ZA",
                                                        label: "جمهورية جنوب افريقيا",
                                                    },
                                                    {
                                                        value: "ZM",
                                                        label: "زامبيا",
                                                    },
                                                    {
                                                        value: "ZW",
                                                        label: "زيمبابوي",
                                                    }
                                                ]}/>
                                        </div>
                                        <div className="col-md-4">
                                            <input className="w-100 icon-black" onChange={(e) => {
                                                setGuidedDateBirth(e.target.value)
                                            }} onFocus={() => {
                                                setTypeInput1("date")
                                            }}
                                                   onBlur={() => {
                                                       setTypeInput1("text")
                                                   }}
                                                   value={getGuidedDateBirth} type={getTypeInput1}
                                                   placeholder="تاريخ الولادة"
                                                   required
                                                   maxLength="500"/>
                                        </div>
                                        <div className="col-md-4">
                                            <input className="w-100" onChange={(e) => {
                                                setGuidedClairvoyantWritings(e.target.value)
                                            }} value={getGuidedClairvoyantWritings} type="text"
                                                   placeholder="المولفات" required
                                                   maxLength="500"/>
                                        </div>
                                        <div className="col-md-12">
                                            <input className="w-100" onChange={(e) => {
                                                setGuidedDescription(e.target.value)
                                            }} value={getGuidedDescription} type="text"
                                                   placeholder="اسباب و قصة الاستبصار" required
                                                   maxLength="1000"/>
                                        </div>
                                        <div
                                            className="col-12 d-sm-flex push-bottom-in-form d-block justify-content-between align-items-center mt-5">
                                            <div className="checkbox c-pointer" onClick={() => {
                                                setGuidedPublic(!getGuidedPublic)
                                            }}>
                                                {getGuidedPublic ?
                                                    <input className="checkbox-pop checkbox-pop-2"
                                                           checked={true} type="checkbox"/>
                                                    :
                                                    <input className="checkbox-pop checkbox-pop-2"
                                                           checked={false} type="checkbox"/>
                                                }

                                                <label htmlFor="check2" className="color-label-f2 c-pointer">
                                                    <span className="ms-0"></span>
                                                    ترغب بعرض هذه المعلومات في الموقع
                                                </label>
                                            </div>
                                            <button type="submit" aria-label="إرسال المعلومات"
                                                    title="إرسال المعلومات"
                                                    className="more-btn border-0 fw-light mt-4 mt-sm-0">
                                                إرسال المعلومات
                                            </button>
                                        </div>

                                    </form>
                                </div>

                                : getFormTab == 4 ?
                                    <div className="form-body-item">
                                        <div className="form-title mt-3 pt-1">
                                            إضافة معلومات عن الشيعة
                                        </div>
                                        <div className="form-disc">
                                            يمكنكم هنا إضافة معلومات بشأن الشيعة في العالم
                                        </div>
                                        <div className="user-name-container mt-5 c-999 d-sm-flex d-inline-block">
                                            <svg className=" ms-sm-2 phone-m-auto ms-0 d-sm-inline-block d-block w-35"
                                                 width="35" height="43" viewBox="0 0 56 55"
                                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="28.0237" cy="27.4924" r="27.4924" fill="#045E5E"/>
                                                <path
                                                    d="M37.7361 37.4626C37.1431 35.8027 35.8363 34.3359 34.0185 33.2897C32.2007 32.2436 29.9734 31.6765 27.6821 31.6765C25.3908 31.6765 23.1635 32.2436 21.3457 33.2897C19.5279 34.3359 18.2211 35.8026 17.6281 37.4626"
                                                    stroke="white" strokeOpacity="0.9" strokeWidth="1.54887"
                                                    strokeLinecap="round"/>
                                                <circle cx="27.6809" cy="21.2673" r="5.20433" stroke="white"
                                                        strokeOpacity="0.9"
                                                        strokeWidth="1.54887" strokeLinecap="round"/>
                                            </svg>
                                            <span className="f-16" title="محمد ابراهيم سليماني">
                                    {
                                        nameIsLogin + " " + lastNameIsLogin
                                    }
                                </span>
                                            <div
                                                className="d-flex justify-content-center align-items-center gap-3 religion-container-11">
                                                <div
                                                    className="d-flex align-items-center phone-jus-center phone-m-b-2 me-sm-5 me-0">
                                                    <ReactCountryFlag
                                                        countryCode={CountryIsLogin}
                                                        svg
                                                        style={{
                                                            width: '1.6em',
                                                            height: '1.6em',
                                                            borderRadius: '8px'
                                                        }}
                                                        title={countries.getName(CountryIsLogin, 'ar')}
                                                        alt={countries.getName(CountryIsLogin, 'ar')}
                                                    />
                                                    <span
                                                        className="pe-2">{countries.getName(CountryIsLogin, 'ar')}</span>
                                                </div>
                                                <div className="date-container-news text-right me-sm-5 me-0 pb-0">
                                                    <svg className="ms-2" width="26" height="26" viewBox="0 0 26 26"
                                                         fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M21.137 21.298H7.52466C6.62485 19.7033 6.47368 17.8228 7.14314 16.1351C8.3021 13.2247 10.9368 10.9189 14.3272 10.0883C17.7177 10.9189 20.3524 13.2247 21.5114 16.1351C22.188 17.8228 22.0368 19.7033 21.137 21.298Z"
                                                            stroke="#737373" strokeWidth="0.9" stroke-miterlimit="10"
                                                            strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path
                                                            d="M8.58166 6.38051H4.86723C4.62248 5.94195 4.57929 5.43031 4.76645 4.97182C5.08318 4.17444 5.80303 3.54984 6.73164 3.31727C7.66025 3.54319 8.3801 4.17444 8.69683 4.97182C8.8696 5.43031 8.82641 5.9486 8.58166 6.38051Z"
                                                            stroke="#737373" strokeWidth="0.9" stroke-miterlimit="10"
                                                            strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path
                                                            d="M7.14342 16.135C7.47456 15.2978 7.93526 14.5137 8.48954 13.7961V6.38051H4.95508V21.298H7.51775C6.61793 19.7033 6.46676 17.8228 7.14342 16.135Z"
                                                            stroke="#737373" strokeWidth="0.9" stroke-miterlimit="10"
                                                            strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M14.3262 10.0883V8.2743" stroke="#737373"
                                                              stroke-miterlimit="10"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                        <path d="M6.72656 3.31728V1.5099" stroke="#737373"
                                                              strokeWidth="0.9"
                                                              stroke-miterlimit="10" strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                        <path d="M22.9658 21.298H3.30664V24.2749H22.9658V21.298Z"
                                                              stroke="#737373"
                                                              strokeWidth="0.9" stroke-miterlimit="10"
                                                              strokeLinecap="round"
                                                              strokeLinejoin="round"/>
                                                    </svg>
                                                    {religionIsLogin}
                                                </div>
                                            </div>
                                            <div className="date-container-news text-right me-sm-5 me-0 pb-0">
                                                <svg className="ms-2" width="22" height="20" viewBox="0 0 24 22"
                                                     fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M4.32292 4.49247H19.6562C20.7104 4.49247 21.5729 5.27997 21.5729 6.24247V16.7425C21.5729 17.705 20.7104 18.4925 19.6562 18.4925H4.32292C3.26875 18.4925 2.40625 17.705 2.40625 16.7425V6.24247C2.40625 5.27997 3.26875 4.49247 4.32292 4.49247Z"
                                                        stroke="#949494" strokeLinecap="round" strokeLinejoin="round"/>
                                                    <path d="M21.5729 6.24243L11.9896 12.3674L2.40625 6.24243"
                                                          stroke="#949494"
                                                          strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>
                                                <span className="f-12-g">
                                        {emailIsLogin}
                                    </span>
                                            </div>
                                        </div>
                                        <form className="row h-100 form-container form-container-c-black"
                                              onSubmit={handleSubmitShia}>
                                            <div className="col-md-4 child-m-0-in">
                                                <Select
                                                    showSearch
                                                    placeholder="الدولة"
                                                    defaultValue="IR"
                                                    onChange={handleChangeCountry21}
                                                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                    filterSort={(optionA, optionB) =>
                                                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                    }
                                                    options={[
                                                        {
                                                            value: "AD",
                                                            label: "أندورا",
                                                        },
                                                        {
                                                            value: "AE",
                                                            label: "الامارات العربية المتحدة",
                                                        },
                                                        {
                                                            value: "AF",
                                                            label: "أفغانستان",
                                                        },
                                                        {
                                                            value: "AG",
                                                            label: "أنتيجوا وبربودا",
                                                        },
                                                        {
                                                            value: "AI",
                                                            label: "أنجويلا",
                                                        },
                                                        {
                                                            value: "AL",
                                                            label: "ألبانيا",
                                                        },
                                                        {
                                                            value: "AM",
                                                            label: "أرمينيا",
                                                        },
                                                        {
                                                            value: "AO",
                                                            label: "أنجولا",
                                                        },
                                                        {
                                                            value: "AQ",
                                                            label: "القطب الجنوبي",
                                                        },
                                                        {
                                                            value: "AR",
                                                            label: "الأرجنتين",
                                                        },
                                                        {
                                                            value: "AS",
                                                            label: "ساموا الأمريكية",
                                                        },
                                                        {
                                                            value: "AT",
                                                            label: "النمسا",
                                                        },
                                                        {
                                                            value: "AU",
                                                            label: "أستراليا",
                                                        },
                                                        {
                                                            value: "AW",
                                                            label: "آروبا",
                                                        },
                                                        {
                                                            value: "AX",
                                                            label: "جزر أولان",
                                                        },
                                                        {
                                                            value: "AZ",
                                                            label: "أذربيجان",
                                                        },
                                                        {
                                                            value: "BA",
                                                            label: "البوسنة والهرسك",
                                                        },
                                                        {
                                                            value: "BB",
                                                            label: "بربادوس",
                                                        },
                                                        {
                                                            value: "BD",
                                                            label: "بنجلاديش",
                                                        },
                                                        {
                                                            value: "BE",
                                                            label: "بلجيكا",
                                                        },
                                                        {
                                                            value: "BF",
                                                            label: "بوركينا فاسو",
                                                        },
                                                        {
                                                            value: "BG",
                                                            label: "بلغاريا",
                                                        },
                                                        {
                                                            value: "BH",
                                                            label: "البحرين",
                                                        },
                                                        {
                                                            value: "BI",
                                                            label: "بوروندي",
                                                        },
                                                        {
                                                            value: "BJ",
                                                            label: "بنين",
                                                        },
                                                        {
                                                            value: "BL",
                                                            label: "سان بارتيلمي",
                                                        },
                                                        {
                                                            value: "BM",
                                                            label: "برمودا",
                                                        },
                                                        {
                                                            value: "BN",
                                                            label: "بروناي",
                                                        },
                                                        {
                                                            value: "BO",
                                                            label: "بوليفيا",
                                                        },
                                                        {
                                                            value: "BQ",
                                                            label: "بونير",
                                                        },
                                                        {
                                                            value: "BR",
                                                            label: "البرازيل",
                                                        },
                                                        {
                                                            value: "BS",
                                                            label: "الباهاما",
                                                        },
                                                        {
                                                            value: "BT",
                                                            label: "بوتان",
                                                        },
                                                        {
                                                            value: "BV",
                                                            label: "جزيرة بوفيه",
                                                        },
                                                        {
                                                            value: "BW",
                                                            label: "بتسوانا",
                                                        },
                                                        {
                                                            value: "BY",
                                                            label: "روسيا البيضاء",
                                                        },
                                                        {
                                                            value: "BZ",
                                                            label: "بليز",
                                                        },
                                                        {
                                                            value: "CA",
                                                            label: "كندا",
                                                        },
                                                        {
                                                            value: "CC",
                                                            label: "جزر كوكوس",
                                                        },
                                                        {
                                                            value: "CD",
                                                            label: "جمهورية الكونغو الديمقراطية",
                                                        },
                                                        {
                                                            value: "CF",
                                                            label: "جمهورية افريقيا الوسطى",
                                                        },
                                                        {
                                                            value: "CG",
                                                            label: "الكونغو - برازافيل",
                                                        },
                                                        {
                                                            value: "CH",
                                                            label: "سويسرا",
                                                        },
                                                        {
                                                            value: "CI",
                                                            label: "ساحل العاج",
                                                        },
                                                        {
                                                            value: "CK",
                                                            label: "جزر كوك",
                                                        },
                                                        {
                                                            value: "CL",
                                                            label: "شيلي",
                                                        },
                                                        {
                                                            value: "CM",
                                                            label: "الكاميرون",
                                                        },
                                                        {
                                                            value: "CN",
                                                            label: "الصين",
                                                        },
                                                        {
                                                            value: "CO",
                                                            label: "كولومبيا",
                                                        },
                                                        {
                                                            value: "CR",
                                                            label: "كوستاريكا",
                                                        },
                                                        {
                                                            value: "CU",
                                                            label: "كوبا",
                                                        },
                                                        {
                                                            value: "CV",
                                                            label: "الرأس الأخضر",
                                                        },
                                                        {
                                                            value: "CW",
                                                            label: "كوراساو",
                                                        },
                                                        {
                                                            value: "CX",
                                                            label: "جزيرة الكريسماس",
                                                        },
                                                        {
                                                            value: "CY",
                                                            label: "قبرص",
                                                        },
                                                        {
                                                            value: "CZ",
                                                            label: "جمهورية التشيك",
                                                        },
                                                        {
                                                            value: "DE",
                                                            label: "ألمانيا",
                                                        },
                                                        {
                                                            value: "DJ",
                                                            label: "جيبوتي",
                                                        },
                                                        {
                                                            value: "DK",
                                                            label: "الدانمرك",
                                                        },
                                                        {
                                                            value: "DM",
                                                            label: "دومينيكا",
                                                        },
                                                        {
                                                            value: "DO",
                                                            label: "جمهورية الدومينيك",
                                                        },
                                                        {
                                                            value: "DZ",
                                                            label: "الجزائر",
                                                        },
                                                        {
                                                            value: "EC",
                                                            label: "الاكوادور",
                                                        },
                                                        {
                                                            value: "EE",
                                                            label: "استونيا",
                                                        },
                                                        {
                                                            value: "EG",
                                                            label: "مصر",
                                                        },
                                                        {
                                                            value: "EH",
                                                            label: "الصحراء الغربية",
                                                        },
                                                        {
                                                            value: "ER",
                                                            label: "اريتريا",
                                                        },
                                                        {
                                                            value: "ES",
                                                            label: "أسبانيا",
                                                        },
                                                        {
                                                            value: "ET",
                                                            label: "اثيوبيا",
                                                        },
                                                        {
                                                            value: "FI",
                                                            label: "فنلندا",
                                                        },
                                                        {
                                                            value: "FJ",
                                                            label: "فيجي",
                                                        },
                                                        {
                                                            value: "FK",
                                                            label: "جزر فوكلاند",
                                                        },
                                                        {
                                                            value: "FM",
                                                            label: "ميكرونيزيا",
                                                        },
                                                        {
                                                            value: "FO",
                                                            label: "جزر فارو",
                                                        },
                                                        {
                                                            value: "FR",
                                                            label: "فرنسا",
                                                        },
                                                        {
                                                            value: "GA",
                                                            label: "الجابون",
                                                        },
                                                        {
                                                            value: "GB",
                                                            label: "المملكة المتحدة",
                                                        },
                                                        {
                                                            value: "GD",
                                                            label: "جرينادا",
                                                        },
                                                        {
                                                            value: "GE",
                                                            label: "جورجيا",
                                                        },
                                                        {
                                                            value: "GF",
                                                            label: "غويانا",
                                                        },
                                                        {
                                                            value: "GG",
                                                            label: "غيرنزي",
                                                        },
                                                        {
                                                            value: "GH",
                                                            label: "غانا",
                                                        },
                                                        {
                                                            value: "GI",
                                                            label: "جبل طارق",
                                                        },
                                                        {
                                                            value: "GL",
                                                            label: "جرينلاند",
                                                        },
                                                        {
                                                            value: "GM",
                                                            label: "غامبيا",
                                                        },
                                                        {
                                                            value: "GN",
                                                            label: "غينيا",
                                                        },
                                                        {
                                                            value: "GP",
                                                            label: "جوادلوب",
                                                        },
                                                        {
                                                            value: "GQ",
                                                            label: "غينيا الاستوائية",
                                                        },
                                                        {
                                                            value: "GR",
                                                            label: "اليونان",
                                                        },
                                                        {
                                                            value: "GS",
                                                            label: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
                                                        },
                                                        {
                                                            value: "GT",
                                                            label: "جواتيمالا",
                                                        },
                                                        {
                                                            value: "GU",
                                                            label: "جوام",
                                                        },
                                                        {
                                                            value: "GW",
                                                            label: "غينيا بيساو",
                                                        },
                                                        {
                                                            value: "GY",
                                                            label: "غيانا",
                                                        },
                                                        {
                                                            value: "HK",
                                                            label: "هونج كونج الصينية",
                                                        },
                                                        {
                                                            value: "HM",
                                                            label: "جزيرة هيرد وماكدونالد",
                                                        },
                                                        {
                                                            value: "HN",
                                                            label: "هندوراس",
                                                        },
                                                        {
                                                            value: "HR",
                                                            label: "كرواتيا",
                                                        },
                                                        {
                                                            value: "HT",
                                                            label: "هايتي",
                                                        },
                                                        {
                                                            value: "HU",
                                                            label: "المجر",
                                                        },
                                                        {
                                                            value: "ID",
                                                            label: "اندونيسيا",
                                                        },
                                                        {
                                                            value: "IE",
                                                            label: "أيرلندا",
                                                        },
                                                        {
                                                            value: "IL",
                                                            label: "اسرائيل",
                                                        },
                                                        {
                                                            value: "IM",
                                                            label: "جزيرة مان",
                                                        },
                                                        {
                                                            value: "IN",
                                                            label: "الهند",
                                                        },
                                                        {
                                                            value: "IO",
                                                            label: "المحيط الهندي البريطاني",
                                                        },
                                                        {
                                                            value: "IQ",
                                                            label: "العراق",
                                                        },
                                                        {
                                                            value: "IR",
                                                            label: "ايران",
                                                        },
                                                        {
                                                            value: "IS",
                                                            label: "أيسلندا",
                                                        },
                                                        {
                                                            value: "IT",
                                                            label: "ايطاليا",
                                                        },
                                                        {
                                                            value: "JE",
                                                            label: "جيرسي",
                                                        },
                                                        {
                                                            value: "JM",
                                                            label: "جامايكا",
                                                        },
                                                        {
                                                            value: "JO",
                                                            label: "الأردن",
                                                        },
                                                        {
                                                            value: "JP",
                                                            label: "اليابان",
                                                        },
                                                        {
                                                            value: "KE",
                                                            label: "كينيا",
                                                        },
                                                        {
                                                            value: "KG",
                                                            label: "قرغيزستان",
                                                        },
                                                        {
                                                            value: "KH",
                                                            label: "كمبوديا",
                                                        },
                                                        {
                                                            value: "KI",
                                                            label: "كيريباتي",
                                                        },
                                                        {
                                                            value: "KM",
                                                            label: "جزر القمر",
                                                        },
                                                        {
                                                            value: "KN",
                                                            label: "سانت كيتس ونيفيس",
                                                        },
                                                        {
                                                            value: "KP",
                                                            label: "كوريا الشمالية",
                                                        },
                                                        {
                                                            value: "KR",
                                                            label: "كوريا الجنوبية",
                                                        },
                                                        {
                                                            value: "KW",
                                                            label: "الكويت",
                                                        },
                                                        {
                                                            value: "KY",
                                                            label: "جزر الكايمن",
                                                        },
                                                        {
                                                            value: "KZ",
                                                            label: "كازاخستان",
                                                        },
                                                        {
                                                            value: "LA",
                                                            label: "لاوس",
                                                        },
                                                        {
                                                            value: "LB",
                                                            label: "لبنان",
                                                        },
                                                        {
                                                            value: "LC",
                                                            label: "سانت لوسيا",
                                                        },
                                                        {
                                                            value: "LI",
                                                            label: "ليختنشتاين",
                                                        },
                                                        {
                                                            value: "LK",
                                                            label: "سريلانكا",
                                                        },
                                                        {
                                                            value: "LR",
                                                            label: "ليبيريا",
                                                        },
                                                        {
                                                            value: "LS",
                                                            label: "ليسوتو",
                                                        },
                                                        {
                                                            value: "LT",
                                                            label: "ليتوانيا",
                                                        },
                                                        {
                                                            value: "LU",
                                                            label: "لوكسمبورج",
                                                        },
                                                        {
                                                            value: "LV",
                                                            label: "لاتفيا",
                                                        },
                                                        {
                                                            value: "LY",
                                                            label: "ليبيا",
                                                        },
                                                        {
                                                            value: "MA",
                                                            label: "المغرب",
                                                        },
                                                        {
                                                            value: "MC",
                                                            label: "موناكو",
                                                        },
                                                        {
                                                            value: "MD",
                                                            label: "مولدافيا",
                                                        },
                                                        {
                                                            value: "ME",
                                                            label: "الجبل الأسود",
                                                        },
                                                        {
                                                            value: "MF",
                                                            label: "سانت مارتين",
                                                        },
                                                        {
                                                            value: "MG",
                                                            label: "مدغشقر",
                                                        },
                                                        {
                                                            value: "MH",
                                                            label: "جزر المارشال",
                                                        },
                                                        {
                                                            value: "MK",
                                                            label: "مقدونيا",
                                                        },
                                                        {
                                                            value: "ML",
                                                            label: "مالي",
                                                        },
                                                        {
                                                            value: "MM",
                                                            label: "ميانمار",
                                                        },
                                                        {
                                                            value: "MN",
                                                            label: "منغوليا",
                                                        },
                                                        {
                                                            value: "MO",
                                                            label: "ماكاو الصينية",
                                                        },
                                                        {
                                                            value: "MP",
                                                            label: "جزر ماريانا الشمالية",
                                                        },
                                                        {
                                                            value: "MQ",
                                                            label: "مارتينيك",
                                                        },
                                                        {
                                                            value: "MR",
                                                            label: "موريتانيا",
                                                        },
                                                        {
                                                            value: "MS",
                                                            label: "مونتسرات",
                                                        },
                                                        {
                                                            value: "MT",
                                                            label: "مالطا",
                                                        },
                                                        {
                                                            value: "MU",
                                                            label: "موريشيوس",
                                                        },
                                                        {
                                                            value: "MV",
                                                            label: "جزر الملديف",
                                                        },
                                                        {
                                                            value: "MW",
                                                            label: "ملاوي",
                                                        },
                                                        {
                                                            value: "MX",
                                                            label: "المكسيك",
                                                        },
                                                        {
                                                            value: "MY",
                                                            label: "ماليزيا",
                                                        },
                                                        {
                                                            value: "MZ",
                                                            label: "موزمبيق",
                                                        },
                                                        {
                                                            value: "NA",
                                                            label: "ناميبيا",
                                                        },
                                                        {
                                                            value: "NC",
                                                            label: "كاليدونيا الجديدة",
                                                        },
                                                        {
                                                            value: "NE",
                                                            label: "النيجر",
                                                        },
                                                        {
                                                            value: "NF",
                                                            label: "جزيرة نورفوك",
                                                        },
                                                        {
                                                            value: "NG",
                                                            label: "نيجيريا",
                                                        },
                                                        {
                                                            value: "NI",
                                                            label: "نيكاراجوا",
                                                        },
                                                        {
                                                            value: "NL",
                                                            label: "هولندا",
                                                        },
                                                        {
                                                            value: "NO",
                                                            label: "النرويج",
                                                        },
                                                        {
                                                            value: "NP",
                                                            label: "نيبال",
                                                        },
                                                        {
                                                            value: "NR",
                                                            label: "نورو",
                                                        },
                                                        {
                                                            value: "NU",
                                                            label: "نيوي",
                                                        },
                                                        {
                                                            value: "NZ",
                                                            label: "نيوزيلاندا",
                                                        },
                                                        {
                                                            value: "OM",
                                                            label: "عمان",
                                                        },
                                                        {
                                                            value: "PA",
                                                            label: "بنما",
                                                        },
                                                        {
                                                            value: "PE",
                                                            label: "بيرو",
                                                        },
                                                        {
                                                            value: "PF",
                                                            label: "بولينيزيا الفرنسية",
                                                        },
                                                        {
                                                            value: "PG",
                                                            label: "بابوا غينيا الجديدة",
                                                        },
                                                        {
                                                            value: "PH",
                                                            label: "الفيلبين",
                                                        },
                                                        {
                                                            value: "PK",
                                                            label: "باكستان",
                                                        },
                                                        {
                                                            value: "PL",
                                                            label: "بولندا",
                                                        },
                                                        {
                                                            value: "PM",
                                                            label: "سانت بيير وميكولون",
                                                        },
                                                        {
                                                            value: "PN",
                                                            label: "بتكايرن",
                                                        },
                                                        {
                                                            value: "PR",
                                                            label: "بورتوريكو",
                                                        },
                                                        {
                                                            value: "PS",
                                                            label: "فلسطين",
                                                        },
                                                        {
                                                            value: "PT",
                                                            label: "البرتغال",
                                                        },
                                                        {
                                                            value: "PW",
                                                            label: "بالاو",
                                                        },
                                                        {
                                                            value: "PY",
                                                            label: "باراجواي",
                                                        },
                                                        {
                                                            value: "QA",
                                                            label: "قطر",
                                                        },
                                                        {
                                                            value: "RE",
                                                            label: "روينيون",
                                                        },
                                                        {
                                                            value: "RO",
                                                            label: "رومانيا",
                                                        },
                                                        {
                                                            value: "RS",
                                                            label: "صربيا",
                                                        },
                                                        {
                                                            value: "RU",
                                                            label: "روسيا",
                                                        },
                                                        {
                                                            value: "RW",
                                                            label: "رواندا",
                                                        },
                                                        {
                                                            value: "SA",
                                                            label: "المملكة العربية السعودية",
                                                        },
                                                        {
                                                            value: "SB",
                                                            label: "جزر سليمان",
                                                        },
                                                        {
                                                            value: "SC",
                                                            label: "سيشل",
                                                        },
                                                        {
                                                            value: "SD",
                                                            label: "السودان",
                                                        },
                                                        {
                                                            value: "SE",
                                                            label: "السويد",
                                                        },
                                                        {
                                                            value: "SG",
                                                            label: "سنغافورة",
                                                        },
                                                        {
                                                            value: "SH",
                                                            label: "سانت هيلنا",
                                                        },
                                                        {
                                                            value: "SI",
                                                            label: "سلوفينيا",
                                                        },
                                                        {
                                                            value: "SJ",
                                                            label: "سفالبارد وجان مايان",
                                                        },
                                                        {
                                                            value: "SK",
                                                            label: "سلوفاكيا",
                                                        },
                                                        {
                                                            value: "SL",
                                                            label: "سيراليون",
                                                        },
                                                        {
                                                            value: "SM",
                                                            label: "سان مارينو",
                                                        },
                                                        {
                                                            value: "SN",
                                                            label: "السنغال",
                                                        },
                                                        {
                                                            value: "SO",
                                                            label: "الصومال",
                                                        },
                                                        {
                                                            value: "SR",
                                                            label: "سورينام",
                                                        },
                                                        {
                                                            value: "SS",
                                                            label: "جنوب السودان",
                                                        },
                                                        {
                                                            value: "ST",
                                                            label: "ساو تومي وبرينسيبي",
                                                        },
                                                        {
                                                            value: "SV",
                                                            label: "السلفادور",
                                                        },
                                                        {
                                                            value: "SX",
                                                            label: "سينت مارتن",
                                                        },
                                                        {
                                                            value: "SY",
                                                            label: "سوريا",
                                                        },
                                                        {
                                                            value: "SZ",
                                                            label: "سوازيلاند",
                                                        },
                                                        {
                                                            value: "TC",
                                                            label: "جزر الترك وجايكوس",
                                                        },
                                                        {
                                                            value: "TD",
                                                            label: "تشاد",
                                                        },
                                                        {
                                                            value: "TF",
                                                            label: "المقاطعات الجنوبية الفرنسية",
                                                        },
                                                        {
                                                            value: "TG",
                                                            label: "توجو",
                                                        },
                                                        {
                                                            value: "TH",
                                                            label: "تايلند",
                                                        },
                                                        {
                                                            value: "TJ",
                                                            label: "طاجكستان",
                                                        },
                                                        {
                                                            value: "TK",
                                                            label: "توكيلو",
                                                        },
                                                        {
                                                            value: "TL",
                                                            label: "تيمور الشرقية",
                                                        },
                                                        {
                                                            value: "TM",
                                                            label: "تركمانستان",
                                                        },
                                                        {
                                                            value: "TN",
                                                            label: "تونس",
                                                        },
                                                        {
                                                            value: "TO",
                                                            label: "تونجا",
                                                        },
                                                        {
                                                            value: "TR",
                                                            label: "تركيا",
                                                        },
                                                        {
                                                            value: "TT",
                                                            label: "ترينيداد وتوباغو",
                                                        },
                                                        {
                                                            value: "TV",
                                                            label: "توفالو",
                                                        },
                                                        {
                                                            value: "TW",
                                                            label: "تايوان",
                                                        },
                                                        {
                                                            value: "TZ",
                                                            label: "تانزانيا",
                                                        },
                                                        {
                                                            value: "UA",
                                                            label: "أوكرانيا",
                                                        },
                                                        {
                                                            value: "UG",
                                                            label: "أوغندا",
                                                        },
                                                        {
                                                            value: "UM",
                                                            label: "جزر الولايات المتحدة البعيدة الصغيرة",
                                                        },
                                                        {
                                                            value: "US",
                                                            label: "الولايات المتحدة الأمريكية",
                                                        },
                                                        {
                                                            value: "UY",
                                                            label: "أورجواي",
                                                        },
                                                        {
                                                            value: "UZ",
                                                            label: "أوزبكستان",
                                                        },
                                                        {
                                                            value: "VA",
                                                            label: "الفاتيكان",
                                                        },
                                                        {
                                                            value: "VC",
                                                            label: "سانت فنسنت وغرنادين",
                                                        },
                                                        {
                                                            value: "VE",
                                                            label: "فنزويلا",
                                                        },
                                                        {
                                                            value: "VG",
                                                            label: "جزر فرجين البريطانية",
                                                        },
                                                        {
                                                            value: "VI",
                                                            label: "جزر فرجين الأمريكية",
                                                        },
                                                        {
                                                            value: "VN",
                                                            label: "فيتنام",
                                                        },
                                                        {
                                                            value: "VU",
                                                            label: "فانواتو",
                                                        },
                                                        {
                                                            value: "WF",
                                                            label: "جزر والس وفوتونا",
                                                        },
                                                        {
                                                            value: "WS",
                                                            label: "ساموا",
                                                        },
                                                        {
                                                            value: "XK",
                                                            label: "كوسوفو",
                                                        },
                                                        {
                                                            value: "YE",
                                                            label: "اليمن",
                                                        },
                                                        {
                                                            value: "YT",
                                                            label: "مايوت",
                                                        },
                                                        {
                                                            value: "ZA",
                                                            label: "جمهورية جنوب افريقيا",
                                                        },
                                                        {
                                                            value: "ZM",
                                                            label: "زامبيا",
                                                        },
                                                        {
                                                            value: "ZW",
                                                            label: "زيمبابوي",
                                                        }
                                                    ]}/>
                                            </div>
                                            <div className="col-md-4">
                                                <input className="w-100 icon-black" onChange={(e) => {
                                                    setShiaEntryDate(e.target.value)
                                                }} onFocus={() => {
                                                    setTypeInput("date")
                                                }}
                                                       onBlur={() => {
                                                           setTypeInput("text")
                                                       }}
                                                       value={getShiaEntryDate} type={getTypeInput}
                                                       placeholder="تاريخ دخول التشيع"
                                                       required
                                                       maxLength="500"/>
                                            </div>
                                            <div className="col-md-4">
                                                <input className="w-100" onChange={(e) => {
                                                    setShiaNumber(e.target.value)
                                                }} value={getShiaNumber} type="text"
                                                       placeholder="عدد الشيعة" required
                                                       maxLength="500"/>
                                            </div>
                                            <div className="col-md-4">
                                                <input className="w-100" onChange={(e) => {
                                                    setShiaTitle(e.target.value)
                                                }} value={getShiaTitle} type="text" placeholder="الموضوع"
                                                       required maxLength="500"/>
                                            </div>
                                            <div className="col-md-4">
                                                <input className="w-100" onChange={(e) => {
                                                    setPlaceResidence(e.target.value)
                                                }} value={getPlaceResidence} type="text"
                                                       placeholder="أماكن تواجدهم" required
                                                       maxLength="500"/>
                                            </div>
                                            <div className="col-md-12">
                                                <input className="w-100" onChange={(e) => {
                                                    setShiaBody(e.target.value)
                                                }} value={getShiaBody} type="text"
                                                       placeholder="معلومات عامة" required
                                                       maxLength="1000"/>
                                            </div>
                                            <div
                                                className="col-12 push-bottom-in-form d-flex justify-content-end align-items-center mt-5 push-bottom-in-form">
                                                <button type="submit" aria-label="إرسال المعلومات"
                                                        title="إرسال المعلومات"
                                                        className="more-btn border-0 fw-light">
                                                    إرسال المعلومات
                                                </button>
                                            </div>

                                        </form>
                                    </div>
                                    : getFormTab == 5 ?
                                        <div className="form-body-item">
                                            <div className="form-title mt-3 pt-1">
                                                إضافة معلومات عن مؤسسات الشيعة
                                            </div>
                                            <div className="form-disc">
                                                يمكنكم هنا إضافة معلومات بشأن مؤسسات الشيعة في العالم
                                            </div>
                                            <div className="user-name-container mt-5 c-999 d-sm-flex d-inline-block">
                                                <svg
                                                    className=" ms-sm-2 phone-m-auto ms-0 d-sm-inline-block d-block w-35"
                                                    width="35" height="43" viewBox="0 0 56 55"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="28.0237" cy="27.4924" r="27.4924" fill="#045E5E"/>
                                                    <path
                                                        d="M37.7361 37.4626C37.1431 35.8027 35.8363 34.3359 34.0185 33.2897C32.2007 32.2436 29.9734 31.6765 27.6821 31.6765C25.3908 31.6765 23.1635 32.2436 21.3457 33.2897C19.5279 34.3359 18.2211 35.8026 17.6281 37.4626"
                                                        stroke="white" strokeOpacity="0.9" strokeWidth="1.54887"
                                                        strokeLinecap="round"/>
                                                    <circle cx="27.6809" cy="21.2673" r="5.20433" stroke="white"
                                                            strokeOpacity="0.9"
                                                            strokeWidth="1.54887" strokeLinecap="round"/>
                                                </svg>
                                                <span className="f-16" title="محمد ابراهيم سليماني">
                                    {
                                        nameIsLogin + " " + lastNameIsLogin
                                    }
                                </span>
                                                <div
                                                    className="d-flex justify-content-center align-items-center gap-3 religion-container-11">
                                                    <div
                                                        className="d-flex align-items-center phone-jus-center phone-m-b-2 me-sm-5 me-0">
                                                        <ReactCountryFlag
                                                            countryCode={CountryIsLogin}
                                                            svg
                                                            style={{
                                                                width: '1.6em',
                                                                height: '1.6em',
                                                                borderRadius: '8px'
                                                            }}
                                                            title={countries.getName(CountryIsLogin, 'ar')}
                                                            alt={countries.getName(CountryIsLogin, 'ar')}
                                                        />
                                                        <span
                                                            className="pe-2">{countries.getName(CountryIsLogin, 'ar')}</span>
                                                    </div>
                                                    <div className="date-container-news text-right me-sm-5 me-0 pb-0">
                                                        <svg className="ms-2" width="26" height="26" viewBox="0 0 26 26"
                                                             fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M21.137 21.298H7.52466C6.62485 19.7033 6.47368 17.8228 7.14314 16.1351C8.3021 13.2247 10.9368 10.9189 14.3272 10.0883C17.7177 10.9189 20.3524 13.2247 21.5114 16.1351C22.188 17.8228 22.0368 19.7033 21.137 21.298Z"
                                                                stroke="#737373" strokeWidth="0.9"
                                                                stroke-miterlimit="10"
                                                                strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path
                                                                d="M8.58166 6.38051H4.86723C4.62248 5.94195 4.57929 5.43031 4.76645 4.97182C5.08318 4.17444 5.80303 3.54984 6.73164 3.31727C7.66025 3.54319 8.3801 4.17444 8.69683 4.97182C8.8696 5.43031 8.82641 5.9486 8.58166 6.38051Z"
                                                                stroke="#737373" strokeWidth="0.9"
                                                                stroke-miterlimit="10"
                                                                strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path
                                                                d="M7.14342 16.135C7.47456 15.2978 7.93526 14.5137 8.48954 13.7961V6.38051H4.95508V21.298H7.51775C6.61793 19.7033 6.46676 17.8228 7.14342 16.135Z"
                                                                stroke="#737373" strokeWidth="0.9"
                                                                stroke-miterlimit="10"
                                                                strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M14.3262 10.0883V8.2743" stroke="#737373"
                                                                  stroke-miterlimit="10"
                                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                                            <path d="M6.72656 3.31728V1.5099" stroke="#737373"
                                                                  strokeWidth="0.9"
                                                                  stroke-miterlimit="10" strokeLinecap="round"
                                                                  strokeLinejoin="round"/>
                                                            <path d="M22.9658 21.298H3.30664V24.2749H22.9658V21.298Z"
                                                                  stroke="#737373"
                                                                  strokeWidth="0.9" stroke-miterlimit="10"
                                                                  strokeLinecap="round"
                                                                  strokeLinejoin="round"/>
                                                        </svg>
                                                        {religionIsLogin}
                                                    </div>
                                                </div>
                                                <div className="date-container-news text-right me-sm-5 me-0 pb-0">
                                                    <svg className="ms-2" width="22" height="20" viewBox="0 0 24 22"
                                                         fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M4.32292 4.49247H19.6562C20.7104 4.49247 21.5729 5.27997 21.5729 6.24247V16.7425C21.5729 17.705 20.7104 18.4925 19.6562 18.4925H4.32292C3.26875 18.4925 2.40625 17.705 2.40625 16.7425V6.24247C2.40625 5.27997 3.26875 4.49247 4.32292 4.49247Z"
                                                            stroke="#949494" strokeLinecap="round"
                                                            strokeLinejoin="round"/>
                                                        <path d="M21.5729 6.24243L11.9896 12.3674L2.40625 6.24243"
                                                              stroke="#949494"
                                                              strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                    <span className="f-12-g">
                                        {emailIsLogin}
                                    </span>
                                                </div>
                                            </div>
                                            <form className="row h-100 form-container form-container-c-black"
                                                  onSubmit={handleSubmitInstitutions}>
                                                <div className="col-md-4">
                                                    <input className="w-100" onChange={(e) => {
                                                        setInstitutionsTitle(e.target.value)
                                                    }} value={getInstitutionsTitle} type="text"
                                                           placeholder="اسم المركز"
                                                           required maxLength="500"/>
                                                </div>
                                                <div className="col-md-4">
                                                    <input className="w-100" onChange={(e) => {
                                                        setInstitutionsAddress(e.target.value)
                                                    }} value={getInstitutionsAddress} type="text"
                                                           placeholder="العنوان البريدي"
                                                           required
                                                           maxLength="500"/>
                                                </div>
                                                <div className="col-md-4">
                                                    <input className="w-100" onChange={(e) => {
                                                        setInstitutionsManager(e.target.value)
                                                    }} value={getInstitutionsManager} type="text"
                                                           placeholder="المدير" required
                                                           maxLength="500"/>
                                                </div>
                                                <div className="col-md-4 child-m-0-in">
                                                    <Select
                                                        showSearch
                                                        placeholder="الدولة"
                                                        onChange={handleChangeCountry22}
                                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                        filterSort={(optionA, optionB) =>
                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                        }
                                                        options={[
                                                            {
                                                                value: "AD",
                                                                label: "أندورا",
                                                            },
                                                            {
                                                                value: "AE",
                                                                label: "الامارات العربية المتحدة",
                                                            },
                                                            {
                                                                value: "AF",
                                                                label: "أفغانستان",
                                                            },
                                                            {
                                                                value: "AG",
                                                                label: "أنتيجوا وبربودا",
                                                            },
                                                            {
                                                                value: "AI",
                                                                label: "أنجويلا",
                                                            },
                                                            {
                                                                value: "AL",
                                                                label: "ألبانيا",
                                                            },
                                                            {
                                                                value: "AM",
                                                                label: "أرمينيا",
                                                            },
                                                            {
                                                                value: "AO",
                                                                label: "أنجولا",
                                                            },
                                                            {
                                                                value: "AQ",
                                                                label: "القطب الجنوبي",
                                                            },
                                                            {
                                                                value: "AR",
                                                                label: "الأرجنتين",
                                                            },
                                                            {
                                                                value: "AS",
                                                                label: "ساموا الأمريكية",
                                                            },
                                                            {
                                                                value: "AT",
                                                                label: "النمسا",
                                                            },
                                                            {
                                                                value: "AU",
                                                                label: "أستراليا",
                                                            },
                                                            {
                                                                value: "AW",
                                                                label: "آروبا",
                                                            },
                                                            {
                                                                value: "AX",
                                                                label: "جزر أولان",
                                                            },
                                                            {
                                                                value: "AZ",
                                                                label: "أذربيجان",
                                                            },
                                                            {
                                                                value: "BA",
                                                                label: "البوسنة والهرسك",
                                                            },
                                                            {
                                                                value: "BB",
                                                                label: "بربادوس",
                                                            },
                                                            {
                                                                value: "BD",
                                                                label: "بنجلاديش",
                                                            },
                                                            {
                                                                value: "BE",
                                                                label: "بلجيكا",
                                                            },
                                                            {
                                                                value: "BF",
                                                                label: "بوركينا فاسو",
                                                            },
                                                            {
                                                                value: "BG",
                                                                label: "بلغاريا",
                                                            },
                                                            {
                                                                value: "BH",
                                                                label: "البحرين",
                                                            },
                                                            {
                                                                value: "BI",
                                                                label: "بوروندي",
                                                            },
                                                            {
                                                                value: "BJ",
                                                                label: "بنين",
                                                            },
                                                            {
                                                                value: "BL",
                                                                label: "سان بارتيلمي",
                                                            },
                                                            {
                                                                value: "BM",
                                                                label: "برمودا",
                                                            },
                                                            {
                                                                value: "BN",
                                                                label: "بروناي",
                                                            },
                                                            {
                                                                value: "BO",
                                                                label: "بوليفيا",
                                                            },
                                                            {
                                                                value: "BQ",
                                                                label: "بونير",
                                                            },
                                                            {
                                                                value: "BR",
                                                                label: "البرازيل",
                                                            },
                                                            {
                                                                value: "BS",
                                                                label: "الباهاما",
                                                            },
                                                            {
                                                                value: "BT",
                                                                label: "بوتان",
                                                            },
                                                            {
                                                                value: "BV",
                                                                label: "جزيرة بوفيه",
                                                            },
                                                            {
                                                                value: "BW",
                                                                label: "بتسوانا",
                                                            },
                                                            {
                                                                value: "BY",
                                                                label: "روسيا البيضاء",
                                                            },
                                                            {
                                                                value: "BZ",
                                                                label: "بليز",
                                                            },
                                                            {
                                                                value: "CA",
                                                                label: "كندا",
                                                            },
                                                            {
                                                                value: "CC",
                                                                label: "جزر كوكوس",
                                                            },
                                                            {
                                                                value: "CD",
                                                                label: "جمهورية الكونغو الديمقراطية",
                                                            },
                                                            {
                                                                value: "CF",
                                                                label: "جمهورية افريقيا الوسطى",
                                                            },
                                                            {
                                                                value: "CG",
                                                                label: "الكونغو - برازافيل",
                                                            },
                                                            {
                                                                value: "CH",
                                                                label: "سويسرا",
                                                            },
                                                            {
                                                                value: "CI",
                                                                label: "ساحل العاج",
                                                            },
                                                            {
                                                                value: "CK",
                                                                label: "جزر كوك",
                                                            },
                                                            {
                                                                value: "CL",
                                                                label: "شيلي",
                                                            },
                                                            {
                                                                value: "CM",
                                                                label: "الكاميرون",
                                                            },
                                                            {
                                                                value: "CN",
                                                                label: "الصين",
                                                            },
                                                            {
                                                                value: "CO",
                                                                label: "كولومبيا",
                                                            },
                                                            {
                                                                value: "CR",
                                                                label: "كوستاريكا",
                                                            },
                                                            {
                                                                value: "CU",
                                                                label: "كوبا",
                                                            },
                                                            {
                                                                value: "CV",
                                                                label: "الرأس الأخضر",
                                                            },
                                                            {
                                                                value: "CW",
                                                                label: "كوراساو",
                                                            },
                                                            {
                                                                value: "CX",
                                                                label: "جزيرة الكريسماس",
                                                            },
                                                            {
                                                                value: "CY",
                                                                label: "قبرص",
                                                            },
                                                            {
                                                                value: "CZ",
                                                                label: "جمهورية التشيك",
                                                            },
                                                            {
                                                                value: "DE",
                                                                label: "ألمانيا",
                                                            },
                                                            {
                                                                value: "DJ",
                                                                label: "جيبوتي",
                                                            },
                                                            {
                                                                value: "DK",
                                                                label: "الدانمرك",
                                                            },
                                                            {
                                                                value: "DM",
                                                                label: "دومينيكا",
                                                            },
                                                            {
                                                                value: "DO",
                                                                label: "جمهورية الدومينيك",
                                                            },
                                                            {
                                                                value: "DZ",
                                                                label: "الجزائر",
                                                            },
                                                            {
                                                                value: "EC",
                                                                label: "الاكوادور",
                                                            },
                                                            {
                                                                value: "EE",
                                                                label: "استونيا",
                                                            },
                                                            {
                                                                value: "EG",
                                                                label: "مصر",
                                                            },
                                                            {
                                                                value: "EH",
                                                                label: "الصحراء الغربية",
                                                            },
                                                            {
                                                                value: "ER",
                                                                label: "اريتريا",
                                                            },
                                                            {
                                                                value: "ES",
                                                                label: "أسبانيا",
                                                            },
                                                            {
                                                                value: "ET",
                                                                label: "اثيوبيا",
                                                            },
                                                            {
                                                                value: "FI",
                                                                label: "فنلندا",
                                                            },
                                                            {
                                                                value: "FJ",
                                                                label: "فيجي",
                                                            },
                                                            {
                                                                value: "FK",
                                                                label: "جزر فوكلاند",
                                                            },
                                                            {
                                                                value: "FM",
                                                                label: "ميكرونيزيا",
                                                            },
                                                            {
                                                                value: "FO",
                                                                label: "جزر فارو",
                                                            },
                                                            {
                                                                value: "FR",
                                                                label: "فرنسا",
                                                            },
                                                            {
                                                                value: "GA",
                                                                label: "الجابون",
                                                            },
                                                            {
                                                                value: "GB",
                                                                label: "المملكة المتحدة",
                                                            },
                                                            {
                                                                value: "GD",
                                                                label: "جرينادا",
                                                            },
                                                            {
                                                                value: "GE",
                                                                label: "جورجيا",
                                                            },
                                                            {
                                                                value: "GF",
                                                                label: "غويانا",
                                                            },
                                                            {
                                                                value: "GG",
                                                                label: "غيرنزي",
                                                            },
                                                            {
                                                                value: "GH",
                                                                label: "غانا",
                                                            },
                                                            {
                                                                value: "GI",
                                                                label: "جبل طارق",
                                                            },
                                                            {
                                                                value: "GL",
                                                                label: "جرينلاند",
                                                            },
                                                            {
                                                                value: "GM",
                                                                label: "غامبيا",
                                                            },
                                                            {
                                                                value: "GN",
                                                                label: "غينيا",
                                                            },
                                                            {
                                                                value: "GP",
                                                                label: "جوادلوب",
                                                            },
                                                            {
                                                                value: "GQ",
                                                                label: "غينيا الاستوائية",
                                                            },
                                                            {
                                                                value: "GR",
                                                                label: "اليونان",
                                                            },
                                                            {
                                                                value: "GS",
                                                                label: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
                                                            },
                                                            {
                                                                value: "GT",
                                                                label: "جواتيمالا",
                                                            },
                                                            {
                                                                value: "GU",
                                                                label: "جوام",
                                                            },
                                                            {
                                                                value: "GW",
                                                                label: "غينيا بيساو",
                                                            },
                                                            {
                                                                value: "GY",
                                                                label: "غيانا",
                                                            },
                                                            {
                                                                value: "HK",
                                                                label: "هونج كونج الصينية",
                                                            },
                                                            {
                                                                value: "HM",
                                                                label: "جزيرة هيرد وماكدونالد",
                                                            },
                                                            {
                                                                value: "HN",
                                                                label: "هندوراس",
                                                            },
                                                            {
                                                                value: "HR",
                                                                label: "كرواتيا",
                                                            },
                                                            {
                                                                value: "HT",
                                                                label: "هايتي",
                                                            },
                                                            {
                                                                value: "HU",
                                                                label: "المجر",
                                                            },
                                                            {
                                                                value: "ID",
                                                                label: "اندونيسيا",
                                                            },
                                                            {
                                                                value: "IE",
                                                                label: "أيرلندا",
                                                            },
                                                            {
                                                                value: "IL",
                                                                label: "اسرائيل",
                                                            },
                                                            {
                                                                value: "IM",
                                                                label: "جزيرة مان",
                                                            },
                                                            {
                                                                value: "IN",
                                                                label: "الهند",
                                                            },
                                                            {
                                                                value: "IO",
                                                                label: "المحيط الهندي البريطاني",
                                                            },
                                                            {
                                                                value: "IQ",
                                                                label: "العراق",
                                                            },
                                                            {
                                                                value: "IR",
                                                                label: "ايران",
                                                            },
                                                            {
                                                                value: "IS",
                                                                label: "أيسلندا",
                                                            },
                                                            {
                                                                value: "IT",
                                                                label: "ايطاليا",
                                                            },
                                                            {
                                                                value: "JE",
                                                                label: "جيرسي",
                                                            },
                                                            {
                                                                value: "JM",
                                                                label: "جامايكا",
                                                            },
                                                            {
                                                                value: "JO",
                                                                label: "الأردن",
                                                            },
                                                            {
                                                                value: "JP",
                                                                label: "اليابان",
                                                            },
                                                            {
                                                                value: "KE",
                                                                label: "كينيا",
                                                            },
                                                            {
                                                                value: "KG",
                                                                label: "قرغيزستان",
                                                            },
                                                            {
                                                                value: "KH",
                                                                label: "كمبوديا",
                                                            },
                                                            {
                                                                value: "KI",
                                                                label: "كيريباتي",
                                                            },
                                                            {
                                                                value: "KM",
                                                                label: "جزر القمر",
                                                            },
                                                            {
                                                                value: "KN",
                                                                label: "سانت كيتس ونيفيس",
                                                            },
                                                            {
                                                                value: "KP",
                                                                label: "كوريا الشمالية",
                                                            },
                                                            {
                                                                value: "KR",
                                                                label: "كوريا الجنوبية",
                                                            },
                                                            {
                                                                value: "KW",
                                                                label: "الكويت",
                                                            },
                                                            {
                                                                value: "KY",
                                                                label: "جزر الكايمن",
                                                            },
                                                            {
                                                                value: "KZ",
                                                                label: "كازاخستان",
                                                            },
                                                            {
                                                                value: "LA",
                                                                label: "لاوس",
                                                            },
                                                            {
                                                                value: "LB",
                                                                label: "لبنان",
                                                            },
                                                            {
                                                                value: "LC",
                                                                label: "سانت لوسيا",
                                                            },
                                                            {
                                                                value: "LI",
                                                                label: "ليختنشتاين",
                                                            },
                                                            {
                                                                value: "LK",
                                                                label: "سريلانكا",
                                                            },
                                                            {
                                                                value: "LR",
                                                                label: "ليبيريا",
                                                            },
                                                            {
                                                                value: "LS",
                                                                label: "ليسوتو",
                                                            },
                                                            {
                                                                value: "LT",
                                                                label: "ليتوانيا",
                                                            },
                                                            {
                                                                value: "LU",
                                                                label: "لوكسمبورج",
                                                            },
                                                            {
                                                                value: "LV",
                                                                label: "لاتفيا",
                                                            },
                                                            {
                                                                value: "LY",
                                                                label: "ليبيا",
                                                            },
                                                            {
                                                                value: "MA",
                                                                label: "المغرب",
                                                            },
                                                            {
                                                                value: "MC",
                                                                label: "موناكو",
                                                            },
                                                            {
                                                                value: "MD",
                                                                label: "مولدافيا",
                                                            },
                                                            {
                                                                value: "ME",
                                                                label: "الجبل الأسود",
                                                            },
                                                            {
                                                                value: "MF",
                                                                label: "سانت مارتين",
                                                            },
                                                            {
                                                                value: "MG",
                                                                label: "مدغشقر",
                                                            },
                                                            {
                                                                value: "MH",
                                                                label: "جزر المارشال",
                                                            },
                                                            {
                                                                value: "MK",
                                                                label: "مقدونيا",
                                                            },
                                                            {
                                                                value: "ML",
                                                                label: "مالي",
                                                            },
                                                            {
                                                                value: "MM",
                                                                label: "ميانمار",
                                                            },
                                                            {
                                                                value: "MN",
                                                                label: "منغوليا",
                                                            },
                                                            {
                                                                value: "MO",
                                                                label: "ماكاو الصينية",
                                                            },
                                                            {
                                                                value: "MP",
                                                                label: "جزر ماريانا الشمالية",
                                                            },
                                                            {
                                                                value: "MQ",
                                                                label: "مارتينيك",
                                                            },
                                                            {
                                                                value: "MR",
                                                                label: "موريتانيا",
                                                            },
                                                            {
                                                                value: "MS",
                                                                label: "مونتسرات",
                                                            },
                                                            {
                                                                value: "MT",
                                                                label: "مالطا",
                                                            },
                                                            {
                                                                value: "MU",
                                                                label: "موريشيوس",
                                                            },
                                                            {
                                                                value: "MV",
                                                                label: "جزر الملديف",
                                                            },
                                                            {
                                                                value: "MW",
                                                                label: "ملاوي",
                                                            },
                                                            {
                                                                value: "MX",
                                                                label: "المكسيك",
                                                            },
                                                            {
                                                                value: "MY",
                                                                label: "ماليزيا",
                                                            },
                                                            {
                                                                value: "MZ",
                                                                label: "موزمبيق",
                                                            },
                                                            {
                                                                value: "NA",
                                                                label: "ناميبيا",
                                                            },
                                                            {
                                                                value: "NC",
                                                                label: "كاليدونيا الجديدة",
                                                            },
                                                            {
                                                                value: "NE",
                                                                label: "النيجر",
                                                            },
                                                            {
                                                                value: "NF",
                                                                label: "جزيرة نورفوك",
                                                            },
                                                            {
                                                                value: "NG",
                                                                label: "نيجيريا",
                                                            },
                                                            {
                                                                value: "NI",
                                                                label: "نيكاراجوا",
                                                            },
                                                            {
                                                                value: "NL",
                                                                label: "هولندا",
                                                            },
                                                            {
                                                                value: "NO",
                                                                label: "النرويج",
                                                            },
                                                            {
                                                                value: "NP",
                                                                label: "نيبال",
                                                            },
                                                            {
                                                                value: "NR",
                                                                label: "نورو",
                                                            },
                                                            {
                                                                value: "NU",
                                                                label: "نيوي",
                                                            },
                                                            {
                                                                value: "NZ",
                                                                label: "نيوزيلاندا",
                                                            },
                                                            {
                                                                value: "OM",
                                                                label: "عمان",
                                                            },
                                                            {
                                                                value: "PA",
                                                                label: "بنما",
                                                            },
                                                            {
                                                                value: "PE",
                                                                label: "بيرو",
                                                            },
                                                            {
                                                                value: "PF",
                                                                label: "بولينيزيا الفرنسية",
                                                            },
                                                            {
                                                                value: "PG",
                                                                label: "بابوا غينيا الجديدة",
                                                            },
                                                            {
                                                                value: "PH",
                                                                label: "الفيلبين",
                                                            },
                                                            {
                                                                value: "PK",
                                                                label: "باكستان",
                                                            },
                                                            {
                                                                value: "PL",
                                                                label: "بولندا",
                                                            },
                                                            {
                                                                value: "PM",
                                                                label: "سانت بيير وميكولون",
                                                            },
                                                            {
                                                                value: "PN",
                                                                label: "بتكايرن",
                                                            },
                                                            {
                                                                value: "PR",
                                                                label: "بورتوريكو",
                                                            },
                                                            {
                                                                value: "PS",
                                                                label: "فلسطين",
                                                            },
                                                            {
                                                                value: "PT",
                                                                label: "البرتغال",
                                                            },
                                                            {
                                                                value: "PW",
                                                                label: "بالاو",
                                                            },
                                                            {
                                                                value: "PY",
                                                                label: "باراجواي",
                                                            },
                                                            {
                                                                value: "QA",
                                                                label: "قطر",
                                                            },
                                                            {
                                                                value: "RE",
                                                                label: "روينيون",
                                                            },
                                                            {
                                                                value: "RO",
                                                                label: "رومانيا",
                                                            },
                                                            {
                                                                value: "RS",
                                                                label: "صربيا",
                                                            },
                                                            {
                                                                value: "RU",
                                                                label: "روسيا",
                                                            },
                                                            {
                                                                value: "RW",
                                                                label: "رواندا",
                                                            },
                                                            {
                                                                value: "SA",
                                                                label: "المملكة العربية السعودية",
                                                            },
                                                            {
                                                                value: "SB",
                                                                label: "جزر سليمان",
                                                            },
                                                            {
                                                                value: "SC",
                                                                label: "سيشل",
                                                            },
                                                            {
                                                                value: "SD",
                                                                label: "السودان",
                                                            },
                                                            {
                                                                value: "SE",
                                                                label: "السويد",
                                                            },
                                                            {
                                                                value: "SG",
                                                                label: "سنغافورة",
                                                            },
                                                            {
                                                                value: "SH",
                                                                label: "سانت هيلنا",
                                                            },
                                                            {
                                                                value: "SI",
                                                                label: "سلوفينيا",
                                                            },
                                                            {
                                                                value: "SJ",
                                                                label: "سفالبارد وجان مايان",
                                                            },
                                                            {
                                                                value: "SK",
                                                                label: "سلوفاكيا",
                                                            },
                                                            {
                                                                value: "SL",
                                                                label: "سيراليون",
                                                            },
                                                            {
                                                                value: "SM",
                                                                label: "سان مارينو",
                                                            },
                                                            {
                                                                value: "SN",
                                                                label: "السنغال",
                                                            },
                                                            {
                                                                value: "SO",
                                                                label: "الصومال",
                                                            },
                                                            {
                                                                value: "SR",
                                                                label: "سورينام",
                                                            },
                                                            {
                                                                value: "SS",
                                                                label: "جنوب السودان",
                                                            },
                                                            {
                                                                value: "ST",
                                                                label: "ساو تومي وبرينسيبي",
                                                            },
                                                            {
                                                                value: "SV",
                                                                label: "السلفادور",
                                                            },
                                                            {
                                                                value: "SX",
                                                                label: "سينت مارتن",
                                                            },
                                                            {
                                                                value: "SY",
                                                                label: "سوريا",
                                                            },
                                                            {
                                                                value: "SZ",
                                                                label: "سوازيلاند",
                                                            },
                                                            {
                                                                value: "TC",
                                                                label: "جزر الترك وجايكوس",
                                                            },
                                                            {
                                                                value: "TD",
                                                                label: "تشاد",
                                                            },
                                                            {
                                                                value: "TF",
                                                                label: "المقاطعات الجنوبية الفرنسية",
                                                            },
                                                            {
                                                                value: "TG",
                                                                label: "توجو",
                                                            },
                                                            {
                                                                value: "TH",
                                                                label: "تايلند",
                                                            },
                                                            {
                                                                value: "TJ",
                                                                label: "طاجكستان",
                                                            },
                                                            {
                                                                value: "TK",
                                                                label: "توكيلو",
                                                            },
                                                            {
                                                                value: "TL",
                                                                label: "تيمور الشرقية",
                                                            },
                                                            {
                                                                value: "TM",
                                                                label: "تركمانستان",
                                                            },
                                                            {
                                                                value: "TN",
                                                                label: "تونس",
                                                            },
                                                            {
                                                                value: "TO",
                                                                label: "تونجا",
                                                            },
                                                            {
                                                                value: "TR",
                                                                label: "تركيا",
                                                            },
                                                            {
                                                                value: "TT",
                                                                label: "ترينيداد وتوباغو",
                                                            },
                                                            {
                                                                value: "TV",
                                                                label: "توفالو",
                                                            },
                                                            {
                                                                value: "TW",
                                                                label: "تايوان",
                                                            },
                                                            {
                                                                value: "TZ",
                                                                label: "تانزانيا",
                                                            },
                                                            {
                                                                value: "UA",
                                                                label: "أوكرانيا",
                                                            },
                                                            {
                                                                value: "UG",
                                                                label: "أوغندا",
                                                            },
                                                            {
                                                                value: "UM",
                                                                label: "جزر الولايات المتحدة البعيدة الصغيرة",
                                                            },
                                                            {
                                                                value: "US",
                                                                label: "الولايات المتحدة الأمريكية",
                                                            },
                                                            {
                                                                value: "UY",
                                                                label: "أورجواي",
                                                            },
                                                            {
                                                                value: "UZ",
                                                                label: "أوزبكستان",
                                                            },
                                                            {
                                                                value: "VA",
                                                                label: "الفاتيكان",
                                                            },
                                                            {
                                                                value: "VC",
                                                                label: "سانت فنسنت وغرنادين",
                                                            },
                                                            {
                                                                value: "VE",
                                                                label: "فنزويلا",
                                                            },
                                                            {
                                                                value: "VG",
                                                                label: "جزر فرجين البريطانية",
                                                            },
                                                            {
                                                                value: "VI",
                                                                label: "جزر فرجين الأمريكية",
                                                            },
                                                            {
                                                                value: "VN",
                                                                label: "فيتنام",
                                                            },
                                                            {
                                                                value: "VU",
                                                                label: "فانواتو",
                                                            },
                                                            {
                                                                value: "WF",
                                                                label: "جزر والس وفوتونا",
                                                            },
                                                            {
                                                                value: "WS",
                                                                label: "ساموا",
                                                            },
                                                            {
                                                                value: "XK",
                                                                label: "كوسوفو",
                                                            },
                                                            {
                                                                value: "YE",
                                                                label: "اليمن",
                                                            },
                                                            {
                                                                value: "YT",
                                                                label: "مايوت",
                                                            },
                                                            {
                                                                value: "ZA",
                                                                label: "جمهورية جنوب افريقيا",
                                                            },
                                                            {
                                                                value: "ZM",
                                                                label: "زامبيا",
                                                            },
                                                            {
                                                                value: "ZW",
                                                                label: "زيمبابوي",
                                                            }
                                                        ]}/>
                                                </div>
                                                <div className="col-md-4">
                                                    <input className="w-100" onChange={(e) => {
                                                        setInstitutionsCity(e.target.value)
                                                    }} value={getInstitutionsCity} type="text"
                                                           placeholder="المدينة" required
                                                           maxLength="500"/>
                                                </div>
                                                <div className="col-md-4">
                                                    <input className="w-100" onChange={(e) => {
                                                        setInstitutionsFacs(e.target.value)
                                                    }} value={getInstitutionsFacs} type="text"
                                                           placeholder="معلومات التواصل"
                                                           maxLength="500"/>
                                                </div>
                                                <div className="col-md-4">
                                                    <input className="w-100" onChange={(e) => {
                                                        setInstitutionsEmail(e.target.value)
                                                    }} value={getInstitutionsEmail} type="email"
                                                           placeholder="البريد الإلكتروني"
                                                           maxLength="500"/>
                                                </div>

                                                <div className="col-md-12 ">
                                                    <input className="w-100" onChange={(e) => {
                                                        setInstitutionsBody(e.target.value)
                                                    }} value={getInstitutionsBody} type="text"
                                                           placeholder="النشاطات" required
                                                           maxLength="500"/>
                                                </div>

                                                <div
                                                    className="col-12 push-bottom-in-form d-flex justify-content-end align-items-center push-bottom-in-form mt-5">
                                                    <button type="submit" aria-label="إرسال المعلومات"
                                                            title="إرسال المعلومات"
                                                            className="more-btn border-0 fw-light">
                                                        إرسال المعلومات
                                                    </button>
                                                </div>

                                            </form>
                                        </div>
                                        : getFormTab == 6 ?
                                            <div className="form-body-item">
                                                <div className="form-title mt-3 pt-1">
                                                    أضف موقعاً شيعياً
                                                </div>
                                                <div className="form-disc">
                                                    يمكنكم هنا إضافة المواقع والصفحات الشيعية
                                                </div>
                                                <div
                                                    className="user-name-container mt-5 c-999 d-sm-flex d-inline-block">
                                                    <svg
                                                        className=" ms-sm-2 phone-m-auto ms-0 d-sm-inline-block d-block w-35"
                                                        width="35" height="43" viewBox="0 0 56 55"
                                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="28.0237" cy="27.4924" r="27.4924" fill="#045E5E"/>
                                                        <path
                                                            d="M37.7361 37.4626C37.1431 35.8027 35.8363 34.3359 34.0185 33.2897C32.2007 32.2436 29.9734 31.6765 27.6821 31.6765C25.3908 31.6765 23.1635 32.2436 21.3457 33.2897C19.5279 34.3359 18.2211 35.8026 17.6281 37.4626"
                                                            stroke="white" strokeOpacity="0.9" strokeWidth="1.54887"
                                                            strokeLinecap="round"/>
                                                        <circle cx="27.6809" cy="21.2673" r="5.20433" stroke="white"
                                                                strokeOpacity="0.9"
                                                                strokeWidth="1.54887" strokeLinecap="round"/>
                                                    </svg>
                                                    <span className="f-16" title="محمد ابراهيم سليماني">
                                    {
                                        nameIsLogin + " " + lastNameIsLogin
                                    }
                                </span>
                                                    <div
                                                        className="d-flex justify-content-center align-items-center gap-3 religion-container-11">
                                                        <div
                                                            className="d-flex align-items-center phone-jus-center phone-m-b-2 me-sm-5 me-0">
                                                            <ReactCountryFlag
                                                                countryCode={CountryIsLogin}
                                                                svg
                                                                style={{
                                                                    width: '1.6em',
                                                                    height: '1.6em',
                                                                    borderRadius: '8px'
                                                                }}
                                                                title={countries.getName(CountryIsLogin, 'ar')}
                                                                alt={countries.getName(CountryIsLogin, 'ar')}
                                                            />
                                                            <span
                                                                className="pe-2">{countries.getName(CountryIsLogin, 'ar')}</span>
                                                        </div>
                                                        <div
                                                            className="date-container-news text-right me-sm-5 me-0 pb-0">
                                                            <svg className="ms-2" width="26" height="26"
                                                                 viewBox="0 0 26 26"
                                                                 fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M21.137 21.298H7.52466C6.62485 19.7033 6.47368 17.8228 7.14314 16.1351C8.3021 13.2247 10.9368 10.9189 14.3272 10.0883C17.7177 10.9189 20.3524 13.2247 21.5114 16.1351C22.188 17.8228 22.0368 19.7033 21.137 21.298Z"
                                                                    stroke="#737373" strokeWidth="0.9"
                                                                    stroke-miterlimit="10"
                                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path
                                                                    d="M8.58166 6.38051H4.86723C4.62248 5.94195 4.57929 5.43031 4.76645 4.97182C5.08318 4.17444 5.80303 3.54984 6.73164 3.31727C7.66025 3.54319 8.3801 4.17444 8.69683 4.97182C8.8696 5.43031 8.82641 5.9486 8.58166 6.38051Z"
                                                                    stroke="#737373" strokeWidth="0.9"
                                                                    stroke-miterlimit="10"
                                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path
                                                                    d="M7.14342 16.135C7.47456 15.2978 7.93526 14.5137 8.48954 13.7961V6.38051H4.95508V21.298H7.51775C6.61793 19.7033 6.46676 17.8228 7.14342 16.135Z"
                                                                    stroke="#737373" strokeWidth="0.9"
                                                                    stroke-miterlimit="10"
                                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path d="M14.3262 10.0883V8.2743" stroke="#737373"
                                                                      stroke-miterlimit="10"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path d="M6.72656 3.31728V1.5099" stroke="#737373"
                                                                      strokeWidth="0.9"
                                                                      stroke-miterlimit="10" strokeLinecap="round"
                                                                      strokeLinejoin="round"/>
                                                                <path
                                                                    d="M22.9658 21.298H3.30664V24.2749H22.9658V21.298Z"
                                                                    stroke="#737373"
                                                                    strokeWidth="0.9" stroke-miterlimit="10"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"/>
                                                            </svg>
                                                            {religionIsLogin}
                                                        </div>
                                                    </div>
                                                    <div className="date-container-news text-right me-sm-5 me-0 pb-0">
                                                        <svg className="ms-2" width="22" height="20" viewBox="0 0 24 22"
                                                             fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M4.32292 4.49247H19.6562C20.7104 4.49247 21.5729 5.27997 21.5729 6.24247V16.7425C21.5729 17.705 20.7104 18.4925 19.6562 18.4925H4.32292C3.26875 18.4925 2.40625 17.705 2.40625 16.7425V6.24247C2.40625 5.27997 3.26875 4.49247 4.32292 4.49247Z"
                                                                stroke="#949494" strokeLinecap="round"
                                                                strokeLinejoin="round"/>
                                                            <path d="M21.5729 6.24243L11.9896 12.3674L2.40625 6.24243"
                                                                  stroke="#949494"
                                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        <span className="f-12-g">
                                        {emailIsLogin}
                                    </span>
                                                    </div>
                                                </div>
                                                <form className="row h-100 form-container form-container-c-black"
                                                      onSubmit={handleSubmitSite}>
                                                    <div className="col-md-4">
                                                        <input className="w-100" onChange={(e) => {
                                                            setSiteName(e.target.value)
                                                        }} value={getSiteName} type="text" placeholder="اسم الموقع"
                                                               required maxLength="500"/>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input className="w-100" onChange={(e) => {
                                                            setSiteLanguage(e.target.value)
                                                        }} value={getSiteLanguage} type="text" placeholder="اللغة"
                                                               required
                                                               maxLength="500"/>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input className="w-100" onChange={(e) => {
                                                            setSiteLink(e.target.value)
                                                        }} value={getSiteLink} type="text" placeholder="رابط الموقع"
                                                               required
                                                               maxLength="500"/>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input className="w-100 soc-input" onChange={(e) => {
                                                            setSiteSocFacebook(e.target.value)
                                                        }} value={getSiteSocFacebook} type="text" placeholder="facebook"
                                                               maxLength="500"/>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input className="w-100 soc-input" onChange={(e) => {
                                                            setSiteSocYoutube(e.target.value)
                                                        }} value={getSiteSocYoutube} type="text" placeholder="youtube"
                                                               maxLength="500"/>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input className="w-100 soc-input" onChange={(e) => {
                                                            setSiteSocInstagram(e.target.value)
                                                        }} value={getSiteSocInstagram} type="text"
                                                               placeholder="instagram"
                                                               maxLength="500"/>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input className="w-100 soc-input" onChange={(e) => {
                                                            setSiteSocTwitter(e.target.value)
                                                        }} value={getSiteSocTwitter} type="text" placeholder="twitter"
                                                               maxLength="500"/>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input className="w-100 soc-input" onChange={(e) => {
                                                            setSiteSocWhatsapp(e.target.value)
                                                        }} value={getSiteSocWhatsapp} type="text" placeholder="whatsapp"
                                                               maxLength="500"/>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input className="w-100 soc-input" onChange={(e) => {
                                                            setSiteSocTelegram(e.target.value)
                                                        }} value={getSiteSocTelegram} type="text" placeholder="telegram"
                                                               maxLength="500"/>
                                                    </div>

                                                    <div className="col-12">
                                                        <input className="w-100" onChange={(e) => {
                                                            setSiteBody(e.target.value)
                                                        }} value={getSiteBody} type="text" placeholder="محتوى الموقع"
                                                               required maxLength="1000"/>
                                                    </div>
                                                    <div
                                                        className="col-12 push-bottom-in-form d-flex justify-content-end align-items-center mt-5">
                                                        <button type="submit" aria-label="إرسال المعلومات"
                                                                title="إرسال المعلومات"
                                                                className="more-btn border-0
                                                            fw-light">
                                                            إرسال المعلومات
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                            :
                                            <div className="form-body-item">
                                                <div className="form-title mt-3 pt-1">
                                                    إرسال رسالة
                                                </div>
                                                <div className="form-disc">
                                                    يمكنكم التواصل معنا من خلال هذه النافذة
                                                </div>
                                                <div
                                                    className="user-name-container mt-5 c-999 d-sm-flex d-inline-block">
                                                    <svg
                                                        className=" ms-sm-2 phone-m-auto ms-0 d-sm-inline-block d-block w-35"
                                                        width="35" height="43" viewBox="0 0 56 55"
                                                        fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="28.0237" cy="27.4924" r="27.4924" fill="#045E5E"/>
                                                        <path
                                                            d="M37.7361 37.4626C37.1431 35.8027 35.8363 34.3359 34.0185 33.2897C32.2007 32.2436 29.9734 31.6765 27.6821 31.6765C25.3908 31.6765 23.1635 32.2436 21.3457 33.2897C19.5279 34.3359 18.2211 35.8026 17.6281 37.4626"
                                                            stroke="white" strokeOpacity="0.9" strokeWidth="1.54887"
                                                            strokeLinecap="round"/>
                                                        <circle cx="27.6809" cy="21.2673" r="5.20433" stroke="white"
                                                                strokeOpacity="0.9"
                                                                strokeWidth="1.54887" strokeLinecap="round"/>
                                                    </svg>
                                                    <span className="f-16" title="محمد ابراهيم سليماني">
                                    {
                                        nameIsLogin + " " + lastNameIsLogin
                                    }
                                </span>
                                                    <div
                                                        className="d-flex justify-content-center align-items-center gap-3 religion-container-11">
                                                        <div
                                                            className="d-flex align-items-center phone-jus-center phone-m-b-2 me-sm-5 me-0">
                                                            <ReactCountryFlag
                                                                countryCode={CountryIsLogin}
                                                                svg
                                                                style={{
                                                                    width: '1.6em',
                                                                    height: '1.6em',
                                                                    borderRadius: '8px'
                                                                }}
                                                                title={countries.getName(CountryIsLogin, 'ar')}
                                                                alt={countries.getName(CountryIsLogin, 'ar')}
                                                            />
                                                            <span
                                                                className="pe-2">{countries.getName(CountryIsLogin, 'ar')}</span>
                                                        </div>
                                                        <div
                                                            className="date-container-news text-right me-sm-5 me-0 pb-0">
                                                            <svg className="ms-2" width="26" height="26"
                                                                 viewBox="0 0 26 26"
                                                                 fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M21.137 21.298H7.52466C6.62485 19.7033 6.47368 17.8228 7.14314 16.1351C8.3021 13.2247 10.9368 10.9189 14.3272 10.0883C17.7177 10.9189 20.3524 13.2247 21.5114 16.1351C22.188 17.8228 22.0368 19.7033 21.137 21.298Z"
                                                                    stroke="#737373" strokeWidth="0.9"
                                                                    stroke-miterlimit="10"
                                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path
                                                                    d="M8.58166 6.38051H4.86723C4.62248 5.94195 4.57929 5.43031 4.76645 4.97182C5.08318 4.17444 5.80303 3.54984 6.73164 3.31727C7.66025 3.54319 8.3801 4.17444 8.69683 4.97182C8.8696 5.43031 8.82641 5.9486 8.58166 6.38051Z"
                                                                    stroke="#737373" strokeWidth="0.9"
                                                                    stroke-miterlimit="10"
                                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path
                                                                    d="M7.14342 16.135C7.47456 15.2978 7.93526 14.5137 8.48954 13.7961V6.38051H4.95508V21.298H7.51775C6.61793 19.7033 6.46676 17.8228 7.14342 16.135Z"
                                                                    stroke="#737373" strokeWidth="0.9"
                                                                    stroke-miterlimit="10"
                                                                    strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path d="M14.3262 10.0883V8.2743" stroke="#737373"
                                                                      stroke-miterlimit="10"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path d="M6.72656 3.31728V1.5099" stroke="#737373"
                                                                      strokeWidth="0.9"
                                                                      stroke-miterlimit="10" strokeLinecap="round"
                                                                      strokeLinejoin="round"/>
                                                                <path
                                                                    d="M22.9658 21.298H3.30664V24.2749H22.9658V21.298Z"
                                                                    stroke="#737373"
                                                                    strokeWidth="0.9" stroke-miterlimit="10"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"/>
                                                            </svg>
                                                            {religionIsLogin}
                                                        </div>
                                                    </div>
                                                    <div className="date-container-news text-right me-sm-5 me-0 pb-0">
                                                        <svg className="ms-2" width="22" height="20" viewBox="0 0 24 22"
                                                             fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M4.32292 4.49247H19.6562C20.7104 4.49247 21.5729 5.27997 21.5729 6.24247V16.7425C21.5729 17.705 20.7104 18.4925 19.6562 18.4925H4.32292C3.26875 18.4925 2.40625 17.705 2.40625 16.7425V6.24247C2.40625 5.27997 3.26875 4.49247 4.32292 4.49247Z"
                                                                stroke="#949494" strokeLinecap="round"
                                                                strokeLinejoin="round"/>
                                                            <path d="M21.5729 6.24243L11.9896 12.3674L2.40625 6.24243"
                                                                  stroke="#949494"
                                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        <span className="f-12-g">
                                                            {emailIsLogin}
                                                        </span>
                                                    </div>
                                                </div>
                                                <form className="row form-container form-container-c-black"
                                                      onSubmit={handleSubmitContactUs}>
                                                    <div className="col-md-4">
                                                        <input className="w-100" type="text" onChange={(e) => {
                                                            setName(e.target.value)
                                                        }} value={getName} placeholder="الإسم الكامل" required
                                                               maxLength="500"/>
                                                    </div>
                                                    <div className="col-md-4 child-m-0-in">
                                                        <Select
                                                            showSearch
                                                            placeholder="الدولة"
                                                            defaultValue="IR"
                                                            onChange={handleChangeCountry}
                                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                            filterSort={(optionA, optionB) =>
                                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                            }
                                                            options={[
                                                                {
                                                                    value: "AD",
                                                                    label: "أندورا",
                                                                },
                                                                {
                                                                    value: "AE",
                                                                    label: "الامارات العربية المتحدة",
                                                                },
                                                                {
                                                                    value: "AF",
                                                                    label: "أفغانستان",
                                                                },
                                                                {
                                                                    value: "AG",
                                                                    label: "أنتيجوا وبربودا",
                                                                },
                                                                {
                                                                    value: "AI",
                                                                    label: "أنجويلا",
                                                                },
                                                                {
                                                                    value: "AL",
                                                                    label: "ألبانيا",
                                                                },
                                                                {
                                                                    value: "AM",
                                                                    label: "أرمينيا",
                                                                },
                                                                {
                                                                    value: "AO",
                                                                    label: "أنجولا",
                                                                },
                                                                {
                                                                    value: "AQ",
                                                                    label: "القطب الجنوبي",
                                                                },
                                                                {
                                                                    value: "AR",
                                                                    label: "الأرجنتين",
                                                                },
                                                                {
                                                                    value: "AS",
                                                                    label: "ساموا الأمريكية",
                                                                },
                                                                {
                                                                    value: "AT",
                                                                    label: "النمسا",
                                                                },
                                                                {
                                                                    value: "AU",
                                                                    label: "أستراليا",
                                                                },
                                                                {
                                                                    value: "AW",
                                                                    label: "آروبا",
                                                                },
                                                                {
                                                                    value: "AX",
                                                                    label: "جزر أولان",
                                                                },
                                                                {
                                                                    value: "AZ",
                                                                    label: "أذربيجان",
                                                                },
                                                                {
                                                                    value: "BA",
                                                                    label: "البوسنة والهرسك",
                                                                },
                                                                {
                                                                    value: "BB",
                                                                    label: "بربادوس",
                                                                },
                                                                {
                                                                    value: "BD",
                                                                    label: "بنجلاديش",
                                                                },
                                                                {
                                                                    value: "BE",
                                                                    label: "بلجيكا",
                                                                },
                                                                {
                                                                    value: "BF",
                                                                    label: "بوركينا فاسو",
                                                                },
                                                                {
                                                                    value: "BG",
                                                                    label: "بلغاريا",
                                                                },
                                                                {
                                                                    value: "BH",
                                                                    label: "البحرين",
                                                                },
                                                                {
                                                                    value: "BI",
                                                                    label: "بوروندي",
                                                                },
                                                                {
                                                                    value: "BJ",
                                                                    label: "بنين",
                                                                },
                                                                {
                                                                    value: "BL",
                                                                    label: "سان بارتيلمي",
                                                                },
                                                                {
                                                                    value: "BM",
                                                                    label: "برمودا",
                                                                },
                                                                {
                                                                    value: "BN",
                                                                    label: "بروناي",
                                                                },
                                                                {
                                                                    value: "BO",
                                                                    label: "بوليفيا",
                                                                },
                                                                {
                                                                    value: "BQ",
                                                                    label: "بونير",
                                                                },
                                                                {
                                                                    value: "BR",
                                                                    label: "البرازيل",
                                                                },
                                                                {
                                                                    value: "BS",
                                                                    label: "الباهاما",
                                                                },
                                                                {
                                                                    value: "BT",
                                                                    label: "بوتان",
                                                                },
                                                                {
                                                                    value: "BV",
                                                                    label: "جزيرة بوفيه",
                                                                },
                                                                {
                                                                    value: "BW",
                                                                    label: "بتسوانا",
                                                                },
                                                                {
                                                                    value: "BY",
                                                                    label: "روسيا البيضاء",
                                                                },
                                                                {
                                                                    value: "BZ",
                                                                    label: "بليز",
                                                                },
                                                                {
                                                                    value: "CA",
                                                                    label: "كندا",
                                                                },
                                                                {
                                                                    value: "CC",
                                                                    label: "جزر كوكوس",
                                                                },
                                                                {
                                                                    value: "CD",
                                                                    label: "جمهورية الكونغو الديمقراطية",
                                                                },
                                                                {
                                                                    value: "CF",
                                                                    label: "جمهورية افريقيا الوسطى",
                                                                },
                                                                {
                                                                    value: "CG",
                                                                    label: "الكونغو - برازافيل",
                                                                },
                                                                {
                                                                    value: "CH",
                                                                    label: "سويسرا",
                                                                },
                                                                {
                                                                    value: "CI",
                                                                    label: "ساحل العاج",
                                                                },
                                                                {
                                                                    value: "CK",
                                                                    label: "جزر كوك",
                                                                },
                                                                {
                                                                    value: "CL",
                                                                    label: "شيلي",
                                                                },
                                                                {
                                                                    value: "CM",
                                                                    label: "الكاميرون",
                                                                },
                                                                {
                                                                    value: "CN",
                                                                    label: "الصين",
                                                                },
                                                                {
                                                                    value: "CO",
                                                                    label: "كولومبيا",
                                                                },
                                                                {
                                                                    value: "CR",
                                                                    label: "كوستاريكا",
                                                                },
                                                                {
                                                                    value: "CU",
                                                                    label: "كوبا",
                                                                },
                                                                {
                                                                    value: "CV",
                                                                    label: "الرأس الأخضر",
                                                                },
                                                                {
                                                                    value: "CW",
                                                                    label: "كوراساو",
                                                                },
                                                                {
                                                                    value: "CX",
                                                                    label: "جزيرة الكريسماس",
                                                                },
                                                                {
                                                                    value: "CY",
                                                                    label: "قبرص",
                                                                },
                                                                {
                                                                    value: "CZ",
                                                                    label: "جمهورية التشيك",
                                                                },
                                                                {
                                                                    value: "DE",
                                                                    label: "ألمانيا",
                                                                },
                                                                {
                                                                    value: "DJ",
                                                                    label: "جيبوتي",
                                                                },
                                                                {
                                                                    value: "DK",
                                                                    label: "الدانمرك",
                                                                },
                                                                {
                                                                    value: "DM",
                                                                    label: "دومينيكا",
                                                                },
                                                                {
                                                                    value: "DO",
                                                                    label: "جمهورية الدومينيك",
                                                                },
                                                                {
                                                                    value: "DZ",
                                                                    label: "الجزائر",
                                                                },
                                                                {
                                                                    value: "EC",
                                                                    label: "الاكوادور",
                                                                },
                                                                {
                                                                    value: "EE",
                                                                    label: "استونيا",
                                                                },
                                                                {
                                                                    value: "EG",
                                                                    label: "مصر",
                                                                },
                                                                {
                                                                    value: "EH",
                                                                    label: "الصحراء الغربية",
                                                                },
                                                                {
                                                                    value: "ER",
                                                                    label: "اريتريا",
                                                                },
                                                                {
                                                                    value: "ES",
                                                                    label: "أسبانيا",
                                                                },
                                                                {
                                                                    value: "ET",
                                                                    label: "اثيوبيا",
                                                                },
                                                                {
                                                                    value: "FI",
                                                                    label: "فنلندا",
                                                                },
                                                                {
                                                                    value: "FJ",
                                                                    label: "فيجي",
                                                                },
                                                                {
                                                                    value: "FK",
                                                                    label: "جزر فوكلاند",
                                                                },
                                                                {
                                                                    value: "FM",
                                                                    label: "ميكرونيزيا",
                                                                },
                                                                {
                                                                    value: "FO",
                                                                    label: "جزر فارو",
                                                                },
                                                                {
                                                                    value: "FR",
                                                                    label: "فرنسا",
                                                                },
                                                                {
                                                                    value: "GA",
                                                                    label: "الجابون",
                                                                },
                                                                {
                                                                    value: "GB",
                                                                    label: "المملكة المتحدة",
                                                                },
                                                                {
                                                                    value: "GD",
                                                                    label: "جرينادا",
                                                                },
                                                                {
                                                                    value: "GE",
                                                                    label: "جورجيا",
                                                                },
                                                                {
                                                                    value: "GF",
                                                                    label: "غويانا",
                                                                },
                                                                {
                                                                    value: "GG",
                                                                    label: "غيرنزي",
                                                                },
                                                                {
                                                                    value: "GH",
                                                                    label: "غانا",
                                                                },
                                                                {
                                                                    value: "GI",
                                                                    label: "جبل طارق",
                                                                },
                                                                {
                                                                    value: "GL",
                                                                    label: "جرينلاند",
                                                                },
                                                                {
                                                                    value: "GM",
                                                                    label: "غامبيا",
                                                                },
                                                                {
                                                                    value: "GN",
                                                                    label: "غينيا",
                                                                },
                                                                {
                                                                    value: "GP",
                                                                    label: "جوادلوب",
                                                                },
                                                                {
                                                                    value: "GQ",
                                                                    label: "غينيا الاستوائية",
                                                                },
                                                                {
                                                                    value: "GR",
                                                                    label: "اليونان",
                                                                },
                                                                {
                                                                    value: "GS",
                                                                    label: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
                                                                },
                                                                {
                                                                    value: "GT",
                                                                    label: "جواتيمالا",
                                                                },
                                                                {
                                                                    value: "GU",
                                                                    label: "جوام",
                                                                },
                                                                {
                                                                    value: "GW",
                                                                    label: "غينيا بيساو",
                                                                },
                                                                {
                                                                    value: "GY",
                                                                    label: "غيانا",
                                                                },
                                                                {
                                                                    value: "HK",
                                                                    label: "هونج كونج الصينية",
                                                                },
                                                                {
                                                                    value: "HM",
                                                                    label: "جزيرة هيرد وماكدونالد",
                                                                },
                                                                {
                                                                    value: "HN",
                                                                    label: "هندوراس",
                                                                },
                                                                {
                                                                    value: "HR",
                                                                    label: "كرواتيا",
                                                                },
                                                                {
                                                                    value: "HT",
                                                                    label: "هايتي",
                                                                },
                                                                {
                                                                    value: "HU",
                                                                    label: "المجر",
                                                                },
                                                                {
                                                                    value: "ID",
                                                                    label: "اندونيسيا",
                                                                },
                                                                {
                                                                    value: "IE",
                                                                    label: "أيرلندا",
                                                                },
                                                                {
                                                                    value: "IL",
                                                                    label: "اسرائيل",
                                                                },
                                                                {
                                                                    value: "IM",
                                                                    label: "جزيرة مان",
                                                                },
                                                                {
                                                                    value: "IN",
                                                                    label: "الهند",
                                                                },
                                                                {
                                                                    value: "IO",
                                                                    label: "المحيط الهندي البريطاني",
                                                                },
                                                                {
                                                                    value: "IQ",
                                                                    label: "العراق",
                                                                },
                                                                {
                                                                    value: "IR",
                                                                    label: "ايران",
                                                                },
                                                                {
                                                                    value: "IS",
                                                                    label: "أيسلندا",
                                                                },
                                                                {
                                                                    value: "IT",
                                                                    label: "ايطاليا",
                                                                },
                                                                {
                                                                    value: "JE",
                                                                    label: "جيرسي",
                                                                },
                                                                {
                                                                    value: "JM",
                                                                    label: "جامايكا",
                                                                },
                                                                {
                                                                    value: "JO",
                                                                    label: "الأردن",
                                                                },
                                                                {
                                                                    value: "JP",
                                                                    label: "اليابان",
                                                                },
                                                                {
                                                                    value: "KE",
                                                                    label: "كينيا",
                                                                },
                                                                {
                                                                    value: "KG",
                                                                    label: "قرغيزستان",
                                                                },
                                                                {
                                                                    value: "KH",
                                                                    label: "كمبوديا",
                                                                },
                                                                {
                                                                    value: "KI",
                                                                    label: "كيريباتي",
                                                                },
                                                                {
                                                                    value: "KM",
                                                                    label: "جزر القمر",
                                                                },
                                                                {
                                                                    value: "KN",
                                                                    label: "سانت كيتس ونيفيس",
                                                                },
                                                                {
                                                                    value: "KP",
                                                                    label: "كوريا الشمالية",
                                                                },
                                                                {
                                                                    value: "KR",
                                                                    label: "كوريا الجنوبية",
                                                                },
                                                                {
                                                                    value: "KW",
                                                                    label: "الكويت",
                                                                },
                                                                {
                                                                    value: "KY",
                                                                    label: "جزر الكايمن",
                                                                },
                                                                {
                                                                    value: "KZ",
                                                                    label: "كازاخستان",
                                                                },
                                                                {
                                                                    value: "LA",
                                                                    label: "لاوس",
                                                                },
                                                                {
                                                                    value: "LB",
                                                                    label: "لبنان",
                                                                },
                                                                {
                                                                    value: "LC",
                                                                    label: "سانت لوسيا",
                                                                },
                                                                {
                                                                    value: "LI",
                                                                    label: "ليختنشتاين",
                                                                },
                                                                {
                                                                    value: "LK",
                                                                    label: "سريلانكا",
                                                                },
                                                                {
                                                                    value: "LR",
                                                                    label: "ليبيريا",
                                                                },
                                                                {
                                                                    value: "LS",
                                                                    label: "ليسوتو",
                                                                },
                                                                {
                                                                    value: "LT",
                                                                    label: "ليتوانيا",
                                                                },
                                                                {
                                                                    value: "LU",
                                                                    label: "لوكسمبورج",
                                                                },
                                                                {
                                                                    value: "LV",
                                                                    label: "لاتفيا",
                                                                },
                                                                {
                                                                    value: "LY",
                                                                    label: "ليبيا",
                                                                },
                                                                {
                                                                    value: "MA",
                                                                    label: "المغرب",
                                                                },
                                                                {
                                                                    value: "MC",
                                                                    label: "موناكو",
                                                                },
                                                                {
                                                                    value: "MD",
                                                                    label: "مولدافيا",
                                                                },
                                                                {
                                                                    value: "ME",
                                                                    label: "الجبل الأسود",
                                                                },
                                                                {
                                                                    value: "MF",
                                                                    label: "سانت مارتين",
                                                                },
                                                                {
                                                                    value: "MG",
                                                                    label: "مدغشقر",
                                                                },
                                                                {
                                                                    value: "MH",
                                                                    label: "جزر المارشال",
                                                                },
                                                                {
                                                                    value: "MK",
                                                                    label: "مقدونيا",
                                                                },
                                                                {
                                                                    value: "ML",
                                                                    label: "مالي",
                                                                },
                                                                {
                                                                    value: "MM",
                                                                    label: "ميانمار",
                                                                },
                                                                {
                                                                    value: "MN",
                                                                    label: "منغوليا",
                                                                },
                                                                {
                                                                    value: "MO",
                                                                    label: "ماكاو الصينية",
                                                                },
                                                                {
                                                                    value: "MP",
                                                                    label: "جزر ماريانا الشمالية",
                                                                },
                                                                {
                                                                    value: "MQ",
                                                                    label: "مارتينيك",
                                                                },
                                                                {
                                                                    value: "MR",
                                                                    label: "موريتانيا",
                                                                },
                                                                {
                                                                    value: "MS",
                                                                    label: "مونتسرات",
                                                                },
                                                                {
                                                                    value: "MT",
                                                                    label: "مالطا",
                                                                },
                                                                {
                                                                    value: "MU",
                                                                    label: "موريشيوس",
                                                                },
                                                                {
                                                                    value: "MV",
                                                                    label: "جزر الملديف",
                                                                },
                                                                {
                                                                    value: "MW",
                                                                    label: "ملاوي",
                                                                },
                                                                {
                                                                    value: "MX",
                                                                    label: "المكسيك",
                                                                },
                                                                {
                                                                    value: "MY",
                                                                    label: "ماليزيا",
                                                                },
                                                                {
                                                                    value: "MZ",
                                                                    label: "موزمبيق",
                                                                },
                                                                {
                                                                    value: "NA",
                                                                    label: "ناميبيا",
                                                                },
                                                                {
                                                                    value: "NC",
                                                                    label: "كاليدونيا الجديدة",
                                                                },
                                                                {
                                                                    value: "NE",
                                                                    label: "النيجر",
                                                                },
                                                                {
                                                                    value: "NF",
                                                                    label: "جزيرة نورفوك",
                                                                },
                                                                {
                                                                    value: "NG",
                                                                    label: "نيجيريا",
                                                                },
                                                                {
                                                                    value: "NI",
                                                                    label: "نيكاراجوا",
                                                                },
                                                                {
                                                                    value: "NL",
                                                                    label: "هولندا",
                                                                },
                                                                {
                                                                    value: "NO",
                                                                    label: "النرويج",
                                                                },
                                                                {
                                                                    value: "NP",
                                                                    label: "نيبال",
                                                                },
                                                                {
                                                                    value: "NR",
                                                                    label: "نورو",
                                                                },
                                                                {
                                                                    value: "NU",
                                                                    label: "نيوي",
                                                                },
                                                                {
                                                                    value: "NZ",
                                                                    label: "نيوزيلاندا",
                                                                },
                                                                {
                                                                    value: "OM",
                                                                    label: "عمان",
                                                                },
                                                                {
                                                                    value: "PA",
                                                                    label: "بنما",
                                                                },
                                                                {
                                                                    value: "PE",
                                                                    label: "بيرو",
                                                                },
                                                                {
                                                                    value: "PF",
                                                                    label: "بولينيزيا الفرنسية",
                                                                },
                                                                {
                                                                    value: "PG",
                                                                    label: "بابوا غينيا الجديدة",
                                                                },
                                                                {
                                                                    value: "PH",
                                                                    label: "الفيلبين",
                                                                },
                                                                {
                                                                    value: "PK",
                                                                    label: "باكستان",
                                                                },
                                                                {
                                                                    value: "PL",
                                                                    label: "بولندا",
                                                                },
                                                                {
                                                                    value: "PM",
                                                                    label: "سانت بيير وميكولون",
                                                                },
                                                                {
                                                                    value: "PN",
                                                                    label: "بتكايرن",
                                                                },
                                                                {
                                                                    value: "PR",
                                                                    label: "بورتوريكو",
                                                                },
                                                                {
                                                                    value: "PS",
                                                                    label: "فلسطين",
                                                                },
                                                                {
                                                                    value: "PT",
                                                                    label: "البرتغال",
                                                                },
                                                                {
                                                                    value: "PW",
                                                                    label: "بالاو",
                                                                },
                                                                {
                                                                    value: "PY",
                                                                    label: "باراجواي",
                                                                },
                                                                {
                                                                    value: "QA",
                                                                    label: "قطر",
                                                                },
                                                                {
                                                                    value: "RE",
                                                                    label: "روينيون",
                                                                },
                                                                {
                                                                    value: "RO",
                                                                    label: "رومانيا",
                                                                },
                                                                {
                                                                    value: "RS",
                                                                    label: "صربيا",
                                                                },
                                                                {
                                                                    value: "RU",
                                                                    label: "روسيا",
                                                                },
                                                                {
                                                                    value: "RW",
                                                                    label: "رواندا",
                                                                },
                                                                {
                                                                    value: "SA",
                                                                    label: "المملكة العربية السعودية",
                                                                },
                                                                {
                                                                    value: "SB",
                                                                    label: "جزر سليمان",
                                                                },
                                                                {
                                                                    value: "SC",
                                                                    label: "سيشل",
                                                                },
                                                                {
                                                                    value: "SD",
                                                                    label: "السودان",
                                                                },
                                                                {
                                                                    value: "SE",
                                                                    label: "السويد",
                                                                },
                                                                {
                                                                    value: "SG",
                                                                    label: "سنغافورة",
                                                                },
                                                                {
                                                                    value: "SH",
                                                                    label: "سانت هيلنا",
                                                                },
                                                                {
                                                                    value: "SI",
                                                                    label: "سلوفينيا",
                                                                },
                                                                {
                                                                    value: "SJ",
                                                                    label: "سفالبارد وجان مايان",
                                                                },
                                                                {
                                                                    value: "SK",
                                                                    label: "سلوفاكيا",
                                                                },
                                                                {
                                                                    value: "SL",
                                                                    label: "سيراليون",
                                                                },
                                                                {
                                                                    value: "SM",
                                                                    label: "سان مارينو",
                                                                },
                                                                {
                                                                    value: "SN",
                                                                    label: "السنغال",
                                                                },
                                                                {
                                                                    value: "SO",
                                                                    label: "الصومال",
                                                                },
                                                                {
                                                                    value: "SR",
                                                                    label: "سورينام",
                                                                },
                                                                {
                                                                    value: "SS",
                                                                    label: "جنوب السودان",
                                                                },
                                                                {
                                                                    value: "ST",
                                                                    label: "ساو تومي وبرينسيبي",
                                                                },
                                                                {
                                                                    value: "SV",
                                                                    label: "السلفادور",
                                                                },
                                                                {
                                                                    value: "SX",
                                                                    label: "سينت مارتن",
                                                                },
                                                                {
                                                                    value: "SY",
                                                                    label: "سوريا",
                                                                },
                                                                {
                                                                    value: "SZ",
                                                                    label: "سوازيلاند",
                                                                },
                                                                {
                                                                    value: "TC",
                                                                    label: "جزر الترك وجايكوس",
                                                                },
                                                                {
                                                                    value: "TD",
                                                                    label: "تشاد",
                                                                },
                                                                {
                                                                    value: "TF",
                                                                    label: "المقاطعات الجنوبية الفرنسية",
                                                                },
                                                                {
                                                                    value: "TG",
                                                                    label: "توجو",
                                                                },
                                                                {
                                                                    value: "TH",
                                                                    label: "تايلند",
                                                                },
                                                                {
                                                                    value: "TJ",
                                                                    label: "طاجكستان",
                                                                },
                                                                {
                                                                    value: "TK",
                                                                    label: "توكيلو",
                                                                },
                                                                {
                                                                    value: "TL",
                                                                    label: "تيمور الشرقية",
                                                                },
                                                                {
                                                                    value: "TM",
                                                                    label: "تركمانستان",
                                                                },
                                                                {
                                                                    value: "TN",
                                                                    label: "تونس",
                                                                },
                                                                {
                                                                    value: "TO",
                                                                    label: "تونجا",
                                                                },
                                                                {
                                                                    value: "TR",
                                                                    label: "تركيا",
                                                                },
                                                                {
                                                                    value: "TT",
                                                                    label: "ترينيداد وتوباغو",
                                                                },
                                                                {
                                                                    value: "TV",
                                                                    label: "توفالو",
                                                                },
                                                                {
                                                                    value: "TW",
                                                                    label: "تايوان",
                                                                },
                                                                {
                                                                    value: "TZ",
                                                                    label: "تانزانيا",
                                                                },
                                                                {
                                                                    value: "UA",
                                                                    label: "أوكرانيا",
                                                                },
                                                                {
                                                                    value: "UG",
                                                                    label: "أوغندا",
                                                                },
                                                                {
                                                                    value: "UM",
                                                                    label: "جزر الولايات المتحدة البعيدة الصغيرة",
                                                                },
                                                                {
                                                                    value: "US",
                                                                    label: "الولايات المتحدة الأمريكية",
                                                                },
                                                                {
                                                                    value: "UY",
                                                                    label: "أورجواي",
                                                                },
                                                                {
                                                                    value: "UZ",
                                                                    label: "أوزبكستان",
                                                                },
                                                                {
                                                                    value: "VA",
                                                                    label: "الفاتيكان",
                                                                },
                                                                {
                                                                    value: "VC",
                                                                    label: "سانت فنسنت وغرنادين",
                                                                },
                                                                {
                                                                    value: "VE",
                                                                    label: "فنزويلا",
                                                                },
                                                                {
                                                                    value: "VG",
                                                                    label: "جزر فرجين البريطانية",
                                                                },
                                                                {
                                                                    value: "VI",
                                                                    label: "جزر فرجين الأمريكية",
                                                                },
                                                                {
                                                                    value: "VN",
                                                                    label: "فيتنام",
                                                                },
                                                                {
                                                                    value: "VU",
                                                                    label: "فانواتو",
                                                                },
                                                                {
                                                                    value: "WF",
                                                                    label: "جزر والس وفوتونا",
                                                                },
                                                                {
                                                                    value: "WS",
                                                                    label: "ساموا",
                                                                },
                                                                {
                                                                    value: "XK",
                                                                    label: "كوسوفو",
                                                                },
                                                                {
                                                                    value: "YE",
                                                                    label: "اليمن",
                                                                },
                                                                {
                                                                    value: "YT",
                                                                    label: "مايوت",
                                                                },
                                                                {
                                                                    value: "ZA",
                                                                    label: "جمهورية جنوب افريقيا",
                                                                },
                                                                {
                                                                    value: "ZM",
                                                                    label: "زامبيا",
                                                                },
                                                                {
                                                                    value: "ZW",
                                                                    label: "زيمبابوي",
                                                                }
                                                            ]}/>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input className="w-100" onChange={(e) => {
                                                            setReligion(e.target.value)
                                                        }} value={getReligion} type="text" placeholder="الدين والمذهب"
                                                               maxLength="500"/>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input className="w-100" onChange={(e) => {
                                                            setTitle(e.target.value)
                                                        }} value={getTitle} type="text" required placeholder="الموضوع"
                                                               maxLength="500"/>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <input className="w-100 email-input" onChange={(e) => {
                                                            setEmail(e.target.value)
                                                        }} value={getEmail} type="email" placeholder="البريد الالكتروني"
                                                               required/>
                                                    </div>
                                                    <div className="col-12">
                                                        <input className="w-100" onChange={(e) => {
                                                            setBody(e.target.value)
                                                        }} value={getBody} type="text" placeholder="نص الرسالة:"
                                                               required/>
                                                    </div>
                                                    <div
                                                        className="col-12 push-bottom-in-form d-flex justify-content-sm-end justify-content-center align-items-center mt-5 ">
                                                        <button type="submit" title="إرسال رسالة"
                                                                aria-label="إرسال رسالة"
                                                                className="more-btn btn fw-light border-0">
                                                            إرسال رسالة
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                    }

                </div>
            </div>
            <div className={props.show ? "overlay-modal" : ""}></div>
        </>


    );
};

export default Forms;
